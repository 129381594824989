import React from 'react';
import type { Node } from 'react';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { ConceptBasic, ConceptDF } from '../articles/Concept';
import Literal from './Literal';
import type { OntologyConceptType } from './Types';
import { cx, interpose } from '../utils';
import Loading from '../Loading';


export function LoadedConceptBrief(
    { concept, className = '', actions = () => null }:
    {
        concept: OntologyConceptType & { df: number },
        className?: string,
        actions?: (concept: OntologyConceptType & { df: number }) => Node
    }
) {
    return <div className={cx('concept-brief', className)}>
        <ConceptDF concept={concept}/>
        <ConceptBasic concept={concept}/>
        <strong>Literals: </strong>
        {interpose(concept.literals.map(l => <Literal key={l.name} literal={l}/>), ', ')}
        <div className="actions" style={{marginTop: 20}}>
            {actions(concept)}
        </div>
    </div>;
}

export function ConceptBrief(
    { data: { loading, concept, error }, className, actions = () => null }:
    {
        data: { loading: boolean, concept: OntologyConceptType & { df: number }, error: any },
        className: string,
        actions?: (concept: OntologyConceptType) => Node,
    }
) {
    return <Loading what="concept" loading={loading} error={error}>
        {concept && <LoadedConceptBrief concept={concept} actions={actions} className={className} />}
    </Loading>
}

export default graphql(gql`
query concept($id: Int) {
    concept(id: $id) {
        id
        name
        literals {
            name
            composite
            lang
            isAmbiguous
        }
        df
        basic
        deleted
    }
}
`)(ConceptBrief);
