import React from 'react';


export function SelectOptionsFromDict({dict}) {
    return Object.entries(dict).map(([value, name], i) =>
        <option key={i} value={value}>{name}</option>);
}


export function SelectOptionsFromList({list, value, name, defaultValue, defaultLabel}) {
    const result = list.map((item, i) =>
        <option key={i+1} value={item[value]}>{item[name]}</option>);
    result.unshift(<option key={0} value={defaultValue}>{defaultLabel}</option>)
    return result;
}


export function SelectOptGroupsFromList({list, value, name, optGroupName, defaultValue, defaultLabel}) {
    const optGroupNames = new Set(list.map(item => item[optGroupName]));
    if (optGroupNames.size === 1) {
        return <SelectOptionsFromList list={list}
                                      value={value}
                                      name={name}
                                      defaultValue={defaultValue}
                                      defaultLabel={defaultLabel} />
    }

    const optGroup2items = list.reduce((acc, item, i) => {
        if (!acc.has(item[optGroupName])) {
            acc.set(item[optGroupName], []);
        }
        const optGroupList = acc.get(item[optGroupName]);
        optGroupList.push(<option key={i} value={item[value]}>{item[name]}</option>);
        acc.set(item[optGroupName], optGroupList);
        return acc;
    }, new Map())

    const result = []
    optGroup2items.forEach((options, optGroup) => {
        if (optGroup) {
            result.push(<optgroup key={optGroup} label={optGroup}>{options}</optgroup>)
        } else {
            result.push(options)
        }
    })

    result.unshift(<option key={0} value={defaultValue}>{defaultLabel}</option>)
    return result;
}
