import queryString from 'query-string';

import type {
    ProposalType, ArticleType, SettingsType
} from './Types';
import type { FindClaimArticleType } from '../articles/Types';
import { sendRequest } from '../datastore';
import React from 'react';


const TOPIC_STATUSES = {
    included: 0,
    required: 1,
    excluded: 2
};


// Sync with prophy.affiliations.models.InstitutionType
const INSTITUTION_TYPE_VALUE2NAME = {
    '': 'All',
    'education_research': 'Education/research',
    'commercial': 'Commercial',
    'healthcare': 'Healthcare',
    'cultural': 'Cultural',
    'other': 'Other',
}


// Sync with prophy.articles.models.ArticleAuthor
const CONTRIBUTION_IN_ARTICLE_VALUE2NAME = {
    '': 'Any',
    'own_works': 'Own works (≤2 authors or leading/senior/corresponding)',
    'leading_author': 'Leading author',
    'senior_author': 'Senior author',
    'corresponding_author': 'Corresponding author',
}


const GENDER_VALUE2NAME = {
    '': 'All',
    'F': 'Female',
    'f': 'Female and undetermined',
    '?': 'Undetermined',
    'm': 'Male and undetermined',
    'M': 'Male',
}


const STRING_FACETS_KEYS = [
    'gender',
    'refereed_author_contribution_in_article',
    'institution_type',
    'contribution_in_article',
    'authors_group_effect',
]


const LIST_FACETS_KEYS = [
    'include_countries',
    'exclude_countries',
    'authors_groups_ids',
]


const helpers = {
    stringParamToBool(stringParam: string): boolean {
        return ['1', 'true', 't', true].indexOf(stringParam) !== -1;
    },
    settingsToQS(settings: SettingsType, settingsId: number, onlyDefined: boolean = false): string {
        let keys = Object.keys(settings);

        if (onlyDefined) {
            keys = keys.filter(k => settings[k] !== null);
        }

        let qs = keys.map((k) => {
            if (k === 'topics') {
                return `${k}=${JSON.stringify(settings[k])}`;
            }

            if (Array.isArray(settings[k])) {
                return `${k}=${settings[k].join(',')}`;
            }

            // boolean "true" values were not being sent to the server without this conversion
            if (typeof settings[k] === 'boolean') {
                return `${k}=${settings[k] ? '1' : '0'}`;
            }

            return `${k}=${settings[k]}`;
        }).filter(part => !!part).join('&');

        if (settingsId) {
            qs = qs + `&settingsId=${settingsId}`;
        }

        return qs;
    },
    settingsToQSDefined(settings: SettingsType, settingsId: number): string {
        return helpers.settingsToQS(settings, settingsId, true);
    },
    defaultSettings: {
        algorithm: 3,
        candidates_cache_limit: null,
        nearest_articles_limit: null,

        top_author_articles: 10,
        articles_years_limit: 10,
        own_works: false,
        min_h_index: null,
        max_h_index: null,
        min_academic_age: null,
        max_academic_age: null,
        min_articles_count: null,
        max_articles_count: null,
        min_jif: null,
        max_jif: null,
        max_jif_percentile: null,
        gender: '',
        institution_type: '',
        contribution_in_article: '',
        ignore_topics: true,
        topics: null,

        ignore_countries: true,
        include_countries: [],
        exclude_countries: [],
        years_of_affiliation: 2,

        topic_boost_multiplier: 8,

        ignore_authors_group: true,
        authors_groups_ids: [],
        authors_group_effect: 'highlight',

        coauthorship_coi_years: 3,
        exclude_coi: null,

        email_years_limit: null,
        diversify: false,
        exclude_authors_with_retracted_articles: false,

        // for doc_kind === 'author'
        refereed_author_articles_written_since_year: null,
        refereed_author_contribution_in_article: '',
        refereed_author_include_consortiums: false,
        refereed_author_article_max_authors: null,
    },
    settingsFromQS(query: string): SettingsType {
        const parsed = queryString.parse(query);
        const parseBoolValue = (parsed, k) => {
            return helpers.stringParamToBool(parsed[k]);
        }
        const parseStringValue = (parsed, k) => {
            return parsed[k];
        }

        return Object.keys(this.defaultSettings).reduce((config, k) => {
            if (parsed[k] !== undefined) {
                config[k] = parseInt(parsed[k], 10) || null;
                const key = k.toLowerCase();

                if (STRING_FACETS_KEYS.indexOf(k) !== -1) {
                    config[k] = parseStringValue(parsed, k);
                }

                if (LIST_FACETS_KEYS.indexOf(k) !== -1) {
                    if (parsed[k] !== 'null' && parsed[k] !== '') {
                        config[k] = parsed[k].split(',').map(c => parseInt(c, 10));
                    } else {
                        config[k] = [];
                    }
                }

                if (key === 'refereed_author_include_consortiums' ||
                    key === 'diversify') {
                    config[k] = parseBoolValue(parsed, k);
                }

                if (key.indexOf('ignore') !== -1) {
                    config[k] = parseBoolValue(parsed, k);
                }

                if (key === 'topics' && parsed.topics) {
                    const parsedTopics = JSON.parse(parsed.topics);
                    if (Array.isArray(parsedTopics)) {
                        config.topics = parsedTopics;
                    }
                }
            }

            return config;
        }, {});
    },
    getUpdateForIntSetting(key: string, value: string|number) {
        const update = {};
        update[key] = parseInt(value, 10) || null;
        return update;
    },

    getDefaultSettings(doc: ProposalType | ArticleType | AuthorType | JobType<FindClaimArticleType>, docKind) {
        let settings = {...helpers.defaultSettings};
        if (docKind === 'author') {
            settings = {
                ...settings,
                top_author_articles: null,
            };
        }
        else if (docKind === 'proposal') {
            settings = {
                ...settings,
                ...doc.folder.refereeFinderSettings
            };
        }
        else if (docKind === 'folder') {
            settings = {
                ...settings,
                ...doc.refereeFinderSettings
            };
        }
        else if (docKind === 'saved_search') {
            settings = {
                ...settings,
                ...doc.refereeFinderSettings
            };
        }
        else if (docKind === 'vacancy') {
            settings = {
                ...settings,
                candidates_cache_limit: 10000,
            };
        }
        return settings;
    },

    mergeSettingsState(newSettings, defaultSettings) {

        const result = {
            ...defaultSettings,
            ...newSettings,
        };

        if (result.ignore_topics === undefined || result.ignore_topics === null) {
            result.ignore_topics = !result.topics;
        }
        if (result.ignore_countries === undefined || result.ignore_countries === null) {
            result.ignore_countries = !result.include_countries && !result.exclude_countries;
        }
        if (result.ignore_authors_group === undefined || result.ignore_authors_group === null) {
            result.ignore_authors_group = !result.authors_groups_ids;
        }

        return {
            settings: result,
            customArticlesYearsLimit:
                [null, 5, 10].indexOf(result.articles_years_limit || null) !== -1
                ? null : result.articles_years_limit,
            customArticlesYearsLimitChecked: [null, 5, 10].indexOf(result.articles_years_limit) === -1,
        };
    },

    hideReferees(docKind, docId, authorsIds, reason, comment) {
        const url = docKind === 'panel' ?
            `/api/panel/${docId}/hide-candidates/`
            : `/api/referees/${docKind}/${docId}/hide-referees/`;
        return sendRequest(
            url,
            { method: 'POST', body: JSON.stringify({ authorsIds, reason, comment }) }
        );
    },
    EXCLUDE_REASONS: {
        died_retired: 'Person is not active anymore',
        conflict_of_interest: 'Conflict of interest',
        not_relevant: 'Person is not relevant',
        not_suitable_by_judgment: 'Not suitable (professional judgment)',
        other: 'Other reason',

        inactive_author: 'Author is inactive',
        big_author: 'There are probably several authors in the author\'s profile',
        document_author: 'This person is an author of the refereed manuscript',
        small_h_index: 'H-index is too small',
        big_h_index: 'H-index is too big',
        small_academic_age: 'Academic age is too small',
        big_academic_age: 'Academic age is too big',
        small_articles_count: 'Small articles count',
        big_articles_count: 'Big articles count',
        panel_coauthor: 'This person is an author of another manuscript in this folder',
        excluded_gender: 'Filtered by gender filter',
        excluded_institution_type: 'Filtered by institution filter',
        excluded_country: 'Filtered by country filter',
        no_email: 'Author has no email in profile',
        has_retracted_articles: 'Author has published retracted articles',
    },
    HIDE_REASONS: {
        died_retired: {
            label: 'Person is not active anymore',
            commentRequired: false,
            explanation: 'Person is not active anymore',
        },
        conflict_of_interest: {
            label: 'Conflict of interest',
            commentRequired: false,
            explanation: 'Conflict of interest',
        },
        not_relevant: {
            label: 'Should not be relevant…',
            commentRequired: true,
            explanation: 'Person is not relevant',
        },
        not_suitable_by_judgment: {
            label: 'Not suitable (professional judgment)',
            commentRequired: false,
            explanation: 'Not suitable (professional judgment)',
        },
        other: {
            label: 'Other…',
            commentRequired: true,
            explanation: 'Other reason',
        },
    },
    REFEREES_TABLE_ROW_SIZES: {
        normal: 'normal',
        condensed: 'condensed'
    },

    urlifyTopics(topics) {
        return topics.map(t => {
            const conceptIds = t.concepts.map(c => parseInt(c.conceptId, 10));

            return {
                status: t.required ?
                    helpers.TOPIC_STATUSES.required
                    : t.disabled ?
                        helpers.TOPIC_STATUSES.excluded
                        : helpers.TOPIC_STATUSES.included,
                ids: conceptIds
            };
        });
    },
    TOPIC_STATUSES: TOPIC_STATUSES,
    INSTITUTION_TYPE_VALUE2NAME: INSTITUTION_TYPE_VALUE2NAME,
    GENDER_VALUE2NAME: GENDER_VALUE2NAME,
    CONTRIBUTION_IN_ARTICLE_VALUE2NAME: CONTRIBUTION_IN_ARTICLE_VALUE2NAME,
    optionsFromDict: (dict) => {
        return Object.entries(dict).map(([value, name]) =>
            <option value={value}>{name}</option>)
    }
};

export default helpers;
