import React from 'react';

import type { ApplicationType } from './Types';
import { cx } from '../utils';


const formatType2Abbr = {
    'cv': 'CV',
    'coverLetter': 'cover letter',
    'publicationList': 'publication list',
    'researchStatement': 'research statement',
    'teachingStatement': 'teaching statement',
};


export default function ApplicationAttachments(
    { application, className = '', listType = 'list' }
    : { application: ApplicationType, className?: string, listType?: 'list' | 'inline' }
) {
    const classes = cx('application-attachments', className, {
        'list-type-inline': listType === 'inline'
    });

    return <>
        <ul className={classes}>
            {application.attachments.map(attachment => <li key={attachment.id}>
                <a href={`/api/jobs/attachment/${attachment.id}/${attachment.filename}`}
                   target="_blank"
                   rel="noopener noreferrer">
                    {formatType2Abbr[attachment.formatType]}</a>
            </li>)}
        </ul>
    </>
}
