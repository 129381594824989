import React from 'react';
import DocumentTitle from 'react-document-title';

import { ConceptList } from '../articles/Concept';
import Collapsible from '../widgets/Collapsible';


export default function RefereedJob({ job }) {
    return <>
        <DocumentTitle title={`Possible Candidates - ${job.title}`} />
        <h2>
            <a href={job.id ? `/jobs/${job.id}/` : undefined}>
                {job.title}
            </a>
        </h2>
        <Collapsible>
            <div>{job.abstract}</div>
        </Collapsible>
        {!!job.occurrences && !!job.occurrences.length &&
            <h3>Concepts<sup>{job.occurrences.length}</sup></h3>
        }
        <ConceptList concepts={job.occurrences} collapsible />
        <div>&nbsp;</div>
    </>;
}
