import React, { Fragment } from 'react';
import type { Node } from 'react';

import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';

import './text-with-tooltip.css';


export default function TextWithTooltip(
    { id, help, className, children }
    : { id: string, help: Node, className?: string, children: Node }
) {
    const tooltip = <Tooltip id={id} className={className}>
        {help}
    </Tooltip>;

    return <Fragment>
        <OverlayTrigger overlay={tooltip} placement="bottom">
            <span className="text-with-tooltip">{children}</span>
        </OverlayTrigger>
    </Fragment>;
}
