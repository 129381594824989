import React from 'react';

import ApplicantAdvisor from './ApplicantAdvisor';
import AdvisorApplicant from './AdvisorApplicant';
import Rank from './Rank';
import { roundInteger, toFixed } from '../utils';
import SocialLabels from './SocialLabels';
import VoteButton from './VoteButton';
import ApplicantComment from './ApplicantComment';
import ApplicantTags from './ApplicantTags';

import GenderIndicator from '../common/GenderIndicator';


export default function JobApplicantRow(
    { applicant, index, onSelectionChange, isSelected }
) {
    const staticData = React.useMemo(function() {
        return  <>
        <td className="name">
            {/* eslint-disable-next-line */}
            <a className="row-anchor" id={`applicant-${applicant.applicationId}`}></a>
            <GenderIndicator authorId={applicant.authorId}
                             gender={applicant.gender}
                             manualGender={applicant.manualGender} />
            <AdvisorApplicant id={applicant.applicationId} short />
            &nbsp;<SocialLabels person={applicant} /><br/>
            <ApplicantTags applicant={applicant} />
        </td>
        <td className="reference">
            {applicant.advisors.map(advisor => (
                <div key={advisor.groupId}>
                    <ApplicantAdvisor advisor={advisor} />
                </div>
            ))}
        </td>
        <td className="text-right">
            {!!applicant.rank && <Rank person={applicant} />}
        </td>
        <td className="text-right">{applicant.similarityCombined && toFixed(applicant.similarityCombined, 2)}</td>
        <td className="text-right">{applicant.similarityMax && toFixed(applicant.similarityMax, 2)}</td>
        <td className="text-right">
            {applicant.authorId 
                ? <a href={`/author/${applicant.authorId}?inConsortium=0`}>{applicant.articlesCount}</a>
                : applicant.articlesCount}
        </td>
        <td className="text-right">
            {applicant.academicAge}
        </td>
        <td className="text-right notable-articles-count">
            {applicant.authorId && applicant.notableArticlesCount > 0
                ? <a href={`/author/${applicant.authorId}?inConsortium=0&notability=${applicant.notableArticlesCount}`}>
                    {applicant.notableArticlesCount}</a>
                : applicant.notableArticlesCount}
        </td>
        <td className="text-right">
            {applicant.hIndex}
        </td>
        <td className="text-right">
            {applicant.citationsCount && roundInteger(applicant.citationsCount)}
        </td>
        <td className="votes">
            <VoteButton applicant={applicant} />
            <ApplicantComment applicant={applicant} />
        </td>
    </>}, [applicant]);

    return <tr>
        <td className="check">
            <div className="cell-wrapper">
                <input type="checkbox"
                       onChange={() => onSelectionChange(applicant.applicationId)}
                       checked={isSelected}
                />
            </div>
        </td>
        <td className="id">
            {index + 1}
        </td>
        {staticData}
    </tr>;
};
