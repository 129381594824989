import React from 'react';

import { useTrack } from '../datastore';
import ClaimArticles from '../articles/ClaimArticles';
import type { ReactRouterMatchType } from '../common/Types';
import LoadingREST from '../LoadingREST';
import { makeJobAdvisorByAttorneyTokenQuery } from './JobData';


export default function ClaimAdvisorArticles(
    { match }
    : { match: ReactRouterMatchType }
) {
    const [attorney, reqstate] = useTrack(
        makeJobAdvisorByAttorneyTokenQuery(match.params.attorneyToken)
    );

    return <LoadingREST doc={attorney} loadingString="Please wait" reqstate={reqstate}>
        <ClaimArticles person={attorney} />
    </LoadingREST>;
}
