import * as React from 'react';
import Reflux from 'reflux';

import FormGroup from 'react-bootstrap/lib/FormGroup';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Popover from 'react-bootstrap/lib/Popover';
import SafeAnchor from 'react-bootstrap/lib/SafeAnchor';

import parse from './parser';
import type { ParsedTreeNodeType } from './parser';
import {
    ChosenRecommendationTopicsActions, ChosenRecommendationTopicsStore
} from './ChosenRecommendationTopicsStore';
import type { RecommendationTopicType } from './RecommendationTopic';
import { getNotUsedTopics } from '../common/helpers/recommendationTopics';
import { SuggestBox } from '../widgets/Suggest';
import type { SuggestType } from '../widgets/Suggest';
import type { SuggestsResponseType } from '../widgets/Suggest';
import LRUCache from '../LRUCache';
import { cx, KEYS } from '../utils';


/* eslint-disable no-use-before-define */
type TopicIdType = {|
    topicId: number,
    unary?: string
|};

type GroupType = {|
    condition: string,
    unary?: string,
    children: Array<TreeNodeType>
|};

export type TreeNodeType = GroupType | TopicIdType;
/* eslint-enable no-use-before-define */


export default class AdvancedRecommendation extends Reflux.Component {
    input: ?HTMLInputElement;

    state: {
        topics: Array<RecommendationTopicType>,
        topicNames: Array<string>,
        tree: TreeNodeType,
        suggestCache: any,
        error: string,
    } = {
        topics: [],
        topicNames: [],
        tree: { condition: 'OR', children: [] },
        suggestCache: new LRUCache(),
        error: '',
    };

    constructor(props: {}) {
        super(props);

        this.mapStoreToState(ChosenRecommendationTopicsStore, fromStore => {
            const obj = {};

            if (fromStore.topics) {
                obj.topics = fromStore.topics;
                obj.topicNames = fromStore.topics.map(t => t.title);
            }

            if (fromStore.tree) {
                obj.tree = fromStore.tree;
            }

            return obj;
        });
    }

    handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (this.input) {
            const tree = this.handleSearchSuggestSelect({ value: this.input.value });

            if (tree) {
                this.props.onApply(tree);
            }
        }
    };

    stringify(node: TreeNodeType, top: boolean = false) {
        let result;

        if (typeof node.topicId === 'number') {
            result = this.state.topics[node.topicId].title;

            if (result.indexOf(' ') !== -1) {
                result = `"${result}"`;
            }
        } else if (
            typeof node.children === 'object' &&
            typeof node.condition === 'string' &&
            Array.isArray(node.children)
        ) {
            const children = node.children;
            const condition = node.condition;

            result = children
                .map(c => this.stringify(c))
                .join(` ${condition} `);

            if (!top && children.length > 1) {
                result = `(${result})`;
            }
        } else {
            throw new Error('Unexpected node shape');
        }

        if (node.unary) result = `${node.unary.toUpperCase()} ${result}`;

        return result;
    }

    transformTree(tree: ParsedTreeNodeType) {
        const transformNode = (node: ParsedTreeNodeType): TreeNodeType => {
            if (node.name !== undefined) {
                const rawName = node.name;
                const name = node.name.replace(/^"(.+)"$/, '$1').toLowerCase();

                for (let i = 0; i < this.state.topics.length; i++) {
                    if (this.state.topics[i].title.toLowerCase() === name) {
                        return {
                            topicId: i,
                            unary: node.unary,
                        };
                    }
                }

                throw new Error(`Topic '${rawName}' not found.`);
            } else {
                return {
                    condition: node.condition,
                    unary: node.unary,
                    children: node.children.map(transformNode)
                };
            }
        }

        return transformNode(tree);
    }

    handleSearchSuggestSelect = (suggest: any) => {
        let tree;
        // parse suggest into tree and save it to state
        try {
            const value = suggest.value || (this.input ? this.input.value : '');
            tree = this.transformTree(
                parse(value)
            );

            if (tree && tree.unary) {
                throw new Error('Having unary (NOT, BOOST, NEGATE) at query root is ' +
                    'incorrect and is likely to lead to unexpected results.');
            }

            ChosenRecommendationTopicsActions.setTree(tree);
            this.setState({ error: '' });
        } catch (e) {
            this.setState({
                error: e.message
            });
        }

        if (this.input) {
            setTimeout(() => { if (this.input) this.input.focus() }, 0);
        }

        return tree;
    }

    setInput = (el: ?HTMLInputElement) => {
        this.input = el;
    }

    getSuggestions = (query: string): Promise<SuggestsResponseType> => {
        function isCondition(str: string) {
            return str === 'or' || str === 'and';
        }

        function isTopic(str: string) {
            return topicNames
                .indexOf(str.replace(/^"(.+?)"$/, '$1').toLowerCase()) !== -1;
        }

        function formatSuggests(
            suggests: Array<SuggestType>,
            query: string,
            match: string
        ) {
            const quoted = match.indexOf('"') !== -1;
            const closingMatch = query.match(/(\)+)$/);
            const oldInput = query.slice(
                0,
                query.toLowerCase().lastIndexOf(match.toLowerCase())
            ).trim();

            const results = suggests.map(s => {
                // enquote terms that require it
                const prediction = quoted || s.label.indexOf(' ') !== -1 ?
                    `"${s.label}"`
                    : s.label;
                let label = [oldInput, prediction].join(' ').trim();

                if (closingMatch && !isCondition(s.label)) {
                    label = label + closingMatch[0];
                }

                return { ...s, label, value: label, id: label };
            });

            return { results, total: results.length };
        }

        function getLastEntered(query: string) {
            const hasQuotesMatch = query.match(/"/g);
            let last;

            if (hasQuotesMatch && hasQuotesMatch.length % 2) {
                // if quotes unbalanced, consider substring after
                // last quotation mark as last term
                last = query.substr(query.lastIndexOf('"'));
            } else {
                // following regex works only with balanced quotes
                const entities = query.split(/\b(or|and)\b(?=(?:[^"]*"[^"]*")*[^"]*$)/i)
                    .map(p => p.replace(/^\s+|[()]+/g, ''));

                last = entities[entities.length - 1].replace(
                    /^(not|neg\d?|negate\d?|boost\d?)\s*(.*)/i, '$2');

                const closingMatch = query.match(/(\)+)$/);
                const lastBeforeClosing = (entities.slice().reverse().find(e => e) || '')
                    .toLowerCase();

                // user may have entered closing parenthesis after AND|OR
                // this is obviously an error, we won't do any predictions then
                if (closingMatch && isCondition(lastBeforeClosing)) {
                    throw new Error('Misplaced parenthesis');
                }
            }

            return last;
        }

        function getConditionSuggests(last: string) {
            let match = '';
            const lastWords = last.split(/\s+/);
            const prevWord = lastWords[lastWords.length - 1].toLowerCase();
            const candidatesForLastMeaningful = [
                last.trim(),
                prevWord,
                lastWords.slice(0, lastWords.length - 1).join(' ').trim()
            ];
            let suggests = [];

            for (let i = 0; i < candidatesForLastMeaningful.length; i++) {
                if (isTopic(candidatesForLastMeaningful[i])) {
                    match = prevWord;
                    suggests = [{ label: 'and' }, { label: 'or' }]
                        .filter(s => s.label.indexOf(prevWord) === 0);

                    break;
                }
            }

            return { suggests, match };
        }

        const topicNames = this.state.topicNames.map(t => t.toLowerCase());

        const topicsStartingWith = (str: string) => {
            let options = this.state.topics
                .map((t, i) => ({ label: t.title }));
            const hasQuote = str.indexOf('"') !== -1;

            // we must ignore double quote when filtering; the only place
            // it matters is whether we want to suggest NOT or not
            if (!hasQuote) {
                options = options.concat(
                    [{ label: 'not' }, { label: 'boost' }, { label: 'negate' }]);
            } else {
                str = str.replace('"', '');
            }

            return options
                .filter(t => t.label.toLowerCase().indexOf(str.toLowerCase()) === 0);
        };

        return new Promise((resolve, reject) => {
            let suggests = [];
            let last;

            try {
                last = getLastEntered(query);
            } catch (e) {
                // must be misplaced parenthesis, exit early
                resolve({results: [], total: 0});
                return;
            }

            let match = last;

            suggests = topicsStartingWith(last);

            if (!suggests.length) {
                // if all chunk starting from last AND/OR doesn't match
                // any topic, user may have started typing AND/OR. We
                // search for last meaningful term in last chunk to ensure
                // that user wants to add AND/OR
                const lastWords = last.split(/\s+/);
                const prevWord = lastWords[lastWords.length - 1].toLowerCase();
                ({ match, suggests } = getConditionSuggests(last));

                if (!suggests.length && isCondition(prevWord)) {
                    suggests = topicsStartingWith(last.trim());
                    match = last.trim();
                }
            }

            resolve(formatSuggests(
                suggests.map(s => ({
                    id: s.label, type: 'node', value: s.label, ...s
                })),
                query,
                match
            ));
        });
    }

    handleSuggestKeyDown = (e) => {
        if (e.which === KEYS.ENTER) {
            this.handleSubmit(e);
        }
    };

    handleChange = (e) => {
        return this.handleSearchSuggestSelect({ value: e.currentTarget.value });
    };

    render() {
        const notUsedTopics = getNotUsedTopics(
            this.state.topics, this.state.tree
        ).map(t => t.title);
        const suggestWrapperClasses = cx('suggest-group', {
            'has-error': !!this.state.error,
            'has-warning': !!notUsedTopics.length,
        });

        const overlay = (
            <Popover id="help-popover"
                     title="Advanced monitoring">
                <p>
                By default, monitoring is created on union of all topics.
                In other words, if you defined three topics (Topic1, Topic2
                and Topic3), monitoring will be created using &quot;Topic1
                OR Topic2 OR Topic3&quot; condition. If you need more control over
                monitoring, you can write your own query by grouping topics
                in a different manner, like &quot;Topic1 OR Topic2 AND Topic3&quot;,
                which would result in articles always having concepts from Topic3
                and ones from Topic1 or Topic2.
                For topics containing whitespace, please use double quotes.
                </p>
                <p>You can use the following operators here:</p>
                <ul>
                    <li>AND</li>
                    <li>OR</li>
                    <li>NOT</li>
                    <li>BOOST</li>
                    <li>NEGATE</li>
                </ul>
            </Popover>
        );

        return <div>
            <p>
                Compose advanced monitoring by combining topics
                together under AND/OR/NOT conditions.
            </p>
            <OverlayTrigger trigger="click"
                            placement="right"
                            overlay={overlay}
                            rootClose={true}>
                <SafeAnchor componentClass="button"
                            className="get-help inline-link"
                            title="Click to get help">
                    Need help?
                </SafeAnchor>
            </OverlayTrigger>
            <form onSubmit={this.handleSubmit}>
                <FormGroup className={suggestWrapperClasses}>
                    <SuggestBox
                        maxLabelLength={120}
                        delay={50}
                        callback={this.handleSearchSuggestSelect}
                        shouldClearOnSelect={false}
                        suggestFetch={this.getSuggestions}
                        divClasses="advanced-suggest"
                        showSuggestType={false}
                        selectOnExactMatch={true}
                        onKeyDown={this.handleSuggestKeyDown}
                        onChange={this.handleChange}
                        placeholder="E.g., DNA and protein"
                        initialValue={this.stringify(this.state.tree, true)}
                        inputRef={this.setInput}
                        requestCache={this.state.suggestCache} />
                    {this.state.error &&
                        <span className="help-block">{this.state.error}</span>
                    }
                    {!this.state.error &&
                        !!notUsedTopics.length &&
                        <span className="help-block">
                            The following topics are not used in this query:&nbsp;
                            {notUsedTopics.join(', ')}
                        </span>
                    }
                </FormGroup>
            </form>
        </div>;
    }
}
