import React from 'react';
import { ConceptList } from '../articles/Concept';

import { useRequest } from '../datastore';
import LoadingREST from '../LoadingREST';


export default function TopConceptCooccurences({ query, conceptId }) {
    const options = React.useMemo(() => ({
        method: 'POST',
        body: JSON.stringify({
            conceptId,
            selectedDate: query.selectedDate,
            topics: query.topics,
            tree: query.tree,
        }),
    }), [conceptId, query.selectedDate, query.topics, query.tree]);
    const [result, reqstate] = useRequest(`/api/monitoring/top-cooccurrence`, options);

    return <LoadingREST doc={result} reqstate={reqstate}>
        {result?.result && <>
            <p>
                Most frequently, in this collection &quot;{result.result.name}&quot;
                {' '}occurs with the following concepts:
            </p>
            <ConceptList
                concepts={result.result.topConcepts}
                popover={false}
                showDf
                collapsible
                collapsedCount={25}
                collapseThreshold={30}
            />
        </>}
    </LoadingREST>;
}
