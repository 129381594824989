import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';

import Alert from 'react-bootstrap/lib/Alert';
import Col from 'react-bootstrap/lib/Col';

import type { CurrentUserType } from '../accounts/Types';
import { checkStatus, cx } from '../utils';
import CurrentUser from '../accounts/CurrentUser';
import FileUpload from '../widgets/FileUpload';
import LoadingButton from '../widgets/LoadingButton';
import type { AuthorStatsType } from '../jobs/Types';

import AdvisorBrief from './AdvisorBrief';
import withCurrentUser from '../common/withCurrentUser';
import AuthorLink from '../authors/AuthorLink';
import DownloadFileButton from '../widgets/DownloadFileButton';
import './css/recommendation.css';


class ApplicantRecommendationForm extends React.Component<
{
    recommendation: {
        id: string,
        author?: AuthorStatsType,
        applicantName: string,
        applicantAuthorId: ?number,
        vacancyId: string,
        vacancyTitle: string,
        vacancyInstitution: string,
        recommendation: ?string
    },
    advisorUuid: string,
    currentUser: CurrentUserType
},
{
    attachment: any,
    saving: boolean,
    attachmentError: string,
    success: boolean,
    error: string
}
> {
    state = {
        attachment: null,
        saving: false,
        success: false,
        attachmentError: '',
        error: ''
    };

    handleRecommendationSelect = (file: any) => {
        this.setState({ attachment: file });
    }

    submit = () => {
        const attachment = this.state.attachment;

        if (!attachment) {
            this.setState({ attachmentError: 'This field is required.' });
            return;
        }

        const data = new FormData();
        data.append('advisorId', this.props.advisorUuid);
        data.append('recommendation', attachment);

        this.setState({
            saving: true,
            attachmentError: '',
            success: false,
            error: ''
        });

        return fetch('/api/jobs/upload-recommendation/', {
            method: 'POST',
            body: data,
            credentials: 'same-origin'
        }).then(response => {
            this.setState({ saving: false });

            return response;
        })
            .then(checkStatus)
            .then(response => response.json())
            .then(json => {
                if (json.ok) {
                    this.setState({
                        error: '',
                        success: true,
                    });
                } else {
                    this.setState({
                        error: json.error,
                        success: false
                    });
                }

                return json;
            });
    }

    renderAlerts() {
        return <Fragment>
            {this.state.error && <Alert bsStyle="danger">
                {this.state.error}
            </Alert>}
        </Fragment>;
    }

    renderSuccess() {
        const imageStyle = {
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
        }

        const textStyle = {
            color: 'green',
            textAlign: 'center',
            marginTop: '1em',
        }

        return <div>
            <img src="/static/images/success.png" alt="Success!" style={imageStyle} width="100px" height="100px"/>
            <div style={textStyle}>
                Success! We have attached your
                recommendation {this.state.attachment.name} to {this.props.recommendation.applicantName}'s
                application.
            </div>
        </div>
    }

    renderAlreadyUploaded() {
        const recommendation = this.props.recommendation;
        const containerStyle = {
            display: 'flex',
            alignItems: 'center',
        }
        const textStyle = {
            color: 'green',
            marginLeft: '1em',
        }

        return <div style={containerStyle}>
            <img src="/static/images/success.png" alt="Success!" width="75px" height="75px"/>
            <div style={textStyle}>
                <p>
                    You have already uploaded a recommendation&nbsp;
                    <DownloadFileButton to={`/api/jobs/recommendation/${recommendation.id}/${recommendation.filename}`}
                                        filename={recommendation.recommendation}>
                        {recommendation.recommendation}
                    </DownloadFileButton>
                </p>
                <p>
                    You can upload another file instead, and we will replace it.
                </p>
            </div>
        </div>
    }

    render() {
        const recommendation = this.props.recommendation;

        return <div className="job-recommendation-form row">
            <Col xs={12} sm={7}>
                <h2>Recommendation letter for {recommendation.applicantName}</h2>
                {this.renderAlerts()}
                <div>Dear {recommendation.advisorName}</div>
                <p>
                    <ul>
                        <li>
                            {recommendation.applicantAuthorId ?
                                <AuthorLink id={recommendation.applicantAuthorId}
                                            name={recommendation.applicantName}
                                            target="_blank"/>
                                : recommendation.applicantName
                            } applied to{' '}
                            <Link to={`/jobs/${recommendation.vacancyId}`}>
                                {recommendation.vacancyTitle}
                            </Link> position at {recommendation.vacancyInstitution}{' '}
                            and asked you to write a recommendation letter for their job application.
                        </li>
                        <li>
                            It will be reused when applying for other positions.
                        </li>
                        <li>
                            Your recommendation will not be visible to the applicant.
                        </li>
                        <li>
                            If you are having trouble uploading, you
                            can <a href={`mailto:jobs@prophy.ai?subject=Recommendation letter for ${this.props.recommendation.applicantName}`}>
                            send recommendation via email</a>.
                        </li>
                    </ul>
                </p>

                {!this.state.success && recommendation.recommendation && this.renderAlreadyUploaded()}
                {!this.state.success &&
                    <div className={cx('form-group', { 'has-error': !!this.state.error })}>
                        <label htmlFor="applicant-recommendation">
                            Please attach your recommendation (10Mb max, only *.pdf, *.doc, *.docx or *.rtf are allowed):
                        </label>
                        <FileUpload onSelect={this.handleRecommendationSelect}
                                    className="full-width"
                                    accept=".pdf,.doc,.docx,.rtf"
                                    id="applicant-recommendation"
                                    sizeLimit={10 * Math.pow(1024, 2)}/>
                        {!!this.state.error && <p className="help-block">
                            {this.state.error}
                        </p>}
                    </div>}
                {this.state.success ? this.renderSuccess()
                : <div className={cx('form-group', { 'has-error': !!this.state.error })}>
                    <LoadingButton bsStyle="primary"
                                   working={this.state.saving}
                                   disabled={this.state.saving || !this.state.attachment}
                                   workingMessage="Sending"
                                   onClick={this.submit}>
                        Send
                    </LoadingButton>
                </div>}
            </Col>
            {!this.props.currentUser.isAuthenticated && <Col xs={12} sm={5} className="entity-meta-wrapper">
                {this.props.recommendation.author ?
                    <Fragment>
                        <AdvisorBrief author={this.props.recommendation.author} />
                    </Fragment>
                    : <Fragment>
                        <CurrentUser registerLinkMode>
                            <a href="/accounts/register/" className="btn full-width btn-large btn-primary">Register now</a>
                        </CurrentUser>
                        <p>
                            and get access to <strong>tens of millions</strong> of science articles.                        </p>
                    </Fragment>
                }

                <h4>Benefits of using Prophy:</h4>
                <ul>
                    <li>subscribe to interesting topics and receive new articles as they are published</li>
                    <li>our database includes millions of articles in physics, biology and computer science</li>
                    <li>find experts in your area</li>
                    <li>and finally - get our help with searching the best fit for your vacancy{' '}
                        when you want to hire someone.</li>
                </ul>
            </Col>}
        </div>
    }
}

export default withCurrentUser(ApplicantRecommendationForm);
