import React from 'react';

import { Link } from 'react-router-dom';

import './styles.css';


export default React.memo(function SubpanelDocumentsPreview(
        { panel, subpanelCode }
) {
    if (!subpanelCode || panel.noSubpanelDocs) {
        return null;
    }

    const subpanel = panel.subpanels.find(subpanel => subpanel.code === subpanelCode);

    if (!subpanel) {
        return null;
    }

    const documents = subpanel.documents.preview;
    const total = subpanel.documents.total;
    const title = `${subpanel.code} - ${subpanel.name}`;

    return <div>
        <strong>{title}<sup>{total}</sup></strong>
        {documents && documents.length ?
            <ul>
                {documents.map(document =>
                    <li key={document.docKind + document.docId}>
                        <a href={`/${document.docKind}/${document.docId}`}
                           title={document.title} target="_blank" rel="noopener noreferrer">
                            {document.title}
                        </a>
                    </li>)}
                {total > documents.length ?
                    <Link to={`/panel-composer/${panel.id}/subpanels/${subpanel.code}/documents`}>
                        View all &rarr;
                    </Link>
                    : null
                }
            </ul>
            : <p>No documents found.</p>
        }
    </div>;
});
