import React from 'react';

import { sendRequest } from '../datastore';

import type { CurrentUserType } from '../Types';
import withCurrentUser from '../common/withCurrentUser';


function GenderIndicator(
    {authorId, gender, manualGender, currentUser}:
    {
        authorId: number,
        gender: number,
        manualGender: number,
        currentUser: CurrentUserType
    }
) {

    const [stateManualGender, setStateManualGender] = React.useState(manualGender);
    const [shown, setShown] = React.useState(false);

    const setManualGender = (gender) => (
        sendRequest(`/api/authors/${authorId}/set-gender/?gender=${gender}`, {
            method: 'PUT',
        }).then(response => {
            const { ok } = response;

            if (ok) {
                setStateManualGender(gender);
                setShown(false);
            }
        })
    );

    if (authorId === null || !currentUser.activeQuotas.gender_info) {
        return null;
    }

    const genderHint = `Predicted: ${gender} (female=-100, male=100)
${!!stateManualGender ?
    'Manually set to '+ (stateManualGender === -100 ? 'F' : 'M') +' '
    : 'Click F or M to set manually.'}`;

    let genderIndicator;
    if (stateManualGender !== 0 && stateManualGender !== null) {
        if (stateManualGender === 100) {genderIndicator = <b><u>M</u></b>;}
        else if (stateManualGender === -100) {genderIndicator = <b><u>F</u></b>;}
    }
    else {
        if (gender === null) {
            genderIndicator = '';
        }
        else {
            if (gender <= -80) {genderIndicator = 'F';}
            else if (gender <= -20) {genderIndicator = 'f';}
            else if (gender < 20) {genderIndicator = '?';}
            else if (gender < 80) {genderIndicator = 'm';}
            else {genderIndicator = 'M';}
        }
    }

    return <div className="referee-icons" title={genderHint}>
        <span className="gender-indicator" onClick={() => setShown(!shown)}>{genderIndicator}</span>
        <span className="gender-buttons" style={{display: shown ? 'inline-block' : 'none'}}>
            <button className="gender-button" onClick={() => setManualGender(-100)}>F</button>
            <button className="gender-button" onClick={() => setManualGender(100)}>M</button>
        </span>
    </div>;
}

export default withCurrentUser(GenderIndicator);
