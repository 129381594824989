import React from 'react';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import { AuthorList } from './Authors';
import ArticleJournal from './ArticleJournal';
import { singlePlural } from '../utils';

import type { ArticleType } from './Types';

import './entity-meta.css';


export default function ArticleMeta(
    { article, currentAuthorId, noCitationCount }:
    { article: ArticleType, currentAuthorId?: number, noCitationCount?: boolean }
) {
    return <div className="entity-meta">
        {article.notable && <div>
            <Glyphicon glyph="fire" className="notable-icon" />&nbsp;
            <strong>notable article</strong>
        </div>}
        {article.year && <span>publication year: {article.year}</span>}
        <ul className="origins">
            {article.origins.map((o, i) => <li key={i}>
                {o.code}:&nbsp;
                <a href={o.url} target="_blank" rel="noopener noreferrer">
                    {o.originId}&nbsp;
                    <span className="glyphicon glyphicon-new-window" />
                </a>
            </li>)}
        </ul>
        {article.journal && <ArticleJournal journal={article.journal} />}
        {!!article.citationsCount && !noCitationCount &&
         <div>{article.citationsCount} {singlePlural('citation', article.citationsCount)}</div>}
        <AuthorList authors={article.authors}
                    currentAuthorId={currentAuthorId}
                    authorsCount={article.authorsCount}
                    short />
    </div>;
}
