import React from 'react';
import DocumentTitle from 'react-document-title';

import AuthorLink from '../authors/AuthorLink';
import { ConceptList } from '../articles/Concept';


export default function RefereedAuthor({ author }) {
    return <>
        <DocumentTitle title={`Wide Experts - ${author.name}`} />
        <ol className="breadcrumb">
            <li><AuthorLink id={author.id} name={author.name} /></li>
            <li><a href={`/reports/author/${author.id}/dashboard`}>Reports</a></li>
            <li className="active">Wide experts</li>
        </ol>
        {!!author.topConcepts && !!author.topConcepts.length &&
            <h3>Top concepts<sup>{author.topConcepts.length}</sup></h3>
        }
        <ConceptList concepts={author.topConcepts} collapsible />
        <div>&nbsp;</div>
    </>;
}
