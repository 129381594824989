import React from 'react';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';


export default function VacancyTag({
    tag, removeTitle, onRemove, onClick
}) {
    return <span className="label-applicant-tag">
        <span onClick={onClick} title={tag.description}>{tag.name}</span>
        <span onClick={onRemove} title={removeTitle}>
            <Glyphicon glyph="remove" />
        </span>
    </span>;
}
