import React from 'react';

import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';

import helpers from '../jobs/helpers';
import { singlePlural } from '../utils';
import SelectedRefereesContext from './SelectedRefereesContext';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';


export default function AddSelectedAsPreliminaryButton(
    { disabled, vacancyId }:
    {
        disabled: boolean,
        vacancyId: number,
    }
) {
    const [showModal, setShowModal] = React.useState(false);
    const [working, setWorking] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [done, setDone] = React.useState(false);
    const [selectedReferees, { clearSelected }] = React.useContext(SelectedRefereesContext);

    const setStates = (working, error, done) => {
        setWorking(working);
        setError(error);
        setDone(done);
    };

    const addSelectedAsPreliminary = () => {
        setStates(true, null, false);
        const authorsIds = selectedReferees.map(referee => referee.author.id);
        helpers.addPreliminaryApplicants(vacancyId, authorsIds)
        .then(response => {
            const { ok, value } = response;

            if (ok) {
                setStates(false, null, true);
            } else {
                setStates(false, value.error, false);
            }
        });
    }

    const close = () => {
        setStates(false, null, false);
        setShowModal(false);
    }

    const clearAndClose = () => {
        clearSelected();
        close();
    }

    return <>
        <Button className="btn btn-default"
                disabled={disabled}
                onClick={() => setShowModal(true)}>
            <span><Glyphicon glyph="arrow-right"/>&nbsp;Add as preliminary candidate</span>
        </Button>
        <Modal show={showModal} onHide={close} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Add {selectedReferees.length} {singlePlural('candidate', selectedReferees.length)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!done ? <ol style={{ maxHeight: '200px', overflow: 'auto' }}>
                        {selectedReferees.map(referee => <li key={referee.author.id}>{referee.author.name}</li>)}
                    </ol>
                    : <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src="/static/images/success.png" alt="Success!" width="25px" height="25px" style={{ marginRight: '0.5em' }}/>
                        <span>Added {selectedReferees.length} {singlePlural('applicant', selectedReferees.length)}.</span>
                        &nbsp;<Link to={`/jobs/${vacancyId}/applicants/preliminary`}>Go to applicants list &rarr;</Link>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                {!!error ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <img src="/static/images/error.png" alt="Error!" width="14px" height="14px" style={{ marginRight: '0.5em' }}/>
                    <span className="text-danger">{error}</span>
                    <Button style={{ marginLeft: '0.5em' }} onClick={close}>Close</Button>
                </div>
                : done ? <Button style={{ marginLeft: '0.5em' }} onClick={clearAndClose}>Close</Button>
                  : <>
                     <Button onClick={close} disabled={working}>Cancel</Button>
                     <Button bsStyle="primary" onClick={addSelectedAsPreliminary} disabled={working}>
                         Add
                     </Button>
                 </>}
            </Modal.Footer>
        </Modal>
    </>
}
