export default {
    fetchArticlesByTitle: function(file: ?any, plainText: ?string, attorneyToken: ?string) {
        const data = new FormData();
        if (attorneyToken) {
            data.append('attorney_token', attorneyToken);
        }

        if (file) {
            data.append('bibtex_file', file);
        } else if (plainText !== undefined) {
            data.append('plain_text', plainText);
        }

        return fetch('/api/authors/find-claim-articles/', {
            method: 'POST',
            body: data,
            credentials: 'same-origin',
        });
    }
};
