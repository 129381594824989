import React from 'react';

import { SearchAuthorResult } from '../search/SearchAuthors';
import { useCurrentUser } from '../accounts/hooks';
import { singlePlural } from '../utils';

import Button from 'react-bootstrap/lib/Button';

import '../referees/search-entity.css';


export default function JobAuthors({ onRemove, selectedAuthors }) {
    const [currentUser] = useCurrentUser();

    return <>
        <div className="added search-articles selected-articles">
            <div className="search-entity results">
                {currentUser.authorId &&
                    <button className="btn btn-default use-last-articles pull-right"
                            title="This feature is not available"
                            disabled>
                        Add me
                    </button>}
                <h4 className="info"
                    style={{ marginLeft: 0 }}>{selectedAuthors.length} selected {singlePlural('author', selectedAuthors.length)}
                    <p className="help-block">
                        (Candidates cannot see this list)
                    </p>
                </h4>
                <ul>
                    {selectedAuthors.map(a =>
                        <SearchAuthorResult key={a.id}
                                            author={a}>
                            <div className="action">
                                <Button onClick={() => onRemove(a)}
                                        bsStyle={'danger'}
                                        bsSize="sm">{'Remove'}</Button>
                            </div>
                        </SearchAuthorResult>
                    )}
                </ul>
            </div>
        </div>
        <div className="alert alert-warning">
            Old version of author search was removed.
            Reimplement this form if needed again.
        </div>
    </>;
}
