import React from 'react';


export default function OpenAccess({ openAccess, className="open-access-icon" }) {
    if (!openAccess) {
        return null;
    }
    return <img src="/static/images/open-access.svg" className={className}
                alt="Open access" title="Open access article"/>;
}
