import gql from 'graphql-tag';


export default gql`
mutation concept ($id: Int,
                  $name: String,
                  $literals: [LiteralInput],
                  $basic: Boolean,
                  $deleted: Boolean) {
    concept (id: $id,
             name: $name,
             literals: $literals,
             basic: $basic,
             deleted: $deleted)  {
        ok
        concept {
            id
            name
            literals {
                name
                composite
                lang
                isAmbiguous
            }
            basic
            isManuallyModified
            dateModified
            deleted
        }
    }
}
`;
