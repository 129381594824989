import React, { Fragment } from 'react';
import Reflux from 'reflux';
import { Prompt } from 'react-router';

import DragDropContext from 'react-beautiful-dnd/lib/view/drag-drop-context';

import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Fade from 'react-bootstrap/lib/Fade';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Modal from 'react-bootstrap/lib/Modal';

import type { TagType, TopicType, ApplicationType } from './Types';

import { sendRequest, updateGlobalData } from '../datastore';
import { saveTopics } from './JobData';
import DroppableOverlay from '../recommendations/DroppableOverlay';
import AdvisorApplicant from './AdvisorApplicant';
import EditTagModal from './tags/EditTagModal';
import JobApplicantsTopicsStore, {
    JobApplicantsTopicsStoreActions
} from './JobApplicantsTopicsStore';
import { getEntityId } from '../common/helpers/dnd';
import Draggable from '../widgets/Draggable';
import LoadingButton from '../widgets/LoadingButton';
import Loading from '../Loading';
import { cx, isArraysEqual, singlePlural } from '../utils';


const EDIT_TAG_CAPTIONS = {
    destroy: 'Remove tag',
    save: 'Update',
};

export const DISPLAY_STATUS = {
    OPEN: 1,
    CLOSED: 0,
    REQUEST_CLOSED: 2,
};

const LEAVE_MSG = 'You have unsaved topics. If you leave the page ' +
    'now, these changes will be lost. Continue?';

type JobApplicantsSettingsPropsType = {
    show: 0 | 1 | 2,
    applicants: Array<ApplicationType>,
    availableTags: Array<TagType>,
    jobId: number,
    onHide: Function,
    onHideDeclined: Function,
};

export default class JobApplicantsSettings extends Reflux.Component<
    JobApplicantsSettingsPropsType,
    {
        saving: boolean,
        fetching: boolean,
        addingApplicants: boolean,
        showConfirmationModal: boolean,
        manuallyRemovedAllTopics: boolean,
        openModalIndex: ?number,
        success: string,
        error: string,
        topics: Array<TopicType>,
        unclassifiedIds: Array<number>,
        addedApplicantIds: Array<number>,
    }
> {
    store = JobApplicantsTopicsStore;
    storeKeys = ['topics', 'unclassifiedIds'];

    state = {
        saving: false,
        fetching: false,
        addingApplicants: false,
        showConfirmationModal: false,
        manuallyRemovedAllTopics: false,
        openModalIndex: null,
        success: '',
        error: '',
        topics: [],
        unclassifiedIds: [],
        addedApplicantIds: [],
        alreadyExistsInTopic: {},
    };

    collectTopicsFromAvailable() {
        return this.props.availableTags
            .filter(tag => tag.autotag)
            .map(tag => ({
                ...tag,
                applicantsIds: this.props.applicants
                    .reduce(
                        (ids, app) => app.tagsIds.indexOf(tag.id) !== -1 ?
                            [ ...ids, app.applicationId]
                            : ids,
                        []
                    )
            }))
            .filter(tag => !!tag.applicantsIds.length);
    }

    componentDidMount() {
        // prefill the store with existent autotags and people who have them
        const topics = this.collectTopicsFromAvailable();

        if (topics.length) {
            const autotagIds = topics.map(tag => tag.id);

            JobApplicantsTopicsStoreActions.setTopics(topics);
            JobApplicantsTopicsStoreActions.setUnclassifiedIds(
                this.props.applicants
                    .filter(app => !app.tagsIds.some(appTagId => autotagIds.indexOf(appTagId) !== -1))
                    .map(app => app.applicationId)
            );
        } else {
            this.fetchTopics();
        }

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentDidUpdate(prevProps: JobApplicantsSettingsPropsType) {
        if (
            prevProps.show === DISPLAY_STATUS.OPEN &&
            this.props.show === DISPLAY_STATUS.REQUEST_CLOSED
        ) {
            if (this.isChanged()) {
                this.props.onHideDeclined();
                this.pendingDestructiveAction = this.props.onHide;
                this.setState({ showConfirmationModal: true });
            } else {
                this.props.onHide();
            }
        }
    }

    componentWillUnmount() {
        JobApplicantsTopicsStoreActions.resetStore();
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        super.componentWillUnmount();
    }

    handleBeforeUnload = (e: SyntheticEvent<*> & { returnValue: string }) => {
        if (this.isChanged()) {
            e.returnValue = LEAVE_MSG;

            return LEAVE_MSG;
        }
    }

    fetchTopics = () => {
        this.setState({ fetching: true });

        return sendRequest(`/api/jobs/vacancy/${this.props.jobId}/suggest-topics`)
            .then(({ ok, value }) => {
                this.setState({
                    fetching: false,
                    manuallyRemovedAllTopics: false,
                    addedApplicantIds: [],
                    error: value.topics.length ?
                        ''
                        : 'Unable to build topics because there are not enough articles among applicants.',
                });

                if (ok) {
                    JobApplicantsTopicsStoreActions.setTopics(value.topics);
                    JobApplicantsTopicsStoreActions.setUnclassifiedIds(value.unclassifiedApplicantsIds);
                }
            }).catch(({ error }) => {
                this.setState({ fetching: false, error: error.message });
            });
    }

    isChanged = () => {
        const oldTags = this.collectTopicsFromAvailable();

        return oldTags.filter(t => t.autotag).length !== this.state.topics.length ||
            this.state.topics.some(t => {
                const oldTag = oldTags.find(at => at.id === t.id);

                return !oldTag || oldTag.name !== t.name ||
                    oldTag.autotag !== t.autotag || oldTag.description !== t.description ||
                    !isArraysEqual(oldTag.applicantsIds, t.applicantsIds);
            });
    }

    getBlockMessage = () => {
        return this.isChanged() ? LEAVE_MSG : true;
    }

    handleAddApplicantsToTopics = () => {
        // if user changed/removed some suggested topics, clicking
        // "Add applicants to existent topics" will discard these changes;
        // this may not be what user expects, so we come up with confirmation dialog
        if (this.isChanged()) {
            this.pendingDestructiveAction = this.addApplicantsToTopics;
            this.setState({ showConfirmationModal: true });
            return;
        }

        this.addApplicantsToTopics();
    }

    addApplicantsToTopics = () => {
        this.setState({ addingApplicants: true, showConfirmationModal: false });

        return sendRequest(
            `/api/jobs/vacancy/${this.props.jobId}/suggest-topics-for-new-applicants`
        )
            .then(({ ok, value }) => {
                const count = value.suggestedApplicantsIds.length;
                this.setState({
                    addingApplicants: false,
                    success: `Added ${count} ${singlePlural('applicant', count)}.`,
                    addedApplicantIds: value.suggestedApplicantsIds,
                });

                JobApplicantsTopicsStoreActions.setTopics(value.topics);
                JobApplicantsTopicsStoreActions.setUnclassifiedIds(value.unclassifiedApplicantsIds);

                setTimeout(() => {
                    this.setState({ success: '' });
                }, 3000);
            }).catch(({ error }) => {
                this.setState({ addingApplicants: false, error: error.message });
            });
    }

    saveAndClose = () => {
        return this.save().then(this.props.onHide);
    }

    save = () => {
        this.setState({ saving: true });

        return sendRequest('/api/jobs/save-topics/', {
            method: 'post',
            body: JSON.stringify({
                vacancyId: this.props.jobId,
                topics: this.state.topics,
            })
        })
            .then(({ ok, value: { applicants, tags } }) => {
                this.setState({ saving: false });

                if (ok) {
                    updateGlobalData(
                        saveTopics,
                        {vacancyId: this.props.jobId, tags, applicants}
                    );
                }
            })
            .catch(({ error }) => {
                this.setState({ saving: false, error: error.message });
            });
    }

    createChangeTag = (i: number) => {
        return (data: $Shape<TagType>) => {
            JobApplicantsTopicsStoreActions.updateTopicTag(i, data);
            this.setState(data);

            return Promise.resolve();
        }
    }

    createRemoveTopic = (index: number) => {
        return () => {
            if (this.state.topics.length === 1) {
                // we are removing the last tag, make user able to save it
                this.setState({ manuallyRemovedAllTopics: true });
            }

            JobApplicantsTopicsStoreActions.removeTopic(index);

            return Promise.resolve();
        };
    }
    createMerge = (fromIndex: number, toIndex: number) => {
        return () => {
            JobApplicantsTopicsStoreActions.mergeTopic(fromIndex, toIndex);
        };
    }

    createOpenModal = (index: number) => {
        return () => {
            this.setState({ openModalIndex: index });
        };
    }

    closeModal = () => {
        this.setState({ openModalIndex: null });
    }

    closeSelf = () => {
        if (this.isChanged()) {
            this.pendingDestructiveAction = this.props.onHide;
            this.setState({ showConfirmationModal: true });
        } else {
            this.props.onHide();
        }
    }

    closeConfirmationModal = () => {
        this.pendingDestructiveAction = null;
        this.setState({ showConfirmationModal: false });
    }

    doDestructiveAction = () => {
        if (this.pendingDestructiveAction) {
            this.pendingDestructiveAction();
        }

        this.pendingDestructiveAction = null;
    }

    saveAndDoDestructiveAction = () => {
        return this.save().then(this.doDestructiveAction);
    }

    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const from = parseInt(getEntityId(result.source.droppableId), 10);
        const to = parseInt(getEntityId(result.destination.droppableId), 10);
        const applicantId = parseInt(getEntityId(result.draggableId), 10);

        if (to === from) {
            return;
        }

        if (
            (isNaN(to) ? this.state.unclassifiedIds : this.state.topics[to].applicantsIds)
                .indexOf(applicantId) !== -1
        ) {
            this.setState({ alreadyExistsInTopic: { topicId: to, applicantId } });

            setTimeout(() => {
                this.setState({ alreadyExistsInTopic: {} });
            }, 2000);
        } else {
            JobApplicantsTopicsStoreActions.movePerson(
                applicantId,
                isNaN(from) ? undefined : from,
                isNaN(to) ? undefined : to,
            );
        }
    }

    renderTopic = (t: any, i: number) => {
        const prefix = `${t.name}-`;

        return <Fragment key={i}>
            <div className="table-row">
                <DroppableOverlay droppableId={`${i}`} type="applicant">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} className={snapshot.isDraggingOver ? 'is-dragged-over' : ''}>
                            <div className="tag">
                                <div><strong>{t.name}</strong></div>
                                <div>{t.description}</div>
                                <div><strong>{t.autotag ? 'can' : 'cannot'}</strong> be changed by autotagging process</div>
                                <ButtonToolbar>
                                    <Button bsSize="xsmall" onClick={this.createOpenModal(i)}>
                                        Edit tag
                                    </Button>
                                    <Button bsSize="xsmall" onClick={this.createRemoveTopic(i)}>
                                        Remove tag
                                    </Button>
                                    {this.state.topics.length > 1 && <DropdownButton
                                        bsSize="xsmall"
                                        title="Merge to..."
                                        id={`merge-to-${i}`}>
                                        {this.state.topics
                                            .map((otherTopic, otherTopicIndex) => {
                                                if (otherTopic === t) {
                                                    return null;
                                                }

                                                const words = otherTopic.description
                                                    .split(' ')
                                                    .filter(part => part.trim());
                                                let title = words.slice(0, 6).join(' ');

                                                // remove trailing comma if present
                                                if (title[title.length - 1] === ',') {
                                                    title = title.slice(0, title.length - 1);
                                                }

                                                title = title + (words.length > 6 ? '...' : '');

                                                return <MenuItem key={otherTopicIndex}
                                                                eventKey={otherTopicIndex + 1}
                                                                onClick={this.createMerge(i, otherTopicIndex)}>
                                                    <strong>{otherTopic.name}</strong> {title}
                                                </MenuItem>
                                            })}
                                    </DropdownButton>}
                                </ButtonToolbar>
                                <EditTagModal show={this.state.openModalIndex === i}
                                              tag={t}
                                              onSave={this.createChangeTag(i)}
                                              onDestroy={this.createRemoveTopic(i)}
                                              jobId={this.props.jobId}
                                              buttonCaptions={EDIT_TAG_CAPTIONS}
                                              onHide={this.closeModal} />
                            </div>
                            <div className="tag-applicants">
                                {/*
                                Black magic here. When you drop a <Draggable/>, it
                                searches for the exact place to land. When droppable list is empty, it
                                searches for the element with CSS class ".{draggableType}s", e.g., ".applicants"
                                for type === "applicant", ".concepts" for type === "concept" etc.
                                */}
                                <div className="applicants">
                                    {t.applicantsIds.map(id =>
                                        <Draggable draggableId={`${prefix}${id}`}
                                                key={id}
                                                type="applicant">
                                            {provided => {
                                                const justAdded = this.state.addedApplicantIds.indexOf(id) !== -1;

                                                return <span>
                                                    <span
                                                        className="applicant-draggable"
                                                        ref={provided.innerRef}
                                                        style={provided.draggableStyle}
                                                        {...provided.dragHandleProps}>
                                                        <AdvisorApplicant
                                                            key={id}
                                                            id={id}
                                                            draggable={true}
                                                            short
                                                            className={cx({
                                                                'just-added': justAdded,
                                                                'already-exists': (
                                                                    this.state.alreadyExistsInTopic.topicId === i &&
                                                                    this.state.alreadyExistsInTopic.applicantId === id
                                                                ),
                                                            })}
                                                            title={justAdded ? 'just added to the topic' : undefined}
                                                        />
                                                    </span>
                                                    {provided.placeholder}
                                                </span>
                                            }}
                                        </Draggable>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </DroppableOverlay>
            </div>
        </Fragment>;
    }

    render() {
        const hasTopics = !!this.state.topics.length;
        const closeBtn = <Button onClick={this.closeSelf}>Close</Button>;
        const saveAndCloseBtn = <LoadingButton bsStyle="primary"
                                               onClick={this.saveAndClose}
                                               working={this.state.saving}>
            Save and close
        </LoadingButton>;
        const resuggestTopics = <LoadingButton bsStyle="primary"
                                               className="pull-right"
                                               onClick={this.fetchTopics}
                                               workingMessage="Please wait"
                                               working={this.state.fetching}>
            Resuggest topics
        </LoadingButton>;
        const addApplicantsBtn = <LoadingButton onClick={this.handleAddApplicantsToTopics}
                                             working={this.state.addingApplicants}
                                             workingMessage="Please wait"
                                             disabled={this.state.addingApplicants}
                                             className="pull-right">
            Add applicants to existing topics
        </LoadingButton>;

        return <div>
            <Fade in={!!this.state.success}>
                <Alert bsStyle="success" className="col-sm-3 col-xs-10 alert-fixed-bottom-right">
                    {this.state.success}
                </Alert>
            </Fade>
            <Fade in={!!this.state.error}>
                <Alert bsStyle="danger" className="col-sm-3 col-xs-10 alert-fixed-bottom-right">
                    {this.state.error}
                </Alert>
            </Fade>
            <Prompt message={this.getBlockMessage}/>
            <p>
                We can split applicants into groups based on their main research
                field by assigning them a corresponding tag. This can help you
                quickly understand their speciality.
            </p>
            {this.state.manuallyRemovedAllTopics && <div>
                <p>
                    You have removed all tags, suggested earlier.
                    Click &quot;Save and close&quot; to save your changes.
                </p>
                <ButtonToolbar>
                    {saveAndCloseBtn}
                    {closeBtn}
                    {resuggestTopics}
                </ButtonToolbar>
            </div>
            }
            {!hasTopics && !this.state.manuallyRemovedAllTopics && this.state.fetching &&
                <Loading loading loadingString="Please wait, we are loading topics"/>}
            {hasTopics && <Fragment>
                <p>
                    You can drag and drop any applicant name between the topics to
                    assign them to another topic.
                </p>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <div className="table table-jobs-topics-settings">
                        {this.state.topics.map(this.renderTopic)}
                        <div className="table-row" key="unclassified">
                            <DroppableOverlay droppableId="unclassified" type="applicant">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} className={cx('unclassified tag-applicants', snapshot.isDraggingOver ? 'is-dragged-over' : '')}>
                                        <h4>Applicants without tags ({this.state.unclassifiedIds.length})</h4>
                                        {this.state.unclassifiedIds.length ?
                                            <>
                                                <p>These applications did not fit into any group above.</p>
                                                <div className="applicants">
                                                    {this.state.unclassifiedIds.map(id =>
                                                        <Draggable draggableId={`unclassified-${id}`}
                                                                key={id}
                                                                type="applicant">
                                                            {(provided, snapshot) => (
                                                                <span>
                                                                    <span
                                                                        className="applicant-draggable"
                                                                        ref={provided.innerRef}
                                                                        style={provided.draggableStyle}
                                                                        {...provided.dragHandleProps}>
                                                                        <AdvisorApplicant
                                                                            key={id}
                                                                            id={id}
                                                                            draggable
                                                                            short />
                                                                    </span>
                                                                    {provided.placeholder}
                                                                </span>
                                                            )}
                                                        </Draggable>
                                                    )}
                                                </div>
                                            </>
                                            : <>
                                                <p>
                                                    No unclassified applicants.
                                                </p>
                                                <div className="applicants">
                                                    Drag applicant from the topic above to remove them from corresponding topic.
                                                </div>
                                            </>}
                                    </div>
                                )}
                            </DroppableOverlay>
                        </div>
                    </div>
                </DragDropContext>
                <ButtonToolbar>
                    {saveAndCloseBtn}
                    {closeBtn}
                    {resuggestTopics}
                    {addApplicantsBtn}
                </ButtonToolbar>
            </Fragment>}
            <Modal show={this.state.showConfirmationModal}
                   animation={false}
                   rootClose
                   onHide={this.closeConfirmationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>You have unsaved changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You have unsaved changes. Proceeding will discard them. Do you want to continue?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar className="pull-right">
                        <Button onClick={this.closeConfirmationModal}>
                            Cancel
                        </Button>
                        <Button onClick={this.saveAndDoDestructiveAction}>
                            Save and continue
                        </Button>
                        <Button onClick={this.doDestructiveAction}>
                            Discard changes and continue
                        </Button>
                    </ButtonToolbar>
                </Modal.Footer>
            </Modal>
        </div>;
    }
}
