export const COOKIE_RF_TOP_HELP_COLLAPSED = 'COOKIE_RF_TOP_HELP_COLLAPSED';
export const COOKIE_RF_SETTINGS_OPEN = 'COOKIE_RF_SETTINGS_OPEN';
export const COOKIE_RF_IS_GRAPH_COLLAPSED = 'COOKIE_RF_IS_GRAPH_COLLAPSED';
export const COOKIE_EXPORT_REFEREES_AS_ORG_ID = 'COOKIE_EXPORT_REFEREES_AS_ORG_ID';


export function getCookieFlag(name) {
    return !!parseInt(getCookie(name)) || false;
}

export function setCookieFlag(name, value, attrs) {
    return setCookie(name, Number(value), attrs);
}


export function getCookie(name) {
    let matches = document.cookie.match(new RegExp('(?:^|; )' + name + '=([^;]*)'));
    return matches ? matches[1] : undefined;
}


export function setCookie(name, value, attrs) {

    let {path, expiresDays} = attrs || {};

    let expires = '';
    if (typeof expiresDays === 'number') {
        // max-age is deprecated in old browsers, use expires here
        expires = new Date((new Date().getTime() + 1000 * 60 * 60 * 24 * expiresDays)).toUTCString();
    }
    let cookie = `${name}=${value}; expires=${expires}`;

    if (path === undefined) { path = '/'; }

    if (path) {
        cookie += `; path=${path}`;
    }

    document.cookie = cookie;
}
