import React from 'react';

import ArticleSuggest from './ArticleSuggest';
import { useCurrentUser } from '../accounts/hooks';
import { singlePlural } from '../utils';

import '../referees/search-entity.css';


export default function JobArticles({ onRemove, selectedArticles }) {
    const [currentUser] = useCurrentUser();

    return <>
        <div className="added search-articles selected-articles">
            <div className="search-entity results">
                {currentUser.authorId &&
                    <button className="btn btn-default use-last-articles pull-right"
                            title="This feature is not available"
                            disabled>
                        Add my latest articles
                    </button>}
                <h4 className="info"
                    style={{ marginLeft: 0 }}>{selectedArticles.length} selected {singlePlural('article', selectedArticles.length)}
                    <p className="help-block">
                        (Candidates cannot see this list)
                    </p>
                </h4>
                <ul>
                    {selectedArticles.map(a => <ArticleSuggest
                        key={a.articleId}
                        className="article-suggest"
                        isAdded={true}
                        addedArticle={a}
                        onClick={onRemove}
                        article={a}
                        authorsCount={3} />
                    )}
                </ul>
            </div>
        </div>
        <div className="alert alert-warning">
            Old version of article search was removed.
            Reimplement this form if needed again.
        </div>
    </>;
}
