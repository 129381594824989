import React from 'react';
import type { ReactRouterLocationType } from '../common/Types';

import Alert from 'react-bootstrap/lib/Alert';


export default function FlashMessages(
    { location }
    : { location: ReactRouterLocationType}
) {
    return location.state && location.state.message ?
        <div className="messages">
            <div className="container">
                <Alert key={location.state.message.message} bsStyle={location.state.message.kind}>
                    {location.state.message.message}
                </Alert>
            </div>
        </div>
        : null;
}
