import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';

import Popover from 'react-bootstrap/lib/Popover';

import { useTrack, useSubscription } from '../datastore';
import { makeJobApplicationKey, makeJobAuthorTopCitedArticlesQuery } from './JobData';

import AuthorLink from '../authors/AuthorLink';
import type { AdvisorWithScoresType } from './Types';
import RelevantArticlesList from '../common/RelevantArticlesList';
import LinkToRecommendation from './LinkToRecommendation';
import { toFixed } from '../utils';
import LoadingREST from '../LoadingREST';
import Rank from './Rank';


function ApplicantAndRecommendation(
    { id, jobId, close, advisorGroupId }
    : { id: number, jobId?: number, close: Function, advisorGroupId: any }
) {
    const applicant = useSubscription(makeJobApplicationKey(id));
    const recommendation = applicant.advisors.find(adv => adv.groupId === advisorGroupId);

    return <Fragment>
        {jobId ?
            <Link to={`/jobs/${jobId}/applicants#applicant-${id}`} onClick={close}>
                {applicant.authorName || applicant.email}
            </Link>
            : <a href={`#applicant-${id}`} onClick={close}>
                {applicant.authorName || applicant.email}
            </a>
        }{' | '}
        {recommendation.filename ?
            <LinkToRecommendation key={recommendation.id} advisor={recommendation} />
            : <span className="text-muted">has not received recommendation</span>
        }
    </Fragment>;
}

export default function AdvisorPopover(
    { advisor, jobId, close, short = false, ...popoverProps }
    : { advisor: AdvisorWithScoresType, jobId?: number, close: Function, short?: boolean }
) {
    const title = <Fragment>
        {advisor.name}&nbsp;
        {!!advisor.authorId &&
            <AuthorLink id={advisor.authorId} name={advisor.name} target="_blank">
                open author page &rarr;
            </AuthorLink>
        }
    </Fragment>;
    const isPreliminary = !advisor.email;

    let mostCitedArticlesList = '';
    const [mostCitedArticles, reqstate] =
        useTrack(makeJobAuthorTopCitedArticlesQuery(advisor.authorId));

    if (advisor.authorId) {
        mostCitedArticlesList = (
            <LoadingREST what="most cited articles" reqstate={reqstate} doc={mostCitedArticles}>
                <RelevantArticlesList articles={mostCitedArticles} title='Most cited articles:' />
            </LoadingREST>
        )
    }

    return <Popover id="advisor-popover"
                    placement="bottom"
                    className="advisor-popover"
                    title={title}
                    { ...popoverProps }>
        {!isPreliminary && <div>
            <strong>Email: </strong><span>{advisor.email}</span>
        </div>}
        {!short && <Fragment>
            {!!advisor.rank && <div>
                <strong>Rank: </strong>
                <Rank person={advisor} />
            </div>}
            {!!advisor.similarityCombined && <div>
                <strong>Score: </strong><span>{toFixed(advisor.similarityCombined, 2)}</span>
            </div>}
            {!!advisor.similarityMax && <div>
                <strong>Article score: </strong><span>{toFixed(advisor.similarityMax, 2)}</span>
            </div>}
        </Fragment>}
        {advisor.applicantsIds && !!advisor.applicantsIds.length && <div>
            <div>
                <strong>Recommender for:</strong>
            </div>
            <ul>
                {advisor.applicantsIds.map(id => <li key={id}>
                    <ApplicantAndRecommendation
                        id={id}
                        jobId={jobId}
                        advisorGroupId={advisor.groupId}
                        close={close} />
                </li>)}
            </ul>
        </div>}
        <RelevantArticlesList articles={advisor.mostRelevantArticles} />
        {mostCitedArticlesList}
    </Popover>;
}
