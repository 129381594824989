import React, { Fragment } from 'react';
import { makeMonitoringTopicsQuery } from './MonitoringData';

import Panel from 'react-bootstrap/lib/Panel';

import { useTrack } from '../datastore';
import { interpose, KEYS, cx } from '../utils';
import LoadingREST from '../LoadingREST';

import './monitoring-topics.css';


const highlight = (stack, needle) => {
    const start = stack.toLowerCase().indexOf(needle.toLowerCase());
    if (start === -1) return stack;

    return <Fragment>
        {stack.slice(0, start)}<span className="highlight">{stack.slice(start, start + needle.length)}</span>{stack.slice(start + needle.length)}
    </Fragment>
};


export default function MonitoringTopics({ onSelect, currentMonitoringId }) {
    const conceptsLimit = 6;
    const [monitorings, reqstate] = useTrack(makeMonitoringTopicsQuery());
    const [query, setQuery] = React.useState('');
    const [currentMonitoring, setCurrentMonitoring] = React.useState(-1);

    const handleFilterChange = React.useCallback(e => {
        setQuery(e.currentTarget.value);
        setCurrentMonitoring(-1);
    }, []);

    const filtered = monitorings && monitorings
        .filter(m => m.id !== currentMonitoringId)
        .map(m => ({
            ...m,
            topics: m.topics.filter(t =>
                t.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                t.concepts.some(c => c.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
            )
        })).filter(m => m.topics.length);

    const handleKeyDown = React.useCallback(e => {
        let current = 0;

        switch (e.which) {
            case KEYS.DOWN:
                setCurrentMonitoring(currentMonitoring + 1);
                break;
            case KEYS.UP:
                setCurrentMonitoring(currentMonitoring - 1 >= 0 ? currentMonitoring - 1 : -1);
                break;
            case KEYS.ENTER:
                for (let i = 0; i < filtered.length; i++) {
                    for (let j = 0; j < filtered[i].topics.length; j++) {
                        if (current === currentMonitoring) {
                            onSelect(filtered[i].topics[j]);
                            return;
                        }

                        current++;
                    }
                }
                break;
            default:
                break;
        }
    }, [currentMonitoring, onSelect, filtered]);
    let currentlyRenderingIndex = -1;

    return <LoadingREST what="topics" doc={monitorings} reqstate={reqstate}>
        <div className="monitoring-topics">
            <input type="text"
                   autoFocus
                   className="form-control"
                   placeholder="Filter"
                   onChange={handleFilterChange}
                   onKeyDown={handleKeyDown}
                   value={query} />
            {filtered && filtered.map((m, mi) => <Panel key={m.id} className="panel-sm panel-paddingless">
                <Panel.Heading>{m.title}</Panel.Heading>
                <Panel.Body>
                    <ul className="topics-list">
                        {m.topics.map((t, ti) => {
                            currentlyRenderingIndex++;

                            return <li key={t.title}
                                    onClick={() => onSelect(t)}
                                    className={cx({'current': currentlyRenderingIndex === currentMonitoring})}>
                                <strong>{highlight(t.title, query)}:</strong>&nbsp;
                                {interpose(
                                    t.concepts.slice(0, conceptsLimit).map(c => <span key={c.conceptId}>
                                        {highlight(c.name, query)}
                                    </span>),
                                    ', '
                                )}
                                {t.concepts.length > conceptsLimit && <Fragment>
                                    , &hellip;{t.concepts.length - conceptsLimit} more
                                </Fragment>}
                            </li>;
                        })}
                    </ul>
                </Panel.Body>
            </Panel>
            )}
        </div>
    </LoadingREST>;
}
