import React from 'react';
import type { Node } from 'react';


type CollapsibleListPropsType = {
    collapseThreshold: number,
    collapsedCount: number,
    componentClass: string,
    className?: ?string,
    tumblerMarkup: (collapsed: boolean, hiddenCount: number, toggle: () => any) => Node,
    children: Array<Node>
};

export default class CollapsibleList extends React.Component<
    CollapsibleListPropsType,
    { collapsed: boolean }
> {
    static defaultProps = {
        collapseThreshold: 20,
        collapsedCount: 15,
        componentClass: 'div',
        className: null,
        tumblerMarkup: (collapsed: boolean, hiddenCount: number, toggle: () => any) => (
            <button className="toggle" onClick={toggle}>
                {collapsed ?
                    `show ${hiddenCount} more →`
                    : '← collapse'
                }
            </button>
        )
    };

    state = {
        collapsed: true
    };

    isCollapsible() {
        return (this.props.children.length > this.props.collapseThreshold);
    }

    toggle = () => {
        this.setState({ collapsed: !this.state.collapsed });
    }

    render() {
        const items = this.isCollapsible() && this.state.collapsed ?
            this.props.children.slice(0, this.props.collapsedCount)
            : this.props.children;
        const hiddenCount = this.props.children.length -
            this.props.collapsedCount;
        const WrapperComponent = this.props.componentClass;

        return (
            <WrapperComponent className={this.props.className}>
                {items}
                {this.isCollapsible() && this.props.tumblerMarkup.call(
                    this,
                    this.state.collapsed,
                    hiddenCount,
                    this.toggle
                )}
            </WrapperComponent>
        );
    }
}
