import React, { Fragment } from 'react';

import Button from 'react-bootstrap/lib/Button';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Modal from 'react-bootstrap/lib/Modal';

import {
    SaveRecommendationGuestMessage,
    SaveRecommendationNoQuotaMessage,
} from '../accounts/GuestMessages';
import { cx, KEYS } from '../utils';
import helpers from '../common/helpers/loading';
import LoadingButton from '../widgets/LoadingButton';

import type { RecommendationTopicType } from './RecommendationTopic';
import type { TreeNodeType } from './AdvancedRecommendation';
import type { SavedRecommendationType } from './Recommendation';
import type { OrganizationType } from '../accounts/Types';
import Alert from 'react-bootstrap/lib/Alert';
import { sendRequest, updateGlobalData } from '../datastore';
import { makeMonitoringKey } from './MonitoringData';
import withCurrentUser from '../common/withCurrentUser';


type SaveSavedRecommendationButtonPropsType = {
    id: ?number,
    date: ?string,
    topics: Array<RecommendationTopicType>,
    tree: TreeNodeType,
    useTree: boolean,
    submit: (props: {}) => any,
    onSubmit: (recommendation: SavedRecommendationType) => any,
    title: string,
    organization: ?OrganizationType,
    history: any,
    working: bool,
    done: bool,
};


export class SaveSavedRecommendationButton extends React.Component {
    props: SaveSavedRecommendationButtonPropsType;

    state: {
        title: string,
        showModal: boolean,
        chosenOrganization: OrganizationType,
        working: boolean,
        done: boolean,
        error: ?string,
    };

    constructor(props: SaveSavedRecommendationButtonPropsType) {
        super(props);

        this.state = {
            title: props.title,
            showModal: false,
            chosenOrganization: props.organization || {
                'id': null,
                'name': 'Choose organization'
            },
            working: false,
            done: false,
            error: null,
        };
    }

    showModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ error: null, loading: false, showModal: false });
    };

    submit = (
        submitProps
        : {
            id: ?number,
            title: string,
            date: ?string,
            topics: Array<RecommendationTopicType>,
            tree: TreeNodeType,
            useTree: boolean,
            organizationId: number
        }
    ) => {
        return sendRequest('/api/monitoring/edit', {
            method: 'POST',
            body: JSON.stringify(submitProps)
        }).then(result => {
            updateGlobalData(store => {
                const key = makeMonitoringKey(submitProps.id);
                store[key] = {
                    ...store[key],
                    ...submitProps,
                    topics: this.props.topics,
                };
            });

            return result;
        });
    }

    handleClick = () => {
        const topics = this.props.topics.map(t => ({
            color: null,
            title: t.title,
            conceptIds: t.concepts.map(c => c.conceptId)
        }));

        const submitProps = {
            id: this.props.id,
            title: this.state.title,
            date: this.props.date,
            topics,
            tree: this.props.tree,
            useTree: this.props.useTree,
            organizationId: this.state.chosenOrganization.id
        };

        return helpers.loadingAwareSubmit.call(this, submitProps, { submitFn: this.submit })
            .then(result => {
                if (result.ok) {
                    this.closeModal();
                    this.props.onSubmit(result.value);
                    return result;
                } else {
                    this.setState({
                        loading: false,
                        error: result.error,
                    })
                }
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: e.message
                });
            });
    };

    handleTitleChange = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ title: e.currentTarget.value });
    };

    handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
        if (e.which === KEYS.ENTER) {
            this.handleClick();
        }
    };

    selectOrganization = (organization: OrganizationType) => {
        this.setState({ chosenOrganization: organization });
    };

    renderOrganizations(availableOrgs) {
        const chosenOrganization = this.state.chosenOrganization;

        return (
            <DropdownButton title={chosenOrganization.name}
                            id="choose-organization"
                            className="form-control">
                {availableOrgs.map(o =>
                    <MenuItem key={o.id}
                        eventKey={o}
                        onSelect={this.selectOrganization}>{o.name}</MenuItem>
                )}
            </DropdownButton>
        );
    }

    renderSave() {
        const quota = this.props.currentUser.activeQuotas.monitoring;
        const availableOrgs = [...quota.organizations];
        if (quota.canBePrivate) {
            availableOrgs.unshift({'id': null, 'name': 'Private'});
        }
        const titleValid = this.state.title.trim();
        const displayOrg = !!availableOrgs.length;
        const organizationValid = !displayOrg || this.state.chosenOrganization.id !== -1;
        const buttonDisabled = (!titleValid || !organizationValid);

        const titleClasses = cx('form-group', {
            'has-error': !titleValid
        });

        const orgClasses = cx('form-group', {
            'has-error': !organizationValid
        });

        return <>
            <Modal.Body>
                <div className="form-inline">
                    <div className={titleClasses}>
                        <input
                            type="text" placeholder="Short title"
                            style={{width: displayOrg ? 375 : 568}}
                            className="form-control"
                            onChange={this.handleTitleChange}
                            onKeyDown={this.handleKeyDown}
                            value={this.state.title} />
                    </div>
                    {displayOrg &&
                    <div className={orgClasses} style={{marginLeft: 15}}>
                        {this.renderOrganizations(availableOrgs)}
                    </div>}
                    {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.closeModal}>Close</Button>
                <LoadingButton bsStyle="primary"
                               onClick={this.handleClick}
                               disabled={buttonDisabled}
                               working={this.state.working}
                               done={this.state.done}>
                    Save
                </LoadingButton>
            </Modal.Footer>
        </>
    }

    renderSaveWindow() {
        if (!this.props.currentUser.isAuthenticated) {
            return <Modal.Body>
                <SaveRecommendationGuestMessage/>
            </Modal.Body>;
        } else if (!this.props.currentUser.activeQuotas.monitoring) {
            return <Modal.Body>
                <SaveRecommendationNoQuotaMessage/>
            </Modal.Body>;
        } else {
            return this.renderSave();
        }
    }

    render() {
        const primaryBtnText = this.props.id ? 'Save' : 'Save...';
        const primaryBtnClasses = cx('with-spinner', {
            'save-btn': this.props.id,
            'modal-trigger': !this.props.id
        });

        return <Fragment>
            <Modal show={this.state.showModal} onHide={this.closeModal}
                   animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Save monitoring</Modal.Title>
                </Modal.Header>
                {this.renderSaveWindow()}
            </Modal>
            <LoadingButton className={primaryBtnClasses}
                           working={this.state.working}
                           done={this.state.done}
                           onClick={this.props.id ? this.handleClick : this.showModal}>
                {primaryBtnText}
            </LoadingButton>
            {this.props.id &&
                <LoadingButton className="modal-trigger with-spinner"
                               working={this.state.working}
                               done={this.state.done}
                               onClick={this.showModal}>
                    Rename...
                </LoadingButton>}
        </Fragment>;
    }
}

export default withCurrentUser(SaveSavedRecommendationButton);
