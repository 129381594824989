import { sendRequest } from '../datastore';


export function makeOntologyModerationLogQuery(filters = {}) {
    const qs = Object.keys(filters)
        .filter(currentFilter => filters[currentFilter] !== undefined)
        .map(currentFilter => `${currentFilter}=${filters[currentFilter]}`)
        .join('&');
    const key = `ontology-logs-${qs}`;

    return {
        id: key,
        get: key,
        set: function (store, { value }) {
            store[key] = value;
        },
        req: () => sendRequest(`/api/ontology/log?${qs}`),
    }
}
