import React from 'react';

import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Modal from 'react-bootstrap/lib/Modal';

import helpers from '../helpers';
import { useTrack } from '../../datastore';
import { SuggestBox } from '../../widgets/Suggest';
import { makeJobTagsQuery } from '../JobData';
import LoadingButton from '../../widgets/LoadingButton';
import { cx } from '../../utils';


export default function ApplicantTagModal({
    show, onHide, onAssign, applicant, jobId
}) {
    const [name, setName] = React.useState('');
    const [nameError, setNameError] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [autotag, setAutotag] = React.useState(false);
    const [showCreateTagForm, setShowCreateTagForm] = React.useState(false);
    const [error, setError] = React.useState('');
    const [working, setWorking] = React.useState(false);
    const [tags] = useTrack(makeJobTagsQuery(jobId));

    const suggestFetch = React.useCallback((q: string) => {
        const filtered = tags.filter(tag => tag.name.indexOf(q) !== -1);

        return Promise.resolve({
            results: filtered
                .map(tag => ({
                    id: tag.id,
                    label: tag.name,
                    title: tag.description,
                    value: tag.name
                }))
                .concat([{
                    id: null,
                    label: <span>
                        <Glyphicon glyph='plus' />
                        Create new tag &quot;{q}&quot;
                    </span>,
                    type: 'ui',
                    title: `create new tag "${q}"`,
                    value: q,
                }]),
            total: filtered.length
        });
    }, [tags]);


    const descriptionInput = React.useRef();

    const handleNameChange = e => {
        setName(e.currentTarget.value);
    };

    const handleDescriptionChange = e => {
        setDescription(e.currentTarget.value);
    };

    const handleAutotagChange = e => {
        setAutotag(e.currentTarget.checked);
    };

    const handleSubmit = () => {
        if (applicant && applicantHasTag(name)) {
            setNameError(`Tag "${name}" already assigned.`);
            return;
        }

        if (!name) {
            setNameError('Tag name is required.');
            return;
        }

        let tag = availableTags.find(tag => tag.name === name);

        if (tag) {
            return handleAssignTag(tag);
        }

        if (!showCreateTagForm) {
            setShowCreateTagForm(true);
            focusDescription();
            return;
        }

        tag = { name, description, autotag };

        setWorking(true);

        return helpers.postTag(tag, jobId)
            .then(response => {
                return onAssign(response.value);
            })
            .then(handleHide)
            .catch(e => {
                setWorking(false);
                setError(e.message);
            });
    }

    const handleAssignTag = (tag: { id: any }) => {
        setWorking(true);

        return onAssign(tag)
            .then(handleHide)
            .catch(e => {
                setWorking(false);
                setError(e.message);
            });
    }

    const applicantHasTag = name => {
        const tag = availableTags.find(tag => tag.name === name);
        return tag && applicant.tagsIds.some(id => id === tag.id);
    }

    const focusDescription = () => {
        setTimeout(() => {
            descriptionInput.current && descriptionInput.current.focus();
        }, 50);
    }

    const handleTagSelect = (tag: SuggestType) => {
        if (applicant && applicantHasTag(tag.value)) {
            setName(tag.value);
            setNameError(`Tag "${tag.value}" already assigned.`);

            return;
        }

        let tagToAssign;
        if (tag.type !== 'ui') {
            tagToAssign = tag.isNotSuggest ?
                availableTags.find(t => t.name === tag.value)
                : tag;

            if (tagToAssign) {
                return handleAssignTag(tagToAssign);
            }
        }

        // show form to create a new tag
        setShowCreateTagForm(true);
        setError('');
        setNameError('');
        focusDescription();
    }

    const handleHide = () => {
        setNameError('');
        setError('');
        setWorking(false);
        setShowCreateTagForm(false);
        onHide();
    }

    let availableTags = tags;

    if (applicant) {
        availableTags = tags.filter(t => applicant.tagsIds.indexOf(t.id) === -1);
    }

    return <form>
        <Modal show={show} animation={false} onHide={handleHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {applicant ?
                        <>
                            Add tag for <em>{applicant.authorName || applicant.email}</em>
                        </>
                        : 'Add tag'
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert bsStyle="danger">{error}</Alert>}
                <FormGroup className={cx({ 'has-error': nameError })}>
                    <label className="full-width" htmlFor="tag-name">
                        Search tag or type the new tag name in the field below:
                    </label>
                    <SuggestBox id="tag-name"
                                autoFocus
                                autoComplete="off"
                                divClasses="full-width"
                                suggestFetch={suggestFetch}
                                showSuggestType={false}
                                onChange={handleNameChange}
                                callback={handleTagSelect} />
                    <div className="help-block">{nameError}</div>
                    {!!availableTags.length && <p>
                        Alternatively, pick the tag from the list:
                    </p>}
                    {availableTags
                        .sort((t1, t2) => t1.name.localeCompare(t2.name))
                        .map(tag => <span key={tag.id}
                                          className="label-applicant-tag"
                                          title={tag.description}
                                          onClick={() => handleAssignTag(tag)}>
                            <span>{tag.name}</span>
                        </span>)
                    }
                </FormGroup>
                <div className={cx({ 'hidden': !showCreateTagForm })}>
                    <FormGroup>
                        <label className="full-width" htmlFor="tag-description">
                            Description:
                        </label>
                        <input
                            id="tag-description"
                            className="form-control"
                            ref={descriptionInput}
                            type="text"
                            onChange={handleDescriptionChange} />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox checked={autotag}
                                    onChange={handleAutotagChange}>
                            this tag can be assigned or deleted by autotagging process
                        </Checkbox>
                    </FormGroup>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar className="pull-right">
                    <Button onClick={handleHide}>Cancel</Button>
                    <LoadingButton bsStyle="primary"
                                   working={working}
                                   disabled={working}
                                   onClick={handleSubmit}>
                        {showCreateTagForm ?
                            'Create and assign tag'
                            : 'Assign tag'
                        }
                    </LoadingButton>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    </form>
}
