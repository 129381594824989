import React from 'react';

import { sendRequest, updateGlobalData } from '../../datastore';
import type { TagType } from '../Types';
import { removeTag, destroyTag as destroyJobTag } from '../JobData';
import helpers from '../helpers';
import EditTagModal from './EditTagModal';
import VacancyTag from './VacancyTag';
import useModal from '../../common/useModal';


export default function ApplicantTag(
    { tag, applicationId, jobId, onRemove }
    : { tag: TagType, applicationId: number, jobId: number }
) {
    const handleRemoveClick = React.useCallback(() => {
        return sendRequest('/api/jobs/applicant-tag/', {
            method: 'delete',
            body: JSON.stringify({
                applicationId,
                vacancyTagId: tag.id,
            })
        }).then(response => {
            const { ok } = response;

            if (ok) {
                updateGlobalData(removeTag, { applicationId, vacancyId: jobId, vacancyTagId: tag.id });

                onRemove && onRemove(tag, applicationId);
            }

            return response;
        });
    }, [applicationId, tag, jobId, onRemove]);

    const updateTag = React.useCallback(data => {
        return helpers.postTag(data, parseInt(jobId, 10));
    }, [jobId]);

    const destroyTag = React.useCallback(() => {
        return sendRequest('/api/jobs/vacancy-tag/', {
            method: 'delete',
            body: JSON.stringify({
                id: tag.id,
                vacancyId: parseInt(jobId, 10),
            })
        }).then(response => {
            updateGlobalData(destroyJobTag,
                            { vacancyId: jobId, tagId: tag.id});
        });
    }, [jobId, tag.id]);

    const [showModal, { show: openModal, hide: closeModal }] = useModal(false);

    return <>
        <VacancyTag
            tag={tag}
            onClick={openModal}
            onRemove={handleRemoveClick}
            removeTitle={onRemove ? null : 'click to unassign tag from applicant'}
        />
        {jobId &&
            <EditTagModal show={showModal}
                          tag={tag}
                          jobId={jobId}
                          onDestroy={destroyTag}
                          onSave={updateTag}
                          onHide={closeModal} />
        }
    </>;
}
