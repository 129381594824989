import React from 'react';
import DocumentTitle from 'react-document-title';

import type { ArticleType } from './Types';
import ArticleLink from '../articles/ArticleLink';
import { ConceptList } from '../articles/Concept';
import Collapsible from '../widgets/Collapsible';
import { AuthorList } from '../articles/Authors';


export default function RefereedArticle({ article }: { article: ArticleType }) {
    return <div>
        <DocumentTitle title={`Referee Finder - ${article.title}`} />
        <h2><ArticleLink {...article} /></h2>
        <AuthorList authors={article.authors}
                    authorsCount={article.authorsCount}
                    short />
        <Collapsible>
            <div>{article.abstract}</div>
        </Collapsible>
        {!!article.concepts && !!article.concepts.length &&
            <h3>Concepts<sup>{article.concepts.length}</sup></h3>
        }
        <ConceptList concepts={article.concepts} collapsible />
        <div>&nbsp;</div>
    </div>;
}
