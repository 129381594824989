import React from 'react';
import * as Sentry from '@sentry/browser';

import { anonymousUser } from './constants';
import { checkStatus } from '../utils';

const CurrentUserContext = React.createContext();


function transformUser(user) {
    const activeQuotas = {};
    user['active_quotas'].forEach(([quotaName, value]) => {
        activeQuotas[quotaName] = {
            canBePrivate: value['private'],
            organizations: value['organizations_ids'].map(
                orgId => user.organizations.filter(
                    org => org.id === orgId)[0]),
        };
    });

    return {
        id: user.id,
        email: user.email,
        isAuthenticated: user.is_authenticated,
        isExpired: user.is_expired,
        isSuperuser: !!user.is_superuser,
        authorId: user.author_id,
        linkedAuthor: user.linked_author,
        organizations: user.organizations,
        activeQuotas: activeQuotas,
        hasLabelsAccess: user.has_labels_access,
        hasEditableLabelsAccess: user.has_editable_labels_access,
        canManageCollections: user.can_manage_collections,
        orgadminOrganizationsIds: user.orgadmin_organizations_ids,
        orgdevOrganizationsIds: user.orgdev_organizations_ids,
        showChangelogLink: user.show_changelog_link,
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE':
            return transformUser(action.payload);
        case 'UPDATE_PART':
            return {
                ...state,
                ...action.payload,
            };
        case 'CLEAR':
            return anonymousUser;
        default:
            throw new Error();
    }
};

export const CurrentUserContextProvider = ({ children }) => {
    const initialState = transformUser(window._INITIAL_USER);
    const [currentUser, dispatch] = React.useReducer(reducer, initialState);

    const setUser = React.useCallback(user => {
        dispatch({
            type: 'UPDATE',
            payload: user,
        });

        if (process.env.NODE_ENV === 'production') {
            Sentry.configureScope(scope => {
                scope.setUser({ email: user.email });
            });
        }
        window.location.reload();
    }, [dispatch]);

    const fetchUser = React.useCallback(() => {
        return fetch('/api/auth/current-user/', { credentials: 'same-origin' })
            .then(checkStatus)
            .then(response => response.json())
            .then(setUser);
    }, [setUser]);

    return (
        <CurrentUserContext.Provider value={[currentUser, { fetchUser }]}>
            {children}
        </CurrentUserContext.Provider>
    );
};

export default CurrentUserContext;
