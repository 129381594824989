import React, { Fragment } from 'react';

import type { AdvisorType } from './Types';


export default function LinkToRecommendation({ advisor, children }: { advisor: AdvisorType, children: any }) {
    return <span className="actions">
        {!!advisor.filename && advisor.id && <Fragment>
            <a href={`/api/jobs/recommendation/${advisor.id}/${advisor.filename}`}
               target="_blank"
               rel="noopener noreferrer">{ children || 'recommendation'}</a>
        </Fragment>}
    </span>;
}
