import React from 'react';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import { cx } from '../utils';

import './omnibox.css';


function OmniboxOmni({ icon, text } : { icon?: string, text?: string }) {
    return <span className="omni">
        {icon && <span className="omni-icon"><Glyphicon glyph={icon}/></span>}
        {text && <span className="omni-text">{text}</span>}
    </span>;
}

function OmniboxSubmit({ children }) {
    return <div className="input-group-btn omni-submit-btn">
        {children}
    </div>;
}

export default class Omnibox extends React.Component {
    static Omni = OmniboxOmni;
    static Submit = OmniboxSubmit;

    static defaultProps = {
        className: ''
    };

    render() {
        let omni;
        let submit;
        const rest = [];

        React.Children.forEach(this.props.children, c => {
            if (c && c.type === Omnibox.Omni) {
                omni = c;
            } else if (c && c.type === Omnibox.Submit) {
                submit = c;
            } else {
                rest.push(c);
            }
        });

        return <div className={cx('omnibox', this.props.className)}>
            {omni}
            <div className="rest">
                {rest}
            </div>
            {submit}
        </div>;
    }
}
