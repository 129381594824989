import React from 'react';

import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import NavItem from 'react-bootstrap/lib/NavItem';

import { useCurrentUser } from '../accounts/hooks';


export default function OntologyNav() {
    const [currentUser] = useCurrentUser();

    return <ul className="nav nav-tabs">
        <li role="presentation">
            <a href="/ontology/concepts/">Concepts</a>
        </li>
        {!!currentUser.activeQuotas.ontology_moderator && <>
            <li role="presentation">
                <a href="/ontology/rescan-queue/">Rescan queue</a>
            </li>
            <li role="presentation">
                <a href="/ontology/possible-concepts/">Possible concepts</a>
            </li>
            <LinkContainer to="/ontology/import">
                <NavItem>Import from list</NavItem>
            </LinkContainer>
            <LinkContainer to="/ontology/log">
                <NavItem>Ontology changelog</NavItem>
            </LinkContainer>
        </>}
    </ul>
}
