import React from 'react';

import { ConceptList } from '../articles/Concept';
import type { ConceptType } from './Types';


export default function RefereeConcepts(
    { mostRelevantConcepts, highlightTopics }
    : { mostRelevantConcepts: Array<ConceptType>, highlightTopics: boolean }
) {
    return !!mostRelevantConcepts.length && <div className="referee-concepts">
        <div className="list-label">Most relevant concepts:</div>
        <ConceptList concepts={mostRelevantConcepts}
                     collapseThreshold={12}
                     collapsedCount={10}
                     collapsible
                     highlightTopics={highlightTopics} />
    </div>;
}
