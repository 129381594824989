import React from 'react';
import type { Node } from 'react';

import Button from 'react-bootstrap/lib/Button';


type LoadingButtonPropsType = {
    working: bool,
    done?: bool,
    workingMessage?: string,
    children?: Node,
    // Button props
};

export default function LoadingButton(props: LoadingButtonPropsType) {
    let copy;
    const {
        working, done, workingMessage = 'Saving', doneMessage = 'Done!', children, ...buttonProps
    } = props;

    if (working) {
        copy = <div>{workingMessage}<span className="loading" /></div>;
    } else if (done) {
        copy = <div>{doneMessage}</div>;
    } else {
        copy = children;
    }

    return <Button { ...buttonProps }>
        {copy}
    </Button>;
}
