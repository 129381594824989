import React from 'react';

import Button from 'react-bootstrap/lib/Button';

import AuthorLink from '../authors/AuthorLink';
import ArticleLink from '../articles/ArticleLink';
import { cx, interpose } from '../utils';


export default function ArticleSuggest(
    { className, isActive, isAdded, onClick, article,
        authorsLinks = false, authorsCount = 5 }
) {
    const handleClick = React.useCallback(() => onClick(article), [onClick, article]);

    const displayedAuthors = article.authors && interpose(
        article.authors.slice(0, authorsCount).map(author => {
            return authorsLinks && author.id ?
                <AuthorLink key={author.id} {...author} /> : author.name;
        }), ', ');
    if (article.authors && article.authors.length > authorsCount) {
        displayedAuthors.push(' et al.')
    }

    return <li className={cx(className, {'active': isActive, 'with-action': !!onClick})}>
        <div className="article-card">
            <div className="brief">
                <ArticleLink
                    {...article}
                    id={article.articleId}
                    className="article-title"
                    target="_blank"
                    showNotableBadge
                />
                <p className="article-authors">
                    <strong>{article.year}</strong>
                    {' '}
                    {displayedAuthors}
                </p>
            </div>
        </div>
        {!!onClick &&
            <div className="action">
                <Button onClick={handleClick}
                        bsStyle={isAdded ? 'danger' : 'success'}
                        bsSize="sm">{isAdded ? 'Remove' : 'Add'}</Button>
            </div>
        }
    </li>;
}

