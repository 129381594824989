import { graphql } from 'react-apollo';
import gql from 'graphql-tag';


export default graphql(gql`
query ontologyLogs($conceptId: Int, $status: String) {
  ontologyLogs(concept_id: $conceptId, status: $status) {
    id
    dateCreated
    dateModified
    createdByEmail
    modifiedByEmail
    status
    actions
  }
}
`, {
        options: ({conceptId, status = 'pending'}) => ({ variables: {
            conceptId: Number(conceptId),
            status
        }}),
    }
);
