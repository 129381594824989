import type { TopConceptType, AffiliationItemType } from '../authors/Types';
import type { ArticleType } from '../articles/Types';
import { voteLabels } from './helpers';


export type AttachmentFormatType = (
    'cv' | 'researchStatement' | 'teachingStatement' | 'coverLetter' | 'publicationList');

export type AdvisorType = {
    groupId?: string | number,
    id?: string,
    name: string,
    email: string,
    nameError?: string,
    emailError?: string,
    isPhdAdvisor?: boolean,
    hasPostedRecommendation?: boolean,
    filename?: string,
    attorneyToken?: string,
    authorId: ?number,
};

type ReferredHiringCommitteeType = { [key: number]: number };

export type AdvisorWithScoresType = {
    applicantsIds: Array<number>,
    authorId: ?number,
    citedByHiringCouncil: ReferredHiringCommitteeType,
    groupId: any,
    email: string,
    mostRelevantArticles: Array<*>,
    name: string,
    rank: number,
    id: string,
    rankReason: Array<string>,
    referHiringCouncil: ReferredHiringCommitteeType,
    similarityCombined: number,
    similarityMax: number,
    articlesCount: number,
    citationsCount: number,
    hIndex: number,
};

export type AttachmentType = {
    filename: string,
    id: string,
    formatType: AttachmentFormatType
};

export type VoteLabelType = $Values<typeof voteLabels>;

type VoteType = {
    voteType: VoteLabelType,
    name: string,
    ownVote: boolean
};

export type CommentType = {
    comment: string,
    userId: number,
    name: string,
    equalityOfficer?: boolean,
    ownComment?: boolean
};

export type TagType = {
    id: number,
    vacancyId: number,
    name: string,
    description: string,
    autotag: boolean
};

export type TopicType = {
    name: string,
    description: string,
    autotag: boolean,
    applicantsIds: Array<number>
};

export type ApplicationType = {
    applicationId: number,
    vacancyId: number,
    authorName: string,
    authorId: ?number,
    articlesCount: ?number,
    citationsCount: ?number,
    hIndex: ?number,
    email: string,
    phdAwardDate: string,
    advisors: Array<AdvisorType>,
    attachments: Array<AttachmentType>,
    mostRelevantArticles: Array<ArticleType>,
    votes: Array<VoteType>,
    comments: Array<CommentType>,
    rank: number,
    rankReason: Array<string>,
    citedByHiringCouncil?: ReferredHiringCommitteeType,
    referHiringCouncil?: ReferredHiringCommitteeType,
    similarityCombined?: number,
    similarityMax?: number,
    tags?: Array<TagType>,
    tagsIds: Array<$PropertyType<TagType, 'id'>>,
    isEmailed: boolean,
};


export const VacancyStatus = {
    draft: 'draft',
    published: 'published',
    closed: 'closed',
    deleted: 'deleted',
}

export type JobType<ArticleItemGeneric> = {
    id: ?number,
    title: string,
    descriptionMarkdown: string,
    descriptionRendered: string,
    startInfo: string,
    duration: string,
    dateDeadline: string,
    affiliation: string,
    infoEmail: string,
    canEdit?: boolean,
    isActive: boolean,
    canViewApplicants?: boolean,
    requirements: Array<string>,
    articles: Array<ArticleItemGeneric>,
    text: string,
    applicationId?: number,
    application?: ApplicationType,
    actualApplicationsCount?: number,
    prelimApplicationsCount?: number,
    status: VacancyStatus.draft | VacancyStatus.published | VacancyStatus.closed,
};

export type AuthorStatsType = {
    id: number,
    name: string,
    articlesCount: number,
    citationsCount: number,
    hIndex: number,
    topConcepts: Array<TopConceptType>
};


export type FoundArticleType = {
    articleId: number,
    authors: {
        id: number,
        name: string,
    },
    authorsCount: number,
    title: string,
    year: number,
};


export type FoundAuthorType = {
    id: number,
    name: string,
    articlesCount: number,
    hIndex: number,
    affiliations: Array<AffiliationItemType>
};
