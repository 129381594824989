import React from 'react';
import Reflux from 'reflux';

import Button from 'react-bootstrap/lib/Button';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';

import Checkbox from 'react-bootstrap/lib/Checkbox';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';
import TextWithTooltip from '../widgets/TextWithTooltip';
import { SuggestBox } from '../widgets/Suggest';
import { SelectOptionsFromDict, SelectOptGroupsFromList } from '../widgets/SelectOptions';
import { useRequest, sendRequest } from '../datastore';

import type { SettingsType } from './Types';
import helpers from './helpers';
import withCurrentUser from '../common/withCurrentUser';
import BasicRecommendation from '../recommendations/BasicRecommendation';
import type { RecommendationTopicType } from '../recommendations/RecommendationTopic';
import type {
    RecommendationTopicStateType
} from '../recommendations/RecommendationTopic';
import {
    ChosenRecommendationTopicsActions, ChosenRecommendationTopicsStore
} from '../recommendations/ChosenRecommendationTopicsStore';
import { singlePlural, interpose, cx } from '../utils';


export class RefereesSettings extends Reflux.PureComponent<
    {
        docKind: string,
        docId: number,
        settingsId: number | null,
        defaultSettings: SettingsType,
        availableSettings: Array<string>,
        settings: SettingsType,
        availableTopics: Array<RecommendationTopicType>,
        docTopics: Array<RecommendationTopicType>,
        countries: Array<{ name: string, code: string, count: number }>,
        authorsGroups: Array<{ id: number, display_name: string, owner_label: ?string }>,
        updating: boolean,
        customAuthorIds: Array<number>,
        onApply?: () => any,
        currentUser: any,
        message: any,
    }
> {
    state = {
        updating: this.props.updating,
        settingsId: this.props.settingsId,
        topics: this.props.availableTopics,
        ...helpers.mergeSettingsState(this.props.settings, this.props.defaultSettings),
    };

    static defaultProps = {
        availableTopics: [],
        docTopics: [],
        updating: false,
        customAuthorIds: [],
    };

    store = ChosenRecommendationTopicsStore;
    storeKeys = ['topics'];

    componentDidMount() {
        ChosenRecommendationTopicsActions.setTopics(this.getTopicsWithoutBasics(this.props.availableTopics));
    }

    componentDidUpdate(prevProps) {
        this.setState({ updating: this.props.updating });
        const newSettings = this.props.settings;
        if (newSettings.topics !== prevProps.settings.topics) {
            ChosenRecommendationTopicsActions.setTopics(this.getTopicsWithoutBasics(this.props.availableTopics));
        }
        if (newSettings !== prevProps.settings) {
            const customArticlesYearsLimitChecked = [null, 5, 10].indexOf(newSettings.articles_years_limit || null) === -1;
            // we update the value only if it has updated in the settings
            const customArticlesYearsLimit = customArticlesYearsLimitChecked
                                             ? newSettings.articles_years_limit
                                             : this.state.customArticlesYearsLimit;

            this.setState({
                settings: {
                    ...helpers.defaultSettings,
                    ...newSettings,
                    include_countries: newSettings.include_countries || [],
                    exclude_countries: newSettings.exclude_countries || [],
                    ignore_topics: newSettings.ignore_topics !== undefined && newSettings.ignore_topics !== null
                        ? newSettings.ignore_topics
                        : !newSettings.topics,
                    ignore_countries: newSettings.ignore_countries !== undefined && newSettings.ignore_countries !== null
                        ? newSettings.ignore_countries
                        : (!newSettings.include_countries && !newSettings.exclude_countries),
                    ignore_authors_group: newSettings.ignore_authors_group !== undefined && newSettings.ignore_authors_group !== null
                        ? newSettings.ignore_authors_group
                        : (!newSettings.authors_groups_ids),
                },
                settingsId: this.props.settingsId,
                customArticlesYearsLimit,
                customArticlesYearsLimitChecked,
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        ChosenRecommendationTopicsActions.resetRecommendation();
    }

    getTopicsWithoutBasics(topics) {
        return (topics || [])
            .map((t, i) => ({
                ...t,
                disabled: t.status === helpers.TOPIC_STATUSES.excluded,
                required: t.status === helpers.TOPIC_STATUSES.required,
                concepts: t.concepts.filter(c => !c.basic)
            }))
            .filter(t => t.concepts.length);
    }

    createHandleTopicActionChange = (idx: number) => {
        return (value: string) => {
            switch (value) {
                case 'required':
                    ChosenRecommendationTopicsActions.markTopicRequired(idx, true);
                    break;
                case 'disabled':
                    ChosenRecommendationTopicsActions.toggleTopic(idx, true);
                    break;
                default:
                    ChosenRecommendationTopicsActions.resetTopicFlags(idx);
                    break;
            }
        }
    }

    createDeleteTopic = (idx: number) => {
        return () => {
            ChosenRecommendationTopicsActions.deleteTopic(idx);

            if (this.state.topics.length === 1) {
                ChosenRecommendationTopicsActions.addTopic();
            }
        };
    }

    includeCountry = (item) => {
        const countries = this.state.settings.include_countries || [];
        this.setState({
            settings: {
                ...this.state.settings,
                include_countries: countries.indexOf(item) === -1 ? [...countries, item] : countries,
            }
        });
    }

    createRemoveIncludeCountry = (item) => () => {
        this.setState({
            settings: {
                ...this.state.settings,
                include_countries: (this.state.settings.include_countries || []).filter(c => c !== item)
            }
        });
    }

    excludeCountry = (item) => {
        const countries = this.state.settings.exclude_countries || [];
        this.setState({
            settings: {
                ...this.state.settings,
                exclude_countries: countries.indexOf(item) === -1 ? [...countries, item] : countries,
            }
        });
    }

    createRemoveExcludeCountry = (item) => () => {
        this.setState({
            settings: {
                ...this.state.settings,
                exclude_countries: (this.state.settings.exclude_countries || []).filter(c => c !== item)
            }
        });
    }

    renderTopicActions(topic: RecommendationTopicType, state: RecommendationTopicStateType) {
        const idx = this.state.topics.indexOf(topic);
        const value = topic.disabled ?
            'disabled'
            : topic.required ?
                'required'
                : 'included';

        return <ButtonToolbar>
            <ToggleButtonGroup type="radio"
                               name="topic-status"
                               value={value}
                               onChange={this.createHandleTopicActionChange(idx)}>
                <ToggleButton value="required">
                    <Glyphicon glyph="arrow-up" style={{color: "orange"}} />&nbsp;
                    Boosted
                </ToggleButton>
                <ToggleButton value="included">
                    <Glyphicon glyph="ok-circle" style={{color: "green"}} />&nbsp;
                    Included
                </ToggleButton>
                <ToggleButton value="disabled">
                    <Glyphicon glyph="ban-circle" style={{color: "#666"}} />&nbsp;
                    Ignored
                </ToggleButton>
            </ToggleButtonGroup>
            <Button className="delete-topic btn"
                    title="Delete topic"
                    style={{marginLeft: "15px"}}
                    onClick={this.createDeleteTopic(idx)}>
                <Glyphicon glyph="trash" style={{color: "#f00"}} />
            </Button>
        </ButtonToolbar>;
    }

    renderTopicActions = this.renderTopicActions.bind(this);

    apply = () => {
        this.setState({ updating: true });
        const settings = this.getSettings();
        this.props.onApply(settings);
    }

    getSettings = () => {
        const settings = this.props.availableSettings.reduce((acc, key) => ({
            ...acc,
            ...{ [key]: this.state.settings[key] }
        }), {});

        if (this.props.availableSettings.indexOf('topics') !== -1
                && this.state.topics) {
            settings.topics = helpers.urlifyTopics(this.state.topics);
        }

        return settings;
    }

    resetSettings = () => {
        this.setState({
            settings: {
                ...this.props.defaultSettings,
                ignore_countries: true,
                ignore_topics: true,
                ignore_authors_group: true,
            }
        });

        const topics = this.getTopicsWithoutBasics(this.props.docTopics);
        ChosenRecommendationTopicsActions.setTopics(topics);
    }

    loadSettings = (settingsId) => {
        this.setState({
            settingsId: settingsId
        }, () => {
            const settings = this.getSettings();
            this.props.onApply(settings);
        });
    }

    saveSettings = () => {
        const title = prompt("Enter settings label", new Date().toLocaleString().replace(',',''));
        if (title === null) {
            // cancelled
            return;
        }
        let settings = this.getSettings();

        this.setState({updating: true}, () =>

            sendRequest(`/api/referees/${this.props.docKind}/${this.props.docId}/save-settings`, {
                credentials: 'same-origin',
                method: 'POST',
                headers: {'Content-type': 'application/json',},
                body: JSON.stringify({
                    settings: settings,
                    title: title
                })
            })
                .then(response => {
                    if (response.ok) {
                        this.setState({
                            settingsId: response.value.savedSettings.id,
                        }, () => {
                            const settings = this.getSettings();
                            this.props.onApply(settings, response.value.savedSettings.id);
                        });
                    }

                    this.setState({updating: false});
                })
        );

    }

    setSettingFromIntInput = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({
            settings: {
                ...this.state.settings,
                ...helpers.getUpdateForIntSetting(e.currentTarget.name, e.currentTarget.value)
            }
        });
    }

    addAuthorsGroup = (e: SyntheticEvent<HTMLInputElement>) => {
        const authors_groups_ids = this.state.settings.authors_groups_ids || [];
        const authorsGroupId = parseInt(e.currentTarget.value, 10) || null;
        if (!authorsGroupId) {
            return null;
        }

        this.setState({
            settings: {
                ...this.state.settings,
                authors_groups_ids: authors_groups_ids.indexOf(authorsGroupId) === -1 ? [...authors_groups_ids, authorsGroupId] : authors_groups_ids,
            }
        });
    }

    createRemoveAuthorsGroup = (item) => () => {
        this.setState({
            settings: {
                ...this.state.settings,
                authors_groups_ids: (this.state.settings.authors_groups_ids || []).filter(ag => ag !== item)
            }
        });
    }

    setArticlesYearsLimit = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({
            settings: {
                ...this.state.settings,
                articles_years_limit: parseInt(e.currentTarget.value, 10) || null,
            },
            customArticlesYearsLimitChecked: false,
        })
    }

    setCustomArticlesYearsLimit = (e: SyntheticEvent<HTMLInputElement>) => {
        const value = parseInt(e.currentTarget.value, 10) || null;
        this.setState({
            settings: {
                ...this.state.settings,
                articles_years_limit: value,
            },
            customArticlesYearsLimit: e.currentTarget.value,
            customArticlesYearsLimitChecked: true,
        });
    }

    setSettingsFromStringInput = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({
            settings: {
                ...this.state.settings,
                [e.currentTarget.name]: e.currentTarget.value || '',
            }
        })
    }

    setRefereedAuthorIncludeConsortiums = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({
            settings: {
                ...this.state.settings,
                refereed_author_include_consortiums: e.currentTarget.checked,
            }
        })
    }

    toggleSetting = (setting) => {
        this.setState({
            settings: {
                ...this.state.settings,
                [setting]: !this.state.settings[setting]
            }
        })
    }

    toggleTopics = () => { this.toggleSetting('ignore_topics') }
    toggleCountries = () => { this.toggleSetting('ignore_countries') }
    toggleAuthorsGroup = () => { this.toggleSetting('ignore_authors_group') }
    toggleDiversify = () => { this.toggleSetting('diversify') }
    toggleEmailYearsLimit = () => {
        let newValue = this.state.settings.email_years_limit ? null : 3;
        this.setState({
            settings: {
                ...this.state.settings,
                email_years_limit: newValue,
            },
        })
    }

    toggleExcludeAuthorsWithRetractedArticles = () => {
        this.toggleSetting('exclude_authors_with_retracted_articles')
    }

    toggleExcludeCoi = () => {
        this.toggleSetting('exclude_coi')
    }

    render() {
        const { docKind, availableSettings, countries, authorsGroups } = this.props;
        const refereeType = docKind === 'vacancy' ? 'candidate' : 'referee';

        const suggestFetch = (q) => {
            let results = countries.filter(
                ({ code, name }) =>
                code.toLowerCase().indexOf(q.toLowerCase()) !== -1
                || name.toLowerCase().indexOf(q.toLowerCase()) !== -1
            ).map(country => ({
                value: country.name,
                label: country.name + (country.count ? ` — ${country.count}` : ''),
                title: country.count ? `${country.count} matches` : '',
                country: country
            }));
            return Promise.resolve({
                results,
                total: results.length
            });
        };
        const suggestIncludeCallback = (q) => {
            if (q.isNotSuggest) return false;
            this.includeCountry(q.country.id)
        };

        const suggestExcludeCallback = (q) => {
            if (q.isNotSuggest) return false;
            this.excludeCountry(q.country.id)
        };

        const updating = !!(this.props.updating || this.state.updating);

        const settings = this.state.settings;

        return <div className={cx('referees-settings-form', { 'disabled': !!updating })}>
            <div>
                { this.props.message }

                {docKind === 'author' && <>
                    <h3>Filters for the refereed author:</h3>
                    <FormGroup style={{display: "flex"}}>
                        <label htmlFor="refereed_author_articles_written_since_year"
                               className="control-label col-sm-6">
                            Match by papers written since year
                        </label>
                        <div className="col-sm-6">
                            <input
                                name="refereed_author_articles_written_since_year"
                                min="0"
                                onChange={this.setSettingFromIntInput}
                                className="form-control"
                                value={settings.refereed_author_articles_written_since_year || ''}
                                type="number" />
                        </div>
                    </FormGroup>
                    {this.props.currentUser?.activeQuotas.author_positions && <FormGroup style={{display: "flex"}}>
                        <label htmlFor="refereed_author_contribution_in_article"
                               className="control-label col-sm-6">
                            Match by contribution in article
                        </label>
                        <div className="col-sm-6">
                            <select
                                name="refereed_author_contribution_in_article"
                                className="form-control"
                                onChange={this.setSettingsFromStringInput}
                                value={settings.refereed_author_contribution_in_article || ''}
                                style={{width: "160px"}}>
                                <SelectOptionsFromDict dict={helpers.CONTRIBUTION_IN_ARTICLE_VALUE2NAME}/>
                            </select>
                        </div>
                    </FormGroup>}
                    <FormGroup style={{display: "flex"}}>
                        <label htmlFor="refereed_author_include_consortiums"
                               className="control-label col-sm-6">
                            Include consortiums
                        </label>
                        <div className="checkbox col-sm-6">
                            <label>
                                <input name="refereed_author_include_consortiums"
                                       type="checkbox"
                                       style={{marginTop: "1px"}}
                                       checked={settings.refereed_author_include_consortiums || false}
                                       onChange={this.setRefereedAuthorIncludeConsortiums} />
                            </label>
                        </div>
                    </FormGroup>
                    <FormGroup style={{display: "flex"}}>
                        <div className="col-sm-6" style={{textAlign: "right"}}>
                            <label htmlFor="refereed_author_article_max_authors"
                                   className="control-label">
                                Only match by papers with at most X authors
                            </label>
                            <span className="help-block">
                                overrides "Include consortiums"
                            </span>
                        </div>
                        <div className="col-sm-6">
                            <input
                                name="refereed_author_article_max_authors"
                                min="0"
                                onChange={this.setSettingFromIntInput}
                                className="form-control"
                                value={settings.refereed_author_article_max_authors || ''}
                                type="number" />
                        </div>
                    </FormGroup>

                    <h3 style={{display: "inline-block"}}>Filters for the candidates:</h3>
                </>}

                <FormGroup>
                    {availableSettings?.indexOf('candidates_cache_limit') !== -1 &&
                        <input name="candidates_cache_limit" type="hidden" value={settings.candidates_cache_limit}/>}
                    {availableSettings?.indexOf('nearest_articles_limit') !== -1 &&
                        <input name="nearest_articles_limit" type="hidden" value={settings.nearest_articles_limit}/>}
                    {availableSettings?.indexOf('top_author_articles') !== -1 && <>
                        <Radio name="top_author_articles"
                            value=""
                            onChange={this.setSettingFromIntInput}
                            checked={settings.top_author_articles === null}
                            inline>
                            <TextWithTooltip id="tooltip_top_author_articles_all" help="Consider all articles when calculating referee scores. More senior experts.">
                                All articles
                            </TextWithTooltip>
                        </Radio>
                        <Radio name="top_author_articles"
                            value="10"
                            onChange={this.setSettingFromIntInput}
                            checked={settings.top_author_articles === 10}
                            inline>
                            <TextWithTooltip id="tooltip_top_author_articles_10" help="Calculate referee scores using their best 10 articles by similarity. More specific experts.">
                                Top 10 articles
                            </TextWithTooltip>
                        </Radio>
                        <Radio name="top_author_articles"
                               value="3"
                               onChange={this.setSettingFromIntInput}
                               checked={settings.top_author_articles === 3}
                               inline>
                            <TextWithTooltip id="tooltip_top_author_articles_3" help="Calculate referee scores using their best 3 articles by similarity. The most specific experts.">
                                Top 3 articles
                            </TextWithTooltip>
                        </Radio>
                    </>}

                </FormGroup>
                {availableSettings?.indexOf('articles_years_limit') !== -1 && <FormGroup>
                    <Radio name="articles_years_limit"
                        value=""
                        onChange={this.setArticlesYearsLimit}
                        checked={settings.articles_years_limit === null && !this.state.customArticlesYearsLimitChecked}
                        inline>
                        <TextWithTooltip id="tooltip_articles_years_limit_all" help="Consider all articles">
                            All years
                        </TextWithTooltip>
                    </Radio>
                    <Radio name="articles_years_limit"
                        value="10"
                        onChange={this.setArticlesYearsLimit}
                        checked={settings.articles_years_limit === 10 && !this.state.customArticlesYearsLimitChecked}
                        inline>
                        <TextWithTooltip id="tooltip_articles_years_limit_10" help="Consider only articles written during the recent years">
                            Last 10 years
                        </TextWithTooltip>
                    </Radio>
                    <Radio name="articles_years_limit"
                        value="5"
                        onChange={this.setArticlesYearsLimit}
                        checked={settings.articles_years_limit === 5 && !this.state.customArticlesYearsLimitChecked}
                        inline>
                        <TextWithTooltip id="tooltip_articles_years_limit_5" help="Consider only articles written during the recent years">
                            Last 5 years
                        </TextWithTooltip>
                    </Radio>
                    <Radio name="articles_years_limit"
                        defaultValue={this.state.customArticlesYearsLimit}
                        onChange={this.setCustomArticlesYearsLimit}
                        checked={this.state.customArticlesYearsLimitChecked}
                        style={{verticalAlign: "text-top"}}
                        inline>
                        <TextWithTooltip id="tooltip_articles_years_limit_custom" help="Consider only articles written during the recent years">
                            <span className="form-inline number-filter">Last&nbsp;
                                <input
                                    name="articles_years_limit"
                                    onChange={this.setCustomArticlesYearsLimit}
                                    className="form-control"
                                    min="1"
                                    value={this.state.customArticlesYearsLimit || ""}
                                    style={{marginTop: "-3px"}}
                                    type="number" />
                             &nbsp;years</span>
                        </TextWithTooltip>
                    </Radio>

                </FormGroup>}

                {(availableSettings?.indexOf('min_jif') !== -1 || availableSettings?.indexOf('max_jif_percentile') !== -1) &&
                 <>
                    <div>
                        <div style={{display: 'inline-block', padding: '0 10px', paddingTop: '15px', borderRight: this.props.currentUser?.activeQuotas.journals_impact_factor ? '1px solid #ccc' : 'none'}}>
                        {availableSettings?.indexOf('min_h_index') !== -1 && <FormGroup>
                            <div className="form-inline range-filter">
                                <input
                                    id="min_h_index"
                                    name="min_h_index"
                                    min="0"
                                    onChange={this.setSettingFromIntInput}
                                    className="form-control"
                                    value={settings.min_h_index || ''}
                                    type="number" />
                                <span>≤</span>
                                <label htmlFor="min_h_index">h-index</label>
                                <span>≤</span>
                                <input
                                    name="max_h_index"
                                    min="0"
                                    onChange={this.setSettingFromIntInput}
                                    className="form-control"
                                    value={settings.max_h_index || ''}
                                    type="number" />
                            </div>
                        </FormGroup>}
                        {availableSettings?.indexOf('min_academic_age') !== -1 && <FormGroup>
                            <div className="form-inline range-filter">
                                <input
                                    id="min_academic_age"
                                    name="min_academic_age"
                                    min="0"
                                    className="form-control"
                                    onChange={this.setSettingFromIntInput}
                                    value={settings.min_academic_age || ''}
                                    type="number" />
                                <span>≤</span>
                                <label htmlFor="min_academic_age">
                                    <TextWithTooltip id="tooltip_min_academic_age" help={"Number of years since the first publication."}>
                                        Academic age
                                    </TextWithTooltip>
                                </label>
                                <span>≤</span>
                                <input
                                    name="max_academic_age"
                                    min="0"
                                    className="form-control"
                                    onChange={this.setSettingFromIntInput}
                                    value={settings.max_academic_age || ''}
                                    type="number" />
                            </div>
                        </FormGroup>}
                        {availableSettings?.indexOf('min_articles_count') !== -1 && <FormGroup>
                            <div className="form-inline range-filter">
                                <input
                                    id="min_articles_count"
                                    name="min_articles_count"
                                    min="0"
                                    className="form-control"
                                    onChange={this.setSettingFromIntInput}
                                    value={settings.min_articles_count || ''}
                                    type="number" />
                                <span>≤</span>
                                <label htmlFor="min_articles_count">Articles count</label>
                                <span>≤</span>
                                <input
                                    name="max_articles_count"
                                    min="0"
                                    className="form-control"
                                    onChange={this.setSettingFromIntInput}
                                    value={settings.max_articles_count || ''}
                                    type="number" />
                            </div>
                        </FormGroup>}
                        </div>
                        {this.props.currentUser?.activeQuotas.journals_impact_factor &&
                        <div style={{display: 'inline-block', padding: '0 10px', paddingTop: '15px', verticalAlign: 'top' }}>
                        {availableSettings?.indexOf('min_jif') !== -1 && <FormGroup>
                            <div className="form-inline range-filter">
                                <input
                                    id="min_jif"
                                    name="min_jif"
                                    min="0"
                                    className="form-control"
                                    onChange={this.setSettingFromIntInput}
                                    value={settings.min_jif || ''}
                                    type="number" />
                                <span>≤</span>
                                <label htmlFor="min_jif">Journal Impact Factor</label>
                                <span>≤</span>
                                <input
                                    name="max_jif"
                                    min="0"
                                    className="form-control"
                                    onChange={this.setSettingFromIntInput}
                                    value={settings.max_jif || ''}
                                    type="number" />
                            </div>
                        </FormGroup>}
                        {availableSettings?.indexOf('max_jif_percentile') !== -1 && <FormGroup>
                            <span>Journal IF Tier:&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <Radio name="max_jif_percentile"
                                value=""
                                onChange={this.setSettingFromIntInput}
                                checked={[25, 5].indexOf(settings.max_jif_percentile) === -1}
                                inline style={{verticalAlign: 'baseline'}}>
                                Any
                            </Radio>
                            <Radio name="max_jif_percentile"
                                value="25"
                                onChange={this.setSettingFromIntInput}
                                checked={settings.max_jif_percentile === 25}
                                inline style={{verticalAlign: 'baseline'}}>
                                Top 25%
                            </Radio>
                            <Radio name="max_jif_percentile"
                                value="5"
                                onChange={this.setSettingFromIntInput}
                                checked={settings.max_jif_percentile === 5}
                                inline style={{verticalAlign: 'baseline'}}>
                                Top 5%
                            </Radio>
                        </FormGroup>}
                        </div>
                        }
                    </div>
                    <br/>
                </>}
                {this.props.currentUser?.activeQuotas.author_positions && availableSettings?.indexOf('contribution_in_article') !== -1 && <div>
                    <div className="form-inline number-filter">
                        <label htmlFor="contribution_in_article" style={{fontWeight: 'normal', paddingRight: '10px'}}>
                            <TextWithTooltip id="tooltip_contribution_in_article" help="Count papers where the author is lead, corresponding, or senior.">
                                Contribution in article
                            </TextWithTooltip>
                        </label>
                        <select
                            id="contribution_in_article"
                            name="contribution_in_article"
                            className="form-control"
                            onChange={this.setSettingsFromStringInput}
                            value={settings.contribution_in_article || ''}
                            style={{height: "2em", padding: "0 5px", marginTop: "-3px", width: "200px"}}>
                            <SelectOptionsFromDict dict={helpers.CONTRIBUTION_IN_ARTICLE_VALUE2NAME}/>
                        </select>
                    </div>
                </div>}
                {availableSettings?.indexOf('institution_type') !== -1 && <div>
                    <br/>
                    <div className="form-inline number-filter">
                        <label htmlFor="institution_type" style={{fontWeight: 'normal', paddingRight: '10px'}}>
                            <TextWithTooltip id="tooltip_institution_type" help="Choose referees with specified affiliation types only.">
                                Institution type
                            </TextWithTooltip>
                        </label>
                        <select
                            id="institution_type"
                            name="institution_type"
                            className="form-control"
                            onChange={this.setSettingsFromStringInput}
                            value={settings.institution_type || ''}
                            style={{height: "2em", padding: "0 5px", marginTop: "-3px", width: "200px"}}>
                            <SelectOptionsFromDict dict={helpers.INSTITUTION_TYPE_VALUE2NAME}/>
                        </select>
                        <span className="help-block" style={{display: 'inline-block'}}>&nbsp;&nbsp;<em>(last 2 years)</em></span>
                    </div>
                </div>}
                {this.props.currentUser?.activeQuotas.gender_info
                 && availableSettings?.indexOf('gender') !== -1 && <FormGroup className="form-inline">
                    <div className="form-inline number-filter">
                        <label htmlFor="gender" style={{fontWeight: 'normal', paddingRight: '10px'}}>
                            Gender
                        </label>
                        <select
                            id="gender"
                            name="gender"
                            className="form-control"
                            onChange={this.setSettingsFromStringInput}
                            value={settings.gender || ''}
                            style={{height: "2em", padding: "0 5px", marginTop: "-3px", width: "200px"}}>
                          <SelectOptionsFromDict dict={helpers.GENDER_VALUE2NAME}/>
                        </select>
                    </div>
                </FormGroup>}
            </div>
            <div>
                {availableSettings?.indexOf('topics') !== -1 && <>
                    <Checkbox checked={!this.state.settings.ignore_topics} onChange={this.toggleTopics}>
                        Refine {singlePlural(refereeType, 2)} based on specific concepts
                    </Checkbox>
                    {!this.state.settings.ignore_topics && <>
                        <p className="help-block" style={{margin: "0 0 9px"}}>
                            Hold [Shift] to select many concepts</p>
                        <BasicRecommendation topicActions={this.renderTopicActions}
                                             canBeEmpty={true}
                                             allowImport={false} />
                    </>}
                </>}
                {availableSettings?.indexOf('ignore_countries') !== -1 && <div className="country-filter">
                    <Checkbox checked={!this.state.settings.ignore_countries}
                              onChange={this.toggleCountries}
                              style={{display: 'inline-block', margin: '0'}}>
                        Filter {singlePlural(refereeType, 2)} by country/region of affiliation
                    </Checkbox>
                    &nbsp;&nbsp;<em>
                        (last {availableSettings?.indexOf('years_of_affiliation') !== -1 ? <>
                            &nbsp;
                            <div className="form-inline range-filter">
                                <input
                                    id="years_of_affiliation"
                                    name="years_of_affiliation"
                                    min="1"
                                    onChange={this.setSettingFromIntInput}
                                    className="form-control"
                                    value={settings.years_of_affiliation || ''}
                                    type="number" />
                            </div>
                            &nbsp;
                        </> : '2 '}years)
                    </em>

                    {!this.state.settings.ignore_countries && <Row>
                        <Col xs={6}>
                            <div>
                                <label htmlFor="include-country">
                                    Limit to:
                                </label>
                            </div>
                            <div style={{width: '250px'}}>
                                <SuggestBox
                                    minChars={0}
                                    expandable={false}
                                    maxLabelLength={70}
                                    callback={suggestIncludeCallback}
                                    shouldClearOnSelect={true}
                                    suggestFetch={suggestFetch}
                                    fetchOnFocus={true}
                                    button={null}
                                    placeholder="Type to search"
                                    showSuggestType={false}
                                    selectOnExactMatch={false}
                                />
                            </div>
                            <div style={{padding: '5px'}}>
                                {settings.include_countries && interpose(settings.include_countries?.map(id => {
                                    const c = countries.find(c => c.id === id);

                                    if (!c) return null;

                                    return <React.Fragment key={c.id}>
                                        {c.name} <button className="inline-link" onClick={this.createRemoveIncludeCountry(c.id)}>
                                            <Glyphicon glyph="remove" />
                                        </button>
                                    </React.Fragment>
                                }), ', ')}
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div>
                                <label htmlFor="exclude-country">
                                    Exclude:
                                </label>
                            </div>
                            <div style={{width: '250px'}}>
                                <SuggestBox
                                    minChars={0}
                                    expandable={false}
                                    maxLabelLength={70}
                                    callback={suggestExcludeCallback}
                                    shouldClearOnSelect={true}
                                    suggestFetch={suggestFetch}
                                    fetchOnFocus={true}
                                    button={null}
                                    placeholder="Type to search"
                                    showSuggestType={false}
                                    selectOnExactMatch={false}
                                />
                            </div>
                            <div style={{padding: '5px'}}>
                                {settings.exclude_countries && interpose(settings.exclude_countries?.map(id => {
                                    const c = countries.find(c => c.id === id);

                                    if (!c) return null;

                                    return <React.Fragment key={c.id}>
                                        {c.name} <button className="inline-link" onClick={this.createRemoveExcludeCountry(c.id)}>
                                            <Glyphicon glyph="remove" />
                                        </button>
                                    </React.Fragment>
                                }), ', ')}
                            </div>
                        </Col>
                    </Row>}
                </div>}
                {!!authorsGroups && authorsGroups.length > 0 && <div>
                    <Checkbox checked={!this.state.settings.ignore_authors_group}
                              onChange={this.toggleAuthorsGroup}>
                        Use authors groups
                    </Checkbox>
                </div>}
                {!this.state.settings.ignore_authors_group && <div>
                    <FormGroup>
                        <div className="form-inline number-filter">
                            <label htmlFor="authors_groups_ids" style={{fontWeight: 'normal', paddingRight: '5px'}}>
                                Add authors groups
                            </label>
                            <select
                                name="authors_groups_ids"
                                className="form-control"
                                onChange={this.addAuthorsGroup}
                                value=""
                                style={{height: "2em", padding: "0 5px", marginTop: "-3px", width: "200px"}}>
                                <SelectOptGroupsFromList list={authorsGroups}
                                                         value="id"
                                                         name="display_name"
                                                         optGroupName="owner_label"
                                                         defaultValue=""
                                                         defaultLabel="-- Select authors group --"/>
                            </select>
                            {!!this.state.settings.authors_groups_ids && <div style={{ paddingLeft: "55px", marginTop: "10px" }}>
                                {interpose(this.state.settings.authors_groups_ids.map((authorsGroupId, index) => {
                                    const authorsGroup = authorsGroups.find(ag => ag.id === authorsGroupId);
                                    if (!authorsGroup) return null;

                                    return <React.Fragment key={authorsGroupId}>
                                        <a href={`/authors-groups/${authorsGroupId}`}
                                           key={authorsGroupId}
                                           target="blank"
                                           className={`label ag-badge ag-badge-color-${index}`}
                                           title={authorsGroup.display_name}>
                                            <Glyphicon glyph="user"/>&nbsp;
                                            {authorsGroup.label || authorsGroup.display_name}
                                        </a>
                                        <button className="inline-link"
                                                onClick={this.createRemoveAuthorsGroup(authorsGroup.id)}>
                                            <Glyphicon glyph="remove" style={{ top: "-1px" }} />
                                        </button>
                                    </React.Fragment>}), ', ')}
                            </div>}
                        </div>
                    </FormGroup>
                    <FormGroup style={{paddingLeft: "55px", marginTop: "-10px"}}>
                        <Radio name="authors_group_effect"
                               value="highlight"
                               onChange={this.setSettingsFromStringInput}
                               checked={settings.authors_group_effect === 'highlight'}
                               inline style={{verticalAlign: 'baseline'}}>
                            Highlight authors from groups
                        </Radio>
                        <Radio name="authors_group_effect"
                               value="limit_to"
                               onChange={this.setSettingsFromStringInput}
                               checked={settings.authors_group_effect === 'limit_to'}
                               inline style={{verticalAlign: 'baseline'}}>
                            Search only in groups
                        </Radio>
                    </FormGroup>
                </div>}
                {availableSettings?.indexOf('email_years_limit') !== -1 &&
                <div>
                    <Checkbox checked={!!this.state.settings.email_years_limit}
                              onChange={this.toggleEmailYearsLimit}>
                        Display candidates with contact email only
                    </Checkbox>
                </div>}
                {availableSettings?.indexOf('exclude_authors_with_retracted_articles') !== -1 &&
                <div>
                    <Checkbox checked={!!this.state.settings.exclude_authors_with_retracted_articles}
                              onChange={this.toggleExcludeAuthorsWithRetractedArticles}>
                        Exclude authors with retracted articles
                    </Checkbox>
                </div>}
                {availableSettings?.indexOf('exclude_coi') !== -1 && <div>
                <div>
                    <Checkbox checked={!!this.state.settings.exclude_coi}
                              onChange={this.toggleExcludeCoi}
                              style={{display: 'inline-block', margin: '0'}}>
                        Exclude authors with conflicts of interest
                    </Checkbox>
                </div>
                <div className="form-inline range-filter"
                     style={{display: 'inline-block', paddingLeft: 20, marginTop: 10}}>
                    <label htmlFor="coauthorship_coi_years">Detect conflicts of interest during the last</label>
                    &nbsp;<input
                        id="coauthorship_coi_years"
                        name="coauthorship_coi_years"
                        min="1"
                        max="20"
                        onChange={this.setSettingFromIntInput}
                        className="form-control"
                        value={this.state.settings.coauthorship_coi_years || ''}
                        type="number" />
                    &nbsp;years
                </div>
                </div>}
                {availableSettings?.indexOf('diversify') !== -1 &&
                <div>
                    <Checkbox checked={!!this.state.settings.diversify}
                              onChange={this.toggleDiversify}>
                        Diversify results  <TextWithTooltip id="tooltip_diversify" help="Count each relevant article for one candidate only. Results will contain fewer coauthors.">
                            (experimental)
                        </TextWithTooltip>
                    </Checkbox>
                </div>}
            </div>
            <br/>
            <div>
                {this.props.onApply && <>
                    <ButtonToolbar>
                        <Button bsStyle="primary" onClick={this.apply} disabled={updating}>
                            <Glyphicon glyph="ok"/>&nbsp;
                            Apply
                        </Button>
                        <Button onClick={this.resetSettings} disabled={updating}>
                            Reset to defaults
                        </Button>
                        {updating && <div style={{float: 'left', paddingLeft: '10px', color: '#333', opacity: 0.65}}>
                            Loading candidates<span className="loading" aria-hidden="true" />
                         </div>}
                    </ButtonToolbar>
                    {['panel', 'folder', 'organization'].indexOf(this.props.docKind) === -1 && <ButtonToolbar style={{float: 'right'}}>
                        <SavedSettingsDropdown
                            docKind={this.props.docKind}
                            docId={this.props.docId}
                            disabled={updating}
                            selectedSettingsId={this.state.settingsId}
                            customAuthorIds={this.props.customAuthorIds} />
                        <Button onClick={this.saveSettings} disabled={updating}>Save settings</Button>
                     </ButtonToolbar>}
                </>}
            </div>
        </div>;
    }
}

function SavedSettingsDropdown({ docKind, docId, selectedSettingsId, customAuthorIds, disabled }) {

    const [response, reqstate] = useRequest(`/api/referees/${docKind}/${docId}/saved-settings#${selectedSettingsId}`);

    if (!reqstate?.ok || !response?.savedSettings?.length) {
        return null;
    }

    return <DropdownButton title="Load settings" id="load-settings-dropdown" disabled={disabled}>
        <MenuItem header>Saved settings:</MenuItem>
        {response?.savedSettings?.map((set,index) =>
            <LinkContainer to={'?' + [`settingsId=${set.id}`, ...customAuthorIds.map(id => `author=${id}`)].join('&')} key={index}>
                <MenuItem key={index} disabled={set.id === selectedSettingsId}>{set.title}</MenuItem>
            </LinkContainer>
        )}
    </DropdownButton>;
}

export default withCurrentUser(RefereesSettings);
