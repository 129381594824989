export const anonymousUser = {
    id: null,
    email: 'Anonymous',
    isAuthenticated: false,
    isExpired: false,
    authorId: null,
    roles: [],
    organizations: [],
    activeQuotas: {},
    orgadminOrganizationsIds: [],
};
