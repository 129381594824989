import React from 'react';
import { Link } from 'react-router-dom';

import CurrentUser from './CurrentUser';


function FeaturesLink({text='features'}: {text?: string}) {
    return <Link to="/">{text}</Link>;
}


export function ContactUsLink(
        {text='contact us', subject=null, body=null}:
        {text?: string, subject?: ?string, body?: ?string}
) {
    let href = 'mailto:info@prophy.ai';
    if (!!subject) {
        href += `?subject=${encodeURIComponent(subject)}`
    }

    if (!!body) {
        const delimiter = !!subject ? '&' : '?';
        href += `${delimiter}body=${encodeURIComponent(body)}`;
    }

    return <a href={href}>{text}</a>;
}


function singlePluralQuery(count) {
    return count === 1 ? 'query' : 'queries';
}


export function RecommendationGuestMessage() {
    return <div>
        <p>You are logged out. Please, <CurrentUser registerLinkMode/> or <CurrentUser loginLinkMode/> if
           you already have an account.</p>
        <p>Monitorings are concept based search queries used to track new
           scientific articles published every day.</p>
        <p>Type some word and choose a concept from our ontology - add concepts to
           topics and see the results!</p>
    </div>
}


export function SaveRecommendationGuestMessage() {
    return <div>
        <h4>This is a free version of Prophy</h4>
        <p>Guest users can only edit monitoring query and view results.</p>
        <p>Monitorings are concept based search queries used to track new
           scientific articles published every day.</p>
        <p>If you want to save your monitoring and discover new scientific
           articles according to your interests - please <CurrentUser registerLinkMode/> to become
           an academic user or <ContactUsLink/> to get an enterprise access
           to full Prophy <FeaturesLink text="capabilities"/>.</p>
        <p>Click <CurrentUser loginLinkMode/> if you already have an account.</p>
    </div>
}


export function SaveRecommendationNoQuotaMessage() {
    return <div>
        <h4>A plan with monitoring is required.</h4>
        <p>Soon you will be able to buy a plan with monitoring in one click.
           In the meantime, we grant them on request.</p>
        <p><ContactUsLink text="Request a monitoring plan"
                          subject="Monitoring plan request"
                          body="I would like to request a monitoring plan to
                                use in my daily scientific activities."/>.</p>
    </div>
}


export function SearchCallsLimitExceededGuestMessage({limit}: {limit: number}) {
    const queries = singlePluralQuery(limit);
    return <div>
        <h4>This is a free version of Prophy</h4>
        <p>Guest users can do only {limit} search {queries} per day.</p>
        <p>If you want to search scientific articles without limitations -
           please <CurrentUser registerLinkMode/> to become an academic user.
           Click <CurrentUser loginLinkMode/> if you already have an account.</p>
        <p>Full <FeaturesLink text="capabilities"/> of Prophy are available in
           enterprise edition.</p>
    </div>
}
