import React from 'react';

import type { ConceptType } from '../articles/Concept';
import { ConceptList } from '../articles/Concept';


export default class GroupedConcepts extends React.PureComponent<
    {
        groupedConcepts: Array<Array<ConceptType>>,
        collapseThreshold: number,
        collapsedCount: number,
        collapsedLinesCount: number,
        contextsLinkDocKind: ?string,
        contextsLinkDocId: ?number,
    },
    {
        collapsed: boolean,
    }
> {
    static defaultProps = {
        collapseThreshold: 8,
        collapsedCount: 5,
        collapsedLinesCount: 3,
        contextsLinkDocKind: null,
        contextsLinkDocId: null,
    }

    state = {
        collapsed: true,
    }

    isCollapsible() {
        return (
            (this.props.groupedConcepts.length > this.props.collapsedLinesCount) ||
            this.props.groupedConcepts.some(
                groupedConcepts => groupedConcepts.length > this.props.collapseThreshold));
    }

    toggle = () => {
        this.setState({ collapsed: !this.state.collapsed });
    }

    getConceptsCount() {
        return this.props.groupedConcepts.reduce((sum, current) => sum + current.length, 0);
    }

    getHiddenCount() {
        const groupedConcepts = this.props.groupedConcepts;
        const collapsedLinesCount = this.props.collapsedLinesCount;
        return (
            groupedConcepts.slice(0, collapsedLinesCount).reduce((sum, current) =>
                sum + (current.length > this.props.collapseThreshold ?
                       current.length - this.props.collapsedCount : 0), 0) +
            groupedConcepts.slice(collapsedLinesCount).reduce((sum, current) =>
                sum + current.length, 0));
    }

    render() {
        const contextsLinkDocKind = this.props.contextsLinkDocKind;
        const contextsLinkDocId = this.props.contextsLinkDocId;

        const collapsed = this.state.collapsed;
        const groupedConcepts = (
            collapsed ? this.props.groupedConcepts.slice(0, this.props.collapsedLinesCount) :
            this.props.groupedConcepts);

        return <div>
            <h3>
                Concepts<sup>{this.getConceptsCount()}</sup>
                {contextsLinkDocKind && contextsLinkDocId &&
                 <small style={{ marginLeft: "27px" }}>
                     <a href={`/${contextsLinkDocKind}/${contextsLinkDocId}/explain-extract`}>
                         contexts &rarr;
                     </a>
                 </small>}
            </h3>
            {groupedConcepts.map((groupedConcepts, i) =>
                <ConceptList key={i}
                             concepts={groupedConcepts}
                             collapsedCount={this.props.collapsedCount}
                             collapseThreshold={this.props.collapseThreshold}
                             collapsible={this.state.collapsed}
                             className="concept-list-grouped"
                             static/>
            )}
            {this.isCollapsible() && <button className="toggle" onClick={this.toggle}>
                 {collapsed ?
                    `show ${this.getHiddenCount()} more →`
                     : '← collapse'
                 }
                 </button>
            }
        </div>;
    }
}
