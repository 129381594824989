import * as React from 'react';
import uniqBy from 'lodash/uniqBy';
import queryString from 'query-string';

import AutoAffix from 'react-overlays/lib/AutoAffix';
import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Panel from 'react-bootstrap/lib/Panel';

import helpers from './helpers';
import {
    getCookieFlag, setCookieFlag, COOKIE_RF_SETTINGS_OPEN,
    COOKIE_RF_IS_GRAPH_COLLAPSED,
} from '../Cookies';
import type { DocKindType, SettingsType } from './Types';
import type { RecommendationTopicType } from '../recommendations/RecommendationTopic';
import analytics from '../common/analytics';
import { useSubscription, sendRequest, updateGlobalData } from '../datastore';
import jobHelpers from '../jobs/helpers';
import LoadingREST from '../LoadingREST';
import LoadingButton from '../widgets/LoadingButton';
import RefereesSettings from './RefereesSettings';
import RefereeChart from './RefereeChart';
import RefereesTable from './RefereesTable';
import SearchAuthors from './SearchAuthors';
import AddPreliminaryApplicantButton from './AddPreliminaryApplicantButton';
import MassActions from './MassActions';

import { cx, singlePlural, noop } from '../utils';
import { useLoadingAwareSubmit } from '../common/helpers/loading';
import Spinner from '../widgets/Spinner';
import {
    makeSettingsKey, makeTopicsKey, makeRefereesKey, enrichReferees,
    makeCustomRefereesKey,
} from './RefereesData';
import SelectedRefereesContext from './SelectedRefereesContext';

import './referees.css';


export const REFEREE_SETTINGS = [
    'candidates_cache_limit',
    'nearest_articles_limit',
    'algorithm',
    'top_author_articles',
    'articles_years_limit',

    'min_h_index', 'max_h_index',
    'min_academic_age', 'max_academic_age',
    'min_articles_count', 'max_articles_count',
    'min_jif', 'max_jif',
    'max_jif_percentile',

    'gender',
    'institution_type',
    'contribution_in_article',
    'topics',
    'ignore_topics',

    'include_countries',
    'exclude_countries',
    'ignore_countries',
    'years_of_affiliation',

    'ignore_authors_group',
    'authors_groups_ids',
    'authors_group_effect',

    'email_years_limit',
    'diversify',
    'exclude_authors_with_retracted_articles',
    'exclude_coi',
    'coauthorship_coi_years',
];

const AUTHOR_REFEREE_SETTINGS = [
    'refereed_author_articles_written_since_year',
    'refereed_author_contribution_in_article',
    'refereed_author_include_consortiums',
    'refereed_author_article_max_authors',
]

function LoadingMoreReferees({ docKind, docId, settings, settingsId, offset, refereeType = 'referee' }) {

    React.useEffect(() => {
        const qsSettings = helpers.settingsToQSDefined(settings, settingsId);
        const [key, ] = makeRefereesKey(docKind, docId);

        sendRequest(`/api/referees/${docKind}/${docId}/`, {
            method: 'POST',
            // to override json content-type in sendRequest
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
            body: `${qsSettings}&offset=${offset}`,
        })
            .then(response => {
                if (response.ok) {
                    updateGlobalData((store, { value: { hasMoreReferees, referees, totalReferees, countries } }) => {

                        store[key] = {
                            ...store[key],
                            hasMoreReferees,
                            totalReferees: store[key].totalReferees,
                            referees: store[key].referees.concat(enrichReferees(referees)),
                            countries,
                        };

                    }, response);
                }
            });
    }, [docKind, docId, settings, settingsId, offset]);

    return <LoadingButton workingMessage={`Loading more ${singlePlural(refereeType, 2)}`}
                          working
                          disabled />;
}


function LoadMoreReferees({docKind, docId, settings, settingsId, offset, refereeType = 'referee'}) {
    const [queriedOffset, setOffset] = React.useState(0);
    // Don't skip pages when queriedOffset is greater than offset.
    // It can happen when changing referees settings.
    if (queriedOffset >= offset) {
        return <LoadingMoreReferees
            docKind={docKind}
            docId={docId}
            settings={settings}
            settingsId={settingsId}
            refereeType={refereeType}
            offset={Math.min(queriedOffset, offset)} />;
    }

    return <Button onClick={() => setOffset(offset)}>
        Load more {singlePlural(refereeType, 2)}
    </Button>;
}

function VacancyCandidateAdditionalButtons({ referee, forJobId }) {
    const addPreliminary = () => {
        // @TODO: once Alerts context is created, add a message to alerts queue
        // with a link to applicants link
        return jobHelpers.addPreliminaryApplicants(forJobId, [referee.author.id]);
    };

    const { done, working, submit } = useLoadingAwareSubmit({ submitFn: addPreliminary });

    return (forJobId && forJobId !== 'null') ?
        <LoadingButton className="add-preliminary-button"
                       onClick={submit}
                       working={working}
                       workingMessage={<><Spinner /> Add as preliminary candidate</>}
                       done={done}
                       doneMessage={<><Glyphicon glyph="ok" /> Add as preliminary candidate</>}>
            Add as preliminary candidate
        </LoadingButton>
        : null;
}

export default function Referees(
    {
        doc, docKind, docId, docTopics,
        settingsId, defaultSettings,
        customAuthorIds, setCustomAuthorIds, forJobId, setSettings,
        refereeType = 'candidate',
        markedType = 'referee',
        location,
    }
    : {
        doc: any,
        docKind: DocKindType,
        docId: string,
        docTopics: Array<RecommendationTopicType>,
        settingsId: number | null,
        defaultSettings: SettingsType,
        customAuthorIds: Array<number | string>,
        setCustomAuthorIds: (authors: Array<number | string>) => any,
        forJobId: number | null,
        setSettings: (settings: SettingsType) => any,
        refereeType?: string,
        markedType?: string,
        location: any,
    }
) {
    const [hiddenRefereesIds, setHiddenRefereesIds] = React.useState([]);
    const [selectedReferees, {clearSelected}] = React.useContext(SelectedRefereesContext);
    const [canMarkAsReferees, setCanMarkAsReferees] = React.useState(false);
    const [canHideCandidates, setCanHideCandidates] = React.useState(false);
    const [displaySettings, setDisplaySettings] = React.useState();

    const hideReferees = (reason: string, comment: string) => {
        const authorsIds = selectedReferees.map(referee => referee.author.id);
        return helpers.hideReferees(docKind, docId, authorsIds, reason, comment)
            .then(({ ok, value }) => {
                if (ok) {
                    setCustomAuthorIds(customAuthorIds.filter(id => authorsIds.indexOf(id) === -1));
                    setHiddenRefereesIds(prev => prev.concat(authorsIds));
                    clearSelected(authorsIds);
                }
            });
    }

    const [reqstate, setReqstate] = React.useState({});
    React.useEffect(() => {
        const urlSettings = location.search ?
            {
                ...defaultSettings,
                ...helpers.settingsFromQS(location.search),
            }
            : defaultSettings;
        let qsSettings = helpers.settingsToQSDefined(urlSettings, settingsId);

        const [key,] = makeRefereesKey(docKind, docId);
        const [setKey,] = makeSettingsKey(docKind, docId);
        const [topKey,] = makeTopicsKey(docKind, docId);

        setReqstate({loading: true});
        if (docKind === 'proposal') {
            qsSettings = `${qsSettings}&includeMarked=1`
        }

        sendRequest(`/api/referees/${docKind}/${docId}/`, {
            method: 'POST',
            // to override json content-type in sendRequest
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
            body: qsSettings,
        })
            .then(response => {
                if (response.ok) {
                    updateGlobalData((store, { value: { hasMoreReferees, referees, topics, settings, settingsId, savedSettings, totalReferees, countries, authorsGroups, markedAuthorsIds, canMarkAsReferees, canHideCandidates } }) => {
                        store[setKey] = {...settings};
                        store[topKey] = [...(topics || [])];
                        store[key] = {
                            savedSettings,
                            settingsId,
                            hasMoreReferees,
                            referees: enrichReferees(referees),
                            totalReferees,
                            countries,
                            authorsGroups,
                            markedAuthorsIds: markedAuthorsIds || [],
                        };

                    }, response);

                    setDisplaySettings(response.value.displaySettings);
                    setCanMarkAsReferees(response.value.canMarkAsReferees);
                    setCanHideCandidates(response.value.canHideCandidates);
                    setReqstate({loading: false, ok: true});
                } else {
                    setReqstate({loading: false, ok: false, value: {error: response.error}});
                }
            });
    }, [docKind, docId, location.search, defaultSettings, settingsId]);

    const settings = useSubscription(...makeSettingsKey(docKind, docId));
    const storeTopics = useSubscription(...makeTopicsKey(docKind, docId))

    const refereesHits = useSubscription(...makeRefereesKey(docKind, docId));

    const hits = refereesHits || {};
    let { referees } = hits;

    const showOnlyCustom = helpers.stringParamToBool(
        queryString.parse(location.search).only_custom);

    const { hasMoreReferees, countries, totalReferees, markedAuthorsIds, authorsGroups } = hits;

    const customReferees = useSubscription(
        ...makeCustomRefereesKey(docKind, docId, customAuthorIds)
    ) || [];

    return <LoadingREST reqstate={reqstate} doc={settings} partialResponse what={singlePlural(refereeType, 2)}>
        <LoadedReferees
            updating={reqstate.loading}
            setCustomAuthorIds={setCustomAuthorIds}
            setSettings={setSettings}
            customAuthorIds={customAuthorIds}
            forJobId={forJobId}
            hideReferees={hideReferees}
            hiddenRefereesIds={hiddenRefereesIds}
            doc={doc}
            docId={docId}
            docKind={docKind}
            docTopics={docTopics}

            storeTopics={storeTopics}
            referees={referees}
            customReferees={customReferees}
            hasMoreReferees={hasMoreReferees}
            countries={countries}
            authorsGroups={authorsGroups}
            totalReferees={totalReferees}
            markedAuthorsIds={markedAuthorsIds}
            showOnlyCustom={showOnlyCustom}
            canMarkAsReferees={canMarkAsReferees}
            canHideCandidates={canHideCandidates}

            displaySettings={displaySettings}
            settings={settings}
            settingsId={settingsId}
            refereeType={refereeType}
            markedType={markedType}
            defaultSettings={defaultSettings}
        />
    </LoadingREST>;
}

export function LoadedReferees({
    updating,
    customAuthorIds,
    doc,
    docId,
    docKind,
    docTopics,

    storeTopics,
    referees,
    customReferees,
    hasMoreReferees,
    countries,
    authorsGroups,
    totalReferees,
    markedAuthorsIds,
    showOnlyCustom,
    canMarkAsReferees,
    canHideCandidates,

    displaySettings,
    settings,
    settingsId,
    setSettings,
    hideReferees,
    hiddenRefereesIds,
    refereeType,
    markedType,
    defaultSettings,
    forJobId,
    setCustomAuthorIds,
}) {
    if (showOnlyCustom) {
        hasMoreReferees = false;
        // we always show marked referees.
        referees = referees.filter(r => markedAuthorsIds.indexOf(r.author.id) !== -1);
    }

    if (referees && hiddenRefereesIds.length) {
        referees = referees.filter(r => hiddenRefereesIds.indexOf(r.author.id) === -1);
    }

    referees = referees || [];

    const [showSettings, setShowSettings] = React.useState(getCookieFlag(COOKIE_RF_SETTINGS_OPEN));
    const [chartHighlightAuthorId, setChartHighlightAuthorId] = React.useState(null);
    const [selectedReferees, { clearSelected }] = React.useContext(SelectedRefereesContext);
    const [isGraphCollapsed, setIsGraphCollapsed] = React.useState(getCookieFlag(COOKIE_RF_IS_GRAPH_COLLAPSED));

    const toggleSettings = () => {
        setCookieFlag(COOKIE_RF_SETTINGS_OPEN, !showSettings);
        setShowSettings(showSettings => !showSettings)
    };

    const toggleIsGraphCollapsed = () => {
        setCookieFlag(COOKIE_RF_IS_GRAPH_COLLAPSED, !isGraphCollapsed);
        setIsGraphCollapsed(!isGraphCollapsed);
    };

    const clearHighlightOnChart = React.useCallback(() => {
        setChartHighlightAuthorId(null);
    }, []);

    const convertToSeries = referee => {
        return {
            meta: {
                name: referee.author.name,
                id: referee.author.id,
                position: referee.position,
                selected: selectedReferees.some(r => r.author.id === referee.author.id),
                isCustom: customAuthorIds.indexOf(referee.author.id) !== -1,
                isMarked: markedAuthorsIds.indexOf(referee.author.id) !== -1,
                role: 'candidate',
            },
            data: referee.scores.slice().sort().reverse()
        }
    };

    const handleApplySettingsFilter = React.useCallback((settings, settingsId) => {
        clearSelected();
        setSettings(settings, settingsId);

        analytics.log('rf/apply-settings', {
            ...settings,
            docId,
            docKind,
        });
    }, [docKind, docId, setSettings, clearSelected]);

    const [showOnlyMarked, setShowOnlyMarked] = React.useState(false);

    const mergedReferees = React.useMemo(
        () => {
            const uniq = uniqBy([...referees, ...customReferees], 'author.id');
            if (!showOnlyMarked) {
                return uniq;
            }
            return uniq.filter(r => markedAuthorsIds.indexOf(r.author.id) !== -1);
        },
        [referees, customReferees, markedAuthorsIds, showOnlyMarked]
    );

    /* eslint-disable react-hooks/exhaustive-deps */
    // selectedReferees actually are represented in refereeSeries, it's just not
    // obvious from the code, and eslint complains
    const refereeSeries = React.useMemo(() => ([
        ...(mergedReferees).map(convertToSeries),
    ]), [mergedReferees, selectedReferees, customAuthorIds, markedAuthorsIds, convertToSeries]);
    /* eslint-enable react-hooks/exhaustive-deps */

    const topics = React.useMemo(() => settings.topics ? storeTopics : docTopics,
                                 [settings.topics, storeTopics, docTopics]);

    const settingsQS = React.useMemo(
        () => helpers.settingsToQSDefined(settings), [settings]);

    const diffMessage = constructSettingsMessage(defaultSettings, settings);
    let availableSettings = REFEREE_SETTINGS;
    if (docKind === 'author') {
        availableSettings = availableSettings.concat(AUTHOR_REFEREE_SETTINGS);
    }

    let displayedTotalReferees = totalReferees;
    if (displayedTotalReferees > 500) {
        displayedTotalReferees = '500+';
    }


    return <div className="row referees-lists">
        <div className={cx(
            'bg-primary',
            'toggle-graph',
            'show-graph',
            {
                'hidden': !isGraphCollapsed,
            })}
             onClick={toggleIsGraphCollapsed}>
            <span className="glyphicon glyphicon-chevron-right"/>
            Show graph
            <span className="glyphicon glyphicon-chevron-right"/>
        </div>
        <div className={cx('main-content',
            {
                'col-xs-12': isGraphCollapsed,
                'stretched': isGraphCollapsed,
                'col-xs-8': !isGraphCollapsed,
            })}>
            {!!displaySettings &&
                <div style={{ fontSize: "130%", background: 'yellow' }}>
                    {!!displaySettings.banner && <div
                        dangerouslySetInnerHTML={{ __html: displaySettings.banner }}></div>}
                </div>}
            {!!forJobId && forJobId !== 'null' && <>
                <AddPreliminaryApplicantButton
                    docId={docId}
                    docKind={docKind}
                    vacancyId={forJobId}
                    visibleCount={mergedReferees.length}
                    totalCount={totalReferees}
                    getAuthorsIds={() => (mergedReferees.map(r => r.author.id))}
                    settings={settings}
                    customAuthorIds={customAuthorIds}
                />
                <Alert className="alert alert-info">
                    You can fine-tune the list by applying some filters.
                    Click <button
                    className={cx('settings-toggle inline-link', { 'active': showSettings })}
                    title={`${showSettings ? 'Hide' : 'Show'} advanced settings`}
                    onClick={toggleSettings}>
                    <Glyphicon glyph="cog"/>
                </button> in the table below.
                </Alert>
            </>}
            {referees !== undefined &&
                // Custom referees query has to start after the regular referees query is
                // completed. Otherwise, it will fail without a cache or will be merge into
                // the referee list incorrectly.
                <SearchAuthors
                    docKind={docKind}
                    docId={docId}
                    settings={settings}
                    setCustomAuthorIds={setCustomAuthorIds}
                    customAuthorIds={customAuthorIds}/>
            }
            <Panel
                className={cx('panel-table-container referees', { 'show-settings': showSettings })}
                expanded={true}
                onToggle={noop}>
                <Panel.Heading style={{ padding: 0 }}>
                    <Panel.Title>
                        <button className="inline-link settings-toggle"
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    marginLeft: 0,
                                    padding: '10px 15px',
                                }}
                                title={`${showSettings ? 'Hide' : 'Show'} advanced settings`}
                                onClick={toggleSettings}>
                            <strong>
                                {updating ?
                                    <>Updating...</>
                                    : <>Top {mergedReferees.length} {singlePlural(refereeType, mergedReferees.length)} of {displayedTotalReferees}</>}
                            </strong>
                            <div className={cx('pull-right',
                                { 'active': showSettings, 'updating': updating })}
                                 style={{ fontStyle: 'italic' }}>
                                {diffMessage} <Glyphicon glyph="cog"/>
                            </div>
                        </button>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    <div className="settings">
                        <div className="wrapper">
                            <RefereesSettings
                                docKind={docKind}
                                docId={docId}
                                settingsId={settingsId}
                                defaultSettings={defaultSettings}
                                availableSettings={availableSettings}
                                settings={settings}
                                availableTopics={topics}
                                docTopics={docTopics}
                                countries={countries}
                                authorsGroups={authorsGroups}
                                customAuthorIds={customAuthorIds}
                                updating={updating}
                                onApply={handleApplySettingsFilter}
                                message={<p>
                                    Change gender, location, experience level, and
                                    broadness of expertise of
                                    proposed {docKind === 'vacancy' ? 'candidates' : 'referees'}.
                                </p>}/>
                        </div>
                    </div>
                    <div className="mass-actions-wrapper">
                        <MassActions disabled={selectedReferees.length === 0}
                                     refereeType={refereeType}
                                     hideReferees={hideReferees}
                                     vacancyId={forJobId}
                                     doc={doc}
                                     docKind={docKind}
                                     docId={docId}
                                     customAuthorIds={customAuthorIds}
                                     markedAuthorsIds={markedAuthorsIds}
                                     exportOnlyCustom={showOnlyCustom}
                                     canMarkAsReferees={canMarkAsReferees}
                                     canHideCandidates={canHideCandidates}
                                     clearSelected={clearSelected}
                                     showOnlyMarked={showOnlyMarked}
                                     setShowOnlyMarked={setShowOnlyMarked}
                        />
                    </div>
                    <RefereesTable referees={mergedReferees}
                                   docKind={docKind}
                                   docId={docId}
                                   forJobId={forJobId}
                                   settings={settings}
                                   customAuthorIds={customAuthorIds}
                                   handleNameMouseEnter={setChartHighlightAuthorId}
                                   handleNameMouseLeave={clearHighlightOnChart}
                                   AdditionalButtons={forJobId ? VacancyCandidateAdditionalButtons : null}
                                   markedAuthorsIds={markedAuthorsIds}
                                   displaySettings={displaySettings}/>
                    {referees.length === 0 && !showOnlyCustom &&
                        <div className="alert alert-warning">Not enough results?
                            Please check your filters.</div>}
                    {hasMoreReferees &&
                        <LoadMoreReferees
                            key={settingsQS}
                            docKind={docKind}
                            docId={docId}
                            settings={settings}
                            settingsId={settingsId}
                            offset={referees.filter(referee => !referee.isOutOfSample).length}
                            refereeType={refereeType}/>
                    }
                </Panel.Body>
            </Panel>
        </div>
        <div className={
            cx(
                'sidebar',
                {
                    'hidden': isGraphCollapsed,
                    'col-xs-4': !isGraphCollapsed,
                })}
        >
            <AutoAffix affixClassName="affixed"
                       bottomClassName="affixed-bottom"
                       autoWidth
                       viewportOffsetTop={10}
                /* FIXME: do something with "this" */
                       container={this}>
                <div className="sidebar-content">
                    <div className="toggle-graph hide-graph bg-primary"
                         onClick={toggleIsGraphCollapsed}>
                        <span className="glyphicon glyphicon-chevron-left"/>
                        Collapse graph
                        <span className="glyphicon glyphicon-chevron-left"/>
                    </div>
                    <RefereeChart
                        series={refereeSeries}
                        highlightAuthorId={chartHighlightAuthorId}
                        refereeType={refereeType}
                        markedType={markedType}
                        showMarked={docKind === 'proposal'}
                        onMouseOver={setChartHighlightAuthorId}
                        onMouseOut={clearHighlightOnChart}
                        axis
                        grid/>
                </div>
            </AutoAffix>
        </div>
    </div>;
}


export function constructSettingsMessage(defaultSettings, settings) {
    const diff = Object.keys(settings).reduce((diff, key) => {
        if (defaultSettings[key] === settings[key]) return diff;
        return { ...diff, [key]: settings[key] };
    }, {});

    let diffMessage = [];
    if (diff) {
        if (diff.top_author_articles) diffMessage.push('top-' + diff.top_author_articles + ' articles');
        else if (!diff.top_author_articles && diff.top_author_articles !== undefined) diffMessage.push('all articles');

        if (diff.articles_years_limit) diffMessage.push('last ' + diff.articles_years_limit + ' years');
        else if (diff.articles_years_limit !== undefined) diffMessage.push('all years');

        if (diff.min_h_index) diffMessage.push('h-index ≥ ' + diff.min_h_index);
        if (diff.max_h_index) diffMessage.push('h-index ≤ ' + diff.max_h_index);

        if (diff.min_academic_age) diffMessage.push('academic age ≥ ' + diff.min_academic_age);
        if (diff.max_academic_age) diffMessage.push('academic age ≤ ' + diff.max_academic_age);

        if (diff.min_articles_count) diffMessage.push('articles count ≥ ' + diff.min_articles_count);
        if (diff.max_articles_count) diffMessage.push('articles count ≤ ' + diff.max_articles_count);

        if (diff.min_jif) diffMessage.push('JIF ≥ ' + diff.min_jif);
        if (diff.max_jif) diffMessage.push('JIF ≤ ' + diff.max_jif);

        if (diff.max_jif_percentile) diffMessage.push('top ' + diff.max_jif_percentile + '% journals');

        if (diff.gender) diffMessage.push('gender');
        if (diff.institution_type) diffMessage.push(helpers.INSTITUTION_TYPE_VALUE2NAME[diff.institution_type] + ' institutions');
        if (diff.contribution_in_article) diffMessage.push(helpers.CONTRIBUTION_IN_ARTICLE_VALUE2NAME[diff.contribution_in_article].split('(')[0]);

        if (!settings.ignore_topics && settings.ignore_topics !== null) diffMessage.push('topics');
        if (!settings.ignore_countries && settings.ignore_countries !== null) diffMessage.push('countries');
        if (!settings.ignore_authors_group && settings.authors_groups_ids !== null) diffMessage.push(settings.authors_group_effect === 'highlight' ? 'highlight group' : 'limit to group');
        if (settings.exclude_authors_with_retracted_articles) diffMessage.push('exclude retracted');
        if (settings.exclude_coi) diffMessage.push('exclude CoI');
    }
    if (diffMessage.length > 3) {
        diffMessage = diffMessage.slice(0, 3);
        diffMessage.push('...');
    }
    return diffMessage.join(', ');
}
