import gql from 'graphql-tag';


export default gql`
query missingLiterals($conceptId: Int, $destLiterals: [LiteralInput], $literals: [LiteralInput]!) {
    missingLiterals(concept_id: $conceptId, dest_literals: $destLiterals, literals: $literals) {
        name
        composite
        lang
        isAmbiguous
    }
}
`;
