import * as React from 'react';

import { useCurrentUser } from '../accounts/hooks';


export default function withAuthorization(WrappedComponent) {
    return (props) => {
        const [{ isAuthenticated }] = useCurrentUser();
        // redirect to login page if isAuthenticated is false, no JS login needed
        React.useEffect(() => {
            if (isAuthenticated === false) {
                window.location = '/accounts/login/?next=' + encodeURIComponent(window.location.pathname);
            }
        }, [isAuthenticated]);

        if (isAuthenticated === null) {
            return null;
        }

        if (isAuthenticated === false) {
            return <div>
                You are logged out. Log in to continue. Redirecting to login page...
            </div>;
        }

        return <WrappedComponent {...props} />;
    }
}
