import React from 'react';

import type { RefereeType } from './Types';
import { cx, singlePlural } from '../utils';
import helpers from './helpers';


export function getHiddenTitle(hideReasons): string {
    if (!hideReasons) {
        return '';
    }

    const reason = hideReasons.map(r => {
        let text = helpers.HIDE_REASONS[r.reason].explanation;
        if (r.comment) {
            text = `${text}: "${r.comment}"`;
        }
        return text;
    }).join('. ') + '.';
    return `This referee was hidden because: ${reason}`;
}


export function renderHiddenIcon(hiddenTitle) {
    return <div>
        <span className="glyphicon glyphicon-eye-close text-danger" title={hiddenTitle}/>
        &nbsp;
        <span className="referee-icons-title">{hiddenTitle}</span>
    </div>
}


/* eslint-disable complexity */
export default function RefereeLabels(
    { referee, multiLine = false, links = {} }:
        { referee: RefereeType, multiLine?: boolean, links?: {} },
) {
    const recentlyCoauthoredCount = referee.recentlyCoauthoredCount;
    const coauthoredTitle = `${recentlyCoauthoredCount} common ${
        singlePlural('article', recentlyCoauthoredCount)} in the last 5 years`;
    // FIXME: 5 years is the default, but it's configurable, so should
    //  be configurable here as well.
    const affiliationTitle = 'Common affiliation in the last 5 years';
    const externalCoITitle = 'Custom conflict of interest';
    const samePanelGrantTitle = 'Has received a grant from this panel';
    const referencedTitle = 'Has articles that are referenced in the document';
    const retractedTitle = 'Has one or more retracted articles';

    function hasLabel(label) {
        return referee.labels.some(l => l.name === label);
    }

    if (!recentlyCoauthoredCount
        && !referee.isRecentlyCommonAffiliation
        && referee.retractedArticles.length === 0
        && !referee.externalCoI
        && referee.labels.length === 0
        && !referee.excludeReasons
        && !referee.hideReasons) {
        return null;
    }

    let excludedTitle = '';
    if (!!referee.excludeReasons) {
        const reason = referee.excludeReasons.map(r => helpers.EXCLUDE_REASONS[r]).join('. ') + '.';
        excludedTitle = `This referee was excluded because: ${reason}`;
    }

    const hiddenTitle = getHiddenTitle(referee.hideReasons)

    return <div className={cx('referee-icons', {'referee-icons-singleline': !multiLine})}>
        {!!hiddenTitle && renderHiddenIcon(hiddenTitle)}

        {!!referee.excludeReasons && !multiLine && <div>
            <span className="glyphicon glyphicon-exclamation-sign"
                  style={{color: 'darkorange'}}
                  title={excludedTitle}/>
            <span className="referee-icons-title">&nbsp;{excludedTitle}</span>
        </div>}

        {/* We show coauthored and affiliations icons in referee details */}
        {!!referee.recentlyCoauthoredCount && !multiLine && <div>
            <span className="glyphicon glyphicon-user text-danger" title={coauthoredTitle}/>
            <span className="referee-icons-title">&nbsp;{coauthoredTitle}</span>
        </div>}
        {referee.retractedArticles.length !== 0 && !multiLine && <div>
            <span className="glyphicon glyphicon-erase text-danger" title={retractedTitle}/>
            <span className="referee-icons-title">&nbsp;{retractedTitle}</span>
        </div>}
        {referee.isRecentlyCommonAffiliation && !multiLine && <div>
            <span className="glyphicon glyphicon-home text-danger" title={affiliationTitle}/>
            <span className="referee-icons-title">&nbsp;{affiliationTitle}</span>
        </div>}
        {referee.externalCoI && !multiLine && <div>
            <span className="glyphicon glyphicon-exclamation-sign text-danger" title={externalCoITitle}/>
            <span className="referee-icons-title">&nbsp;{externalCoITitle}</span>
        </div>}

        {hasLabel('same_panel_grantee') && <div>
            <span className="glyphicon glyphicon-euro text-success" title={samePanelGrantTitle}/>
            &nbsp;
            <span className="referee-icons-title">{samePanelGrantTitle}</span>
        </div>}
        {(hasLabel('referenced_by_id') || hasLabel('referenced_by_norm_name')) && <div>
            <span className="glyphicon glyphicon-link text-success" title={referencedTitle}/>
            &nbsp;
            <span className="referee-icons-title">{referencedTitle}</span>
        </div>}

    </div>;
}
/* eslint-enable complexity */
