import qs from 'query-string';

import { sendRequest, clearCacheKey } from '../datastore';


export function makeJobKey(id) {
    return `job-${id}`;
}

export function makeJobsListKey() {
    return `jobs-list`;
}

export function makeJobApplicantsKey(id) {
    return `job-applicants-${id}`;
}

export function makeJobApplicationKey(id) {
    return `job-application-${id}`;
}

export function makeJobAdvisorKey(id) {
    return `job-advisor-${id}`;
}

export function makeJobApplicantRecommendationKey(id) {
    return `job-applicant-recommendation-${id}`;
}

export function makeJobAdvisorByAttorneyTokenKey(token) {
    return `job-advisor-by-attorney-${token}`;
}

export function makeLastArticlesKey() {
    return 'last-articles';
}

export function makeSearchApplicantsByConceptsKey(jobId, conceptIds) {
    return `job-applicants-by-concepts-${jobId}-${conceptIds.join(',')}`;
}

export function makeSearchApplicantsTopConceptsKey(jobId, conceptIds) {
    // conceptIds here mean those in input, and top concepts are returned by server
    return `job-applicants-top-concepts-${jobId}-${conceptIds.join(',')}`;
}

export function makeJobTagsKey(jobId) {
    return `job-tags-${jobId}`;
}

export function makeJobAdvisorsKey(jobId) {
    return `job-advisors-${jobId}`;
}

export function makeJobAuthorTopCitedArticlesKey(authorId) {
    return `job-author-top-cited-articles-${authorId}`;
}

export const makeJobAuthorTopCitedArticlesQuery = authorId => ({
    id: makeJobAuthorTopCitedArticlesKey(authorId),
    get: makeJobAuthorTopCitedArticlesKey(authorId),
    set: function (store, { value: { topArticles } }) {
        store[makeJobAuthorTopCitedArticlesKey(authorId)] = topArticles;
    },
    // fake the request when authorId is null
    req: () => authorId
        ? sendRequest(`/api/authors/${authorId}/top-cited-articles`)
        : new Promise(() => {}),
});

export const makeJobQuery = id => ({
    id: makeJobKey(id),
    get: makeJobKey(id),
    set: function (store, { value: { vacancy: job } }) {
        store[makeJobKey(id)] = job;
    },
    req: () => sendRequest(`/api/jobs/vacancy/${id}`),
});

export const makeJobApplicationQuery = id => ({
    id: makeJobApplicationKey(id),
    get: makeJobApplicationKey(id),
    set: function (store, { value: { application } }) {
        store[makeJobApplicationKey(id)] = {
            ...application,
            tagsIds: application.tags.map(t => t.id)
        };
    },
    req: () => sendRequest(`/api/jobs/application/${id}`),
});

export const makeJobTagsQuery = id => ({
    id: makeJobTagsKey(id),
    get: makeJobTagsKey(id),
    set: function (store, { value: { tags } }) {
        store[makeJobTagsKey(id)] = tags;
    },
    req: () => sendRequest(`/api/jobs/vacancy/${id}/tags`),
});

export function makeJobVisibleApplicantsIdsKey(id) {
    return `job-${id}-visible-applicants-list`;
}

function propagateLists(store, id) {
    const applicantsIds = store[makeJobApplicantsIdsKey(id)];

    if (applicantsIds) {
        store[makeJobApplicantsKey(id)] = applicantsIds.map(aid =>
            store[makeJobApplicationKey(aid)]
        );
    }

    const advisorsIds = store[makeJobAdvisorsIdsKey(id)];

    if (advisorsIds) {
        store[makeJobAdvisorsKey(id)] = advisorsIds.map(aid =>
            store[makeJobAdvisorKey(aid)]
        );
    }
}

export function propagateApplicationInfo(store, vacancyId, applicationId) {
    const key = makeJobApplicationKey(applicationId);
    const listKey = makeJobApplicantsKey(vacancyId);
    if (store[listKey]) {
        store[listKey] = store[listKey]
            .map(a => a.applicationId === applicationId ? store[key] : a);
    }
}

export function makeJobApplicantsIdsKey(id) {
    return `job-${id}-applicants-ids`;
}

export function makeJobAdvisorsIdsKey(id) {
    return `job-${id}-advisors-ids`;
}

export function setApplicantsAndAdvisors(id, store, { value }) {
    store[makeJobTagsKey(id)] = value.tags;
    store[makeJobApplicantsIdsKey(id)] = value.applicants.map(app => app.applicationId);
    store[makeJobAdvisorsIdsKey(id)] = value.advisors.map(adv => adv.groupId);

    value.applicants.forEach(app => {
        store[makeJobApplicationKey(app.applicationId)] = app;
    });

    value.advisors.forEach(advisor => {
        store[makeJobAdvisorKey(advisor.groupId)] = advisor;
    });

    propagateLists(store, id);
}

export const makeApplicantRecommendationQuery = advisorUuid => ({
    id: makeJobApplicantRecommendationKey(advisorUuid),
    get: makeJobApplicantRecommendationKey(advisorUuid),
    set: function (store, { value: { recommendation } }) {
        store[makeJobApplicantRecommendationKey(advisorUuid)] = recommendation;
    },
    req: () => sendRequest(`/api/jobs/recommendation-request/${advisorUuid}`),
});

export const makeJobAdvisorByAttorneyTokenQuery = attorneyToken => ({
    id: makeJobAdvisorByAttorneyTokenKey(attorneyToken),
    get: makeJobAdvisorByAttorneyTokenKey(attorneyToken),
    set: function (store, { value: { advisor } }) {
        store[makeJobAdvisorByAttorneyTokenKey(attorneyToken)] = advisor;
    },
    req: () => sendRequest(`/api/jobs/fetch-advisor-by-attorney/${attorneyToken}`),
});


export const makeLastArticlesQuery = () => ({
    id: makeLastArticlesKey(),
    get: makeLastArticlesKey(),
    set: function (store, { value: { articles } }) {
        store[makeLastArticlesKey()] = articles;
    },
    req: () => sendRequest('/api/jobs/last-articles/'),
});


export const makeSearchApplicantsByConceptsQuery = (jobId, conceptIds, withPreliminaries, minSim) => {
    const mainKey = makeSearchApplicantsByConceptsKey(jobId, conceptIds);
    const args = { minSim, cids: conceptIds };
    if (withPreliminaries) {
        args.withPreliminaries = true;
    }
    return {
        id: mainKey,
        get: mainKey,
        set: function (store, { value: { pickedApplicants, topConcepts } }) {
            store[mainKey] = pickedApplicants;
            store[makeSearchApplicantsTopConceptsKey(jobId, conceptIds)] = topConcepts;
        },
        req: () => sendRequest(
            `/api/jobs/vacancy/${jobId}/concepts?${qs.stringify(args)}`
        ),
    }
};

export const setJobConcepts = (jobId, conceptIds, withPreliminaries, minSim,
                               store, { value: { pickedApplicants, topConcepts } }) => {
    const mainKey = makeSearchApplicantsByConceptsKey(jobId, conceptIds);

    store[mainKey] = pickedApplicants;
    store[makeSearchApplicantsTopConceptsKey(jobId, conceptIds)] = topConcepts;
};

export const deleteApplication = (store, { applicationId, vacancyId }) => {
    const applicationKey = makeJobApplicationKey(applicationId);

    const applicantsKey = makeJobApplicantsKey(vacancyId);
    if (store[applicantsKey]) {
        store[applicantsKey] = store[applicantsKey].filter(a => a.applicationId !== applicationId);
    }

    clearCacheKey(applicationKey);

    const advisorsKeys = makeJobAdvisorsKey(vacancyId);
    if (store[advisorsKeys]) {
        let newAdvisors = store[advisorsKeys].map(advisor => ({
            ...advisor,
            applicantsIds: advisor.applicantsIds.filter(a => a.applicationId !== applicationId)
        }));

        newAdvisors.forEach(advisor => {
            if (advisor.applicantsIds.length > 0) {
                store[advisorsKeys].push(advisor);
            } else {
                clearCacheKey(makeJobAdvisorKey(advisor.groupId));
            }
        });
    }

};

export const setApplicantComment = (store, { vacancyId, applicationId, revoke, comment, currentUser }) => {
    const key = makeJobApplicationKey(applicationId);

    store[key] = {
        ...store[key],
        comments: revoke ?
            store[key].comments.filter(c => !c.ownComment)
            : [
                ...store[key].comments.filter(c => !c.ownComment),
                {
                    comment,
                    userId: currentUser.id,
                    name: currentUser.email,
                    ownComment: true
                }
            ],
    };

    propagateApplicationInfo(store, vacancyId, applicationId);
};

export const voteApplicant = (store, { applicationId, vacancyId, revoke, voteType }) => {
    const key = makeJobApplicationKey(applicationId);

    store[key] = {
        ...store[key],
        votes: revoke ?
            store[key].votes.filter(v => !v.ownVote)
            : [ ...store[key].votes.filter(v => !v.ownVote), { voteType, ownVote: true }],
    };

    propagateApplicationInfo(store, vacancyId, applicationId)
}

export const postTag = (store, { vacancyId, tag, tagId }) => {
    const tagsKey = makeJobTagsKey(vacancyId);

    if (store[tagsKey]) {
        const newTag = { ...tag, id: tagId };

        store[tagsKey] = tag.id ?
            store[tagsKey].map(t => t.id === tag.id ? newTag : t)
            : store[tagsKey].concat(newTag);
    }
};

export const addTag = (store, { vacancyId, applicationsIds, tag }) => {
    applicationsIds.forEach(applicationId => {
        const key = makeJobApplicationKey(applicationId);

        store[key] = {
            ...store[key],
            tagsIds: store[key].tagsIds.concat(tag.id)
        };

        propagateApplicationInfo(store, vacancyId, applicationId)
    });
}

export const removeTag = (store, { applicationId, vacancyId, vacancyTagId }) => {
    const key = makeJobApplicationKey(applicationId);

    store[key] = {
        ...store[key],
        tagsIds: store[key].tagsIds.filter(id => id !== vacancyTagId)
    };

    propagateApplicationInfo(store, vacancyId, applicationId)
}

export const destroyTag = (store, { vacancyId, tagId }) => {

    const removeTagFromApplication = app => ({
        ...app,
        tagsIds: app.tagsIds.filter(id => id !== tagId)
    });

    const tagsKey = makeJobTagsKey(vacancyId);

    if (store[tagsKey]) {
        store[tagsKey] = store[tagsKey].filter(t => t.id !== tagId);
    }

    const listKey = makeJobApplicantsKey(vacancyId);
    if (store[listKey]) {
        store[listKey] = store[listKey].map(removeTagFromApplication);

        store[listKey].forEach(app => {
            const appKey = makeJobApplicationKey(app.applicationId);
            store[appKey] = removeTagFromApplication(store[appKey]);
        });
    }

};

export const saveTopics = (store, { vacancyId, tags, applicants }) => {
    const tagsKey = makeJobTagsKey(vacancyId);
    let appKey;

    store[tagsKey] = tags;
    applicants.forEach(applicant => {
        appKey = makeJobApplicationKey(applicant.applicationId);

        store[appKey] = ({
            ...store[appKey],
            tagsIds: applicant.tagsIds
        });

        propagateApplicationInfo(store, vacancyId, applicant.applicationId)
    });
};

export const updateJob = (store, { id, updatedFields, articles, authors, concepts }) => {
    const key = makeJobKey(id);
    store[key] = {
        ...store[key],
        articles,
        authors,
        concepts,
        ...updatedFields,
    };

    // clear the cache out; applicants and advisors
    // have to be recalculated anyway
    wipeJobCache(store, { id });
};

export const setApplicationsEmailed = (store, { vacancyId, applicationsIds}) => {
    applicationsIds.forEach(applicationId => {
        const key = makeJobApplicationKey(applicationId);
        store[key] = {
            ...store[key],
            isEmailed: true,
        };
        propagateApplicationInfo(store, vacancyId, applicationId)
    })
};

export const mergeApplications = (store, { vacancyId, actualApplication, preliminaryApplicationId }) => {
    const actualKey = makeJobApplicationKey(actualApplication.applicationId);

    deleteApplication(store, {
        applicationId: preliminaryApplicationId,
        vacancyId
    });

    store[actualKey] = actualApplication;
    propagateApplicationInfo(store, vacancyId, actualApplication.applicationId)
};

export const wipeJobCache = (store, { id }) => {

    const applicantsKey = makeJobApplicantsKey(id);
    if (store[applicantsKey]) {
        store[applicantsKey].forEach(app => clearCacheKey(makeJobApplicationKey(app.applicationId)));
    }
    clearCacheKey(applicantsKey);

    const advisorsKey = makeJobAdvisorsKey(id);
    if (store[advisorsKey]) {
        store[advisorsKey].forEach(adv => clearCacheKey(makeJobAdvisorKey(adv.groupId)));
    }
    clearCacheKey(advisorsKey);

};
