import React from 'react';

import { useSubscription } from '../datastore';
import { makeJobAdvisorKey } from './JobData';
import type { AdvisorType, AdvisorWithScoresType } from './Types';
import AdvisorPopover from './AdvisorPopover';
import OverlayTrigger from '../widgets/OverlayTrigger';
import { cx } from '../utils';


export default function ApplicantAdvisor(
    { advisor, jobId, short = false }
    : { advisor: AdvisorType | AdvisorWithScoresType, jobId?: number, short?: boolean }
) {
    const [triggerRef, setTriggerRef] = React.useState(null);
    const saveTriggerRef = React.useCallback(ref => setTriggerRef(ref), []);
    const advisorDetails = useSubscription(makeJobAdvisorKey(advisor.groupId));
    const advisorPopover = <AdvisorPopover
        advisor={advisorDetails}
        close={triggerRef && triggerRef.handleHide}
        jobId={jobId}
        short={short} />;
    let className = '';
    let title = null;

    if (typeof advisor.filename !== 'undefined') {
        className = cx({ 'without-recommendation': !advisor.filename });

        if (!advisor.filename) {
            title = 'has not given a recommendation';
        }
    }

    return <span className={className} title={title}>
        <OverlayTrigger overlay={advisorPopover} trigger="click" rootClose ref={saveTriggerRef}>
            <button className="inline-link overlay-trigger">{advisor.name}</button>
        </OverlayTrigger>
    </span>;
}
