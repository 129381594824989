import type { RecommendationTopicType } from '../../recommendations/RecommendationTopic';
import type { TreeNodeType } from '../../recommendations/AdvancedRecommendation';

/**
 * Get topic index given concept
 *
 * @param conceptId string Concept ID we're looking for in topics list
 * @param topics Array<RecommendationTopicType> topics
 *
 * @return number
 */
export function getConceptTopicIndex(
    conceptId: string,
    topics: Array<RecommendationTopicType>,
) {
    // find topic index concept belongs to
    for (let i = 0; i < topics.length; i++) {
        if (topics[i].concepts
            .map(c => c.conceptId)
            .indexOf(conceptId) !== -1
        ) {
            return i;
        }
    }

    return -1;
}

/**
 * Get concatenated array of concept IDs from all topics
 *
 * @param topics Array<RecommendationTopicType> topics
 *
 * @return Array<number>
 */
export function getConceptIds(topics: Array<RecommendationTopicType>): Array<any> {
    return topics
        .reduce((acc, t) => acc.concat(t.concepts), [])
        .map(c => c.conceptId);
}

/**
 * Traverses the tree and returns topics that don't appear in the tree.
 * Used to show warning at AdvancedRecommendation
 */
export function getNotUsedTopics(
    topics: Array<RecommendationTopicType>, tree: TreeNodeType
): Array<RecommendationTopicType> {
    /**
     * Returns flattened list of topic titles
     */
    function getChildrenTopics(node: TreeNodeType) : Array<string> {
        if (node.children) {
            return node.children.reduce(
                (acc, c) => acc.concat(getChildrenTopics(c)),
                []
            );
        } else {
            return topics[node.topicId] ? [ topics[node.topicId].title ] : [];
        }
    }

    const used = getChildrenTopics(tree);

    return topics.filter(t => used.indexOf(t.title) === -1);
}

/**
 * Filters tree removing topics not meeting filterCallback() condition
 *
 * @return TreeNodeType
 */
export function filterTree(
    tree: TreeNodeType,
    topics: Array<RecommendationTopicType>,
    filterCallback: (n: RecommendationTopicType, i: number) => bool
) {
    const newIds = {};
    let j = 0;

    topics.forEach((t, i) => {
        if (filterCallback(t, i)) {
            newIds[i] = j;
            j++;
        }
    });

    const excludeTopics = (node): TreeNodeType => {
        if (node.topicId !== undefined) {
            if (node.unary !== undefined) {
                return { unary: node.unary, topicId: newIds[node.topicId] };
            }
            return { topicId: newIds[node.topicId] };
        }
        return {
                ...node,
                children: node.children
                    .filter(n => (n.topicId === undefined || (
                        topics[n.topicId] &&
                        filterCallback(topics[n.topicId], n.topicId)
                    )))
                    .map(excludeTopics)
            };
    };

    return excludeTopics(tree);
}
