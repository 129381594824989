import React, { useEffect, useState } from 'react';
import qs from 'query-string';

import { useLocation } from 'react-router-dom';

import { useSubscription, sendRequest, updateGlobalData } from '../../datastore';
import {
    makePanelKey, makeLoadMoreCandidatesQuery,
    makePanelMembersUrl, makePanelMemberCandidatesKey
} from './PanelMembersData';
import PanelMemberCandidates from './PanelMemberCandidates';
import LoadingREST from '../../LoadingREST';
import LoadMore from '../../widgets/LoadMore';
import { makeCustomRefereesWithAdditionalDocKey } from '../../referees/RefereesData';
import { NonEditableWarning } from './helpers';


export default function PanelMemberCandidatesPage({ match: { params: { id, compositionId }} }) {
    const location = useLocation();
    const parsedQS = qs.parse(location.search);
    const { author, ...restQS } = parsedQS;
    // we don't pass location.search here because by doing so we would refetch candidates
    // after adding a custom author which is pointless
    const qsForStore = `?${qs.stringify(restQS)}`;

    const candidatesKey = makePanelMemberCandidatesKey(id, compositionId, qsForStore);
    const panelKey = makePanelKey(id, compositionId);

    const response = useSubscription(candidatesKey);
    const panel = useSubscription(panelKey);

    const [reqstate, setReqstate] = useState({loading: true});

    useEffect(() => {
        let cancel = false;
        setReqstate({loading: true});
        sendRequest(makePanelMembersUrl(id, compositionId, 'candidates', qsForStore))
            .then((data) => {
                if (!cancel) {
                    if (!data.ok) {
                        setReqstate({loading: false, ok: false, value: {error: data.error}});
                        return;
                    }

                    updateGlobalData(
                        (store, { value: {scoredPanelMembers, panelName, subpanels,
                                          countries, authorsGroups, hasMore, isEditable, noSubpanelDocs,
                                          cacheMiss, inProgress, error} }) => {
                            store[candidatesKey] = {
                                scoredPanelMembers,
                                subpanels: subpanels.reduce((acc, { id, score }) => ({ ...acc, [id]: score }), {}),
                                countries,
                                authorsGroups,
                                hasMore,
                                isEditable,
                                cacheMiss,
                                inProgress,
                                error
                            };
                            store[panelKey] = {
                                subpanels,
                                id,
                                noSubpanelDocs,
                                name: panelName,
                            }
                        },
                        data
                    );
                    setReqstate({loading: false, ok: true});
                }
            })
        return () => {cancel = true;};
    }, [id, compositionId, qsForStore, panelKey, candidatesKey]);

    const { hasMore, scoredPanelMembers, countries, authorsGroups, subpanels, isEditable, cacheMiss, inProgress, error } = response || {};
    const customAuthorIds = React.useMemo(
        () => !author ?
                []
                : (typeof author === 'string' ? [author] : author).map(id => parseInt(id, 10)),
        // disable eslint because author is derived from location.search
        /* eslint-disable-next-line */
        [location.search]
    );
    const customCandidates = useSubscription(
        ...makeCustomRefereesWithAdditionalDocKey(
            'panel',
            id,
            'composition',
            compositionId,
            customAuthorIds)) || [];

    return <LoadingREST what="panel" reqstate={reqstate} doc={response}>
        {subpanels && <>
            <ol className="breadcrumb">
                <li><a href={`/panel-composer/${panel.id}/compositions/`}>{panel.name} compositions</a></li>
                <li><a href={`/panel-composer/${panel.id}/compositions/${compositionId}/members/`}>
                    Members
                </a></li>
                <li className="active">Candidates</li>
            </ol>
            {!isEditable && <NonEditableWarning panelId={panel.id} compositionId={compositionId} /> }
            <LoadingREST what="candidates" reqstate={reqstate} doc={scoredPanelMembers} partialResponse>
                {response && <div>
                    <PanelMemberCandidates
                        panel={panel}
                        compositionId={compositionId}
                        storeKey={candidatesKey}
                        isEditable={isEditable}
                        showingCandidates
                        people={scoredPanelMembers}
                        countries={countries}
                        authorsGroups={authorsGroups}
                        customPeople={customCandidates}
                        customAuthorIds={customAuthorIds}
                        panelScores={subpanels}
                        updating={reqstate.loading}
                        cacheMiss={cacheMiss}
                        inProgress={inProgress}
                        error={error}
                    />
                    {hasMore && <LoadMore
                        workingMessage="Loading more candidates"
                        queryArgs={[id, compositionId, qsForStore, scoredPanelMembers.length]}
                        queryFn={makeLoadMoreCandidatesQuery}
                        offset={scoredPanelMembers.length}
                    >
                        Load more candidates
                    </LoadMore>}
                </div>}
            </LoadingREST>
        </>}
    </LoadingREST>;
}
