import React from 'react';

import Button from 'react-bootstrap/lib/Button';

import { useTrack } from '../datastore';
import LoadingButton from './LoadingButton';


function LoadingMore({ args, queryFn, workingMessage }) {
    useTrack(queryFn(...args));

    return <LoadingButton className="load-more"
                          workingMessage={workingMessage}
                          working
                          disabled />;
}

export default function LoadMore({ queryArgs, offset, queryFn, workingMessage, children }) {
    const [queriedOffset, setOffset] = React.useState(0);
    if (queriedOffset >= offset) {
        return <LoadingMore
            args={queryArgs}
            queryFn={queryFn}
            workingMessage={workingMessage}
            offset={Math.min(queriedOffset, offset)} />;
    }

    return <Button className="load-more" onClick={() => setOffset(offset)}>
        {children}
    </Button>;
}
