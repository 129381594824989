import React, { Fragment } from 'react';
import Reflux from 'reflux';

import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import Col from 'react-bootstrap/lib/Col';
import Panel from 'react-bootstrap/lib/Panel';
import Row from 'react-bootstrap/lib/Row';
import DocumentTitle from 'react-document-title';

import queryString from 'query-string';
import { Prompt } from 'react-router';

import isEqual from 'lodash/isEqual';

import { useTrack } from '../datastore';
import { useCurrentUser } from '../accounts/hooks';
import { RecommendationGuestMessage } from '../accounts/GuestMessages';
import type { ReactRouterHistoryType } from '../common/Types';
import {
    ChosenRecommendationTopicsActions, ChosenRecommendationTopicsStore
} from './ChosenRecommendationTopicsStore';
import { getConceptIds, filterTree } from '../common/helpers/recommendationTopics';
import BasicRecommendation from './BasicRecommendation';
import AdvancedRecommendation from './AdvancedRecommendation';
import SaveSavedRecommendation from './SaveSavedRecommendationButton';
import DeleteMonitoringButton from './DeleteMonitoringButton';
import Dates from './Dates';
import LoadingREST from '../LoadingREST';
import MonitoringArticles from './MonitoringArticles';
import { SuggestionsPopover } from '../articles/Concept';
import ToTheTop from '../widgets/ToTheTop';
import { makeMonitoringQuery } from './MonitoringData';

import type { OrganizationType } from '../accounts/Types';
import { pick } from '../utils';

import './styles.css';


export type SavedRecommendationType = {
    id: ?number,
    title: string,
    date: ?string,
    organization: ?OrganizationType,
    history: ReactRouterHistoryType,
    defaultCollapsed: boolean,
};

const LEAVE_MSG = 'You have unsaved monitoring. If you leave the page ' +
    'now, these changes will be lost. Continue?';


export class Recommendation extends Reflux.PureComponent {
    props: SavedRecommendationType;

    static defaultProps = {
        id: null,
        title: '',
        defaultCollapsed: true,
        date: 'month',
        organization: null,
    };

    state = {
        topics: [],
        tree: { condition: 'OR', children: []},
        useTree: false,
        date: this.props.date,
        collapsed: this.props.defaultCollapsed,
        origRecommendation: null,
        articlesProps: null,
        disabledApply: true,
    };

    suppressBeforeUnload = false;
    suppressResetStore = false;

    constructor(props: SavedRecommendationType) {
        super(props);

        this.mapStoreToState(ChosenRecommendationTopicsStore, fromStore => {
            // if we already have results - don't update articlesProps in state
            // so backend isn't queried immediately with new arguments
            if (this.state.articlesProps) {
                return {
                    ...fromStore,
                    disabledApply: false,
                };
            }

            // Reflux sends in `fromStore` only changed fields, so get info
            // from own state.
            // This branch is needed for the first fetch-on-open behaviour.
            const tree = fromStore.tree || this.state.tree;
            const stateTopics = fromStore.topics || this.state.topics;
            const useTree = fromStore.useTree ||
               (fromStore.useTree === undefined && this.state.useTree);
            const date = fromStore.date !== undefined ? fromStore.date : this.state.date;

            return {
                ...fromStore,
                disabledApply: true,
                articlesProps: this.getArticlesProps(stateTopics, useTree, tree, date),
            };
        });
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    onSubmit = (recommendation: SavedRecommendationType) => {
        ChosenRecommendationTopicsActions.setOrigRecommendation({
            id: recommendation.id,
            ...this.collectOrigRecommendationFrom(this.state)
        });

        if (recommendation.id && recommendation.id !== this.props.id) {
            this.suppressBeforeUnload = true;
            this.props.history.replace(`/monitoring/${recommendation.id}`);
        }
    }

    onDelete = () => {
        this.suppressBeforeUnload = true;
        this.props.history.replace('/saved-monitorings');
    }

    collectOrigRecommendationFrom = src => ({
        ...pick(['date', 'topics', 'useTree', 'tree'], src),
        topics: src.topics.map(t => {
            const { disabled, ...rest } = t;
            return rest;
        })
    });

    mustShowLeaveConfirmation() {
        if (!this.state.origRecommendation) {
            return !this.suppressBeforeUnload;
        }

        return !this.suppressBeforeUnload && (
            !isEqual(
                this.collectOrigRecommendationFrom(this.state.origRecommendation),
                this.collectOrigRecommendationFrom(this.state)
            )
        );
    }

    componentWillUnmount() {
        if (!this.suppressResetStore) {
            ChosenRecommendationTopicsActions.resetRecommendation();
        }

        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        super.componentWillUnmount();
    }

    toggle = () => {
        this.setState({ collapsed: !this.state.collapsed });
    }

    handleDuplicate = () => {
        this.suppressResetStore = true;
        this.props.history.push('/monitoring');
    }

    handleBeforeUnload = (e: SyntheticEvent<*> & { returnValue: string }) => {
        if (this.mustShowLeaveConfirmation()) {
            e.returnValue = LEAVE_MSG;

            if (this.suppressResetStore) {
                setTimeout(() => {
                    // user stayed
                    this.suppressResetStore = false;
                }, 1000);
            }

            return LEAVE_MSG;
        }
    }

    handleDatesClick = (date: ?string) => {
        ChosenRecommendationTopicsActions.setDate(date);
    }

    toggleAdvanced = () => {
        ChosenRecommendationTopicsActions.setUseTree(!this.state.useTree);
    }

    getTreeWithEnabledTopicsOnly(tree, topics) {
        return filterTree(tree, topics, t => (
            !t.disabled && !!t.concepts.length
        ));
    }

    getBlockMessage = () => {
        return this.mustShowLeaveConfirmation() ? LEAVE_MSG : true;
    }

    // @TODO: remove once following GH issue is resolved:
    // @see {@link https://github.com/react-bootstrap/react-bootstrap/issues/3141|GitHub}
    noop() {}

    getArticlesProps = (topics, useTree, tree, date) => {
        const filteredToptics = topics.filter(t => !t.disabled && t.concepts.length);
        if (getConceptIds(filteredToptics).length === 0) {
            return null;
        }

        return {
            topics: filteredToptics.map(t => ({
                title: t.title,
                conceptIds: t.concepts.map(c => c.conceptId)
            })),
            size: 50,
            tree: useTree && tree ?
                this.getTreeWithEnabledTopicsOnly(tree, topics) : null,
            selectedDate: date,
        }
    };

    apply = () => {
        this.setState({
            disabledApply: true,
            articlesProps: this.getArticlesProps(
                this.state.topics,
                this.state.useTree,
                this.state.tree,
                this.state.date,
            )
        });
    }

    applyWithTree = tree => {
        this.setState({
            disabledApply: true,
            articlesProps: this.getArticlesProps(
                this.state.topics,
                this.state.useTree,
                tree,
                this.state.date,
            )
        });
    }

    renderConceptPopover = (concept, onHide) => (
        <SuggestionsPopover concept={concept} onHide={onHide} onApply={this.apply} />
    )

    /* eslint-disable complexity */
    render() {
        const activeTopics = this.state.topics
            .filter(t => !t.disabled && t.concepts.length);
        const canSave = !!activeTopics.length;

        return <div className="recommendation-page">
            {this.props.title &&
            <DocumentTitle
                title={`${this.props.title} monitoring - Prophy`} />}
            <ToTheTop />
            <Prompt message={this.getBlockMessage}/>
            {!this.props.currentUser.isAuthenticated &&
                <Alert><RecommendationGuestMessage/></Alert>}
            <h2 className="pull-left">{this.props.title}</h2>
            <Dates className="pull-right"
                   initialSelected={this.props.date}
                   handleClick={this.handleDatesClick} />
            {this.state.collapsed ?
                this.state.topics.length > 1 && <div className="topics-brief">
                    <strong>Topics: </strong>
                    {this.state.topics.map((t, i) => <span className="topic" key={t.title}>
                        <div className={`topic-color topic-color-${i}`}></div>
                        {t.title}
                    </span>)}
                </div>
                : <Fragment>
                    <BasicRecommendation/>
                    <div>
                        <label className="advanced-toggle">
                            <input type="checkbox"
                                checked={this.state.useTree}
                                onChange={this.toggleAdvanced}/>
                            <span>Advanced (optional)</span>
                            <span className="note">
                                {this.state.useTree ?
                                    'Monitoring will be based on query below.'
                                    : 'Monitoring will be based on enabled topics only.'}
                            </span>
                        </label>
                    </div>
                    <Panel eventKey={1}
                           expanded={this.state.useTree}
                           onToggle={this.noop}
                           className={{ 'collapsed': !this.state.useTree }}>
                        <Panel.Collapse>
                            <Panel.Body>
                                <AdvancedRecommendation onApply={this.applyWithTree} />
                            </Panel.Body>
                        </Panel.Collapse>
                    </Panel>
                </Fragment>
            }
            <Row>
                <Col xs={12} className="actions">
                    <Button className="pull-left toggle-recommendation" onClick={this.toggle}>
                        {this.state.collapsed ? 'Edit monitoring' : 'Collapse'}
                    </Button>
                    <ButtonToolbar className="pull-left">
                        {!this.state.collapsed && <Fragment>
                            {canSave &&
                                <SaveSavedRecommendation
                                    id={this.props.id}
                                    title={this.props.title}
                                    date={this.state.date}
                                    topics={this.state.topics}
                                    tree={this.state.tree}
                                    useTree={this.state.useTree}
                                    onSubmit={this.onSubmit}
                                    organization={this.props.organization} />}
                            {this.props.id !== null &&
                                <DeleteMonitoringButton
                                    id={this.props.id}
                                    onDelete={this.onDelete}
                                    title={this.props.title} />}
                        </Fragment>}
                        {this.props.id !== null && this.state.topics.some(t => t.concepts.length) &&
                            <Button className="duplicate-recommendation" onClick={this.handleDuplicate}>
                                Duplicate
                            </Button>
                        }
                        <Button className="apply" onClick={this.apply} bsStyle="primary"
                                disabled={this.state.disabledApply}
                                title={this.state.disabledApply && "No changes to apply"}>
                            Apply changes
                        </Button>
                    </ButtonToolbar>
                </Col>
            </Row>

            {this.state.articlesProps &&
                <MonitoringArticles
                    monitoringId={this.props.id}
                    popover={this.renderConceptPopover}
                    { ...this.state.articlesProps } />}
        </div>;
    }
}


function SavedMonitoring({ id, history }) {
    const [currentUser] = useCurrentUser();
    const [monitoring, reqstate] = useTrack(makeMonitoringQuery(id));

    React.useEffect(() => {
        Reflux.initStore(ChosenRecommendationTopicsStore);
        if (monitoring) {
            ChosenRecommendationTopicsActions.setRecommendation(
                monitoring.topics,
                monitoring.useTree,
                monitoring.tree || null,
                monitoring.date,
            );
        }
    }, [monitoring]);

    return <LoadingREST what="monitoring" reqstate={reqstate} doc={monitoring}>
        {monitoring &&
            <Recommendation
                currentUser={currentUser}
                history={history}
                id={monitoring.id}
                title={monitoring.title}
                date={monitoring.date}
                organization={monitoring.organization} />}
    </LoadingREST>;
}


export function MonitoringLoader({ match: { params }, history }) {
    return <SavedMonitoring id={params.id} history={history} />;
}


export function NewMonitoring({ location: { search }, history }) {
    const [currentUser] = useCurrentUser();

    React.useEffect(() => {
        const query = queryString.parse(search);
        if (query.conceptId && query.conceptName) {
            const topics = [{
                title: query.conceptName,
                concepts: [{
                    conceptId: query.conceptId,
                    name: query.conceptName,
                }],
            }];

            Reflux.initStore(ChosenRecommendationTopicsStore);
            ChosenRecommendationTopicsActions.setTopics(topics);
        }
    }, [search]);

    return <Recommendation
        currentUser={currentUser}
        history={history}
        date={'month'}
        defaultCollapsed={false} />;

}
