import React, { Component } from 'react';
import type { Node, Ref } from 'react';

import MaskedInput from 'react-text-mask';

import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';

import { cx, KEYS } from '../utils';

import './styles.css';


type DatesPropsType = {
    initialSelected: ?string,
    handleClick: (x: ?string) => any,
};


const DATE_OPTIONS = [
    { id: 'day', label: 'Day' },
    { id: 'week', label: 'Week' },
    { id: 'month', label: 'Month' },
    { id: '1year', label: '1 Year' },
    { id: '5years', label: '5 Years' },
    { id: 'range', label: 'Range' },
    { id: null, label: 'All' },
];


class DateButton extends React.Component<{
    kind: ?string,
    active: boolean,
    inputRef: Ref<*>,
    handleClick: (kind: ?string) => any,
    children: Node
}> {
    static defaultProps = {
        active: false
    };

    handleClick = () => {
        this.props.handleClick(this.props.kind);
    }

    render() {
        return <Button className={cx({ 'active': this.props.active })}
                       onClick={this.handleClick}
                       ref={this.props.inputRef}>
            {this.props.children}
        </Button>;
    }
}


export default class Dates extends Component<
    DatesPropsType,
    {
        rangeOpen: boolean,
        selected: ?string,
        start: string,
        end: string,
        startHasError: boolean,
        endHasError: boolean
    }
> {
    DATE_MASK = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/];

    startDateRef = React.createRef();
    rangeButtonRef = React.createRef();

    constructor(props: DatesPropsType) {
        super(props);

        const { initialSelected } = this.props;

        this.state = {
            rangeOpen: false,
            start: '',
            end: '',
            startHasError: false,
            endHasError: false,
            ...(
                initialSelected && initialSelected.indexOf('-') !== -1 ?
                    {
                        selected: 'range',
                        start: initialSelected.split('-')[0],
                        end: initialSelected.split('-')[1],
                    }
                    : { selected: initialSelected }
            ),
        };
    }

    validateDate(field: 'start' | 'end') {
        const [, month, day] = this.state[field].split('/').map(num => parseInt(num, 10));

        return isNaN(month) || month < 1 || month > 12 || isNaN(day) || day < 1 || day > 31;
    }

    handleClick = (selected: ?string) => {
        this.setState({ selected, rangeOpen: selected === 'range' });
        if (selected !== 'range') {
            this.props.handleClick(selected);
        }
    }

    handleStartDateChange = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ start: e.currentTarget.value });
    }

    handleEndDateChange = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ end: e.currentTarget.value });
    }

    validateAndSubmitRange = () => {
        const startHasError = this.validateDate('start');
        const endHasError = this.validateDate('end');

        this.setState({ startHasError, endHasError });

        if (!startHasError && !endHasError) {
            this.props.handleClick(`${this.state.start}-${this.state.end}`);
            this.handleHide();
        }
    }

    handleMaskedInputKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
        if (e.which === KEYS.ENTER) {
            this.validateAndSubmitRange();
        }
    }

    setFocus = () => {
        setTimeout(() => {
            this.startDateRef.current &&
            this.startDateRef.current.inputElement.focus();
        }, 1);
    }

    handleHide = () => {
        this.setState({ rangeOpen: false });
    }

    render() {
        const { start, end, selected } = this.state;
        const maskedInputProps = {
            className: 'form-control',
            onKeyDown: this.handleMaskedInputKeyDown,
            type: 'text',
            placeholder: 'YYYY/MM/DD',
            mask: this.DATE_MASK
        };

        return <div>
            <Overlay show={this.state.rangeOpen}
                     onHide={this.handleHide}
                     onEnter={this.setFocus}
                     placement="bottom"
                     animation={true}
                     target={this.rangeButtonRef.current}
                     rootClose>
                <Popover title="Select dates"
                         id="dates-range-popover">
                    <div className={cx('form-group', { 'has-error': !!this.state.startHasError })}>
                        <MaskedInput
                            { ...maskedInputProps }
                            ref={this.startDateRef}
                            onChange={this.handleStartDateChange}
                            value={start} />
                    </div>
                    {' to '}
                    <div className={cx('form-group', { 'has-error': !!this.state.endHasError })}>
                        <MaskedInput
                            { ...maskedInputProps }
                            onChange={this.handleEndDateChange}
                            value={end} />
                    </div>
                    <div>
                        <Button title="Save"
                                bsSize="small"
                                bsStyle="primary"
                                onClick={this.validateAndSubmitRange}>
                            <span>Apply</span>
                        </Button>
                    </div>
                </Popover>
            </Overlay>
            <ButtonGroup bsClass="btn-group pull-right dates">
                {DATE_OPTIONS.map(o => <DateButton kind={o.id}
                                                   key={o.label}
                                                   handleClick={this.handleClick}
                                                   active={selected === o.id}
                                                   { ...(o.id === 'range' ?
                                                       { inputRef: this.rangeButtonRef }
                                                       : {}
                                                   )}>
                    {o.label}
                </DateButton>)}
            </ButtonGroup>
        </div>;
    }
}
