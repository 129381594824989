import React from 'react';
import { Link } from 'react-router-dom';

import DocumentTitle from 'react-document-title';
import queryString from 'query-string';

import Alert from 'react-bootstrap/lib/Alert';
import Col from 'react-bootstrap/lib/Col';

import type { ApplicationType } from './Types';
import ApplicationAttachments from './ApplicationAttachments';
import type { AuthorType } from '../authors/Types';
import ApplicantArticles from './ApplicantArticles';
import type { ReactRouterMatchType } from '../common/Types';
import withCurrentUser from '../common/withCurrentUser';
import type { CurrentUserType } from '../accounts/Types';
import { useTrack } from '../datastore';
import { makeFullAuthorQuery, makeLoadMoreArticlesQuery } from '../authors/AuthorData';
import { makeJobApplicationQuery } from './JobData';
import TopConceptsChart from '../authors/TopConceptsChart';
import LoadingREST from '../LoadingREST';
import withAuthorization from '../common/withAuthorization';
import LinkToRecommendation from './LinkToRecommendation';
import VoteButton from './VoteButton';
import ApplicantComments from './ApplicantComment';
import ApplicantTags from './ApplicantTags';
import PreliminaryBadge from './PreliminaryBadge';
import { roundInteger } from '../utils';
import AuthorLink from '../authors/AuthorLink';
import { AuthorArticlesHeader, parseAuthorParams } from '../authors/Author';


class LoadedApplicant extends React.Component<{
    application: ApplicationType,
    author?: AuthorType,
    activeParams: any,
    articles?: any,
    authorArticles?: { articleIds: Array<number>, hasMoreArticles: boolean },
    location: any,
    currentUser: CurrentUserType
}> {
    makeUrl = (data) => {
        return `${this.props.location.pathname}?${queryString.stringify(data)}`;
    }

    renderAuthorData() {
        const { author, currentUser } = this.props;

        if (!author) return null;

        const filterEnabled = currentUser.isAuthenticated && !currentUser.isExpired;
        const filterDisabledMessage = !currentUser.isAuthenticated ?
            'login to enable filtering'
            : currentUser.isExpired ?
                'your trial period expired and filtering is not longer available'
                : null;

        const citationsCountNotInCollab = roundInteger(author.citationsCountNotInCollab);
        // show collab count only if it's visibly greater than not-in-collab
        const citationsCount =
            author.citationsCount > author.citationsCountNotInCollab &&
            citationsCountNotInCollab !== roundInteger(author.citationsCount) ?
                roundInteger(author.citationsCount) : null;

        return <>
            <div>
            {author.hIndexNotInCollab > 0 &&
             <div>
                 <h4 title="for our collection">
                     h-index: {author.hIndexNotInCollab}
                     {author.hIndex > author.hIndexNotInCollab &&
                      <small><br />
                          {author.hIndex}&nbsp;including&nbsp;consortiums
                      </small>}
                 </h4>
             </div>}

            {author.citationsCountNotInCollab > 0 &&
             <div>
                 <h4 title="for our collection">
                     Citations: {citationsCountNotInCollab}
                     {citationsCount &&
                      <small><br />
                          {citationsCount}&nbsp;including&nbsp;consortiums
                      </small>}
                 </h4>
             </div>}

            {author.notableArticlesCountNotInCollab > 0 &&
             <div>
                 <h4>Notables: {author.notableArticlesCountNotInCollab}
                     {author.notableArticlesCount > author.notableArticlesCountNotInCollab &&
                     <small><br />
                         {author.notableArticlesCount}&nbsp;including&nbsp;consortiums
                     </small>}
                 </h4>
             </div>}

            </div>

            <TopConceptsChart
                concepts={author.topConcepts}
                makeUrl={this.makeUrl}
                limit={10}
                activeParams={this.props.activeParams}
                enabled={filterEnabled}
                disabledMessage={filterDisabledMessage}
            />
        </>;
    }

    renderArticlesSection() {
        const { author, authorArticles, location, activeParams } = this.props;
        if (!author || !authorArticles) {
            return <div className="row">
                <h4>Articles</h4>
                <Alert bsStyle="danger">
                    <p>Electronic publication list is not available yet.</p>
                </Alert>
            </div>;
        }

        return <>
            <AuthorArticlesHeader
                activeParams={activeParams}
                displayedArticlesCount={authorArticles.articleIds.length}
                totalArticlesCount={author.articlesCount}
                makeUrl={this.makeUrl}
                headerSmWidth={3}
            />

            <ApplicantArticles
                articleIds={authorArticles.articleIds}
                authorId={author.id}
                hasMoreArticles={authorArticles.hasMoreArticles}
                queryString={location.search} />
        </>;
    }

    render() {
        const { application, author } = this.props;

        const applicantName = author ? author.name : application.email;

        return <div className="job-applicant">
            <DocumentTitle title={applicantName + ' - Prophy'} />

            <h2 className="hero-heading">
                {applicantName}
                {application.isPreliminary && <PreliminaryBadge/>}
                {author && <>
                    {' '}<small><AuthorLink id={author?.id}>author &rarr;</AuthorLink></small>
                </>}
            </h2>

            <div className="row">
                <Col sm={3} className="job-applicant-sidebar">
                    <div className="applicant-email">
                    {application.isPreliminary ?
                        <>
                            {application.email ?
                                <h4 style={{display: 'block', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                    Probable email: <a href={`mailto:${application.email}`}>
                                    {application.email}</a>
                                </h4> : null}
                        </> :
                        <h4 style={{display: 'block', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            Email:&nbsp;<a href={`mailto:${application.email}`}>
                            {application.email}</a>
                        </h4>
                    }
                    </div>
                    <div>
                        <h4 style={{display: 'inline-block', marginRight: '10px'}}>Tags</h4>
                        <ApplicantTags applicant={application} />
                    </div>
                    <div>
                        <h4 style={{display: 'inline-block'}}>Votes</h4>
                        <VoteButton applicant={application} />
                    </div>
                    <div className="applicant-comments">
                        <h4 style={{display: 'inline-block'}}>Comments</h4>
                        <ApplicantComments applicant={application} showAll />
                    </div>
                    <div>
                        <h4>
                            Files
                            {!application.attachments.length && <small>&nbsp;No&nbsp;files&nbsp;attached</small>}
                        </h4>
                        <ApplicationAttachments application={application} />
                    </div>
                    <div>
                        <h4>Recommendations</h4>
                        <ul style={{paddingLeft: 20}}>
                            {application.advisors.map((advisor, i) => (
                                <li key={advisor.email + '-' + i} style={{lineHeight: '15px', marginBottom: '10px'}}>
                                    {advisor.filename ?
                                        <LinkToRecommendation advisor={advisor}>
                                            {advisor.name}
                                        </LinkToRecommendation>
                                        : <>
                                            {advisor.name}:{' '}
                                            <span className="text-muted">has not given recommendation</span>
                                            {this.props.currentUser.isSuperuser &&
                                                <div><Link to={`/jobs/recommendation/${advisor.id}`}>upload</Link></div>}
                                        </>}
                                </li>
                            ))}
                        </ul>
                    </div>
                    {this.renderAuthorData()}
                </Col>
                <Col sm={9}>
                    {this.renderArticlesSection()}
                </Col>
            </div>
        </div>;
    }
}


const LoadedApplicantWithCurrentUser = withCurrentUser(LoadedApplicant);

function LoadedApplication(
    { application, location }
) {
    const authorId = application.authorId;
    const [author, authorReqstate] = useTrack(makeFullAuthorQuery(authorId, location.search));
    const [authorArticles, articlesReqstate] = useTrack(
        makeLoadMoreArticlesQuery(authorId, location.search)
    );

    const [activeParams] = parseAuthorParams(location.search);

    return <div className="job-applicant">
        <LoadingREST what="author" doc={author} reqstate={authorReqstate}>
            <LoadingREST what="articles"
                         doc={authorArticles}
                         reqstate={articlesReqstate}
                         partialResponse>
                <LoadedApplicantWithCurrentUser
                    application={application}
                    author={author}
                    authorArticles={authorArticles}
                    activeParams={activeParams}
                    location={location}
                />
            </LoadingREST>
        </LoadingREST>
    </div>;
}

function JobApplicantPage(
    { match, location }
    : { match: ReactRouterMatchType, location: any }
) {
    const [application, reqstate] = useTrack(makeJobApplicationQuery(match.params.id));

    return <LoadingREST what="applicant data" doc={application} reqstate={reqstate}>
        {application && application.authorId ?
            <LoadedApplication application={application} location={location} />
            : <LoadedApplicantWithCurrentUser application={application} location={location} />
        }
    </LoadingREST>;
}

export default withAuthorization(JobApplicantPage);
