import React from 'react';
import qs from 'query-string';
import flatten from 'lodash/flatten';

import { Link, useHistory, useLocation } from 'react-router-dom';

import { sendRequest, updateGlobalData } from '../../datastore';
import { makePanelKey } from './PanelMembersData';

import Col from 'react-bootstrap/lib/Col';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Panel from 'react-bootstrap/lib/Panel';
import Row from 'react-bootstrap/lib/Row';
import AutoAffix from 'react-overlays/lib/AutoAffix';

import PanelMemberRow from './PanelMemberRow';
import PanelChart from './PanelChart';
import RefereesSettings from '../../referees/RefereesSettings';
import SearchAuthors from '../../referees/SearchAuthors';
import SubpanelDocumentsPreview from './SubpanelDocumentsPreview';
import SortableTable from '../../widgets/SortableTable';
import { cx, toFixed, noop, singlePlural } from '../../utils';
import analytics from '../../common/analytics';
import { defaultSettings } from './helpers';
import helpers from '../../referees/helpers';

import './panel-member-candidates.css';


const COLUMNS = [
    {
        text: '',
        selectable: true,
    },
    {
        field: 'id',
        key: true,
    },
    {
        field: 'position',
        text: '#',
        alphaNum: true,
        className: "text-right",
    },
    {
        field: 'name',
        text: 'Name',
        className: 'name',
    },
    {
        field: 'gender',
        text: '',
    },
    {
        field: 'score',
        text: 'Score',
        className: 'score',
    },
    {
        field: 'detailedScore',
        text: 'Detailed score',
        className: 'score-by-subpanels',
    },
    {
        field: 'totalArticlesCount',
        text: 'Articles',
        className: 'articles-count',
    },
    {
        field: 'hIndex',
        text: 'h-index',
        className: 'h-index',
    },
    {
        field: 'citationsCount',
        text: 'Citations',
        className: 'citations-count',
    },
    {
        field: '',
        text: 'Details',
        className: 'actions',
    },
];

const CANDIDATES_SETTINGS = [
    'gender',
    'include_countries',
    'exclude_countries',
    'ignore_countries',

    'ignore_authors_group',
    'authors_groups_ids',
    'authors_group_effect',
];

export default function PanelMemberCandidates(
    { panel, compositionId, storeKey,
      isEditable, people, customPeople = [], panelScores,
      countries, customAuthorIds = [],
      authorsGroups = [],
      showingCandidates = false, updating = false,
      cacheMiss = false,
      inProgress = false,
      error = false }
) {
    const history = useHistory();
    const location = useLocation();
    const parsedQS = React.useMemo(() => qs.parse(location.search), [location.search]);
    const [sortSubpanels, setSortSubpanels] = React.useState([]);
    const settings = React.useMemo(() => parsedQS.include_countries
                                         || parsedQS.exclude_countries
                                         || parsedQS.gender
                                         || parsedQS.authors_groups_ids ?
        {
            ...defaultSettings,
            ...helpers.settingsFromQS(location.search),
        }
        : defaultSettings,
        // disable extensive dependencies because all deps are derived from location.search
        [location.search] //eslint-disable-line
    );
    const [showSettings, setShowSettings] = React.useState(settings !== defaultSettings);
    const toggleShowSettings = () => setShowSettings(!showSettings);

    const firstSubpanelCode = sortSubpanels.length ? sortSubpanels[0] : null;
    const getUrl = React.useCallback(
        query => {
            const url = `/panel-composer/${panel.id}/compositions/${compositionId}`;
            return `${url}/${showingCandidates ? 'candidates' : 'members'}/?${qs.stringify(query)}`;
        },
        [panel, compositionId, showingCandidates]
    );

    const setSettings = React.useCallback(settings => {
        history.push(
            getUrl({
                ...(parsedQS.author && { author: parsedQS.author }),
                ...(parsedQS.sort && { sort: parsedQS.sort }),
                ...qs.parse(helpers.settingsToQS(settings)),
            })
        );
        // parsedQS is derived from location.search
    }, [history, getUrl, location.search]); //eslint-disable-line

    const pushSort = React.useCallback((subpanelsCodes) => {
        history.push(getUrl({ ...parsedQS, sort: subpanelsCodes }));
    }, [history, parsedQS, getUrl]);

    const setCustomAuthorIds = React.useCallback(ids => {
        history.push(getUrl({ ...parsedQS, author: ids }));
    }, [getUrl, history, parsedQS]);

    const handleBarClick = React.useCallback((payload, modifier) => {
        setSortSubpanels(subpanels => {
            // with modifier (SHIFT) simply toggle selection
            // without modifier toggle selection when no other subpanels selected
            // when more than one subpanel selected, deselect others
            const wasSelected = subpanels.indexOf(payload.name) >= 0;
            if (modifier) {
                subpanels = wasSelected ? subpanels.filter(s => s !== payload.name) :
                    [...subpanels, payload.name];
            } else {
                subpanels = wasSelected && subpanels.length === 1 ? [] : [payload.name];
            }
            pushSort(subpanels);
            return subpanels;
        });
    }, [pushSort]);

    const [hoveredPerson, setHoveredPerson] = React.useState(false);
    const [selectedPeople, setSelectedPeople] = React.useState([]);

    const onPersonSelect = React.useCallback((person, selected, hover) => {
        if (hover !== null && hover !== undefined) {
            setHoveredPerson(hover ? person : false);
        } else if (selected !== null && selected !== undefined) {
            setSelectedPeople(people =>
                selected ?
                    people.indexOf(person) === -1 ? // if selecting:
                        [...people, person] // add if person is not there
                        : people // or do nothing
                    : people.filter(p => p !== person) // deselecting, remove
            );
        } else {
            console.warn('Invalid parameters for onPersonSelect', person, selected, hover);
        }
    }, [setSelectedPeople, setHoveredPerson]);

    const onPersonToggle = React.useCallback(person => {
        const adding = person.status === null;

        return sendRequest(`/api/panel/${adding ? 'add-candidate' : 'remove-candidate'}`, {
            method: 'POST',
            body: JSON.stringify({
                panelId: panel.id,
                compositionId: compositionId,
                authorId: person.author.id,
            }),
        }).then(response => {
            const { ok } = response;

            if (ok) {
                const status = adding ? 'candidate' : null;
                const newPerson = {...person, status};

                updateGlobalData(store => {
                    const panelKey = makePanelKey(panel.id, compositionId);
                    store[panelKey] = {
                        ...store[panelKey],
                        subpanels: store[panelKey].subpanels.map(subpanel => ({
                            ...subpanel,
                            score: subpanel.score + (person.subpanelId2score[subpanel.id] || 0) * (adding ? 1 : -1)
                        })),
                    };

                    let index = store[storeKey].scoredPanelMembers.indexOf(person);
                    if (index === -1) {
                        index = store[storeKey].scoredPanelMembers.length;
                    }
                    store[storeKey] = {
                        ...store[storeKey],
                        scoredPanelMembers: [
                            ...store[storeKey].scoredPanelMembers.slice(0, index),
                            newPerson,
                            ...store[storeKey].scoredPanelMembers.slice(index+1)
                        ]
                    };
                });
                onPersonSelect(newPerson, null, false);
                onPersonSelect(newPerson, null, true);
            }

            return response;
        });
    }, [panel, compositionId, onPersonSelect, storeKey]);

    const onPersonHide = React.useCallback(person => {
        updateGlobalData(store => {
            store[storeKey] = {
                ...store[storeKey],
                scoredPanelMembers: store[storeKey].scoredPanelMembers.filter(p => p !== person)
            }
        });
    }, [storeKey]);

    const domain = React.useMemo(() => ([
        0, parseInt(toFixed(Math.max(...flatten(people.map(c => Object.values(c.subpanelId2score)))), 0), 10)
    ]), [people]);

    const isSelected = React.useCallback(
        person => selectedPeople.indexOf(person) !== -1,
        [selectedPeople]
    );

    const renderRow = React.useCallback(person => {
        const isCustom = customAuthorIds.indexOf(person.author.id) !== -1;

        return <PanelMemberRow
            key={`${person.author.id}${isCustom ? '-custom' : ''}`}
            person={person}
            panel={panel}
            compositionId={compositionId}
            isEditable={isEditable}
            isCandidate={person.status === null}
            scoreChartDomain={domain}
            onPersonSelect={onPersonSelect}
            onPersonToggle={onPersonToggle}
            onPersonHide={onPersonHide}
            onSubpanelBarClick={handleBarClick}
            sortedBySubpanels={sortSubpanels}
            isSelected={isSelected(person)}
            isHovered={hoveredPerson === person}
            isCustom={isCustom}
        />;
    }, [panel, compositionId, domain, onPersonSelect, onPersonToggle, onPersonHide,
        isEditable, handleBarClick, sortSubpanels, isSelected, hoveredPerson, customAuthorIds]);

    const mergedPeople = React.useMemo(() => {
        const peopleIds = people.reduce((obj, ref) => ({ ...obj, [ref.author.id]: true }), {});

        const additional = customPeople.filter(custom => !peopleIds[custom.author.id]);
        return people.concat(additional);
    }, [people, customPeople]);

    const handleApplySettingsFilter = React.useCallback((settings: any) => {
        analytics.log('panel-members/apply-settings', {
            ...settings,
            type: showingCandidates ? 'candidates' : 'members',
            docId: panel.id,
            docKind: 'panel'
        });

        setSettings(settings);
    },[panel.id, setSettings, showingCandidates]);

    React.useEffect(() => {
        setSortSubpanels(parsedQS.sort ?
            typeof parsedQS.sort === 'string' ?
                [parsedQS.sort]
                : parsedQS.sort
            : []
        );
    }, [parsedQS.sort]);

    React.useEffect(() => {
        if (cacheMiss && (error || inProgress)) {
            console.log("Refreshing in 30s if the page is open");
            setInterval(() => {if (!document.hidden) {window.location.reload()}}, 30000);

            document.addEventListener("visibilitychange", () => {
                if (!document.hidden) window.location.reload();
            });
        }
    }, [cacheMiss, error, inProgress]);

    const table = <>
        {cacheMiss && <>
            {error && <div className="alert alert-danger" style={{marginBottom: 0,  clear: 'both'}}>
                <p>The most recent computation of this panel failed. We are working on this issue.</p>
                <p>If you have any questions, please contact us at&nbsp;
                <a href={`mailto:support@prophy.ai?subject=Error panel #${panel.id}`}>support@prophy.ai</a>.</p>
             </div>}
            {inProgress && <div className="alert alert-info" style={{marginBottom: 0,  clear: 'both'}}>
                <p>This panel is being computed right now.</p>
                <p>For any information, please contact us at&nbsp;
                <a href={`mailto:support@prophy.ai?subject=Error panel #${panel.id}`}>support@prophy.ai</a>.</p>
             </div>}
            {!error && !inProgress && <div className="alert alert-warning" style={{marginBottom: 0,  clear: 'both'}}>
                <p>This panel has not been computed yet. &nbsp;
                   <button className="btn btn-default btn-large"
                           style={{marginTop: '-2px'}}
                           onClick={() =>
                                fetch(`/api/panel/${panel.id}/compute`, {method: 'POST'})
                                .then(response => {console.log(response); window.location.href = `/panel-composer/${panel.id}/compositions`;})
                           }>Schedule computation</button></p>
             </div>}
        </>}
        <SortableTable
            className="panel-member-candidates-table table-double-striped condensed table-sticky-head"
            columns={COLUMNS}
            data={mergedPeople}
            onSelect={onPersonSelect}
            isSelected={isSelected}
            defaultSortingField="position"
            defaultSortingDirection="asc"
            renderRow={renderRow}
        />
    </>;

    return <Row className="panel-member-candidates referees-lists">
        <Col xs={12} md={8}>
            {!showingCandidates ?
                <div>
                    <div className="pull-right">
                        <Link to={`/panel-composer/${panel.id}/compositions/${compositionId}/candidates/${location.search}`}>
                            <Glyphicon glyph="plus" /> {isEditable ? 'add' : 'find'} candidates
                        </Link>
                    </div>
                </div>
                : <SearchAuthors
                    docKind="panel"
                    docId={panel.id}
                    additionalDocKind="composition"
                    additionalDocId={compositionId}
                    customAuthorIds={customAuthorIds}
                    setCustomAuthorIds={setCustomAuthorIds}
                    settings={parsedQS}
                />
            }
            {showingCandidates ?
                <Panel className={cx('panel-table-container panel-members', { 'show-settings': showSettings })}
                       expanded={true}
                       onToggle={noop}>
                    <Panel.Heading>
                        <Panel.Title>
                            <button className='inline-link settings-toggle'
                                    style={{display: 'block', width: '100%', marginLeft: 0}}
                                    title={`${showSettings ? 'Hide' : 'Show'} advanced settings`}
                                    onClick={toggleShowSettings}>
                                <strong>
                                    Top {mergedPeople.length} {singlePlural(showingCandidates ? 'candidate' : 'member', mergedPeople.length)}
                                </strong>
                                <div className={cx('pull-right', { 'active': showSettings })}
                                     style={{fontStyle: 'italic'}}>
                                    show settings <Glyphicon glyph="cog"/>
                                </div>
                            </button>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {!!CANDIDATES_SETTINGS.length &&
                            <div className="settings">
                                <div className="wrapper">
                                    <RefereesSettings
                                        docKind="panel"
                                        docId={panel.id}
                                        defaultSettings={defaultSettings}
                                        availableSettings={CANDIDATES_SETTINGS}
                                        settings={settings}
                                        authorsGroups={authorsGroups}
                                        countries={countries}
                                        updating={updating}
                                        onApply={handleApplySettingsFilter}
                                        message={<p>
                                            Change gender, location, experience level, and
                                            broadness of expertise of
                                            proposed candidates.
                                        </p>} />
                                </div>
                            </div>
                        }
                        {table}
                    </Panel.Body>
                </Panel>
                : table
            }
        </Col>
        <Col xs={12} md={4}>
            <AutoAffix affixClassName="affixed"
                       bottomClassName="affixed-bottom"
                       autoWidth
                       viewportOffsetTop={10}>
                <div>
                    <PanelChart
                        showingCandidates={showingCandidates}
                        panel={panel}
                        scores={panelScores}
                        selectedPeople={selectedPeople}
                        hoveredPerson={hoveredPerson}
                        onSubpanelBarClick={handleBarClick}
                        sortedBySubpanels={sortSubpanels}
                        people={mergedPeople}
                    />
                    <SubpanelDocumentsPreview panel={panel} subpanelCode={firstSubpanelCode}/>
                </div>
            </AutoAffix>
        </Col>
    </Row>
}
