import React from 'react';
import { useCurrentUser } from '../accounts/hooks';
import { REFEREE_SETTINGS } from './Referees'
import RefereesSettings from './RefereesSettings';
import helpers from './helpers';
import { useSubscription, sendRequest, updateGlobalData } from '../datastore';
import { makeSettingsKey } from './RefereesData';
import analytics from '../common/analytics';
import { checkStatus } from '../utils';


const FOLDER_REFEREE_SETTINGS = REFEREE_SETTINGS.filter(
    l => l !== 'topics' && l !== 'ignore_topics');


export default function FolderSettingsPage({ match: {params: {folderId: docId} } }) {

    const [currentUser] = useCurrentUser();
    const [updating, setUpdating] = React.useState(true);

    const [folder, setFolder] = React.useState({});
    const [countries, setCountries] = React.useState([]);
    const [authorsGroups, setAuthorsGroups] = React.useState([]);

    const docKind = 'folder';
    const settingsId = null;
    const defaultSettings = helpers.getDefaultSettings({id: docId}, 'folder');

    const rfMessage = <p className="help-block">The following settings will be used as default for documents in this folder:</p>
    const styles = {marginTop: 0};
    const alertStyles = {marginLeft: '-15px', display: 'inline-block'};

    React.useEffect(() => {
        setUpdating(true);

        sendRequest(`/api/referees/${docKind}/${docId}/settings`, {
            method: 'GET',
            // to override json content-type in sendRequest
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
        })
            .then(checkStatus)
            .then(response => {
                if (response.ok) {
                    updateGlobalData((store, { value: { folder, countries, authorsGroups } }) => {
                        const setKeys = makeSettingsKey(docKind, docId)
                        store[setKeys[0]] = {...folder.refereeFinderSettings};
                        setFolder(folder);
                        setCountries(countries);
                        setAuthorsGroups(authorsGroups)
                    }, response);
                }
                setUpdating(false);
            });
    }, [docKind, docId]);

    const settingsKey = makeSettingsKey(docKind, docId);
    const settings = useSubscription(...settingsKey);

    const handleApplySettingsFilter = React.useCallback((settings, settingsId) => {
        setUpdating(true);
        sendRequest(`/api/referees/folder/${docId}/settings`, {
            credentials: 'same-origin',
            method: 'POST',
            headers: {'Content-type': 'application/json',},
            body: JSON.stringify({
                settings: settings,
            })
        }).then(response => {
            if (response.ok) {
                alert('Settings saved!')
            }
            setUpdating(false);
        });

        analytics.log('rf/apply-settings', {
            ...settings,
            docId,
            docKind,
        });
    }, [docKind, docId]);

    return <div>
        <a href={`/folder/${docId}`}>&larr; back to the folder </a>
        <h2>{folder.name}</h2>
        <h3 className="help-block">{updating ? 'Updating...' : 'Default Referee Finder settings'}</h3>

        {!!settings && <div className="referees-lists referees show-settings" style={styles}>
            <br/>
            {currentUser.isSuperuser && <div className="alert alert-warning" style={alertStyles}>
                You are a superuser. Other users might not have access to some of the features.
            </div>}
            <div className="settings">
                <div className="wrapper">
                    <RefereesSettings
                        docKind={docKind}
                        docId={docId}
                        settingsId={settingsId}
                        defaultSettings={defaultSettings}
                        availableSettings={FOLDER_REFEREE_SETTINGS}
                        settings={settings}
                        availableTopics={null}
                        docTopics={null}
                        countries={countries}
                        authorsGroups={authorsGroups}
                        customAuthorIds={null}
                        updating={updating}
                        onApply={handleApplySettingsFilter}
                        message={rfMessage} />
                </div>
            </div>
        </div>}
    </div>;
}
