import React from 'react';

import BaseRootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';

import dom from '../common/helpers/dom';


export default class RootCloseWrapper extends BaseRootCloseWrapper {
    el: ?HTMLDivElement = null;

    setContainer = (el: ?HTMLDivElement) => {
        this.container = el;
    }

    handleMouseCapture = (e: SyntheticMouseEvent<*>) => {
        const target = e.target;

        this.preventMouseRootClose = e.button !== 0 || (
            target instanceof Element && (
                dom.hasParent(target, this.container) || (
                    // special case for clicking inside modal or its backdrop
                    // that was triggered from popover
                    (dom.hasParent(target, '.modal') || target.classList.contains('modal')) &&
                    !this.container.querySelector('.modal') &&
                    !this.container.classList.contains('modal')
                )
            )
        );
    }

    render() {
        return <div ref={this.setContainer}>
            {super.render()}
        </div>;
    }
}
