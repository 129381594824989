import React, { useEffect, useState } from 'react';

import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import helpers from './helpers';
import { useSubscription } from '../datastore';
import { makeSettingsKey } from './RefereesData';
import { useCurrentUser } from '../accounts/hooks';
import {
    getCookie, setCookie, COOKIE_EXPORT_REFEREES_AS_ORG_ID,
} from '../Cookies';


// Synchronize with export-marked-referees-modal.html
export default function RefereesExport(
    {
        doc,
        docKind,
        docId,
        customAuthorIds,
        hasMarkedReferes,
        onlyCustom,
    }) {

    const [show, setShow] = useState(false);
    const [exportFormat, setExportFormat] = useState('xlsx');
    const [csvDelimiter, setCSVDelimiter] = useState(',');
    const [refereesLimit, setRefereesLimit] = useState(50);
    const [onlyMarked, setOnlyMarked] = useState(false);
    const [displaySettingsTemplateOrgId, setDisplaySettingsTemplateOrgId] = useState(getCookie(COOKIE_EXPORT_REFEREES_AS_ORG_ID));
    const [currentUser, ] = useCurrentUser();

    const settings = useSubscription(...makeSettingsKey(docKind, docId));
    const settingsQs = React.useMemo(() => helpers.settingsToQSDefined({
        custom_authors_ids: customAuthorIds,
        ...settings || {},
    }), [customAuthorIds, settings]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const setLimitAndOnlyMarked = (value) => {
        if (value === 'marked') {
            setRefereesLimit(0);
            setOnlyMarked(true);
        } else {
            setRefereesLimit(value);
            setOnlyMarked(false);
        }
    }
    useEffect(() => {
        setLimitAndOnlyMarked(hasMarkedReferes && 'marked');
    }, [hasMarkedReferes]);

    const qs = [
        settingsQs,
        `doc_kind=${docKind}`,
        `export_format=${exportFormat}`
        + (exportFormat === 'csv' ? `&csv_delimiter=${csvDelimiter}` : ''),
        `limit=${refereesLimit}`,
        onlyCustom ? 'only_custom=1' : null,
        onlyMarked ? 'only_marked=1' : null,
        displaySettingsTemplateOrgId ? `as_org_id=${displaySettingsTemplateOrgId}`: null,
    ].filter(item => !!item).join('&');

    const handeAsOrgIdChange = (asOrgId) => {
        setDisplaySettingsTemplateOrgId(asOrgId);
        setCookie(COOKIE_EXPORT_REFEREES_AS_ORG_ID, asOrgId);
    }

    const renderOrgsSelect = () => {
        const orgIdsStr = currentUser.organizations.map(org => org.id.toString());
        const value = orgIdsStr.indexOf(displaySettingsTemplateOrgId) !== -1 ? displaySettingsTemplateOrgId : null;

        return <div>
            <span>Select display settings template&nbsp;</span>
            <select onChange={(e) => handeAsOrgIdChange(e.target.value)}
                    value={value}>
                <option key="" value="">Platform default</option>
                <optgroup label=""/>
                <optgroup label="Organizations"/>
                {currentUser.organizations.map((org) =>
                    <option key={org.id} value={org.id}>{org.name}</option>)}
            </select>
        </div>
    }

    return !!settings &&
        <>
        <Button className="btn" onClick={handleShow}>
            <Glyphicon glyph="save" />&nbsp;Export
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Export candidates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Export &nbsp;
                    <select className="form-inline"
                            value={onlyMarked ? "marked" : refereesLimit}
                            onChange={(e) => setLimitAndOnlyMarked(e.target.value)}>
                        <option value="50">top 50</option>
                        <option value="100">top 100</option>
                        <option value="200">top 200</option>
                        {docKind === 'proposal' &&
                            <option value="marked">only marked as
                                referees</option>}
                    </select>
                    &nbsp;candidates in&nbsp;
                    <select className="form-inline" value={exportFormat}
                            onChange={(e) => setExportFormat(e.target.value)}>
                        <option value="xlsx">xlsx</option>
                        <option value="csv">csv</option>
                        <option value="json">json</option>
                    </select>
                    &nbsp;format{exportFormat === 'csv' &&
                    <>&nbsp;with delimiter&nbsp;
                        <select className="form-inline" value={csvDelimiter}
                                onChange={(e) => setCSVDelimiter(e.target.value)}>
                            <option value="\t">&lt;TAB&gt;</option>
                            <option value=",">,</option>
                            <option value=";">;</option>
                            <option value="|">|</option>
                        </select>
                    </>}.
                </p>
                <p>
                    {docKind === 'proposal' && doc.folder.id && doc.folder.org_id &&
                      <a href={`/orgadmin/display-settings/export-referees-view/${doc.folder.org_id}/${doc.folder.id}`}>Export settings</a>
                    }
                    {docKind !== 'proposal' && renderOrgsSelect()}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}
                        className="pull-left">
                    Close
                </Button>
                <a href={`/referees-beam/export-referees/${docId}/?${qs}`}
                   className="btn btn-primary" onClick={handleClose}>
                    Export
                </a>
            </Modal.Footer>
        </Modal>
    </>;
}
