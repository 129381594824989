import * as React from 'react';
import ReactDOM from 'react-dom';

import objectWithoutProperties from 'babel-runtime/helpers/objectWithoutProperties';

import Button from 'react-bootstrap/lib/Button';
import Dropdown from 'react-bootstrap/lib/Dropdown';

import ExpandableInput from '../widgets/ExpandableInput';
import Spinner from '../widgets/Spinner';
import Omnibox from '../widgets/Omnibox';

import { cx, KEYS } from '../utils';
import LRUCache from '../LRUCache';

import './suggest.css';


const SUGGEST_TYPES = {
    article: {
        type: 'article',
        header: 'Articles:',
        urlTitle: 'Go to article',
    },
    concept: {
        type: 'concept',
        header: 'Concepts:',
        urlTitle: 'View in ontology',
    },
    author: {
        type: 'author',
        header: 'Authors:',
        urlTitle: 'Go to author',
    },
};

export type AbstractSuggestType = {
    id: any,
    type: any,
    value: any,
    url?: string,
    title?: string,
    label: string,
    extra?: any,
    isNotSuggest?: bool
};

export type SuggestType = AbstractSuggestType & { label: any };

export type SuggestsResponseType = {
    results: Array<SuggestType>,
    total: number,
};

export type SuggestFetchCallbackType = (q: string) => Promise<SuggestsResponseType>;

export type SuggestBoxPropsType = {
    query?: string,
    delay: number,
    expandable: bool,
    minChars: number,
    maxLabelLength: number,
    shouldClearOnSelect: bool,
    shouldCallbackOnTextChange: bool,
    showSuggestType: bool,
    callback: (suggest: AbstractSuggestType) => any,
    inputRef?: (el: ?HTMLInputElement) => any,
    onKeyDown?: (e: SyntheticKeyboardEvent<HTMLInputElement>) => any,
    onChange?: (e: SyntheticEvent<HTMLInputElement>, value: string) => any,
    onGetSuggests?: (total: number, suggests: Array<SuggestType>) => any,
    onSuggestFocus?: (suggest: AbstractSuggestType) => any,
    suggestFetch: SuggestFetchCallbackType,
    fetchOnFocus: bool,
    placeholder: string,
    button: any,
    divClasses: string,
    showLink: bool,
    selectOnExactMatch: boolean,
    requestCache: any,
    disabled: bool,
    initialValue: string,
    tabIndex?: number
};


function isNodeInRoot(node: any, root: any) {
    while (node) {
        if (node === root) {
            return true;
        }
        node = node.parentNode;
    }

    return false;
}


function _escapeRegExp(str) {
    return str.replace(/[\\\]/[{}()*+?.^$|-]/g, ' ');
}


type SuggestBoxStateType = {
    suggests: Array<SuggestType>,
    total: number,
    focused: number,
    enteredValue: string,
    value: string,
    open: bool,
    loading: bool,
};


class SuggestBox extends React.Component<SuggestBoxPropsType, SuggestBoxStateType> {
    static defaultProps = {
        delay: 250,
        expandable: false,
        minChars: 2,
        maxLabelLength: 65,
        shouldClearOnSelect: false,
        shouldCallbackOnTextChange: false,
        showSuggestType: true,
        placeholder: 'Search',
        omniProps: null,
        buttonProps: null,
        divClasses: '',
        input: null,
        showLink: true,
        selectOnExactMatch: false,
        requestCache: new LRUCache(),
        disabled: false,
        initialValue: '',
        tabIndex: 0,
        suggestFetch: (q: string): Promise<SuggestsResponseType> => {
            return Promise.resolve({
                results: [],
                total: 0
            })
        },
        fetchOnFocus: false,
    };

    state = {
        suggests: [],
        total: 0,
        focused: -1,
        enteredValue: typeof this.props.query === 'string' ?
            this.props.query
            : this.props.initialValue || '',
        value: typeof this.props.query === 'string' ?
            this.props.query
            : this.props.initialValue || '',
        open: false,
        loading: false,
    };

    _mounted = false;
    inputChangedTimeout: TimeoutID;
    ownProps = [
        'query', 'delay', 'expandable', 'minChars', 'maxLabelLength',
        'shouldClearOnSelect', 'shouldCallbackOnTextChange', 'showSuggestType',
        'callback', 'input', 'inputProps', 'inputRef', 'dropdownRef', 'onGetSuggests', 'onSuggestFocus',
        'suggestFetch', 'fetchOnFocus', 'omniProps', 'buttonProps', 'divClasses', 'dropdownStyle',
        'showLink', 'selectOnExactMatch', 'requestCache',
        'initialValue', 'children'
    ];

    /**
     * Reference to suggest input HTMLElement
     */
    input: ?HTMLInputElement = null;

    setValue(e: SyntheticEvent<*>, value: string, enteredValue: ?string) {
        if (typeof this.props.query === 'string') {
            this.props.onChange && this.props.onChange(e, value);
        } else {
            const newState = enteredValue !== undefined ?
                { value, enteredValue: value }
                : { value };

            this.setState(newState);
        }
    }

    componentDidMount() {
        this._mounted = true;

        if (this.props.initialValue !== this.state.value) {
            this.setState({
                value: this.props.initialValue
            });
        }
    }

    setDropdownState(newState: bool) {
        if (newState) {
            this.bindRootCloseHandlers();
        } else {
            this.unbindRootCloseHandlers();
        }

        this.setState({open: newState});
    }

    handleDocumentKeyUp = (e: SyntheticKeyboardEvent<*>) => {
        if (e.keyCode === 27) {
            this.setDropdownState(false);
        }
    };

    handleDocumentClick = (e: SyntheticEvent<*>) => {
        // If the click originated from within this component
        // don't do anything.
        const root = ReactDOM.findDOMNode(this);
        if (!isNodeInRoot(e.target, root)) {
            this.setDropdownState(false);
        }
    };

    bindRootCloseHandlers() {
        window.addEventListener('click', this.handleDocumentClick);
        window.addEventListener('keyup', this.handleDocumentKeyUp);
    }

    unbindRootCloseHandlers() {
        window.removeEventListener('click', this.handleDocumentClick);
        window.removeEventListener('keyup', this.handleDocumentKeyUp);
    }

    componentWillUnmount() {
        this.unbindRootCloseHandlers();
        this._mounted = false;
        clearTimeout(this.inputChangedTimeout);
    }

    handleChange = (e: any) => {
        // const value = e.target.value;
        const value = this.input.value;

        const newState = {
            focused: -1,
            suggests: [],
            total: 0,
            ...(
                typeof this.props.query === 'string' ?
                    {}
                    : {
                        value,
                        enteredValue: value
                    }
            )
        };

        if (value.trim().length >= this.props.minChars) {
            if (this.props.requestCache.has(value)) {
                const { suggests, total } = this.props.requestCache.get(value);
                newState.suggests = suggests;
                newState.total = total;

                this.props.onGetSuggests && this.props.onGetSuggests(total, suggests);
            } else {
                if (this.inputChangedTimeout) {
                    clearTimeout(this.inputChangedTimeout);
                }

                this.inputChangedTimeout = setTimeout(this.inputChanged, this.props.delay, value);
            }
        }

        this.setState(newState);
        this.setDropdownForSuggest(newState.suggests);

        if (this.props.shouldCallbackOnTextChange) {
            this.props.callback(this.getTempSuggest());
        }

        this.props.onChange && this.props.onChange(e, value);
    };

    inputChanged = (value: string) => {
        // if user didn't enter additional characters
        if (this.input && this.input.value !== value) {
            return;
        }

        this.getData(value);
    };

    getData(value: string) {
        this.setState({ loading: true });

        return this.props.suggestFetch(value).then(response => {
            const { results: suggests = [], total = 0 } = response;

            this.props.requestCache.set(value, { suggests, total });

            // Don't rely on state here, because it may be
            // outdated (this then() callback may pop in between
            // keyup and change event handlers, resulting in
            // this.state.value not being updated by the time of
            // resolving the fetch, and the fact that setState outside
            // react lifecycle causes state to be set synchronously,
            // so that handleChange() will never be called for this
            // iteration, that results in loosing user input.
            if (this.input && this.input.value === value && this._mounted) {
                this.setState({ suggests, total, loading: false });
                this.setDropdownForSuggest(suggests);

                this.props.onGetSuggests && this.props.onGetSuggests(total, suggests);
            }
        }).catch(err => {});
    }

    setDropdownForSuggest(suggests: Array<SuggestType>) {
        if (suggests && this.state.open !== !!suggests.length) {
            this.setDropdownState(!this.state.open);
        }
    }

    modifyFocusedItem(down: bool) {
        if (!this.state.suggests) {
            return;
        }
        let focused;
        if (this.state.focused === -1) {
            focused = down ? 0 : -1;
        } else {
            focused = this.state.focused + (down ? 1 : -1);
        }

        if (focused < 0) {
            focused = this.state.suggests.length - 1;
        } else if (focused >= this.state.suggests.length) {
            focused = 0;
        }
        this.setState({ focused, value: this.state.suggests[focused].value });
        this.props.onSuggestFocus && this.props.onSuggestFocus(this.state.suggests[focused]);
    }

    getTempSuggest() {
        const enteredValue = this.input ?
            this.input.value
            : this.state.enteredValue;

        return {
            id: 'temp_' + enteredValue,
            value: enteredValue,
            label: enteredValue,
            type: null,
            isNotSuggest: true
        };
    }

    getSuggestHeader(suggestType: string) {
        if (suggestType in SUGGEST_TYPES) {
            return SUGGEST_TYPES[suggestType].header;
        }

        return suggestType + ':';
    }

    handleSuggestSelect = (suggest: SuggestType) => {
        if (this.props.shouldClearOnSelect) {
            this.setState({value: ''});
        } else {
            this.setState({value: suggest.value});
        }

        this.setDropdownState(false);
        this.props.callback(suggest);
    };

    handleKeyDown = (e: any) => {
        this.props.onKeyDown && this.props.onKeyDown(e);

        if (e.keyCode === KEYS.ENTER) {
            e.preventDefault();
            // const enteredValue = e.target.value;
            const enteredValue = this.input.value;

            if (enteredValue.trim() === '') {
                this.setValue(e, '', '');

                return;
            }

            const focusedIndex = this.state.focused;

            if (this.state.open && focusedIndex != null && focusedIndex !== -1) {
                const suggest = this.state.suggests[focusedIndex];
                this.props.callback(suggest);
            } else if (this.state.open && this.props.selectOnExactMatch) {
                const exactMatch = this.state.suggests.filter(x =>
                    x.value.toLowerCase() === enteredValue.toLowerCase());
                const suggest = exactMatch.length === 0 ?
                    this.getTempSuggest() : exactMatch[0];
                this.props.callback(suggest);
            } else {
                this.props.callback(this.getTempSuggest());
            }

            if (this.state.open) {
                this.setDropdownState(false);
            }

            if (this.props.shouldClearOnSelect) {
                this.setState({
                    value: '',
                    enteredValue: ''
                });
            } else {
                this.setState({value: enteredValue});
            }
        } else if (e.keyCode === KEYS.ESCAPE) {
            if (this.state.open) {
                e.preventDefault();
                this.setDropdownState(false);
            }
        } else if (e.keyCode === KEYS.DOWN) {
            if (this.state.open) {
                e.preventDefault();
                this.modifyFocusedItem(true);
            } else if (this.props.fetchOnFocus) {
                e.preventDefault();
                this.getData('');
            }
        } else if (e.keyCode === KEYS.UP) {
            if (this.state.open) {
                e.preventDefault();
                this.modifyFocusedItem(false);
            }
        }
    };

    setInput = (el: ?HTMLInputElement) => {
        this.input = el;

        if (this.props.inputRef) {
            this.props.inputRef(el);
        }
    };

    handleToggle() {}

    handleButtonClick = (e: any) => {
        e.preventDefault();
        const enteredValue = typeof this.props.query === 'string' ?
            this.props.query
            : this.state.value;

        if (this.state.open) {
            this.setDropdownState(false);
        }

        if (this.props.shouldClearOnSelect) {
            this.setValue(e, '', '');
        } else {
            this.setValue(e, enteredValue);
        }

        if (enteredValue.trim() !== '') {
            this.props.callback(this.getTempSuggest());
        }
    };

    renderButton() {
        const button = this.props.button;

        if (!button) {
            return null;
        }

        return React.cloneElement(
            button,
            {
                onClick: this.handleButtonClick,
            }
        );
    }

    render() {
        const focused = this.state.focused;

        const suggests = this.state.suggests.map((suggest, i, items) => {
            let rv = <SuggestItem key={i} index={i} focused={focused} suggest={suggest}
                                  entered={typeof this.props.query === 'string' ?
                                      this.props.query
                                      : this.state.enteredValue
                                  }
                                  onSelect={this.handleSuggestSelect}
                                  maxLabelLength={this.props.maxLabelLength}
                                  showLink={this.props.showLink} />;

            if (this.props.showSuggestType) {
                if (i === 0) {
                    rv = [<MenuItem key={"h-" + i} header style={{marginLeft: 8}}>
                              {this.getSuggestHeader(suggest.type)}
                          </MenuItem>,
                          rv];
                }

                if (i > 0 && suggest.type !== items[i - 1].type) {
                    rv = [<MenuItem key={"d-" + i} divider />,
                          <MenuItem key={"h-" + i} header style={{marginLeft: 8}}>
                              {this.getSuggestHeader(suggest.type)}
                          </MenuItem>,
                          rv];
                }
            }

            return rv;
        });

        if (this.state.total > this.state.suggests.length) {
            suggests.push(
                <MenuItem key="footer" disabled>
                    <span className="footer">
                        &hellip;{this.state.total - this.state.suggests.length} more available
                    </span>
                </MenuItem>
            );
        }

        const inputProps = {
            ...objectWithoutProperties(this.props, this.ownProps),
            onKeyDown: this.handleKeyDown,
            onChange: this.handleChange,
            value: typeof this.props.query === 'string' ?
                this.props.query
                : this.state.value || '',
        };

        if (this.props.fetchOnFocus) {
            inputProps.onFocus = () => this.getData('');
        }

        const inputElement = this.props.expandable ?
            <ExpandableInput
                { ...inputProps }
                inputRef={this.setInput} />
            : this.props.input ?
                this.props.input({ ...inputProps, inputRef: this.setInput })
                : <input
                    type="search"
                    placeholder={this.props.placeholder}
                    { ...inputProps }
                    ref={this.setInput}
                    className={cx('search form-control suggest-input', this.props.inputProps && this.props.inputProps.className)}/>;

        const omniProps = this.props.omniProps;
        const buttonProps = this.props.buttonProps;

        return <Omnibox className="mixed-input">
            {omniProps &&
                <Omnibox.Omni icon={omniProps.icon} text={omniProps.text} />}
            <div className={cx('dropdown-react form-search suggest-box',
                               {'expandable-suggest': this.props.expandable}, this.props.divClasses)}>
                <div className="input-wrapper">
                    {inputElement}
                </div>
                {this.state.loading && <Spinner />}
                <Dropdown id={`suggest-box-dropdown-${Date.now()}`}
                          className="suggest-box-dropdown"
                          defaultOpen={false}
                          open={this.state.open}
                          onToggle={this.handleToggle}>
                    <Dropdown.Toggle style={{visibility: 'hidden', height: 0, padding: 0}} />
                    <Dropdown.Menu style={this.props.dropdownStyle?.()}>
                        {suggests}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            {buttonProps && <Omnibox.Submit>
                <Button {...buttonProps} onClick={this.handleButtonClick}>{buttonProps.text}</Button>
            </Omnibox.Submit>}
        </Omnibox>;
    }
}


type SuggestItemPropsType = {
    maxLabelLength: number,
    suggest: SuggestType,
    showLink: ?bool,
    onSelect: (suggest: SuggestType) => any,
    entered: string,
    focused: ?number,
    index: number,
};


export class SuggestItem extends React.PureComponent<SuggestItemPropsType> {
    static defaultProps = {
        showLink: true,
        focused: null,
    };

    handleSelect = () => {
        this.props.onSelect(this.props.suggest);
    };

    renderStrongedSuggest = (): Array<React.Node> => {
        var label = this.props.suggest.label;
        var maxLabelLength = this.props.maxLabelLength;

        if (label && label.length > maxLabelLength) {
            label = label.substring(0, maxLabelLength) + '...';
        }
        var entered = this.props.entered;

        var enteredWords = _escapeRegExp(entered).toLowerCase().split(" ");
        enteredWords = enteredWords.filter(word => word !== '');
        var regexGroups = enteredWords.map(word => "(" + word + ")");
        var regexPattern = regexGroups.join("|");
        var regex = new RegExp(regexPattern, 'i');
        var splitted = label.split(regex);
        return splitted.map(
            (k, i) => (k && enteredWords.indexOf(k.toLowerCase()) > -1 ?
                <strong key={i}>{k}</strong> : k)
        );
    };

    renderLink = () => {
        const suggest = this.props.suggest;

        if (!this.props.showLink || !this.props.suggest.url) {
            return null;
        }

        const style = {
            paddingRight: "10px",
            paddingLeft: "10px",
        };

        let title = "Go to";
        if (suggest.type in SUGGEST_TYPES) {
            title = SUGGEST_TYPES[suggest.type].urlTitle;
        }

        return <a href={suggest.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={title}
                  style={style}
                  className="suggest-menu-icon">
            <i className="icon icon-chevron-right">&nbsp;</i>
        </a>;
    };

    render() {
        const className = cx({
            'active': this.props.focused === this.props.index,
            'suggest-menu-item': true
        });

        return <MenuItem style={{display: 'flex'}}>
            {/* eslint-disable-next-line */}
            <a title={this.props.suggest.title || this.props.suggest.label}
               className={className}
               onClick={this.handleSelect}>
                {this.props.suggest.type !== 'ui' ?
                    this.renderStrongedSuggest()
                    : this.props.suggest.label
                }
            </a>
            {this.renderLink()}
        </MenuItem>;
    }
}


type MenuItemPropsType = {
    style: ?Object,
    header: ?bool,
    divider: ?bool,
    active: ?bool,
    disabled: ?bool,
    className: ?string,
    children?: any,
};


class MenuItem extends React.PureComponent<MenuItemPropsType> {
    static defaultProps = {
        style: null,
        header: false,
        divider: false,
        active: false,
        disabled: false,
        className: null,
    };

    render() {
        const className = {
            'dropdown-header': this.props.header,
            'divider': this.props.divider,
            'active': this.props.active,
            'disabled': this.props.disabled
        };

        return (
            <li role="presentation" style={this.props.style}
                className={cx(this.props.className, className)}>
                {this.props.children}
            </li>
        );
    }
}

export {SuggestBox};
