import React from 'react';

import Col from 'react-bootstrap/lib/Col';

import ArticleLink from '../articles/ArticleLink';
import ArticleMeta from '../articles/ArticleMeta';
import { ConceptList } from '../articles/Concept';
import Collapsible from '../widgets/Collapsible';


export const RecommendedArticle = React.memo(
    function RecommendedArticle({ popover, article, currentAuthorId }) {
        return <div className="row recommended-article">
            <Col xs={12} md={3}>
                <ArticleMeta article={article} currentAuthorId={currentAuthorId}/>
            </Col>
            <Col xs={12} md={9}>
                <h4><ArticleLink {...article} /></h4>
                <Collapsible>{article.abstract}</Collapsible>

                {article.concepts &&
                    <ConceptList
                        concepts={article.concepts}
                        popover={popover}
                        collapsible />}
            </Col>
        </div>;
    }
);
