export function enrichReferees(referees) {
    return referees.map(ref => ({
        ...ref,
        scoredArticlesCount: ref.scores.length,
        maxArticleScore: ref.scores.length ? Math.max.apply(null, ref.scores) : 0,
    }));
}

export function makeSettingsKey(docKind, docId) {
    const base = `rf-settings-${docKind}-${docId}`;
    return [base, base];
}

export function makeTopicsKey(docKind, docId) {
    const base = `rf-topics-${docKind}-${docId}`;
    return [base, base];
}

export function makeRefereesWithAdditionalDocKey(
    docKind, docId, additionalDocKind, additionalDocId, offset
) {
    let base = `referees-${docKind}-${docId}`;
    if (additionalDocKind && additionalDocId) {
        base = `${base}-${additionalDocKind}-${additionalDocId}`
    }
    let key = base;

    if (offset) {
        key = `${key}-offset${offset}`;
    }
    return [key, base];
}

export function makeRefereesKey(docKind, docId, offset) {
    return makeRefereesWithAdditionalDocKey(docKind, docId, null, null, offset)
}

export function makeCustomRefereesWithAdditionalDocKey(
    docKind, docId, additionalDocKind, additionalDocId, customAuthorIds
) {
    const [key, ] = makeRefereesWithAdditionalDocKey(
        docKind, docId, additionalDocKind, additionalDocId);
    return [`${key}-custom-${customAuthorIds.join('-')}`, `${key}-custom`];
}

export function makeCustomRefereesKey(docKind, docId, customAuthorIds) {
    return makeCustomRefereesWithAdditionalDocKey(
        docKind, docId, null, null, customAuthorIds);
}
