/**
 * Drag-n-drop entities often generate IDs in format '{prefix}-{real ID}'.
 * This is done to make them unique across the page, since concept may be
 * included in more than one topic. Usually we do care about real ID only -
 * and this is the purpose of this method.
 */
export function getEntityId(id: string) {
    const match = id.match(/-(\d+)/);
    return parseInt(match ? match[1] : id, 10);
}
