import React from 'react';
import * as Sentry from '@sentry/browser';

import dom from '@common/helpers/dom';
import { ConceptLink } from '../../articles/Concept';
import { checkStatus, combineRefs, cx, KEYS } from '../../utils';
import { splitIntoTokens as splitIntoTokensFn } from '../SearchInput';
import Overlay from '../../widgets/Overlay';
import Popover from '../../widgets/Popover';
import { SuggestBox } from '../../widgets/Suggest';
import { QRecommendedConceptList } from '../../recommendations/Concepts';

import s from './styles.module.css';


const isConcept = item => !!item?.label;
const shouldPolyfillInputEvent = () => !!window.msCrypto;
const stringifyQueryFn = query => query
    .map((item, i) => item.label ? `"${item.label}"` : item)
    .join('');

const ContentEditable = React.forwardRef(function ContentEditable({ className, ...props }, ref) {
    const inputRef = React.useRef();

    React.useImperativeHandle(ref, () => ({
        ...inputRef.current,
        get value() {
            return inputRef.current.innerText;
        },
        set value(value) {
            inputRef.current.innerHTML = value;
        },
        get node() {
            return inputRef.current;
        },
        focus() {
            inputRef.current.focus();
        }
    }));
    return <div key="input" ref={inputRef} contentEditable="true" {...props} className={cx(s.input, className)} />;
});

export const MixedInput = React.forwardRef(function MixedInput(
    {
        query,
        onChange,
        onSubmit,
        inputClassName = '',
        splitIntoTokens = splitIntoTokensFn,
        stringifyQuery = stringifyQueryFn,
        prepareSuggestQuery,
        validate,
        conceptPopoverContents,
        omniProps = { icon: 'file', text: 'Search articles' },
        buttonProps = {text: 'Search', bsStyle: 'primary'},
        placeholder = 'Search for articles by title or ids (DOI, PMC5502336, arXiv:1511.04078)',
        ...rest
    },
    ref
) {
    const inputRef = React.useRef();
    const overlayTarget = React.useRef();
    const cursorPosition = React.useRef(0);
    const [popover, setPopover] = React.useState(null);
    const [error, setError] = React.useState('');
    const [showPlaceholder, setShowPlaceholder] = React.useState(!query.join('').length);

    const getPosition = () => {
        const sel = document.getSelection();
        let { anchorOffset: position, anchorNode: el } = sel;

        try {
            el = getCurrentImmediateChild();
        } catch (e) {
            // we should not calculate caret position when focus
            // is not in current contenteditable, which is the case here
            throw new Error('Position not calculated because element is not in focus');
        }

        while ((el = el.previousSibling)) {
            position += el.textContent.length;
        }

        return position;
    };

    const setQuery = q => {
        const newQuery = q[q.length - 1] === '&nbsp;' ? q.slice(0, q.length - 1) : q;
        onChange && onChange(newQuery);
    };

    const getCurrentToken = (q, back = false) => {
        let position;

        // in safari, selection is reset on click. To work around this, we
        // cache the position on change event
        try {
            position = getPosition();
        } catch {
            position = cursorPosition.current;
        }

        const { tokens, indices } = splitIntoTokens(stringifyQuery(q), '');
        const indicesWithoutQuotes = indices.slice();
        let removedQuotes = 0;

        tokens.forEach((token, i) => {
            const newToken = token.replace(/"/g, '');
            removedQuotes += token.length - newToken.length;
            if (i + 1 < tokens.length) {
                indicesWithoutQuotes[i + 1] -= removedQuotes;
            }
        });

        let currentIndex;

        for (let i = 0; i < indicesWithoutQuotes.length; i++) {
            const pos = indicesWithoutQuotes[i];

            if (pos === position && back) {
                currentIndex = Math.max(i - 1, 0);
                break;
            }

            if (pos <= position && (indicesWithoutQuotes[i + 1] > position || i + 1 === indicesWithoutQuotes.length)) {
                currentIndex = i;
                break;
            }
        }

        return { currentIndex, tokens, indices, currentToken: tokens[currentIndex], indicesWithoutQuotes };
    }

    const replaceCurrentToken = (replacement) => {
        // we enforce quotes here to parse concepts with quotes properly;
        // later in getCurrentToken() we compencate these extra characters
        // when calculating the current token by substracting amount of quotes
        const { currentIndex } = getCurrentToken(query, true);

        return [ ...query.slice(0, currentIndex), ...replacement, ...query.slice(currentIndex + 1) ];
    };

    const suggestConcept = q => {
        const { currentToken } = getCurrentToken(query, true);
        const suggestQuery = prepareSuggestQuery ? prepareSuggestQuery(currentToken) : currentToken;

        if (suggestQuery) {
            return fetch(`/api/autocomplete/concepts?query=${suggestQuery}`, {
                credentials: 'same-origin',
            })
                .then(checkStatus)
                .then(response => response.json());
        } else {
            return Promise.resolve({
                results: [],
                success: true,
                total: 0
            });
        }
    };

    const placeCursorAt = (pos, posInsideNode = 0, shouldCollapse = false) => {
        const range = document.createRange();
        let offsetElement = inputRef.current.node.childNodes.length ?
            inputRef.current.node.childNodes[pos]
            : inputRef.current.node;

        if (offsetElement.childNodes?.length) {
            offsetElement = offsetElement.childNodes[0];
        }

        range.setStart(offsetElement, posInsideNode);
        range.setEnd(offsetElement, posInsideNode);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        if (shouldCollapse) {
            selection.collapseToEnd();
        }

        cursorPosition.current = getPosition();
    }

    const queryPositionToHTMLPosition = (q, newIndex) =>
        newIndex + q.slice(0, newIndex + 1).reduce(
            (acc, current, i, items) => isConcept(current) && (i === 0 || isConcept(items[i - 1])) ?
                acc + 1
                : acc,
            0
        );

    const handleSuggestSelect = item => {
        if (item.isNotSuggest) {
            handleSubmit(query);
            return;
        }

        const newQuery = replaceCurrentToken([item, '&nbsp;']);
        const newIndex = newQuery.indexOf(item);
        // +1 for "after the current token"
        const newPosition = queryPositionToHTMLPosition(newQuery, newIndex + 1);

        setQuery(newQuery);
        inputRef.current.value = queryToHTML(newQuery);

        placeCursorAt(newPosition, 1, true);
    };

    const queryToHTML = query => {
        const q = query.slice();

        if (q[0]?.label) {
            q.unshift('');
        }

        if (q[q.length - 1]?.label) {
            q.push('');
        }

        return q.map((item, i, items) => typeof item === 'string' ?
            `<span>${item}</span>`
            : `<div contenteditable="false" class="concept badge" data-id="${item.id}">${item.label}</div>${q[i + 1]?.label ? '<span></span>' : ''}`
        ).join('');
    }

    const parseHTML = html => {
        const template = document.createElement('template');
        template.innerHTML = html.trim();
        return [...template.content.childNodes].reduce(
            (acc, el, i, items) => {
                let text = el.nodeValue;

                if (el.nodeType === Node.TEXT_NODE || el.nodeType === Node.ELEMENT_NODE) {
                    if (el.nodeType === Node.ELEMENT_NODE && dom.matches(el, '.concept')) {
                        return [ ...acc, { id: el.dataset.id, label: el.innerText }];
                    } else {
                        text = el.textContent;

                        if (!text.match(/\s$/) && items[i + 1] && items[i + 1].textContent.match(/^\s/)) {
                            // move leading space from next token to current one
                            text += ' ';
                        }
                    }
                }

                const tokens = splitIntoTokens(text, '').tokens;
                return [...acc, ...(tokens.length ? tokens : text ? [text] : [])];
            },
            []
        );
    };

    const handleSubmit = (q = query) => {
        const data = q.map(part => typeof part === 'string' ? part.replace(/&nbsp;/g, ' ') : part);

        if (validate) {
            const err = validate(data);
            setError(err);

            if (err) {
                return;
            }
        }

        onSubmit(data);
    }

    const createSelection = (nodeStart, posStart, nodeEnd, posEnd) => {
        const selection = window.getSelection();
        const range = document.createRange();
        range.setStart(nodeStart, posStart);
        range.setEnd(nodeEnd, posEnd);

        selection.removeAllRanges();
        selection.addRange(range);
    }

    const getCurrentImmediateChild = () => {
        const { anchorNode, anchorOffset } = window.getSelection();
        let el = anchorNode;

        if (el !== inputRef.current.node) {
            while (el.parentNode !== inputRef.current.node) {
                el = el.parentNode;
            }
        } else if (el.childElementCount) {
            // IE places cursor after empty span, so anchorOffset at the right most position
            // could be childrenCount + 1
            el = el.children[anchorOffset >= el.childElementCount ? anchorOffset - 1 : anchorOffset];
        }

        return el;
    }

    /**
      This function exists because of buggy FF and IE contenteditable implementation
      @FIXME remove once IE11 is dead and https://bugzilla.mozilla.org/show_bug.cgi?id=1665167 is fixed
     */
    const maybeSelectConcept = e => {
        const { isCollapsed, anchorOffset }  = window.getSelection();
        const isBackspace = e.which === KEYS.BACKSPACE;
        const isCtrlD = e.which === 68 && e.ctrlKey;

        if (isCollapsed && (isBackspace || isCtrlD)) {
            const anchor = getCurrentImmediateChild();

            if (
                (isBackspace && anchorOffset === 0 && dom.matches(anchor.previousSibling, '.concept')) ||
                (isCtrlD && anchorOffset === anchor.textContent.length && dom.matches(anchor.nextSibling, '.concept'))
            ) {
                try {
                    const start = Array.prototype.indexOf.call(anchor.parentNode.children, isBackspace ? anchor.previousSibling : anchor.nextSibling);
                    createSelection(inputRef.current.node, start, inputRef.current.node, start + 1);
                } catch (e) {
                    console.error(e.message);
                    Sentry.captureException(e, {
                        query: JSON.stringify(query),
                        textContent: inputRef.current?.node.textContent,
                    });
                }
            }
        }
    }

    const processShortcuts = e => {
        if (navigator.platform.indexOf('Mac') === 0 && e.ctrlKey) {
            // ctrl + k
            if (e.which === 75) {
                e.preventDefault();
                const selection = window.getSelection();
                const { startContainer, startOffset } = selection.getRangeAt(0);
                createSelection(startContainer, startOffset, inputRef.current.node, inputRef.current.node.children.length);
                // @TODO: execCommand is obsolete, but still works.
                // No native alternatives so far, so we stick to it for the time being
                document.execCommand('delete');
            // ctrl + a
            } else if (e.which === 65) {
                placeCursorAt(0, 0, true);
            // ctrl + e
            } else if (e.which === 69) {
                const lastIndex = inputRef.current?.node.childElementCount - 1;
                placeCursorAt(lastIndex, Math.max(0, inputRef.current?.node.children[lastIndex].textContent.length - 1), true);
            }
        }
    }

    const openPopover = React.useCallback(e => {
        let p = e.target;
        e.preventDefault();
        e.stopImmediatePropagation();

        do {
            if (dom.matches(p, 'div.concept')) {
                overlayTarget.current = p;
                setPopover({ conceptId: p.dataset.id, name: p.innerText });
                return;
            }
        // eslint-disable-next-line no-cond-assign
        } while (p = p.parentElement);

        setPopover(null);
    }, []);

    const handleSuggestionsClick = concept => {
        const item = {
            id: concept.conceptId,
            label: concept.name,
        };

        const { currentIndex } = getCurrentToken(query, true);
        const newQuery = [...query.slice(0, currentIndex + 1), 'or ', item, ...query.slice(currentIndex + 1)];

        setQuery(newQuery);
        inputRef.current.value = queryToHTML(newQuery);
        // leading whitespace + two inserted nodes + one after
        placeCursorAt(queryPositionToHTMLPosition(newQuery, currentIndex) + 2 + 1);
    };

    const isCursorInConcept = () => {
        try {
            const anchor = window.getSelection().anchorNode;

            return dom.hasParent(anchor, '.badge');
        } catch (e) {
            // focus not in input
            return false;
        }
    }

    const handleKeyDown = e => {
        processShortcuts(e);
        maybeSelectConcept(e); // @FIXME hello IE and FF

        // inside the badge, restrict all but navigation and edit
        if ((isCursorInConcept() && !e.metaKey && !e.ctrlKey && e.key.length === 1)) {
            e.preventDefault();
        }

        // the only place we want to suppress submit is adding suggests with a keyboard
        if (e.which === KEYS.ENTER && !dom.matches(inputRef.current.node.parentNode.nextElementSibling, '.open')) {
            setTimeout(handleSubmit, 100);
        }
    }

    const getDropdownStyle = () => {
        const node = window.getSelection().anchorNode;

        if (node && dom.hasParent(node, inputRef.current?.node)) {
            return {
                left: node.nodeType === Node.TEXT_NODE ?
                    (node.parentNode !== inputRef.current.node ?
                        node.parentNode.offsetLeft
                        : (node.previousSibling || node.nextSibling || {}).offsetLeft || 0) // hello IE11
                    : node.offsetLeft
            };
        }

        return {};
    }

    const getUsedConcepts = React.useCallback(() => query.reduce((acc, item) => item.label ? [...acc, { conceptId: item.id }] : acc, []), [query]);

    const suggestbox = <SuggestBox
        suggestFetch={suggestConcept}
        callback={handleSuggestSelect}
        dropdownStyle={getDropdownStyle}
        buttonProps={buttonProps}
        omniProps={omniProps}
        input={(inputProps) => {
            const { value, onChange: onInputChange, onKeyDown, inputRef: inputRefForSuggestBox, ...restInputProps } = inputProps;
            const handleChange = (e) => {
                if ([KEYS.LEFT, KEYS.RIGHT, KEYS.UP, KEYS.DOWN].indexOf(e.which) !== -1) return; // hello IE

                Array.prototype.forEach.call(e.currentTarget.children, el => {
                    const emptySpan = document.createElement('span');
                    // restrict removing empty nodes after concepts
                    if (dom.matches(el, '.concept')) {
                        if (el.nextSibling && dom.matches(el.nextSibling, '.concept')) {
                            e.currentTarget.insertBefore(emptySpan, el.nextSibling);
                        } else if (dom.matches(el, ':first-child')) {
                            e.currentTarget.insertBefore(emptySpan, el);
                        } else if (dom.matches(el, ':last-child')) {
                            e.currentTarget.appendChild(emptySpan);
                        }
                    }
                });

                const newQuery = parseHTML(e.currentTarget.innerHTML);

                try {
                    cursorPosition.current = getPosition();
                } catch (e) { /* ignore losing focus */ }

                if (popover) {
                    setPopover(null);
                }

                setShowPlaceholder(!e.currentTarget.textContent.length);
                setQuery(newQuery);
                onInputChange(e);
            };

            const handlePaste = (e) => {
                const paste = (e.clipboardData || window.clipboardData).getData('text').replace(/\n/g, '');
                const selection = window.getSelection();

                if (!selection.rangeCount) return false;

                selection.deleteFromDocument();
                selection.getRangeAt(0).insertNode(document.createTextNode(paste));
                selection.collapseToEnd();

                e.preventDefault();
                // manually call change handler, because preventDefault() prevents change event triggering
                handleChange(e);
            };

            const handleInputKeyDown = e => {
                handleKeyDown(e);
                onKeyDown(e);
            };

            return <ContentEditable
                {...rest}
                {...restInputProps}
                {...({ [shouldPolyfillInputEvent() ? 'onKeyUp' : 'onInput']: handleChange })}
                className={inputClassName}
                onSubmit={handleSubmit}
                onPaste={handlePaste}
                ref={combineRefs([ref, inputRef, inputRefForSuggestBox])}
                onKeyDown={handleInputKeyDown}
                spellCheck="false"
            />;
        }}
        {...(placeholder && showPlaceholder && { 'data-placeholder': placeholder })}
    />;

    React.useEffect(() => {
        // replacing div content causes losing caret position
        if (document.activeElement !== inputRef.current.node && !dom.hasParent(document.activeElement, inputRef.current.node)) {
            inputRef.current.node.innerHTML = queryToHTML(query);
        }
    }, [query]);

    /* eslint-disable react-hooks/exhaustive-deps */
    React.useEffect(() => {
        const input = inputRef.current.node;
        input && input.addEventListener('click', openPopover);

        return () => input && input.removeEventListener('click', openPopover);
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */

    return <div className={cx(s.root, { 'search-omnibox': omniProps, 'has-error': !!error })}>
        {suggestbox}
        {error && <div className="help-block">
            {error}
        </div>}
        <Overlay show={!!popover}
                 onHide={() => setPopover(null)}
                 placement="bottom"
                 key="popover"
                 animation={false}
                 rootClose
                 target={() => overlayTarget.current}>
            {popover && <Popover className="concept-popover"
                     id="search-concept-popover"
                     title={<><span>{popover.name}</span><ConceptLink concept={popover}/></>}
            >
                {conceptPopoverContents ?
                    conceptPopoverContents(popover)
                    : <QRecommendedConceptList
                        conceptId={popover.conceptId}
                        isDragDisabled
                        draggable={false}
                        except={getUsedConcepts()}
                        handleClickSuggestions={handleSuggestionsClick}
                    />
                }
            </Popover>}
        </Overlay>
    </div>;
});
