import React, { Fragment } from 'react';

import Button from 'react-bootstrap/lib/Button';

import type { AuthorToClaimType } from './TypesClaim';
import AffiliationItem from '../authors/AffiliationItem';
import AuthorLink from '../authors/AuthorLink';
import { singlePlural } from '../utils';

import './styles.css';


type AuthorToClaimProps = {
    author: AuthorToClaimType,
    claimed?: boolean,
    hideEmails?: boolean,
    hideLink?: boolean,
    onClaim?: (authorId: number) => any,
    resultAuthorId?: ?number,
    attorneyToken?: ?string,
    loading: boolean,
}


export default function AuthorToClaim({
    author, claimed, hideEmails, hideLink, onClaim, resultAuthorId,
    attorneyToken, loading
}: AuthorToClaimProps) {
    function renderAuthorLink() {
        if (hideLink) {
            return
        }

        // If author was claimed - he might be merged into another author and changed his id.
        // Change id in link to not redirect to deleted author.

        const authorForLink = {
            ...author,
            id: claimed ? resultAuthorId : author.id,
            attorneyToken: claimed ? attorneyToken : null,
        };

        return <AuthorLink { ...authorForLink } target="_blank">see details</AuthorLink>
    }

    return <div className="claim-author-group">
        {claimed ? <Button className="btn-xs claim-btn claimed" disabled>Claimed</Button>
         : <Button className="btn-xs claim-btn"
                   onClick={() => onClaim(author)}
                   disabled={loading}>
                Claim
         </Button>}
        <label>
            <strong>
                {!hideEmails && author.matchedEmails.length > 0 && <Fragment>
                    {author.matchedEmails.join(', ')} &#8212;{' '}
                </Fragment>}
                {author.name} ({author.articlesCount}{' '}
                {singlePlural('article', author.articlesCount)}){' '}
                {renderAuthorLink()}
            </strong>
        </label>
        <ul className="affiliations">
            {author.datedAffiliations.map((aff) => <li key={aff.value + aff.count}>
                <AffiliationItem item={aff} />
            </li>)}
        </ul>
    </div>;
}
