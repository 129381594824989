import React from 'react';

import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';

import { useCurrentUser } from './hooks';
import AuthorLink from '../authors/AuthorLink';

import './styles.css';


function CurrentUserDropdown() {
    const [currentUser] = useCurrentUser();
    const { authorId, email } = currentUser;

    const orgadminOrganizationsIds = currentUser.orgadminOrganizationsIds;
    const orgdevOrganizationsIds = currentUser.orgdevOrganizationsIds;
    let orgadminLink;
    if (orgadminOrganizationsIds.length === 1) {
        const orgId = orgadminOrganizationsIds[0];
        orgadminLink = <a href={`/orgadmin/${orgId}/`}>Organization</a>
    } else if (orgadminOrganizationsIds.length > 1) {
        orgadminLink = <a href="/orgadmin/organizations/">Organizations</a>
    }
    let orgdevLink;
    if (orgdevOrganizationsIds.length === 1) {
        orgdevLink = <a href={`/orgdev/integrations/${orgdevOrganizationsIds[0]}/`}>Integrations</a>
    } else if (orgdevOrganizationsIds.length > 1) {
        orgdevLink = <a href={`/orgdev/integration-partners/`}>Integrations</a>
    }
    return <DropdownButton title={email} id="user-menu">
        <MenuItem header>{email}</MenuItem>
        <li role="presentation"><a href="/profile/">Profile</a></li>
        <li role="presentation">
            {authorId ? <AuthorLink id={authorId} name='Linked author' />
             : <a href="/find-authors">Linked author</a>}
        </li>
        {!!currentUser.canManageCollections &&
            <li role="presentation"><a href="/collections/manage/">Manage labels</a></li>}
        {(!!orgadminLink || !!orgdevLink) && <MenuItem divider></MenuItem>}
        {!!currentUser.showChangelogLink &&
            <li role="presentation"><a href="https://p.prophy.ai/whats-new-in-prophy-erc/">Changelog</a></li>}
        {!!orgadminLink && <li role="presentation">{orgadminLink}</li>}
        {!!orgdevLink && <li role="presentation">{orgdevLink}</li>}
        {(!!currentUser.activeQuotas.ontology_moderator || currentUser.isSuperuser) && <MenuItem divider></MenuItem>}
        {!!currentUser.activeQuotas.ontology_moderator &&
            <li role="presentation"><a href="/ontology/concepts/">Ontology</a></li>}
        {currentUser.isSuperuser && <li role="presentation"><a href="/superadmin/">Superadmin</a></li>}
        <MenuItem divider></MenuItem>
        <li role="presentation"><a href="/accounts/logout/">Log out</a></li>
    </DropdownButton>;
}


export default function CurrentUser({ loginLinkMode = false, registerLinkMode = false, children }) {
    const [currentUser] = useCurrentUser();
    const { isAuthenticated } = currentUser;

    if (isAuthenticated === null) {
        // fetching currentUser from backend...
        return null;
    }

    if (loginLinkMode) {
        return <span className="current-user-link">
            <a href="/accounts/login/">login</a>
        </span>;
    } else if (registerLinkMode) {
        return children ?
            <div className="current-user-link">
                {children}
            </div>
            : <span className="current-user-link">
                <a href="/accounts/register/">register</a>
            </span>;
    }

    return <div className="current-user">
        {isAuthenticated ?
            <CurrentUserDropdown />
            : <div className="btn-toolbar">
                <a href="/accounts/login/" className="btn btn-primary">Login</a>
                <a href="/accounts/register/" className="btn btn-default">Register</a>
            </div>
        }
    </div>;
}
