import React from 'react';

import Rectangle from 'recharts/es6/shape/Rectangle';


const ChartCursor = ({ onClick, pointerEvents, ...props }) => {
    const handleClick = React.useCallback(e => {
        onClick(props.payload[0].payload, e.shiftKey);
    }, [onClick, props]);

    return <Rectangle { ...props } pointerEvents="all" onClick={handleClick} />;
};

export default ChartCursor;
