import React from 'react';
import queryString from 'query-string';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import FormGroup from 'react-bootstrap/lib/FormGroup';
import Pagination from 'react-bootstrap/lib/Pagination';

import { useTrack } from '../../datastore';
import { makeOntologyModerationLogQuery } from '../OntologyData';
import LogRow from './LogRow';

import { KEYS } from '../../utils';
import SortableTable from '../../widgets/SortableTable';

import './ontology-moderation-log.css';


const LOGS_PER_PAGE = 100;

const COLUMNS = [
    {
        field: 'id',
        key: true,
    },
    {
        field: 'dateCreated',
        text: 'Date',
        className: 'date',
    },
    {
        field: 'name',
        text: 'Name',
        className: 'name',
    },
    {
        field: 'literals',
        text: 'Literals'
    },
    {
        field: 'basic',
        text: 'Basic',
        className: 'basic',
    },
    {
        field: 'createdByEmail',
        text: 'User',
        className: 'email',
    },
];

export default function OntologyModerationLog() {
    const history = useHistory();
    const { path } = useRouteMatch();
    const location = useLocation();
    const parsedQS = queryString.parse(location.search);
    const [email, setEmail] = React.useState(parsedQS.email);
    const offset = (parsedQS.offset && parseInt(parsedQS.offset, 10)) || 0;
    const getUrl = (options) => {
        const qs = Object.keys(options).map(key => (
            options[key] ? `${key}=${options[key]}` : ''
        )).filter(part => part);

        return `${path}${qs.length ? `?${qs.join('&')}` : ''}`;
    }

    const setEmailInState = e => {
        setEmail(e.currentTarget.value);
    };

    const updateUrl = React.useCallback(e => {
        if (e.which === KEYS.ENTER) {
            history.push(getUrl({ ...parsedQS, email, offset: 0 }))
        }
    // disables the next line because parsedQS, email are derived from location.search
    }, [history, getUrl, location.search]); // eslint-disable-line react-hooks/exhaustive-deps

    const [result, reqstate] = useTrack(makeOntologyModerationLogQuery(parsedQS));
    const goto = offset => history.push(getUrl({ offset, email }));

    const pagination = <div className="table-pagination pull-right">
        {result && <span>
            Page {Math.ceil(offset / LOGS_PER_PAGE) + 1} / {Math.ceil(result.total / LOGS_PER_PAGE)}
        </span>}
        <Pagination>
            <Pagination.First onClick={() => goto()} />
            <Pagination.Prev onClick={() => goto(offset - LOGS_PER_PAGE)} disabled={offset - LOGS_PER_PAGE < 0} />
            <Pagination.Next onClick={() => goto(offset + LOGS_PER_PAGE)} disabled={result && (offset + LOGS_PER_PAGE >= result.total)} />
        </Pagination>
    </div>;

    return <div className="ontology-moderation-log">
        <h2 className="hero-heading">
            Moderation: ontology
        </h2>
        <div className="filters-and-pagination">
            <div className="form-inline pull-left">
                <label className="control-label">Filter by:&nbsp;</label>
                <FormGroup>
                    <input
                        type="text"
                        className="form-control"
                        onChange={setEmailInState}
                        value={email}
                        onKeyDown={updateUrl}
                        placeholder="Email or organization" />
                </FormGroup>
            </div>
            {pagination}
        </div>
        <SortableTable
            className="table-sticky-head"
            data={(result && result.logs) || []}
            columns={COLUMNS}
            renderRow={LogRow}
            renderNoResults={() => <tr>
                <td colSpan={COLUMNS.length} className="text-center">
                    {reqstate.ok === null || reqstate.loading ?
                        'Loading...'
                        : 'No results'}
                </td>
            </tr>}
        />
        {result && pagination}
    </div>;
}
