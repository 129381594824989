import React from 'react';

import HideReferee from './HideReferee';
import AddSelectedAsPreliminaryButton from './AddSelectedAsPreliminaryButton';
import MarkSelectedAsReferee from './MarkSelectedAsReferee';
import helpers from './helpers';
import RefereesExport from './RefereesExport';
import { useCurrentUser } from '../accounts/hooks';
import { cx } from '../utils';


export default function MassActions(
    {
        disabled,
        refereeType,
        hideReferees,
        vacancyId,
        doc,
        docKind,
        docId,
        customAuthorIds,
        markedAuthorsIds,
        exportOnlyCustom,
        canMarkAsReferees,
        canHideCandidates,
        clearSelected,
        showOnlyMarked,
        setShowOnlyMarked,
    }: {
        disabled: boolean,
        refereeType: string,
        hideReferees: (reason: string, comment: string) => any,
        vacancyId: ?number,
        doc: any,
        docKind: string,
        docId: number,
        customAuthorIds: Array<number>,
        markedAuthorsIds: Array<number>,
        exportOnlyCustom: boolean,
        canMarkAsReferees: boolean,
        canHideCandidates: boolean,
        authorsGroupId: ?number,
        authorsGroupEffect: ?string,
        clearSelected: () => any,
        showOnlyMarked: boolean,
        setShowOnlyMarked: (showOnlyMarked: boolean) => any,
    }
) {
    const [currentUser] = useCurrentUser();
    const hasExportQuota = currentUser.activeQuotas.referees_export;

    return <div className="mass-actions">
        {(docKind !== 'proposal' || canHideCandidates) &&
            <HideReferee
                id="hide-referees-btn"
                disabled={disabled}
                options={helpers.HIDE_REASONS}
                refereeType={refereeType}
                onHideReferee={hideReferees} />}
        {docKind === 'proposal' && canMarkAsReferees &&
            <MarkSelectedAsReferee proposalId={docId} markedAuthorsIds={markedAuthorsIds} clearSelected={clearSelected} />}
        {!!vacancyId && <AddSelectedAsPreliminaryButton disabled={disabled} vacancyId={vacancyId} />}
        {hasExportQuota &&
            <RefereesExport
                doc={doc}
                docKind={docKind}
                docId={docId}
                customAuthorIds={customAuthorIds}
                hasMarkedReferes={markedAuthorsIds.length > 0}
                onlyCustom={exportOnlyCustom}
        />}
        {docKind === 'proposal' && canMarkAsReferees &&
            <button className={cx("btn btn-default", {active: showOnlyMarked})}
                    onClick={() => setShowOnlyMarked(!showOnlyMarked)}
                    disabled={!markedAuthorsIds.length && !showOnlyMarked}>
                Show only marked</button>}
    </div>
}
