import React from 'react';

import Col from 'react-bootstrap/lib/Col';
import Button from 'react-bootstrap/lib/Button';

import { useSubscription } from '../datastore';
import ArticleLink from '../articles/ArticleLink';
import ArticleMeta from '../articles/ArticleMeta';
import { makeArticleKey } from '../articles/ArticleData';
import { LoadMoreArticlesButton } from '../authors/AuthorArticles';
import { ConceptList } from '../articles/Concept';
import { cx } from '../utils';
import Collapsible from '../widgets/Collapsible';


const ArticleInApplicant = React.memo(function ArticleInApplicant(
    { articleId, authorId }
    : { articleId: number, authorId: number }
) {
    const article = useSubscription(makeArticleKey(articleId));

    return <div className="row recommended-article">
        <Col xs={12} md={3}>
            <ArticleMeta article={article} currentAuthorId={authorId}/>
        </Col>
        <Col xs={12} md={9}>
            <h4><ArticleLink {...article} /></h4>
            <Collapsible className="abstract">{article.abstract}</Collapsible>

            {article.concepts && <ConceptList concepts={article.concepts}
                                              collapseThreshold={6}
                                              collapsedCount={4}
                                              collapsible />
            }
        </Col>
    </div>;
});


export default function ApplicantArticles(
    { authorId, articleIds, hasMoreArticles, queryString }
    : { authorId: number, articleIds: Array<number>, hasMoreArticles: boolean, queryString: string }
) {
    const [showAbstract, setShowAbstract] = React.useState(false);

    return <>
        <div className="toggle-abstracts">
            <Button className="btn-sm" onClick={() => setShowAbstract(!showAbstract)} active={showAbstract}>
                Show abstracts
            </Button>
        </div>
        <div className={cx('row applicant-articles', { 'hidden-abstracts': !showAbstract })}>
            {articleIds.map(articleId =>
                <ArticleInApplicant
                    key={articleId}
                    articleId={articleId}
                    authorId={authorId} />)}
            {hasMoreArticles && <LoadMoreArticlesButton authorId={authorId}
                                                        queryString={queryString}
                                                        offset={articleIds.length} />
            }
        </div>
    </>;
}
