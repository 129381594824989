import React from 'react';

import OntologyModerationLog from './moderation/OntologyModerationLog';
import OntologyNav from './OntologyNav';


export default function OntologyModerationPage() {
    return <div>
        <OntologyNav />
        <OntologyModerationLog />
    </div>;
}
