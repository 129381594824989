import React from 'react';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Panel from 'react-bootstrap/lib/Panel';

import VacancyTag from './tags/VacancyTag';
import JobAdvisorRow from './JobAdvisorRow';
import JobApplicantRow from './JobApplicantRow';
import JobApplicantsSettings, { DISPLAY_STATUS } from './JobApplicantsSettings';
import { singlePlural, cx } from '../utils';
import SortableTable from '../widgets/SortableTable';
import helpers from './helpers';
import BodyClass from '../common/BodyClass';

import './css/job-applicants-table.css';


const noop = () => {};

function filterOption(voteKey, label) {
    if (helpers.voteLabels[voteKey]) {
        const vote = helpers.voteLabels[voteKey];
        const glyph = <span className={`glyphicon glyphicon-${vote.glyph}`} />;
        label = label || vote.label;
        label = <>{glyph} {label}</>;
    }
    return {
        value: voteKey,
        label,
    };
}


const ADVISORS_COLUMNS = [
    {
        field: 'id',
        text: '#',
        className: 'id',
        key: true
    },
    {
        field: 'name',
        text: 'Name',
        className: 'name',
    },
    {
        field: 'applicants',
        text: 'Applicants',
        className: 'applicants',
    },
    {
        field: 'rank',
        text: 'Relevance',
        help: 'Relevance represents candidate\'s and their recommender\'s score and h-index relative to other candidates',
        className: 'rank',
        sortable: true
    },
    {
        field: 'similarityCombined',
        text: 'Score',
        help: 'Cumulative degree of semantic similarity between vacancy and all recommender\'s articles',
        className: 'similarity-combined',
        sortable: true
    },
    {
        field: 'similarityMax',
        text: 'Art. score',
        className: 'similarity-max',
        help: 'Degree of semantic similarity between vacancy and the best recommender article',
        sortable: true
    },
    {
        field: 'articlesCount',
        text: 'Articles',
        className: 'articles-count',
        sortable: true
    },
    {
        field: 'notableArticlesCount',
        text: 'Notables',
        className: 'notable-articles-count',
        sortable: true
    },
    {
        field: 'hIndex',
        text: 'h-index',
        className: 'h-index',
        sortable: true
    },
    {
        field: 'citationsCount',
        text: 'Citations',
        className: 'citations',
        sortable: true
    },
];

export default function LoadedJobApplicantsList({
    jobId, applicants, availableTags, advisors, showPreliminaries, currentTab,
    selectedApplicantsIds, setSelectedApplicantsIds, location
}) {
    const APPLICANTS_COLUMNS = React.useMemo(() => ([
        {
            text: '',
            selectable: true
        },
        {
            field: 'applicationId',
            key: true,
        },
        {
            field: 'id',
            text: '#',
            className: 'id',
        },
        {
            field: 'name',
            text: 'Applicant',
            className: 'name',
            filter: {
                type: 'select[]',
                options: availableTags.map(({ id, name }) => filterOption(id, name)),
                fn: (filterValues, item) => {
                    return filterValues.every(id => item.tagsIds.indexOf(id) !== -1);
                },
                renderSelectedOptions: ({ options, onRemove }) => <div>
                    {options?.map(option =>
                        <VacancyTag
                            key={option}
                            onRemove={() => onRemove(option)}
                            tag={availableTags.find(tag => tag.id === option)}
                        />
                    )}
                </div>,
            },
        },
        {
            field: 'advisors',
            text: 'References',
            className: 'reference'
        },
        {
            field: 'rank',
            text: 'Relevance',
            help: 'Relevance represents candidate\'s and their recommender\'s score and h-index relative to other candidates',
            className: 'rank',
            sortable: true
        },
        {
            field: 'similarityCombined',
            text: 'Score',
            help: 'Cumulative degree of semantic similarity between the vacancy and all applicant\'s articles',
            className: 'similarity-combined',
            sortable: true
        },
        {
            field: 'similarityMax',
            text: 'Art. score',
            className: 'similarity-max',
            help: 'Degree of semantic similarity between the vacancy and the best applicant\'s article',
            sortable: true
        },
        {
            field: 'articlesCount',
            text: 'Articles',
            className: 'articles-count',
            sortable: true
        },
        {
            field: 'academicAge',
            text: 'Ac. age',
            className: 'academic-age',
            help: 'Academic age: number of years since the first publication',
            sortable: true
        },
        {
            field: 'notableArticlesCount',
            text: 'Notables',
            className: 'notable-articles-count',
            sortable: true
        },
        {
            field: 'hIndex',
            text: 'h-index',
            className: 'h-index',
            sortable: true
        },
        {
            field: 'citationsCount',
            text: 'Citations',
            className: 'citations',
            sortable: true
        },
        {
            field: 'votes',
            text: 'Votes',
            sortable: true,
            sortFn: (applicant1, applicant2) => {
                const boosts = [applicant1, applicant2].map(helpers.summarizeVotes);

                if (boosts[0] > boosts[1]) return -1;
                if (boosts[0] < boosts[1]) return 1;
                return 0;
            },
            filter: {
                type: 'select',
                options: [
                    filterOption('short_list'),
                    filterOption('long_list'),
                    filterOption('seen'),
                    filterOption('reject', 'Rejected'),
                    filterOption('not_seen', 'Not seen'),
                ],
                fn: (filterValue, item) => {
                    return filterValue === 'not_seen' ?
                        item.votes.every(v => !v.ownVote)
                        : item.votes.some(v => v.ownVote && v.voteType === filterValue)
                }
            }
        },
    ]), [availableTags]);

    const [showSettings, setShowSettings] = React.useState(DISPLAY_STATUS.CLOSED);
    const displaySettings = () => setShowSettings(DISPLAY_STATUS.OPEN);
    const hideSettings = () => setShowSettings(DISPLAY_STATUS.CLOSED);
    const toggleSettings = () => setShowSettings(
        showSettings === DISPLAY_STATUS.OPEN ? DISPLAY_STATUS.REQUEST_CLOSED : DISPLAY_STATUS.OPEN
    );

    const isRecommendersTab = currentTab === 'recommenders';

    const renderNoResults = React.useCallback(() => {
        const colspan = isRecommendersTab ?
            ADVISORS_COLUMNS.length
            : APPLICANTS_COLUMNS.length;
        return <tr><td colSpan={colspan} className="text-center">No results.</td></tr>;
    }, [isRecommendersTab, APPLICANTS_COLUMNS.length]);

    const settingsTitle = `${showSettings ? 'Hide' : 'Edit'} topics`;
    const preliminaries = React.useMemo(
        () => (isRecommendersTab ? advisors : applicants).filter(helpers.isPreliminary),
        [advisors, applicants, isRecommendersTab]
    );
    const reals = React.useMemo(
        () => (isRecommendersTab ? advisors : applicants).filter(a => !helpers.isPreliminary(a)),
        [advisors, applicants, isRecommendersTab]
    );

    const props = isRecommendersTab ? {
        columns: ADVISORS_COLUMNS,
        list: advisors,
        entity: 'recommender',
    } : {
        columns: APPLICANTS_COLUMNS,
        list: applicants,
        entity: 'applicant',
    };

    if (!showPreliminaries) {
        props.list = reals;
    }

    const selectApplicant = React.useCallback(
        applicationId => {
            setSelectedApplicantsIds(
                prevSelectedApplicantsIds => [...prevSelectedApplicantsIds, applicationId]);
        },
        [setSelectedApplicantsIds],
    );

    const unselectApplicant = React.useCallback(
        applicationId => {
            setSelectedApplicantsIds(
                prevSelectedApplicantsIds => prevSelectedApplicantsIds.filter(item => item !== applicationId));
        },
        [setSelectedApplicantsIds],
    );

    const isApplicantSelected = React.useCallback(
        applicant => selectedApplicantsIds.indexOf(applicant.applicationId) !== -1,
        [selectedApplicantsIds],
    );
    const toggleApplicantSelection = React.useCallback(
        applicationId => {
            if (selectedApplicantsIds.indexOf(applicationId) === -1) {
                selectApplicant(applicationId);
            } else {
                unselectApplicant(applicationId);
            }
        },
        [selectedApplicantsIds, selectApplicant, unselectApplicant],
    );
    const onMassApplicantsSelect = (applicant, nextChecked: boolean) => {
        if (nextChecked) {
            selectApplicant(applicant.applicationId);
        } else {
            unselectApplicant(applicant.applicationId);
        }
    };

    const renderRow = React.useCallback(
        (row, i) => isRecommendersTab ?
            <JobAdvisorRow key={row.groupId} advisor={row} jobId={jobId} index={i} />
            : <JobApplicantRow key={row.applicationId}
                               applicant={row}
                               index={i}
                               onSelectionChange={toggleApplicantSelection}
                               isSelected={isApplicantSelected(row)}/>,
        [jobId, isRecommendersTab, isApplicantSelected, toggleApplicantSelection],
    );

    React.useEffect(() => {
        if (location.hash) {
            const el = document.getElementById(location.hash.slice(1));
            el && el.scrollIntoView();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab]);

    const panelClass = cx('panel-table-container job-applicants', {
        'show-settings': !isRecommendersTab && showSettings,
    })

    return <div>
        <BodyClass className="job-applicants-table-page"/>
        <Panel className={panelClass}
               expanded={true}
               onToggle={noop}>
            <Panel.Heading>
                <Panel.Title componentClass="h3">
                    {props.list.length}{' '}
                    {singlePlural(props.entity, props.list.length)}
                    {showPreliminaries && preliminaries.length > 0 && <small className="bigger">
                        {' '}(including {preliminaries.length} preliminaries)
                    </small>}
                    {!isRecommendersTab &&
                        <button className={cx('inline-link pull-right', { 'active': showSettings })}
                                title={settingsTitle}
                                onClick={toggleSettings}>
                            <span>{settingsTitle}&nbsp;</span>
                            <Glyphicon glyph="cog" />
                        </button>
                    }
                </Panel.Title>
            </Panel.Heading>
            <Panel.Body>
                {!isRecommendersTab && showSettings !== DISPLAY_STATUS.CLOSED &&
                    <div className="settings">
                        <div className="wrapper">
                            <JobApplicantsSettings show={showSettings}
                                                    applicants={props.list}
                                                    availableTags={availableTags}
                                                    onHide={hideSettings}
                                                    onHideDeclined={displaySettings}
                                                    jobId={jobId} />
                        </div>
                    </div>
                }
                {/* we modify `data` prop based on props.entity and showPreliminaries
                  thus we need to reflect it in `key` to work properly, especially
                  when `editable` prop is true */}
                <SortableTable
                    key={`${props.entity}-table-${showPreliminaries}`}
                    className={`job-applicants-table table-striped table-sticky-head ${currentTab}-tab`}
                    columns={props.columns}
                    editable={currentTab === 'applicants'}
                    renderRow={renderRow}
                    renderNoResults={renderNoResults}
                    jobId={jobId} // used in filtern.fn callback
                    data={props.list}
                    onSelect={onMassApplicantsSelect}
                    isSelected={isApplicantSelected}/>
            </Panel.Body>
        </Panel>
    </div>;
}
