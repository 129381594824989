import React, { Fragment } from 'react';
import type { Node } from 'react';
import { withApollo } from 'react-apollo';
import type { ApolloClient } from 'apollo-client';

import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';

import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import Col from 'react-bootstrap/lib/Col';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Popover from 'react-bootstrap/lib/Popover';
import Row from 'react-bootstrap/lib/Row';

import type { AuthorToClaimType } from '../accounts/TypesClaim';
import type { UserType } from '../accounts/Types';
import type { FindClaimArticleType } from './Types';
import helpers from './helpers';
import withAuthorization from '../common/withAuthorization';
import withCurrentUser from '../common/withCurrentUser';
import FileUpload from '../widgets/FileUpload';
import LoadingButton from '../widgets/LoadingButton';
import { constructArticlePath } from './ArticleLink';
import { checkStatus, interpose, singlePlural, capitalizeFirstLetter } from '../utils';
import AuthorToClaim from '../accounts/AuthorToClaim';
import CurrentUserContext from '../accounts/CurrentUserContext';

import './claim-articles.css';



type NameClusterType = {
    names: Array<string>,
    articles_ids: Array<number>,
    articles_ids_found: Array<number>,
    articles_ids_suggested: Array<number>,
};

type FindClaimArticlesResponseType = {
    ok: boolean,
    error?: string,
    name_clusters: Array<NameClusterType>,
    articles: Array<FindClaimArticleType>,
    articles_ids_found: Array<number>,
    articles_ids_suggested: Array<number>,
    input_count: number,
    not_found: Array<string>,
    token: string
};

type ClaimArticlesStateType = {
    loading: boolean,
    articles: ?Array<FindClaimArticleType>,
    articlesIdsFound: Array<number>,
    articlesIdsSuggested: Array<number>,
    articlesCount: number,
    notFound: Array<string>,
    nameClusters: Array<NameClusterType>,
    selectedNameClusters: Array<NameClusterType>,
    token: string,
    error: ?Node,
    plainText: string,
    filter: string,
    showMoreAuthors: boolean,
    shortAuthor: ?AuthorToClaimType,
    fetchInitialShortAuthorDone: boolean,
    step: number,
    selectedFoundArticlesIds: Array<number>,
    selectedSuggestedArticlesIds: Array<number>,
    foundArticlesToDisplay: Array<FindClaimArticleType>,
    suggestedArticlesToDisplay: Array<FindClaimArticleType>,
    claimedFoundArticleCount: ?number,
};


class ClaimArticles extends React.Component<
    {
        client: ApolloClient,
        currentUser: UserType,
        person?: {
            advisorName: string,
            articlesCount: number,
            attorneyToken: string,
            authorId: number,
            authorName: string,
            email: string
        }
    },
    ClaimArticlesStateType
> {
    defaultState = {
        loading: false,
        articles: null,
        articlesIdsFound: [],
        articlesIdsSuggested: [],
        articlesCount: 0,
        notFound: [],
        nameClusters: [],
        selectedNameClusters: [],
        token: '',
        error: null,
        plainText: '',
        filter: '',
        showMoreAuthors: false,
        shortAuthor: null,
        fetchInitialShortAuthorDone: false,
        step: 1,
        selectedFoundArticlesIds: [],
        selectedSuggestedArticlesIds: [],
        foundArticlesToDisplay: [],
        suggestedArticlesToDisplay: [],
        claimedFoundArticleCount: null,
    };

    state = { ...this.defaultState };

    componentDidMount() {
        const { authorId } = this.props.person || this.props.currentUser;
        this.fetchShortAuthor(authorId, true);
    }

    componentDidUpdate(prevProps: any, prevState: ClaimArticlesStateType) {
        if (
            (prevState.step !== 1 && this.state.step === 1) ||
            (prevState.token !== this.state.token)
        ) {
            this.scrollTo('results', 'smooth');
        }

        if (this.state.step > prevState.step) {
            this.scrollTo('root', 'instant');
        }
    }

    yourOrAdvisors() {
        return this.props.person ? "your recommender's" : 'your';
    }

    youOrAdvisor() {
        return this.props.person ? 'your recommender' : 'you';
    }

    fetchShortAuthor = (authorId: ?number, isInitial: boolean) => {
        if (!authorId) {
            if (isInitial) {
                this.setState({ fetchInitialShortAuthorDone: true });
            }
            return;
        }

        return fetch(`/api/authors/${authorId}/short`, {
            credentials: 'same-origin',
            method: 'GET',
        })
            .then(response => {
                this.setState({ loading: false });
                return response;
            })
            .then(checkStatus)
            .then(response => response.json())
            .then(response => {
                const newState = { shortAuthor: response.author };
                if (isInitial) {
                    newState.fetchInitialShortAuthorDone = true;
                }
                this.setState(newState);
            });
    }

    renderShortAuthor() {
        const shortAuthor = this.state.shortAuthor;

        if (!shortAuthor) {
            return
        }

        const author = {
            ...shortAuthor,
            matchedEmails: [],
        }

        return <div className="claimed-author">
            <div><label><strong>{capitalizeFirstLetter(this.yourOrAdvisors())}&nbsp;linked author:</strong></label></div>
            <AuthorToClaim author={author}
                           resultAuthorId={shortAuthor.id}
                           claimed
                           hideEmails
                           attorneyToken={this.attorneyToken()}
                           loading={false}/>
        </div>
    }

    scrollTo(elId: string, behaviour: 'smooth' | 'instant') {
        const results = document.getElementById(elId);

        if (results) {
            window.scrollTo({
                top: results.offsetTop,
                left: 0,
                behavior: behaviour,
            });
        }
    }

    attorneyToken() {
        return this.props.person?.attorneyToken;
    }

    setFoundClaimArticles = (fetchResult: FindClaimArticlesResponseType) => {
        if (fetchResult) {
            if (fetchResult.articles) {
                this.setState({
                    articles: fetchResult.articles,
                    articlesIdsFound: fetchResult.articles_ids_found,
                    articlesIdsSuggested: fetchResult.articles_ids_suggested,
                    articlesCount: fetchResult.input_count,
                    nameClusters: fetchResult.name_clusters.map(nc => ({
                        ...nc,
                        articles_ids_found: intersection(nc.articles_ids, fetchResult.articles_ids_found),
                        articles_ids_suggested: intersection(nc.articles_ids, fetchResult.articles_ids_suggested),
                    })),
                    selectedNameClusters: [],
                    notFound: fetchResult.not_found,
                    error: null,
                    token: fetchResult.token
                });
            } else if (fetchResult.error) {
                this.setState({
                    articles: null,
                    articlesIdsFound: [],
                    articlesIdsSuggested: [],
                    articlesCount: 0,
                    nameClusters: [],
                    error: fetchResult.error,
                    token: ''
                });
            }
        }
    }

    createToggleArticle = (id: number, listType: 'found' | 'suggested') => {
        return () => {
            let articlesIdsList = null;

            if (listType === 'found') {
                articlesIdsList = this.state.selectedFoundArticlesIds;
            } else if (listType === 'suggested') {
                articlesIdsList = this.state.selectedSuggestedArticlesIds;
            } else {
                return;
            }

            let newIdsList = articlesIdsList.slice();

            if (newIdsList.some(listId => listId === id)) {
                newIdsList = newIdsList.filter(listId => listId !== id);
            } else {
                newIdsList.push(id);
            }

            if (listType === 'found') {
                this.setState({ selectedFoundArticlesIds: newIdsList});
            } else if (listType === 'suggested') {
                this.setState({ selectedSuggestedArticlesIds: newIdsList });
            };
        }
    }

    handlePlainTextChange = (e: SyntheticEvent<HTMLTextAreaElement>) => {
        this.setState({ plainText: e.currentTarget.value });
    }

    uploadText = () => {
        this.setState({ loading: true });

        return helpers.fetchArticlesByTitle(null, this.state.plainText, this.attorneyToken())
            .then(checkStatus)
            .then(response => response.json())
            .then((response: FindClaimArticlesResponseType) => {
                this.setState({ loading: false });
                this.setFoundClaimArticles(response);
            });
    }

    redirectToAuthor(authorId, claimedCount) {
        const attorneyToken = this.attorneyToken();

        let url = `/author/${authorId}?claimed_count=${claimedCount}`;
        if (attorneyToken) {
            url += `&attorney_token=${attorneyToken}`;
        }
        // make a new http request and fetch author, because he might be changed.
        window.location.href = url;
    }

    claimFoundArticles = () => {
        const selectedNameClusters = this.state.selectedNameClusters;
        const stateArticles = this.state.articles;
        if (!selectedNameClusters.length || !stateArticles) return;

        this.setState({ loading: true });

        const articlesIdsFromSelectedNameClusters = uniq(
            selectedNameClusters.reduce((result, nameCluster) =>
                result.concat(nameCluster.articles_ids), []));

        const articlesIds = intersection(
            articlesIdsFromSelectedNameClusters,
            this.state.selectedFoundArticlesIds,
        );

        const names = selectedNameClusters.reduce((result, nameCluster) => result.concat(nameCluster.names), []);

        return this.claim(articlesIds, names, 'found')
            .then(response => {
                if (response.ok) {
                    this.switchToClaimSuggestedArticlesStep(response.stats.author_id, response.stats.claimed_count);
                } else {
                    this.setState({
                        error: response.error,
                        loading: false
                    });
                }
            })
    }

    claimSuggestedArticles = () => {
        const selectedNameClusters = this.state.selectedNameClusters;
        const stateArticles = this.state.articles;
        if (!selectedNameClusters.length || !stateArticles) return;

        this.setState({ loading: true });

        const articlesIdsFromSelectedNameClusters = uniq(
            selectedNameClusters.reduce((result, nameCluster) =>
                result.concat(nameCluster.articles_ids), []));

        const articlesIds = intersection(
            articlesIdsFromSelectedNameClusters,
            this.state.selectedSuggestedArticlesIds,
        );

        const names = selectedNameClusters.reduce((result, nameCluster) => result.concat(nameCluster.names), []);

        return this.claim(articlesIds, names, 'suggested')
            .then(response => {
                if (response.ok) {
                    this.redirectToAuthor(response.stats.author_id,
                                          this.state.claimedFoundArticleCount + response.stats.claimed_count);
                } else {
                    this.setState({ error: response.error, loading: false });
                }
            })
    }

    claim = (articlesIds, names, listType: 'found' | 'suggested') => {
        return fetch('/api/authors/claim-articles/', {
            method: 'POST',
            body: JSON.stringify({
                articles_ids: articlesIds,
                all_articles_ids: this.state.articles.map(a => a.article_id),
                names: names,
                token: this.state.token,
                comment: `claimed ${listType} articles from Find your articles page`,
                ...(this.props.person &&
                    { attorney_token: this.props.person.attorneyToken }
                )
            }),
            headers: { 'Content-type': 'application/json' }
        })
            .then(checkStatus)
            .then(response => {
                // refetch user to update it's authorId
                if (!this.props.person) {
                    const [, { fetchUser }] = this.context;
                    fetchUser();
                }
                return response;
            })
            .then(response => response.json())
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    createHandleAddNameCluster = (nc: NameClusterType) => {
        return () => {
            this.setState({
                selectedNameClusters: this.state.selectedNameClusters.concat([nc])
            });
        };
    }

    createHandleRemoveNameCluster = (nc: NameClusterType) => {
        return () => {
            this.setState({
                selectedNameClusters: this.state.selectedNameClusters.filter(n => n !== nc)
            });
        };
    }

    setAvailableAuthorsFilter = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ filter: e.currentTarget.value });
    }

    switchToClaimFoundArticlesStep = () => {
        const articlesIdsFromSelectedNames = this.getArticlesIdsFromSelectedNames();
        const foundArticlesIds = intersection(articlesIdsFromSelectedNames, this.state.articlesIdsFound);
        const foundArticlesToDisplay = this.state.articles.filter(a => foundArticlesIds.indexOf(a.article_id) !== -1);

        this.setState({
            step: 2,
            selectedFoundArticlesIds: foundArticlesIds,
            foundArticlesToDisplay,
        });
    }

    switchToClaimSuggestedArticlesStep = (authorId, claimedCount) => {
        const articlesIdsFromSelectedNames = this.getArticlesIdsFromSelectedNames();
        const suggestedArticlesIds = intersection(articlesIdsFromSelectedNames, this.state.articlesIdsSuggested);
        const suggestedArticlesToDisplay = this.state.articles.filter(a => suggestedArticlesIds.indexOf(a.article_id) !== -1);

        if (suggestedArticlesToDisplay.length !== 0) {
            this.fetchShortAuthor(authorId, false)
                .then(() => this.setState({
                    step: 3,
                    selectedSuggestedArticlesIds: suggestedArticlesIds,
                    claimedFoundArticleCount: claimedCount,
                    suggestedArticlesToDisplay: suggestedArticlesToDisplay,
                    loading: false,
                    error: '',
                }));
        } else {
            this.redirectToAuthor(authorId, claimedCount);
        }
    }

    back = () => {
        this.setState({ step: 1, });
    }

    reset = () => {
        this.setState(this.defaultState);
    }

    label = (str: string) => {
        const labels = this.props.person ?
            {
                you: this.props.person.authorName || this.props.person.advisorName,
                yourOrPerson: `${this.props.person.authorName || this.props.person.advisorName}'s`,
            }
            : {
                you: 'you',
                yourOrPerson: 'your',
            };

        return labels[str];
    }

    selectAllArticles(listType: 'found' | 'suggested') {
        if (listType === 'found') {
            this.setState({ selectedFoundArticlesIds: this.state.foundArticlesToDisplay.map(a => a.article_id) });
        } else if (listType === 'suggested') {
            this.setState({ selectedSuggestedArticlesIds: this.state.suggestedArticlesToDisplay.map(a => a.article_id) });
        }
    }

    selectNoneArticles(listType: 'found' | 'suggested') {
        if (listType === 'found') {
            this.setState({ selectedFoundArticlesIds: [] });
        } else if (listType === 'suggested') {
            this.setState({ selectedSuggestedArticlesIds: [] });
        }
    }

    toggleShowMoreAuthors = () => {
        this.setState({ showMoreAuthors: !this.state.showMoreAuthors });
    }

    formatNameCluster = (foundCount: number, totalFound: number, remaining: boolean = false) => {
        return `found in ${
            foundCount ?
                `${foundCount} of ${totalFound} ${remaining ? 'remaining' : ''}`
                : ''
        } ${
            singlePlural('article', totalFound)
        }`;
    }

    renderNoResults() {
        return <Fragment>
            <h3>No matches</h3>
            <p>Unfortunately, we didn&apos;t find any articles using your input.</p>
        </Fragment>;
    }

    renderResults() {
        const { articlesIdsFound, selectedNameClusters } = this.state;

        if (!articlesIdsFound) return null;

        const summarize = (articleType: 'found' | 'suggested') => {
            const idx = `articlesIds${articleType[0].toUpperCase()}${articleType.slice(1)}`;
            return (acc, nc) => acc.concat(intersection(nc.articles_ids, this.state[idx]));
        };
        const selectedFoundArticles = uniq(selectedNameClusters.reduce(summarize('found'), []));
        const availableNameClusters = this.state.nameClusters
            .filter(nc => selectedNameClusters.indexOf(nc) === -1 &&
                nc.articles_ids.every(id => selectedFoundArticles.indexOf(id) === -1) &&
                nc.names.join(', ').toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1
            );

        const missingOverlay = <Popover id="not-found-popover"
                                        title="Articles not found in Prophy">
            <ul>
                {this.state.notFound.map(title => <li key={title}>{title}</li>)}
            </ul>
        </Popover>;
        const maxFound = Math.max.apply(null, availableNameClusters.map(nc => nc.articles_ids_found.length));
        const you = this.label('you');

        return <Fragment>
            <h3>
                Found {articlesIdsFound.length} of {this.state.articlesCount} titles&nbsp;
                {!!this.state.notFound.length && <OverlayTrigger overlay={missingOverlay}
                                                                 trigger="click"
                                                                 placement="bottom"
                                                                 rootClose>
                    <button className="inline-link">see missing</button>
                </OverlayTrigger>}
            </h3>
            <p>Having processed your data, we&apos;ve found the following authors. Please select those who
                correspond to {you}.
            </p>
            <Row>
                <Col xs={6} className="available-author-clusters">
                    <strong>Available for select:
                        ({(this.state.articlesIdsFound.length - selectedFoundArticles.length) || 'no'} articles remain)</strong>
                    <input type="text"
                           className="form-control"
                           placeholder="Type to filter authors (e.g., John Smith)"
                           onChange={this.setAvailableAuthorsFilter}/>
                    {availableNameClusters.length ?
                        <Fragment>
                            <ul>
                                {availableNameClusters
                                    .filter(nc => this.state.showMoreAuthors || nc.articles_ids_found.length === maxFound)
                                    .map(nc => {
                                        const names = nc.names.join(', ');

                                        return <li key={names}>
                                            {names} ({this.formatNameCluster(nc.articles_ids_found.length, this.state.articlesIdsFound.length - selectedFoundArticles.length, selectedFoundArticles.length !== 0)})
                                            &nbsp;
                                            <Button bsStyle="success"
                                                    bsSize="xs"
                                                    onClick={this.createHandleAddNameCluster(nc)}>
                                                add name
                                            </Button>
                                        </li>;
                                    })}
                                <li className="show-more">
                                    <button className="inline-link"
                                            onClick={this.toggleShowMoreAuthors}>
                                        {this.state.showMoreAuthors ?
                                            'or show less'
                                            : 'or show more →'
                                        }
                                    </button>
                                </li>
                            </ul>
                        </Fragment>
                        : <p>Nothing to select.</p>
                    }
                </Col>
                <Col xs={6}>
                    {selectedNameClusters.length ?
                        <Fragment>
                            <strong>
                                Selected{selectedFoundArticles.length ?
                                    ` (found in ${selectedFoundArticles.length} of ${this.state.articlesIdsFound.length})`
                                    : ''}:
                            </strong>
                            <ul>
                                {selectedNameClusters.map(nc => {
                                    const names = nc.names.join(', ');

                                    return <li key={names}>
                                        {names} ({this.formatNameCluster(nc.articles_ids_found.length, this.state.articlesIdsFound.length)})
                                        &nbsp;
                                        <Button bsStyle="danger"
                                                bsSize="xs"
                                                onClick={this.createHandleRemoveNameCluster(nc)}>
                                            remove
                                        </Button>
                                    </li>
                                })}
                            </ul>
                            <Button bsStyle="primary"
                                    disabled={!this.state.selectedNameClusters.length}
                                    onClick={this.switchToClaimFoundArticlesStep}>
                                Confirm selected names and continue
                            </Button>
                        </Fragment>
                        : <p>Nothing selected yet.</p>
                    }
                </Col>
            </Row>
        </Fragment>;
    }

    renderArticles(articles: Array<FindClaimArticleType>, listType: 'found' | 'suggested') {
        let selectedIds = [];
        if (listType === 'found') {
            selectedIds = this.state.selectedFoundArticlesIds;
        } else if (listType === 'suggested') {
            selectedIds = this.state.selectedSuggestedArticlesIds;
        }

        return <div>
            <div className="selected">
                <strong>
                    Selected {selectedIds.length} / {articles.length} articles
                </strong>
                <ButtonToolbar className="selection pull-right">
                    <Button onClick={() => this.selectAllArticles(listType)}>Select all</Button>
                    <Button onClick={() => this.selectNoneArticles(listType)}>Unselect all</Button>
                </ButtonToolbar>
            </div>
            <ul className="list-unstyled">
                {articles.map(a => <li key={a.article_id}>
                    <input type="checkbox"
                        onChange={this.createToggleArticle(a.article_id, listType)}
                        checked={(selectedIds.indexOf(a.article_id) !== -1)}
                        id={`checkbox-${a.article_id}`}/>
                    <label htmlFor={`checkbox-${a.article_id}`}>
                        {a.title} ({interpose(a.authors.map(a => a.name), ', ')}
                        {a.authors_count > a.authors.length ? ', et al.' : ''})
                    </label>&nbsp;
                    {/* eslint-disable react/jsx-no-target-blank */}
                    <a href={constructArticlePath(a.article_id, a.title)}
                       target="_blank">
                        see article
                    </a>
                    {/* eslint-enable react/jsx-no-target-blank */}
                </li>)}
            </ul>
        </div>;
    }

    renderError() {
        return <Fragment>
            {this.state.error &&
                <Alert bsStyle="danger"
                    className="col-sm-3 col-xs-10 alert-fixed-bottom-right">
                    {this.state.error}
                </Alert>
            }
        </Fragment>;
    }

    getPlaceholder() {
        return (
`[1] M. Anelli et al. [SHiP Collaboration], “A facility to Search for Hidden Particles (SHiP) at the CERN SPS,” arXiv:1504.04956 [physics.ins-det].
[2] S. Alekhin et al., “A facility to Search for Hidden Particles at the CERN SPS: the SHiP physics case,” arXiv:1504.04855 [hep-ph].
[3] W. Bonivento et al., “Proposal to Search for Heavy Neutral Leptons at the SPS,” arXiv:1310.1762 [hep-ex].`)
    }

    getArticlesIdsFromSelectedNames() {
        return uniq(this.state.selectedNameClusters.reduce((result, nameCluster) => result.concat(nameCluster.articles_ids), []));
    }

    renderInputArticlesListStep() {
        // Step 1
        const yourOrPerson = this.label('yourOrPerson');

        return <Fragment>
            <Row>
                <Col sm={8} xs={12}>
                    <h2>Upload {yourOrPerson} bibliography and claim articles</h2>

                    {this.renderShortAuthor()}

                    <p>
                        Please upload a BibTeX file (*.bib) and we will find publications in our
                        database and add them to {yourOrPerson} profile.
                    </p>
                    <p>
                        You can export BibTeX from systems like{' '}
                        <a href="https://orcid.org/" target="_blank" rel="noopener noreferrer">ORCID</a>,{' '}
                        <a href="https://scholar.google.com/" target="_blank" rel="noopener noreferrer">Google Scholar</a>,{' '}
                        <a href="https://www.scopus.com/" target="_blank" rel="noopener noreferrer">Scopus</a>,{' '}
                        <a href="https://inspirehep.net/" target="_blank" rel="noopener noreferrer">Inspire</a>,{' '}
                        <a href="https://ui.adsabs.harvard.edu/" target="_blank" rel="noopener noreferrer">NASA ADS</a> and others.
                    </p>

                    <FileUpload uploadFn={(file) => helpers.fetchArticlesByTitle(file, null, this.attorneyToken())}
                                callback={this.setFoundClaimArticles} />
                    <div className="or">OR</div>
                    <p>alternatively, you can place the list of {yourOrPerson} papers in the field below, one paper per line:</p>
                    <div>
                        <textarea
                            className="form-control"
                            placeholder={this.getPlaceholder()}
                            onChange={this.handlePlainTextChange}
                            value={this.state.plainText}></textarea>
                    </div>
                    <LoadingButton onClick={this.uploadText}
                                   className="submit-plain-text"
                                   workingMessage="Searching"
                                   disabled={!this.state.plainText || this.state.loading}
                                   working={this.state.loading}>
                        Find articles
                    </LoadingButton>
                </Col>
            </Row>
            <Row>
                {this.state.articles && <Col id="results" xs={12}>
                    {this.state.articles.length ?
                        this.renderResults()
                        : this.renderNoResults()
                    }
                </Col>}
            </Row>
        </Fragment>
    }

    renderClaimFoundArticlesStep() {
        // Step 2
        return <div className="articles-list">
            <h3>
                Claim articles&nbsp;
                <button className="inline-link" onClick={this.back}>
                    back to authors selection
                </button>
            </h3>
            {this.renderShortAuthor()}
            {this.renderArticles(this.state.foundArticlesToDisplay, 'found')}

            <LoadingButton bsStyle="primary"
                           className="submit-claim"
                           working={this.state.loading}
                           disabled={!this.state.selectedFoundArticlesIds.length || this.state.loading}
                           onClick={this.claimFoundArticles}>
                Claim articles
            </LoadingButton>
        </div>;
    }

    renderClaimSuggestedArticlesStep() {
        // Step 3
        return <div className="articles-list">
             <Alert bsStyle="success" className="claimed-count-alert">
                 Claimed {this.state.claimedFoundArticleCount} new articles
             </Alert>
            {this.renderShortAuthor()}
            <h3>
                We have found more articles that may belong to {this.youOrAdvisor()}&nbsp;
                <button className="inline-link" onClick={this.back}>
                    back to authors selection
                </button>
            </h3>
            {this.renderArticles(this.state.suggestedArticlesToDisplay, 'suggested')}

            <ButtonToolbar>
                <LoadingButton bsStyle="primary"
                               className="submit-claim"
                               working={this.state.loading}
                               disabled={!this.state.selectedSuggestedArticlesIds.length || this.state.loading}
                               onClick={this.claimSuggestedArticles}>
                    Claim articles
                </LoadingButton>
                <Button onClick={() => this.redirectToAuthor(this.state.shortAuthor.id, this.state.claimedFoundArticleCount)}>Close</Button>
            </ButtonToolbar>
        </div>;
    }

    render() {
        if (!this.state.fetchInitialShortAuthorDone) {
            return <div>Loading<span className="loading" aria-hidden="true" /></div>;
        }

        let body = null;
        switch (this.state.step) {
            default:
                break;
            case 1:
                body = this.renderInputArticlesListStep();
                break;
            case 2:
                body = this.renderClaimFoundArticlesStep();
                break;
            case 3:
                body = this.renderClaimSuggestedArticlesStep();
                break;
        }

        return <div className="claim-articles">
            {this.renderError()}
            {body}
        </div>;
    }
}

ClaimArticles.contextType = CurrentUserContext;

export default withAuthorization(withApollo(withCurrentUser(ClaimArticles)));
