import React from 'react';

import { cx } from '../utils';

import './expandable-input.css';


type ExpandableInputPropsType = {
    value: string,
    className: string,
    inputRef?: (el: ?HTMLInputElement) => any,
};


export default class ExpandableInput extends React.Component<
    ExpandableInputPropsType
> {
    static defaultProps = {
        value: ''
    };

    input: ?HTMLInputElement = null;
    pre: ?HTMLPreElement = null;

    setInput = (el: ?HTMLInputElement) => {
        this.input = el;
        this.props.inputRef && this.props.inputRef(el);
    }

    render() {
        const { inputRef, className, ...inputProps } = this.props;

        return <span className={cx('expandable-input-wrapper', className)}>
            <input
                type="text"
                className="expandable-input"
                ref={this.setInput}
                { ...inputProps }
            />
            <pre>{this.props.value}</pre>
        </span>
    }
}
