import React from 'react';
import { useCurrentUser } from '../accounts/hooks';
import { REFEREE_SETTINGS } from './Referees'
import RefereesSettings from './RefereesSettings';
import helpers from './helpers';
import { useSubscription, sendRequest, updateGlobalData } from '../datastore';
import { makeSettingsKey } from './RefereesData';
import analytics from '../common/analytics';


const ORGANIZATION_REFEREE_SETTINGS = REFEREE_SETTINGS.filter(
    l => l !== 'topics' && l !== 'ignore_topics');


export default function OrganizationSettingsPage({ match: {params: {orgId: docId} } }) {

    const [currentUser] = useCurrentUser();
    const [updating, setUpdating] = React.useState(true);

    const [organization, setOrganization] = React.useState({});
    const [countries, setCountries] = React.useState([]);
    const [authorsGroups, setAuthorsGroups] = React.useState([]);

    const docKind = 'organization';
    const settingsId = null;
    const defaultSettings = helpers.getDefaultSettings({id: docId}, 'organization');

    const rfMessage = <p className="help-block">The following settings will be used as default for documents in this organization:</p>;
    const styles = {marginTop: 0};
    const alertStyles = {marginLeft: '-15px', width: '50%'};

    React.useEffect(() => {
        setUpdating(true);

        sendRequest(`/api/referees/${docKind}/${docId}/settings`, {
            method: 'GET',
            // to override json content-type in sendRequest
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
        })
            .then(response => {
                if (response.ok) {
                    updateGlobalData((store, { value: { organization, countries, authorsGroups } }) => {
                        const setKeys = makeSettingsKey(docKind, docId)
                        store[setKeys[0]] = {...organization.refereeFinderSettings};
                        setOrganization(organization);
                        setCountries(countries);
                        setAuthorsGroups(authorsGroups);
                    }, response);
                }
                setUpdating(false);
            });
    }, [docKind, docId]);

    const settingsKey = makeSettingsKey(docKind, docId);
    const settings = useSubscription(...settingsKey);

    const handleApplySettingsFilter = React.useCallback((settings, settingsId) => {
        setUpdating(true);
        sendRequest(`/api/referees/organization/${docId}/settings`, {
            credentials: 'same-origin',
            method: 'POST',
            headers: {'Content-type': 'application/json',},
            body: JSON.stringify({
                settings: settings,
            })
        }).then(response => {
            if (response.ok) {
                alert('Settings saved!')
            }
            setUpdating(false);
        });

        analytics.log('rf/apply-settings', {
            ...settings,
            docId,
            docKind,
        });
    }, [docKind, docId]);

    return <div>
        <a href={`/folders/`}>&larr; back to the folders </a>
        <h2>{organization.name}</h2>
        <h3 className="help-block">{updating ? 'Updating...' : 'Default Referee Finder settings'}</h3>

        {!!settings && <div className="referees-lists referees show-settings" style={styles}>
            <br/>
            {currentUser.isSuperuser && <div className="alert alert-warning" style={alertStyles}>
                You are a superuser. Other users might not have access to some of the features.
            </div>}
            <div className="alert alert-warning" style={alertStyles}>
                Newly created folders will have the default settings.
                Changes to the default settings will not apply to existing folders.
                If you want to change the settings for existing folders,
                you will need to do this separately for each folder.
            </div>
            <div className="settings">
                <div className="wrapper">
                    <RefereesSettings
                        docKind={docKind}
                        docId={docId}
                        settingsId={settingsId}
                        defaultSettings={defaultSettings}
                        availableSettings={ORGANIZATION_REFEREE_SETTINGS}
                        settings={settings}
                        availableTopics={null}
                        docTopics={null}
                        countries={countries}
                        authorsGroups={authorsGroups}
                        customAuthorIds={null}
                        updating={updating}
                        onApply={handleApplySettingsFilter}
                        message={rfMessage} />
                </div>
            </div>
        </div>}
    </div>;
}
