import React, { Fragment } from 'react';
import Reflux from 'reflux';
import compose from 'lodash/flowRight';

import { graphql, withApollo } from '@apollo/react-hoc';
import type { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';

import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Row from 'react-bootstrap/lib/Row';
import Modal from 'react-bootstrap/lib/Modal';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';

import Affix from 'react-overlays/lib/Affix';

import ConceptRow from './ConceptRow';

import Loading from '../../Loading';
import helpers from '../helpers';
import dom from '../../common/helpers/dom';
import mutateConcept from '../gql/mutateConceptGQL';
import missingLiterals from '../gql/missingLiteralsGQL';
import { cx, pick } from '../../utils';
import { EditConceptForm } from '../EditConceptForm';
import MergeActions from '../MergeActions';
import UseAsParentComposite from '../UseAsParentComposite';
import QMissingLiterals from '../MissingLiterals';
import PossibleConceptsStore, {
    PossibleConceptsActions
} from './PossibleConceptsStore';
import type {
    OntologyConceptType, DocsBatchStatusType,
    LiteralType, ConceptMutationResponseType
} from '../Types';
import { SimilarConceptPropsFragment,  wikipediaLiteralFragment } from '../../common/Fragments';
import SortableTable from '../../widgets/SortableTable';
import ToTheTop from '../../widgets/ToTheTop';
import withCurrentUser from '../../common/withCurrentUser';
import {
    HideOrUnhideMode, HideOrUnhideDocsBatchPossibleConcept
} from './HideDocsBatchPossibleConcept';
import type { UserType } from '../../accounts/Types';
import type { SimpleConceptType, UseAsParentCompositeResultType } from '../Types';

import mergeIcon from '../../css/images/merge.svg';


const summarize = field => {
    return (total, l, _, literals) => total + (l[field] || 0);
};


const maxOf = field => {
    return (acc, l, _, literals) => acc > (l[field] || 0) ? acc : l[field];
};


export type LiteralWithDFType = LiteralType & {
    df: number,
    tf: number,
    dfCollection: number,
    searchQuery: string,
    lexicalNeighborsConcepts: Array<SimpleConceptType>,
    wikiLink?: string,
    wikiAmbiguous?: boolean,
    wikiVariants?: Array<any>
};

type PossibleConceptType = {
    name: string,
    literals: Array<LiteralWithDFType>,
    hidden: boolean,
};

export type LocalPossibleConceptType = PossibleConceptType & {
    _id: number,
    selected: boolean,
    basic: boolean,
    conflicts: Array<OntologyConceptType>,
    neighbors: Array<OntologyConceptType>,
    score: number,
    created?: boolean,
    merged?: boolean,
    searchQuery?: string,
    potentialTotalDf?: number,
    df: number,
};

type DocsBatchDetailsPropsType = {
    batch: {
        id: string,
        status: DocsBatchStatusType,
        source: string,
        hiddenCount: number,
        possibleConcepts: Array<PossibleConceptType>,
    },
    currentUser: UserType,
    showDfCollection: boolean,
    showTf: boolean,
    showHidden: boolean,
    history: any,
};

type DocsBatchDetailsStateType = {
    possibleConcepts: ?Array<LocalPossibleConceptType>,
    currentIndex: ?number, // active table row
    prevIndex: ?number,
    creating: boolean,
    created: number,
    creatingError: ?Error,
    hiddenCount: number,
};


export class LoadedDocsBatchDetails extends Reflux.Component<
    DocsBatchDetailsPropsType,
    DocsBatchDetailsStateType
> {
    store = PossibleConceptsStore;
    storeKeys = ['possibleConcepts'];

    state: DocsBatchDetailsStateType = {
        possibleConcepts: null,
        currentIndex: null,
        prevIndex: null,
        creating: false,
        created: 0,
        creatingError: null,
        hiddenCount: this.props.batch.hiddenCount,
    };

    forms = [];

    DEFAULT_ANIMATION = {
        timeout: 300,
        keyFieldName: '_id'
    };

    DEFAULT_PAGINATION = {
        rowsPerPage: 50
    };

    PossibleConceptColumns = [
        {
            field: '_id',
            key: true
        },
        {
            field: 'selected',
            text: '',
            selectable: true
        },
        {
            field: 'literals',
            text: 'Literals',
            filter: {
                type: 'substring',
                fn: (pattern: string, item: LocalPossibleConceptType) => item.literals
                    .map(l => l.name.toLowerCase())
                    .some(l => l.indexOf(pattern.toLowerCase()) !== -1)
            }
        },
        { field: 'score', text: 'Score', sortable: true, filter: { type: 'between' } },
        {
            field: 'dfCollection',
            text: 'DF\u00A0(coll.)',
            sortable: true,
            filter: { type: 'between' }
        },
        { field: 'tf', text: 'TF', sortable: true, filter: { type: 'between' } },
        {
            field: 'df',
            text: 'DF\u00A0(total)',
            sortable: true,
            filter: { type: 'between' },
        },
        { field: 'wikipedia', text: 'Wikipedia', sortable: true, isList: true },
        { field: 'conflicts', text: 'Conflicts', sortable: true, isList: true },
        {
            field: 'neighbors',
            text: 'Neighbors',
            sortable: true,
            isList: true,
            filter: {
                type: 'checkbox',
                label: 'hide basics',
            },
        },
    ];

    componentDidMount() {
        if (this.props.batch.status === 'success') {
            // adds _id as unique identifier as possibles don't have any
            // and some other valuable defaults
            PossibleConceptsActions.init(
                this.props.batch.possibleConcepts
                    .map((p, i) => ({
                        ...p,
                        _id: i,
                        basic: p.basic || false,
                        deleted: false,
                        conflicts: p.literals.reduce(
                            (conflicts, l) => conflicts.concat(l.similarLiteralsConcepts),
                            []
                        ),
                        neighbors: p.literals.reduce(
                            (neighbors, l) => neighbors.concat(l.lexicalNeighborsConcepts),
                            []
                        ),
                        // TODO: change to summarize after fixes on backend
                        tf: p.literals.reduce(maxOf('tf'), 0),
                        df: p.literals.reduce(summarize('df'), 0),
                        dfCollection: p.literals.reduce(summarize('dfCollection'), 0),
                    }))
            );
        }
    }

    componentWillUnmount() {
        PossibleConceptsActions.reset();
        super.componentWillUnmount();
    }

    getIndex2Id = (): Array<number> => this.state.possibleConcepts ?
        this.state.possibleConcepts.map(c => c._id)
        : [];

    getSelected(): Array<LocalPossibleConceptType> {
        if (!this.state.possibleConcepts) return [];
        return this.state.possibleConcepts.filter(c => c.selected);
    }

    setForm = (index: number, form: ?EditConceptForm) => {
        if (form) {
            this.forms[index] = form;
        }
    };

    createShowForm = (index: ?number) => {
        return (e: SyntheticEvent<HTMLElement>) => {
            const target = e.target;
            const { possibleConcepts } = this.state;

            if (!possibleConcepts) return;

            if (
                (target instanceof HTMLInputElement &&
                 target.type === 'checkbox') ||
                (target instanceof HTMLElement && (
                    dom.hasParent(target, '.overlay-trigger') ||
                    target.classList.contains('overlay-trigger') ||
                    dom.hasParent(target, '.popover')
                ))
            ) {
                return;
            }

            // to satisfy flow check:
            // Cannot call this.updateConceptFromForm with index bound to _id
            // because null or undefined [1] is incompatible with number [2].
            let updateIndex: number = -1;
            let conceptId: number = -1;
            let concept = null;

            this.setState({
                currentIndex: index,
                prevIndex: this.state.currentIndex
            });

            if (typeof index === 'number') {
                updateIndex = index;
                concept = possibleConcepts.find(c => c._id === index);
            }

            // update dfs and searchQueries after first click
            if (concept && !concept.searchQuery) {
                conceptId = concept._id;

                const literals = concept.literals.slice();
                const literalsVariables = literals.map(helpers.nameOrComposite);

                // set dfs stored in db as actual while waiting server answer
                const change = {
                    potentialTotalDf: concept.df,
                    searchQuery: helpers.getSearchQuery(concept),
                    literals: literals.map((literal, i) => ({
                        ...literal,
                        df: literal.df,
                        searchQuery: `"${literal.name}"`,
                    })),
                };
                this.updateConceptFromForm(change, updateIndex);

                return this.props.client.query({
                    query: fetchLiteralsDfs,
                    variables: {
                        literals: literalsVariables,
                    },
                }).then(({data}) => {
                    const change = {
                        potentialTotalDf: data.literalsDfs.total.df,
                        searchQuery: data.literalsDfs.total.searchQuery,
                        literals: literals.map((literal, i) => ({
                            ...literal,
                            df: data.literalsDfs.perLiteral[i].df,
                            searchQuery: data.literalsDfs.perLiteral[i].searchQuery,
                        })),
                    };
                    this.updateConceptFromForm(change, updateIndex);
                }).catch(e => {
                    // eslint-disable-next-line no-console
                    console.error(e);
                    this.updateConceptFromForm(
                        { errors: ['Network problem, please try again.'] },
                        conceptId,
                    );
                });
            }
        }
    }

    updateConceptFromForm = (change: any, _id: number) => {
        const { possibleConcepts } = this.state;

        if (possibleConcepts) {
            const index = this.getIndex2Id().indexOf(_id);
            let newConcept = possibleConcepts[index];

            if (change.literals) {
                const newLiterals = change.literals.map((l, i) => {
                    const literal = possibleConcepts[index].literals
                        .find(cl => cl.name === l.name);

                    return ({
                        ...literal,
                        ...l
                    }: LiteralWithDFType);
                });

                newConcept = {
                    ...possibleConcepts[index],
                    ...change,
                    literals: newLiterals,
                    df: newLiterals.reduce(summarize('df'), 0),
                    dfCollection: newLiterals.reduce(summarize('dfCollection'), 0),
                    conflicts: newLiterals.reduce(
                        (conflicts, l) => [...conflicts, ...l.similarLiteralsConcepts],
                        []
                    ),
                    neighbors: newLiterals.reduce(
                        (neighbors, l) => [...neighbors, ...l.lexicalNeighborsConcepts], []),
                };
            }

            PossibleConceptsActions.changeQueueItem(index, {
                ...newConcept,
                ...change,
            });
        }
    }

    collapseRow() {
        this.setState({
            currentIndex: null,
            prevIndex: this.state.currentIndex
        });
    }

    handleFormError = (id: number, errors: Array<string>) => {
        this.updateConceptFromForm({ errors }, id);
    };

    handleConceptCreated = (index: number, response: ConceptMutationResponseType) => {
        const { possibleConcepts } = this.state;
        if (!possibleConcepts) {
            return;
        }

        if (response.data.concept.ok && response.data.concept.concept.id) {
            const i = this.getIndex2Id().indexOf(index);

            PossibleConceptsActions.changeQueueItem(i, {
                ...possibleConcepts[i],
                created: true,
                id: response.data.concept.concept.id,
                errors: [], // it's created, so no errors occured
            });

            this.collapseRow();
        }
    };

    handleSelect = (concept: LocalPossibleConceptType, checked: boolean) => {
        const { possibleConcepts } = this.state;
        if (!possibleConcepts) return;

        const i = this.getIndex2Id().indexOf(concept._id);

        PossibleConceptsActions.changeQueueItem(
            i,
            {
                ...possibleConcepts[i],
                selected: checked
            }
        );

        return i;
    }

    handleMergeConcepts = (e: SyntheticEvent<*>) => {
        e.stopPropagation();

        const concepts = this.getSelected();

        if (concepts.length > 1) {
            const [ resulting, ...others ] = concepts;
            const otherLiterals = others.reduce((acc, c) => acc.concat(c.literals), []);

            /*
            we don't want to shoot several requests to get missingLiterals
            for each "other" concept. Instead, we concatenate all literals
            and get back the list of literals, unique by norm
            */
            return this.props.client.query({
                query: missingLiterals,
                variables: {
                    literals: otherLiterals
                        .map(pick.bind(null, ['name', 'composite', 'isAmbiguous'])),
                    destLiterals: resulting.literals
                        .map(pick.bind(null, ['name', 'composite', 'isAmbiguous'])),
                }
            }).then(({ data }) => {
                const { possibleConcepts } = this.state;

                if (!possibleConcepts) return;

                const resultingConcept = others.reduce(
                    // passing [] as missingLiterals for now, append it later
                    (result, cur) => helpers.constructMergedConcept(cur, result, []),
                    resulting
                );

                const allLiterals = resulting.literals.concat(otherLiterals);
                const newLiteralNames = resulting.literals.concat(data.missingLiterals)
                    .map(l => l.name);
                const newLiterals = allLiterals.filter(l => newLiteralNames.indexOf(l.name) !== -1);
                const idx = possibleConcepts.indexOf(resulting);

                PossibleConceptsActions.changeQueueItem(idx, {
                    ...resultingConcept,
                    literals: newLiterals,
                    score: Math.max.apply(null, concepts.map(c => c.score)),
                    df: newLiterals.reduce(summarize('df'), 0),
                    dfCollection: newLiterals.reduce(summarize('dfCollection'), 0),
                    conflicts: newLiterals.reduce(
                        (conflicts, l) => conflicts
                            .concat(l.similarLiteralsConcepts),
                        []
                    ),
                    neighbors: newLiterals.reduce(
                        (neighbors, l) => [...neighbors, ...l.lexicalNeighborsConcepts], []),
                });

                others.forEach(c => {
                    PossibleConceptsActions.removeFromQueue(c);
                });
            });
        }
    }

    handleToggleRow = (index: number, open: boolean) => {
        this.setState({
            currentIndex: open ? index : null,
            prevIndex: this.state.currentIndex
        });
    }

    updateConceptAtIndex = (concept: LocalPossibleConceptType, change: {}) => {
        const { possibleConcepts } = this.state;

        if (!possibleConcepts) return;

        const index = possibleConcepts
            .map(p => p._id)
            .indexOf(concept._id);

        PossibleConceptsActions.changeQueueItem(
            index,
            {
                ...possibleConcepts[index],
                ...change
            }
        );
    };

    handleSubmit = (e: SyntheticEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        let failed = false;
        const createQueue = this.getSelected();

        this.setState({
            creating: true,
            created: 0,
            currentIndex: null,
            prevIndex: this.state.currentIndex,
            creatingError: null
        });

        // shoot requests one by one
        createQueue.reduce((cur, concept) => {
            const promise = cur.then(() => {
                const validationResults = helpers.validateAll(concept);

                if (validationResults.errors.length) {
                    setTimeout(() => {
                        this.updateConceptAtIndex(concept, validationResults);
                        this.setState({ creating: false });
                    }, 1500);
                }

                return helpers.saveConcept(concept, this.props.submit)
                    .then(result => {
                        if (result instanceof Error) {
                            failed = true;
                        } else {
                            this.updateConceptAtIndex(concept, {
                                id: result.data.concept.concept.id,
                                created: true,
                                errors: [], // it's created, so no errors occured
                            });

                            this.setState({ created: this.state.created + 1 });
                        }
                    })
                    .catch((err: Error) => {
                        failed = true;
                        this.updateConceptAtIndex(concept, {
                            errors: [err.message]
                        });

                        setTimeout(() => {
                            this.setState({ creating: false });
                        }, 1500);
                    });
            });

            // is it last one
            if (concept === createQueue[createQueue.length - 1]) {
                promise.then(() => {
                    setTimeout(() => {
                        const state = {};

                        state.creating = false;

                        if (failed) {
                            state.creatingError = new Error(
                                'There was an error saving' +
                                ' concepts. Check the table for details.'
                            );
                        }

                        createQueue.forEach(concept => {
                            const { possibleConcepts } = this.state;

                            if (!possibleConcepts) return;
                            const index = possibleConcepts
                                .map(p => p._id)
                                .indexOf(concept._id);

                            PossibleConceptsActions.changeQueueItem(
                                index,
                                {
                                    ...possibleConcepts[index],
                                    selected: false
                                }
                            );
                        });

                        this.setState(state);
                    }, 2000);
                });
            }

            return promise;
        }, Promise.resolve());
    };

    handleMassReportFalse = (e: SyntheticEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        const selected = this.getSelected();
        const literals = selected
            .map(p => p.literals.filter(l => !l.composite).map(l => l.name))
            .reduce((total, conceptLiterals) => total.concat(conceptLiterals), []);

        this.props.reportFalse({ literals })
            .then(() => {
                selected.forEach(c => {
                    PossibleConceptsActions.removeFromQueue(c);
                });
            });
    };

    createHandleMergeWithExistentClick<T: LiteralType>(
        possible: LocalPossibleConceptType,
        missingLiterals: Array<T>,
        to: OntologyConceptType
    ) {
        return () => {
            const newConcept = helpers.constructMergedConcept(
                possible,
                to,
                missingLiterals
            );

            return this.props.submit(newConcept)
                .then(({ data: { concept: { concept } } }) => {
                    const index = this.getIndex2Id().indexOf(possible._id);

                    PossibleConceptsActions.changeQueueItem(
                        index,
                        {
                            id: to.id,
                            merged: true,
                            selected: false,
                            conflicts: []
                        }
                    )

                    this.setState({ currentIndex: null });
                });
        };
    }

    updateAfterUseAsParentComposite(
        possible: LocalPossibleConceptType,
        newPossible: UseAsParentCompositeResultType
    ) {
        const { possibleConcepts } = this.state;

        if (!possibleConcepts) return;

        const i = this.getIndex2Id().indexOf(possible._id);
        const newLiterals = newPossible.literals;
        PossibleConceptsActions.changeQueueItem(
            i,
            {
                ...possibleConcepts[i],
                ...newPossible,
                conflicts: newLiterals.reduce(
                    (conflicts, l) => [...conflicts, ...l.similarLiteralsConcepts], []),
                neighbors: newLiterals.reduce(
                    (neighbors, l) => [...neighbors, ...l.lexicalNeighborsConcepts], []),
            }
        );
    }

    renderActions = (possible: LocalPossibleConceptType, concept: OntologyConceptType, canBeParent: boolean) => {
        // to satisfy flow we pass only needed fields into UseAsParentCompositesrc/prophy/articles/merge.py
        const literalsForUpdate = possible.literals
            .map(pick.bind(null, ['name', 'composite', 'lang', 'isAmbiguous']));

        return <div className="merge">
            <QMissingLiterals conceptId={concept.id} literals={possible.literals}>
                {missingLiterals => (
                    <MergeActions
                        concept={concept}
                        missingLiterals={missingLiterals}
                        onMerge={this.createHandleMergeWithExistentClick(
                            possible, missingLiterals, concept
                        )}
                    />
                )}
            </QMissingLiterals>
            {canBeParent &&
             <UseAsParentComposite parentConcept={concept}
                                   literalsForUpdate={literalsForUpdate}
                                   onResult={newPossible => this.updateAfterUseAsParentComposite(possible, newPossible)}/>}
        </div>
    }

    createReportFalse = (concept: any) => {
        return () => {
            const literals = concept.literals
                .filter(l => !l.composite)
                .map(l => l.name);

            this.props.reportFalse({ literals })
                .then(() => {
                    PossibleConceptsActions.removeFromQueue(concept);
                });
        }
    }

    renderRowFormAdditionalButtons = (form: EditConceptForm, concept: any) => {
        if (this.props.reportFalse)
            return <Button onClick={this.createReportFalse(concept)}>Report false</Button>
        return null;
    }

    renderRow(concept: LocalPossibleConceptType, index: any, items: any, filterInCell: {[string]: boolean}) {
        const hideBasicNeighbors = !!filterInCell.neighbors;
        return <ConceptRow concept={concept}
                           isCurrent={this.state.currentIndex === concept._id}
                           formRef={this.setForm}
                           onToggleRow={this.handleToggleRow}
                           showDfCollection={this.props.showDfCollection}
                           showTf={this.props.showTf}
                           hideBasicNeighbors={hideBasicNeighbors}
                           submit={this.props.submit}
                           client={this.props.client}
                           renderFormAdditionalButtons={this.renderRowFormAdditionalButtons}
                           onShowForm={this.createShowForm}
                           onFormEdit={this.updateConceptFromForm}
                           onFormError={this.handleFormError}
                           onFormSubmit={this.handleConceptCreated}
                           onRenderActions={this.renderActions}
                           onSelectConcept={this.handleSelect}/>;
    }

    renderRow = this.renderRow.bind(this);

    onHideResult = (names: Array<string>) => {
        if (!this.state.possibleConcepts) {
            return;
        }

        const hiddenConcepts = this.state.possibleConcepts.filter(c => names.indexOf(c.name) !== -1);
        const ids = this.getIndex2Id();

        if (this.props.showHidden) {
            hiddenConcepts.forEach(c => {
                const index = ids.indexOf(c._id);
                PossibleConceptsActions.changeQueueItem(index, {
                    ...c,
                    hidden: true,
                });
            });
        } else {
            hiddenConcepts.forEach(c => PossibleConceptsActions.removeFromQueue(c));
        }

        this.setState({ hiddenCount: this.state.hiddenCount + names.length })
    }

    onUnhideResult = (names: Array<string>) => {
        const { possibleConcepts } = this.state;

        if (!possibleConcepts) return;

        const ids = this.getIndex2Id();

        possibleConcepts.filter(c => names.indexOf(c.name) !== -1).forEach(c => {
            const index = ids.indexOf(c._id);
            PossibleConceptsActions.changeQueueItem(index, {
                ...c,
                hidden: false,
            });
        });

        this.setState({ hiddenCount: this.state.hiddenCount - names.length });
    }

    renderToggleShowHidden() {
        if (!this.props.batch.id) {
            return null;
        }

        let buttonText = null;
        if (this.state.hiddenCount === 0) {
            return <span className="hide-link">No hidden concepts</span>
        } else if (this.props.showHidden) {
            buttonText = `Hide ${this.state.hiddenCount} hidden`;
        } else {
            buttonText = `Show ${this.state.hiddenCount} hidden`;
        }

        const link = `/ontology/possible-concepts/${this.props.batch.id}?showHidden=${!this.props.showHidden}`;

        return <a href={link} className="hide-link">
            {buttonText}
        </a>
    }

    renderMassButtons() {
        if (!this.state.possibleConcepts) {
            return null;
        }

        const disabled = this.state.possibleConcepts.every(c => !c.selected);
        const selected = this.getSelected();
        const toHideNames = selected.filter(c => !c.hidden).map(c => c.name);
        const toUnhideNames = selected.filter(c => c.hidden).map(c => c.name);

        return <div className="mass-actions">
            <Button onClick={this.handleSubmit} disabled={disabled}>
                <span className="icon">
                    <Glyphicon glyph="edit"/>
                </span> Create selected
            </Button>
            <Button onClick={this.handleMergeConcepts} disabled={disabled}>
                <span className="icon">
                    <img src={mergeIcon} className="merge-icon" alt="merge concepts"/>
                </span> Merge concepts
            </Button>
            {this.props.currentUser.isSuperuser && this.props.reportFalse &&
                <Button onClick={this.handleMassReportFalse} disabled={disabled}>
                    <span className="icon">
                        <Glyphicon glyph="remove"/>
                    </span> Report False
                </Button>}
            {this.props.batch.id && <Fragment>
                <HideOrUnhideDocsBatchPossibleConcept mode={HideOrUnhideMode.hide}
                                                      docsBatchId={this.props.batch.id}
                                                      names={toHideNames}
                                                      onResult={this.onHideResult}/>
                {this.props.showHidden &&
                    <HideOrUnhideDocsBatchPossibleConcept mode={HideOrUnhideMode.unhide}
                                                          docsBatchId={this.props.batch.id}
                                                          names={toUnhideNames}
                                                          onResult={this.onUnhideResult}/>}
            </Fragment>}
        </div>
    }

    render() {
        const { possibleConcepts } = this.state;

        const selectedCount = this.getSelected().length;
        const src = this.props.batch.source.indexOf(document.location.origin) === 0 ?
            this.props.batch.source.slice(document.location.origin.length)
            : this.props.batch.source;

        let columns = this.PossibleConceptColumns;
        if (!this.props.showDfCollection) {
            columns = columns.filter(column => column.field !== 'dfCollection');
        }
        if (!this.props.showTf) {
            columns = columns.filter(column => column.field !== 'tf');
        }

        return <Row className="possible-concepts-list">
            <Col xs={12}>
                <Row className="title-row">
                    <Col xs={9}><h1>Possible concepts <small>{src}</small></h1></Col>
                    <Col xs={3}>{this.renderToggleShowHidden()}</Col>
                </Row>

                {this.state.creatingError && <Alert bsStyle="danger">
                    There was an error creating concepts. Check red rows below.
                </Alert>}
                {/*
                to get over the problem when SortableTable gets initialized with empty dataset
                (after <DocsBatchDetails/> initial render and before its componentDidMount())
                (including its "frozen" state), we postpone rendering to point in time
                when possibleConcepts are settled in the store and are mapped to state component
                */}
                {possibleConcepts && <Fragment>
                    {this.renderMassButtons()}
                    <SortableTable
                        className="table-solid-bg table-hover table-sticky-head"
                        animation={this.DEFAULT_ANIMATION}
                        pagination={this.DEFAULT_PAGINATION}
                        data={possibleConcepts}
                        editable={true}
                        renderRow={this.renderRow}
                        columns={columns}
                        onSelect={this.handleSelect}
                    />
                    {!!selectedCount && <Affix affixClassName="affixed"
                                               bottomClassName="affixed-bottom"
                                               viewportOffsetTop={window.innerHeight - 49}
                                               offsetBottom={49}>
                        <div className="has-selected">
                            <div className="container">
                                {this.renderMassButtons()}
                            </div>
                        </div>
                    </Affix>}
                </Fragment>
                }
                <ToTheTop />
            </Col>
            <Modal show={this.state.creating} className="modal-creating-concepts">
                <Modal.Body>
                    <Loading loading={this.state.created !== selectedCount}
                             loadingString="Saving"
                             what="concepts"
                             error={this.state.creatingError}>
                        <h3 className="text-success">Done!</h3>
                    </Loading>
                    <h4 className={cx({
                        'text-success': this.state.created === selectedCount,
                        'text-danger': this.state.creatingError
                    })}>
                        {this.state.created} / {selectedCount}
                    </h4>
                    <ProgressBar
                        striped
                        bsStyle="success"
                        now={100 * this.state.created / selectedCount}
                    />
                </Modal.Body>
            </Modal>
        </Row>;
    }
}

function DocsBatchDetails(
    { data: { loading, docsBatch, error }, submit, reportFalse, currentUser, client, history, showHidden }:
    {
        data: {
            loading: boolean, docsBatch: Array<any>, error: any
        },
        submit: () => any,
        reportFalse: () => any,
        currentUser: UserType,
        client: ApolloClient,
        history: any,
        showHidden: boolean,
    }
) {
    // remember that <DocsBatchDetails/> is called from <OntologyPossibleConceptsPage/>
    return <Loading what="possible concepts" error={error} loading={loading}>
        <LoadedDocsBatchDetails
            batch={docsBatch}
            showDfCollection={true}
            showTf={false}
            submit={submit}
            client={client}
            currentUser={currentUser}
            reportFalse={reportFalse}
            history={history}
            showHidden={showHidden}
        />
    </Loading>;
}


const queryDocsBatch = gql`
query docsBatch ($id: Int!, $showHidden: Boolean!) {
    docsBatch(id: $id, showHidden: $showHidden) {
        id
        status
        source
        hiddenCount
        possibleConcepts {
            name
            score
            hidden
            literals {
                name
                isAmbiguous
                similarLiteralsConcepts {
                    ...similarConceptProps
                }
                lexicalNeighborsConcepts {
                    ...similarConceptProps
                }
                df
                dfCollection
                wikiLink
                wikiAmbiguous
                wikiVariants {
                    ...wikipediaLiteral
                }
            }
        }
    }
}

${SimilarConceptPropsFragment}
${wikipediaLiteralFragment}

`;

const mutationBanLiterals = gql`
mutation banPossibleLiterals($literals: [String]) {
    banPossibleLiterals(literals: $literals) {
        ok
    }
}
`;

const fetchLiteralsDfs = gql`
query fetchLiteralsDfs($literals: [LiteralInput]!) {
    literalsDfs(literals: $literals) {
        perLiteral {
            searchQuery
            df
        }
        total {
            searchQuery
            df
        }
    }
}
`;

export default withCurrentUser(withApollo(compose(
    graphql(queryDocsBatch, {
        options: ({ id, showHidden }) => ({
            variables: { id, showHidden },
            fetchPolicy: 'network-only',
        })
    }),
    graphql(mutationBanLiterals, {
        props: ({ mutate, ownProps }) => ({
            reportFalse: ({ literals }) =>
                mutate({
                    variables: { literals },
                    refetchQueries: [{
                        query: queryDocsBatch,
                        variables: { id: ownProps.id, showHidden: ownProps.showHidden },
                    }]
                })
        }),
    }),
    graphql(mutateConcept, {
        props: ({ mutate, ownProps }) => ({
            submit: ({id, name, literals, basic, deleted}) =>
                mutate({
                    variables: {id, name, literals, basic, deleted},
                    refetchQueries: [{
                        query: queryDocsBatch,
                        variables: { id: ownProps.id, showHidden: ownProps.showHidden },
                    }]
                }),
        }),
    })
)(DocsBatchDetails)));
