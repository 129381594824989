import { sendRequest } from '../../datastore';


export const makePanelMembersUrl = (panelId, compositionId, kind, qs, offset) => {
    let url = `/api/panel/${panelId}/composition/${compositionId}/${kind}${qs}`;
    if (offset) {
        if (qs) {
            url += `&offset=${offset}`;
        } else {
            url += `?offset=${offset}`;
        }
    }
    return url;
}


export const makePanelMembersKey = (panelId, compositionId, qs) => {
    const base = `members-panel-${panelId}-composition-${compositionId}`;
    const key = `${base}-qs-${qs}`;

    return key;
};

export const makePanelMemberCandidatesKey = (panelId, compositionId, qs, offset) => {
    const base = `candidates-panel-${panelId}-composition-${compositionId}`;
    const key = `${base}-qs-${qs}${offset ? `-offset-${offset}` : ''}`;

    return key;
};

export const makePanelKey = (panelId, compositionId) => `panel-${panelId}-composition-${compositionId}`;
export const makeSubpanelDocumentsKey = (panelId, subpanelCode) => (
    `panel-${panelId}-subpanel-${subpanelCode}-documents`);


export const makeLoadMoreCandidatesQuery = (panelId, compositionId, qs, offset) => {
    const key = makePanelMemberCandidatesKey(panelId, compositionId, qs, offset);
    return {
        id: key,
        get: key,
        set: function (store, { value: { hasMore, scoredPanelMembers } }) {
            const storeKey = makePanelMemberCandidatesKey(panelId, compositionId, qs);

            store[storeKey] = {
                ...store[storeKey],
                hasMore,
                scoredPanelMembers: store[storeKey].scoredPanelMembers.concat(scoredPanelMembers),
            }
        },
        req: () => {
            const url = makePanelMembersUrl(panelId, compositionId, 'candidates', qs, offset);
            return sendRequest(url);
        }
    }
};

export const makeSubpanelDocumentsQuery = (panelId, subpanelCode) => {
    const key = makeSubpanelDocumentsKey(panelId, subpanelCode);

    return {
        id: key,
        get: key,
        set: function (store, { value: { documents }}) {
            store[key] = documents;
        },
        req: () => sendRequest(`/api/panel/${panelId}/subpanel/${subpanelCode}/documents`),
    };
};
