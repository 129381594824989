import React from 'react';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';

const AuthorFromGroupList = ({ list }) => {
    return !!list && list.map(afg =>
        <div key={afg.groupId}><a href={`/authors-groups/edit-found-author/${afg.id}/`}
            target="blank"
            className={`label ag-badge ag-badge-color-${afg.groupIndex}`}
            title={afg.groupDisplayName}>
            <Glyphicon glyph="user"/>&nbsp;
            {afg.groupLabel || afg.groupDisplayName}
        </a></div>
    );
};

export default AuthorFromGroupList;
