import Reflux from 'reflux';

import type { ConceptItemType } from '../ontologies/Types';
import type { TreeNodeType } from './AdvancedRecommendation';
import type { RecommendationTopicType } from './RecommendationTopic';
import { filterTree } from '../common/helpers/recommendationTopics';
import { pick } from '../utils';


export const ChosenRecommendationTopicsActions = Reflux.createActions([
    'setTopics', 'addTopic', 'deleteTopic', 'toggleTopic',
    'addConceptToTopic', 'removeConceptFromTopic', 'setTopicTitle', 'moveConcept',
    'setTree', 'setUseTree', 'setRecommendation',
    'resetRecommendation', 'markTopicRequired', 'resetTopicFlags',
    'selectConceptInTopic', 'setOrigRecommendation', 'setDate',
]);


export class ChosenRecommendationTopicsStore extends Reflux.Store {
    defaultState = {
        topics: [
            { title: 'Topic 0', concepts: [] }
        ],
        topicIndices: {},
        tree: { condition: 'OR', children: [{ topicId: 0 }] },
        date: 'month',
        useTree: false,
    };

    constructor() {
        super();

        this.state = {
            ...this.defaultState,
            origRecommendation: pick(['topics', 'tree', 'useTree', 'date'], this.defaultState)
        };
        this.listenables = ChosenRecommendationTopicsActions;
    }

    _isFlatORTree(tree: TreeNodeType) {
        return tree.topicId !== undefined || (
            tree.condition === 'OR' &&
            tree.children &&
            // Flow required refinement here but it's not mandatory -
            // we allow only trees with leaf nodes anyway
            // $FlowFixMe
            tree.children.every(c => c.topicId !== undefined)
        );
    }

    _calculateTopicIndices(topics: Array<RecommendationTopicType>) {
        topics = topics || this.state.topics;

        return topics.reduce(
            (indices, topic, i) => topic.concepts
                .reduce((indices, c) => ({
                    ...indices,
                    [c.conceptId]: [ ...(indices[c.conceptId] || []), i ]
                }), indices),
            {}
        );
    }

    /**
     * @private
     */
    getTopicsNorm(topics: Array<RecommendationTopicType>): Array<RecommendationTopicType> {
        return topics.slice().map(t => ({
            title: '',
            disabled: false,
            concepts: [],
            ...t
        }));
    }

    setTopics(topics: Array<RecommendationTopicType>) {
        topics = this.getTopicsNorm(topics);

        this.setState({
            topics,
            topicIndices: this._calculateTopicIndices(topics),
        });
    }

    addTopic(topic: ?RecommendationTopicType) {
        topic = {
            title: `Topic ${this.state.topics.length}`,
            concepts: [],
            ...topic
        };

        while (this.state.topics.some(t => t.title === topic.title)) {
            topic.title += ' copy';
        }

        const topics = this.state.topics.concat(topic);
        const partialState = { topics };

        if (topic.concepts.length) {
            partialState.topicIndices = this._calculateTopicIndices(topics);
        }

        if (this.state.tree && this._isFlatORTree(this.state.tree)) {
            const children = this.state.tree.children.slice();
            children.push({ topicId: topics.length - 1 });

            partialState.tree = {
                ...this.state.tree,
                children
            };
        }

        this.setState(partialState);
    }

    deleteTopic(topicIndex: number) {
        const topics = this.state.topics.slice();
        topics.splice(topicIndex, 1);

        this.setState({
            topics,
            tree: filterTree(this.state.tree, this.state.topics, (t, i) => (
                i !== topicIndex
            )),
            ...(this.state.topics[topicIndex].concepts.length ? this._calculateTopicIndices(topics) : {}),
        });
    }

    toggleTopic(topicIndex: number, toggle: boolean) {
        const topics = this.state.topics.slice();
        const topic = { ...topics[topicIndex], disabled: toggle };
        delete topic.required;

        topics.splice(topicIndex, 1, topic);

        this.setState({ topics });
    }

    markTopicRequired(topicIndex: number, toggle: boolean) {
        const topics = this.state.topics.slice();
        const topic = { ...topics[topicIndex], required: toggle };

        delete topic.disabled;

        topics.splice(topicIndex, 1, topic);

        this.setState({ topics });
    }

    resetTopicFlags(topicIndex: number) {
        const topics = this.state.topics.slice();
        const topic = { ...topics[topicIndex] };
        delete topic.required;
        delete topic.disabled;

        topics.splice(topicIndex, 1, topic);

        this.setState({ topics });
    }

    addConceptToTopic(concept: ConceptItemType, topicIndex: number) {
        const topics = this.state.topics.slice();

        // do not add duplicates
        if (!topics[topicIndex].concepts.some(c => c.conceptId === concept.conceptId)) {
            const concepts = topics[topicIndex].concepts.slice();
            const conceptObj = {
                conceptId: concept.conceptId,
                name: concept.name
            };

            concepts.push(conceptObj);

            topics[topicIndex] = {
                ...topics[topicIndex],
                concepts
            };

            this.setState({
                topics,
                topicIndices: this._calculateTopicIndices(topics),
            });
        }
    }

    setTopicTitle(topicIndex: number, title: string) {
        const topics = this.state.topics.slice();
        topics[topicIndex] = { ...topics[topicIndex], title };

        this.setState({ topics });
    }

    /**
     * Removes concept from topic
     *
     * @param conceptId number
     * @param topicIndex number Index of topic we remove from
     */
    removeConceptFromTopic(conceptId: number | Array<number>, topicIndex: number) {
        const topics = this.state.topics.slice();
        const conceptIds = typeof conceptId === 'string' || typeof conceptId === 'number' ?
            [conceptId]
            : conceptId;

        topics[topicIndex] = {
            ...topics[topicIndex],
            concepts: topics[topicIndex].concepts
                .filter(c => conceptIds.indexOf(c.conceptId) === -1)
        };

        this.setState({
            topics,
            topicIndices: this._calculateTopicIndices(topics)
        });
    }

    moveConcept(
        conceptId: number | string | Array<number | string>, fromIndex: number, toIndex: number
    ) {
        const topics = this.state.topics.slice();
        const conceptIds = typeof conceptId === 'number' || typeof conceptId === 'string' ?
            [conceptId]
            : conceptId;

        const fromTopicConcepts = this.state.topics[fromIndex].concepts
            .filter(c => conceptIds.indexOf(parseInt(c.conceptId, 10)) !== -1);

        topics[fromIndex] = {
            ...topics[fromIndex],
            concepts: topics[fromIndex].concepts
                .filter(c => conceptIds.indexOf(parseInt(c.conceptId, 10)) === -1)
        };

        const conceptsTo = topics[toIndex].concepts.slice();

        conceptsTo.splice(
            conceptsTo.length,
            0,
            ...fromTopicConcepts
                .filter(c => !conceptsTo.some(tc => parseInt(tc.conceptId, 10) === parseInt(c.conceptId, 10)))
        );

        topics[toIndex] = {
            ...topics[toIndex],
            concepts: conceptsTo
        };

        this.setState({
            topics,
            topicIndices: this._calculateTopicIndices(topics),
        });
    }

    selectConceptInTopic(conceptId: number, topicIndex: number, toggle: boolean) {
        const topics = this.state.topics.slice();

        topics[topicIndex] = {
            ...topics[topicIndex],
            concepts: topics[topicIndex].concepts.map(c => (
                c.conceptId !== conceptId ?
                    c
                    : { ...c, selected: toggle }
            ))
        }

        this.setState({ topics });
    }

    setDate(date: ?string) {
        this.setState({ date });
    }

    setTree(tree: TreeNodeType) {
        this.setState({ ...this.state, tree });
    }

    setUseTree(useTree: boolean) {
        this.setState({ useTree });
    }

    setRecommendation(
        topics: Array<RecommendationTopicType>,
        useTree: boolean,
        tree: ?TreeNodeType,
        date: ?string,
    ) {
        tree = tree || {
            condition: 'OR',
            children: topics.map((t, i) => ({ topicId: i }))
        };

        topics = this.getTopicsNorm(topics);

        this.setState({
            ...this.state,
            topics,
            tree,
            useTree,
            date,
            topicIndices: this._calculateTopicIndices(topics),
            origRecommendation: {
                topics,
                tree,
                useTree,
                date,
            },
        });
    }

    setOrigRecommendation(recommendation: any) {
        this.setState({ origRecommendation: recommendation });
    }

    resetRecommendation() {
        this.setState({
            ...this.defaultState,
            origRecommendation: pick(['topics', 'tree', 'useTree', 'date'], this.defaultState)
        });
    }
}
