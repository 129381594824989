import React from 'react';

import Button from 'react-bootstrap/lib/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Fade from 'react-bootstrap/lib/Fade';

import '../css/to-the-top.css';


type ToTheTopPropsType = {
    minTop: number
};

class ToTheTop extends React.PureComponent<ToTheTopPropsType, { show: boolean }> {
    static defaultProps = {
        minTop: 300
    };

    state = {
        show: false
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const shouldBeShown = window.scrollY >= this.props.minTop;

        if (shouldBeShown !== this.state.show) {
            this.setState({ show: shouldBeShown });
        }
    };

    handleClick() {
        window.scrollTo(0, 0);
    }

    render() {
        return <Fade in={this.state.show}>
            <Button className="to-the-top"
                    onClick={this.handleClick}
                    title="Click to scroll to the top">
                <Glyphicon glyph="chevron-up"/>
            </Button>
        </Fade>;
    }
}

export default ToTheTop;
