import React from 'react';

import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Modal from 'react-bootstrap/lib/Modal';
import Panel from 'react-bootstrap/lib/Panel';

import { useTrack, sendRequest, updateGlobalData } from '../datastore';
import withAuthorization from '../common/withAuthorization';
import withCurrentUser from '../common/withCurrentUser';
import LoadingREST from '../LoadingREST';
import SortableTable from '../widgets/SortableTable';
import FileUpload from '../widgets/FileUpload';
import { makeFileExchangeListQuery, makeFileExchangeKey } from './Data';
import DownloadFileButton from '../widgets/DownloadFileButton';
import { KEYS } from '../utils';
import type { FileExchangeType, UploadFileResultType } from './Types';

import './file-exchange.css';


const COLUMNS = [
    {
        field: 'id',
        text: '#',
        className: 'id',
        key: true
    },
    {
        field: 'filename',
        text: 'Filename',
        sortable: true
    },
    {
        field: 'size',
        text: 'Size',
        sortable: true
    },
    {
        field: 'dateCreated',
        text: 'Date',
        sortable: true
    },
    {
        field: 'comment',
        text: 'Comment',
        sortable: true
    },
    {
        field: '*editComment*',
        text: '',
    },
];


type FileExchangeRowPropsType = {
    fileExchange: FileExchangeType,
    index: number,
    recent: bool,
    submitComment: (id: string, comment: ?string) => any,
}


type FileExchangeRowStateType = {
    showCommentModal: bool,
    comment: ?string,
}


function getHumanReadableFileSize(fileSizeInBytes) {
    let unitIndex = 0;
    const byteUnits = [' B', ' KB', ' MB', ' GB'];
    while (fileSizeInBytes > 1024) {
        fileSizeInBytes = fileSizeInBytes / 1024;
        unitIndex++;
    }

    return fileSizeInBytes.toFixed(0) + byteUnits[unitIndex];
};


class FileExchangeRow extends React.PureComponent<
    FileExchangeRowPropsType,
    FileExchangeRowStateType
> {
    state = {
        showCommentModal: false,
        comment: this.props.fileExchange.comment,
    }

    openModal = () => {
        this.setState({ showCommentModal: true });
    };

    closeModal = () => {
        this.setState({ showCommentModal: false });
    }

    handleCommentChange = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ comment: e.currentTarget.value });
    }

    handleReasonKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
        if (e.which === KEYS.ENTER) {
            // prevent ENTER propagation to "edit comment" button in table
            e.preventDefault();
            this.submitComment();
        }
    }

    submitComment = () => {
        if (this.state.comment !== this.props.fileExchange.comment) {
            this.props.submitComment(this.props.fileExchange.id, this.state.comment);
        }
        this.closeModal();
    }

    renderModal() {
        return <Modal show={this.state.showCommentModal}
               onHide={this.closeModal}
               animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Edit comment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup>
                    <FormControl
                        type="text"
                        value={this.state.comment || ''}
                        placeholder="Comment"
                        onChange={this.handleCommentChange}
                        onKeyDown={this.handleReasonKeyDown}
                        autoFocus />
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.closeModal}>Cancel</Button>
                <Button bsStyle="primary" onClick={this.submitComment}>Save</Button>
            </Modal.Footer>
        </Modal>
    }

    render() {
        const fileExchange = this.props.fileExchange;
        const className = this.props.recent ? 'warning' : null;

        return <React.Fragment>
            <tr className={className}>
                <td>{this.props.index + 1}</td>
                <td>
                    <DownloadFileButton
                        to={`/api/file-exchange/download/${fileExchange.id}`}
                        filename={fileExchange.filename}>
                        {fileExchange.filename}
                    </DownloadFileButton>
                </td>
                <td>
                    {getHumanReadableFileSize(fileExchange.size)}
                </td>
                <td>
                    {fileExchange.dateCreated}
                </td>
                <td>
                    {fileExchange.comment}
                </td>
                <td>
                    <button className="inline-link" onClick={this.openModal}>edit comment</button>
                </td>
            </tr>
            {this.renderModal()}
        </React.Fragment>;
    }
}


type LoadedFileExchangeListPropsType = {
    fileExchangeList: Array<FileExchangeType>
}


type LoadedFileExchangeListStateType = {
    error: ?string,
    recentIds: Array<string>,
}


class LoadedFileExchangeList extends React.Component<
    LoadedFileExchangeListPropsType,
    LoadedFileExchangeListStateType,
> {
    state = {
        error: null,
        recentIds: [],
    };


    renderRow = (fileExchange: FileExchangeType, i: number) => {
        const recent = this.state.recentIds.indexOf(fileExchange.id) !== -1;
        return <FileExchangeRow key={fileExchange.id}
                                recent={recent}
                                fileExchange={fileExchange}
                                index={i}
                                submitComment={this.submitComment}/>
    };

    submitComment = (id: string, comment: ?string) => {
        const body = JSON.stringify({ id, comment });
            sendRequest(`/api/file-exchange/submit-comment/`,
                        { method: 'POST', body })
            .then(({ok, value}) => {
                if (ok) {
                    const fileExchange = value.fileExchange;
                    this.addRecentId(fileExchange.id);

                    updateGlobalData(
                        (store, newFileExchange) => {
                            const key = makeFileExchangeKey();
                            store[key] = store[key].map(fileExchange => {
                                if (fileExchange.id === newFileExchange.id) {
                                    return newFileExchange;
                                }
                                return fileExchange;
                            });
                        },
                        fileExchange,
                    );
                }
            });
    }

    uploadFile(file: File) {
        const data = new FormData();
        data.append('file', file);

        return fetch('/api/file-exchange/upload/', {
            method: 'POST',
            body: data,
            credentials: 'same-origin',
        })
    }

    addRecentId(id: string) {
        this.setState({ recentIds: this.state.recentIds.concat(id) })
    }

    afterUploadFile = (result: UploadFileResultType) => {
        if (!result.ok) {
            this.setState({ error: result.error });
            return;
        }

        const fileExchange = result.fileExchange;

        updateGlobalData(
            (store, newFileExchange) => {
                const key = makeFileExchangeKey();
                store[key] = [newFileExchange].concat(store[key]);
            },
            fileExchange,
        );

        this.addRecentId(fileExchange.id);
    };

    render() {
        return <div className="file-exchange-page">
            {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
            <FileUpload className="full-width" uploadFn={this.uploadFile} callback={this.afterUploadFile} />
            <Panel className="panel-table-container">
                <Panel.Body>
                    {this.props.fileExchangeList.length ?
                     <SortableTable
                         className="file-exchange-table table-striped"
                         columns={COLUMNS}
                         renderRow={this.renderRow}
                         data={this.props.fileExchangeList} />
                     : <Alert bsStyle="info">No files yet</Alert>}
                </Panel.Body>
            </Panel>
        </div>;
    }
}


function FileExchangePage() {
    const [fileExchangeList, reqstate] = useTrack(makeFileExchangeListQuery());

    return <div>
        <LoadingREST reqstate={reqstate} doc={fileExchangeList}>
            <LoadedFileExchangeList fileExchangeList={fileExchangeList} />
        </LoadingREST>
    </div>
}

export default withAuthorization(withCurrentUser(FileExchangePage));
