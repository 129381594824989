import React from 'react';

import ArticleLink from '../articles/ArticleLink';
import AuthorLink from '../authors/AuthorLink';
import type { AuthorType, CoauthoredArticle as CoauthoredArticleType } from './Types';
import type { ArticleShortType } from '../articles/Article';
import { checkStatus, interpose } from '../utils';
import OverlayTrigger from '../widgets/OverlayTrigger';
import Popover from '../widgets/Popover';
import Loading from '../Loading';
import OpenAccess from '../widgets/OpenAccess';


const currentYear = (new Date()).getFullYear();


function recentYear(year: ?number) {
    if (year) {
        return currentYear - year < 3 ?
            <strong title="Recent article">{year}</strong>
            : year;
    }
}

function Coauthored({coauthored}: {coauthored: Array<AuthorType>}) {
    if (coauthored.length === 0) {
        return null;
    }

    const coauthors = coauthored.map(coauthor =>
        <AuthorLink key={coauthor.id}
                    id={coauthor.id}
                    name={coauthor.name}
                    target="_blank"/>
    );

    return <span> with {interpose(coauthors, ', ')}</span>;
}

export default function RelevantArticle(
    { article, authorId }
    : {article: ArticleShortType, authorId: number}
) {
    const popoverTitle = <ArticleLink {...article} target="_blank" />;
    const popover = <Popover id={`art-${article.id}-popover`}
                             title={popoverTitle}
                             className="article-popover">
        <ArticlePopoverContent article={article} authorId={authorId} />
    </Popover>;

    return <li key={article.id}>
        {recentYear(article.year)}
        <OpenAccess openAccess={article.openAccess} />
        &nbsp;
        <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={popover} >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a style={{cursor: "pointer"}}>{article.title}</a>
        </OverlayTrigger>
    </li>;
}

export function CoauthoredArticle(
    { article }
    : { article: CoauthoredArticleType }
) {
    return <li key={article.id}>
        {recentYear(article.article.year)}
        {article.openAccess}
        &nbsp;
        <ArticleLink {...article.article} />
        <Coauthored coauthored={article.coauthored} />
    </li>;
}


function AuthorsCountedMore({ authors, authorId }) {
    // we want to display first three authors, "...N more..." and last author
    // we always want to display current author (if there is one)
    const authorsOrCounter = authors.reduce((acc, author, i) => {
        if (i < 3 || i === authors.length - 1
            || (authorId && author.authorId === authorId)) {
            acc.push(author);
        } else if (author.isLeading || author.isSenior || author.isCorresponding) {
            acc.push(author);
        } else if (Number.isInteger(acc[acc.length - 1])) {
            acc[acc.length - 1] += 1;
        } else {
            acc.push(1);
        }
        return acc;
    }, []);

    // we want to get rid of stupid case "1 more" and display an author instead
    let i = 0;
    const authorEls = authorsOrCounter.map((author) => {
        if (Number.isInteger(author)) {
            if (author !== 1) {
                i += author;
                return <span key={i}>{author} more authors...</span>;
            } else {
                author = authors[i];
            }
        }
        i += 1;
        const authorPositions = [];
        let positionsElement = null;
        if (author.isLeading) {
            authorPositions.push('leading')
        }
        if (author.isSenior) {
            authorPositions.push('senior')
        }
        if (author.isCorresponding) {
            authorPositions.push('corr.')
        }

        if (authorPositions.length > 0) {
            positionsElement = <span>&nbsp;({authorPositions.join(', ')})</span>
        }

        let authorel = <React.Fragment key={author.authorId}>
            <AuthorLink id={author.authorId}
                        name={author.name}
                        target="_blank"/>
                {positionsElement}
        </React.Fragment>;
        // highlight current author with bold
        if (authorId && author.authorId === authorId) {
            authorel = <strong>{authorel}</strong>;
        }
        return authorel;
    });

    return <dd>{interpose(authorEls, ', ')}</dd>;
}


class ArticlePopoverContent extends React.Component<{
    article: any,
    authorId: number,
}> {
    state = {
        loadingPromise: null,
        article: null,
        error: null,
    };

    componentDidMount() {
        const url = `/api/articles/${this.props.article.id}/for-popover`;
        const loadingPromise = fetch(url, {
            credentials: 'same-origin',
        })
            .then(checkStatus)
            .then(response => response.json())
            .then(json => {
                this.setState({
                    loadingPromise: null,
                    article: json.article,
                });
            }).catch(error => {
                console.error(`Error loading article details: ${error}`);
                this.setState({ loadingPromise: null, error });
            });
        this.setState({ loadingPromise });
    }

    render() {
        const { article, loadingPromise, error } = this.state;
        if (loadingPromise) {
            return <Loading what="article details" loading={!!loadingPromise}/>;
        }
        if (error || !article) {
            return <p>{"" + error}</p>;
        }

        const j = article.journal;
        const details = [];
        if (j && j.volume) {
            details.push(`vol. ${j.volume}`);
        }
        if (j && j.issue) {
            details.push(`issue ${j.issue}`);
        }
        if (j && j.pages) {
            details.push(`pp. ${j.pages}`);
        }
        if (details.length === 0 && j && j.preprint) {
            details.push('preprint');
        }

        return <dl className="dl-horizontal">
            {j && j.title && <>
                <dt>Journal</dt>
                <dd>{!j.journalId ? j.title :
                    <a href={`/search/?q=j:${j.journalId}`}>{j.title}</a>
                }
                {!j.impactFactor ? '' : <> (impact&nbsp;factor:&nbsp;{j.impactFactor})</>}
                </dd>
            </>}
            <dt>Publication date</dt>
            <dd>{article.year}</dd>
            {details.length > 0 && <>
                <dt>Publication details</dt><dd>{interpose(details, ', ')}</dd>
            </>}
            {article.manuscriptType && <>
                <dt>Manuscript type</dt>
                <dd>{article.manuscriptType}</dd>
            </>}
            <dt>Authors</dt>
            <AuthorsCountedMore authors={article.authors}
                                authorId={this.props.authorId}/>
            {article.citationsCount > 0 && <>
                <dt>Citations</dt>
                <dd>{article.citationsCount}</dd>
            </>}
        </dl>;
    }
}
