import React, { Fragment } from 'react';

import DocumentTitle from 'react-document-title';

import type { FindClaimArticleType } from '../articles/Types';
import withAuthorization from '../common/withAuthorization';
import type { ReactRouterHistoryType, ReactRouterMatchType } from '../common/Types';
import type { JobType } from './Types';
import { LoadingJob } from './Job';
import { LoadingApplication } from './Application';
import JobApplicationForm from './JobApplicationForm';


export function EditApplicationPage(
    { match, history }
    : {
        match: ReactRouterMatchType,
        history: ReactRouterHistoryType
    }
) {
    return <LoadingJob id={match.params.id}>
        {(job: JobType<FindClaimArticleType>) => {
            return <Fragment>
                {job && <DocumentTitle title={`${job.title} - Apply - Jobs - Prophy`}/>}
                {match.params.applicationId ?
                    <LoadingApplication id={match.params.applicationId}>
                        {application => <JobApplicationForm
                            job={job}
                            history={history}
                            { ...application } />
                        }
                    </LoadingApplication>
                    : <JobApplicationForm job={job} history={history} />
                }
            </Fragment>;
        }}
    </LoadingJob>;
}

export default withAuthorization(EditApplicationPage);
