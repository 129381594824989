import React from 'react';

import { sendRequest, updateGlobalData } from '../datastore';
import {
    wipeJobCache, addTag as addJobTag, postTag as postJobTag
} from './JobData';
import refereesHelpers from '../referees/helpers';

export const requirementsOptions = [
    {
        label: 'CV',
        id: 'cv',
        fieldType: 'file',
        alwaysRequired: true
    },
    {
        label: 'research statement',
        id: 'researchStatement',
        fieldType: 'file'
    },
    {
        label: 'teaching statement',
        id: 'teachingStatement',
        fieldType: 'file'
    },
    {
        label: 'cover letter',
        id: 'coverLetter',
        fieldType: 'file'
    },
    {
        label: 'publication list (*.docx, *.pdf, etc.)',
        helpText: 'applicants will have electronic publications list, so check only if you really need it',
        id: 'publicationList',
        fieldType: 'file'
    },
];

export const voteLabels = {
    short_list: {
        label: 'Short list',
        boost: '+2',
        glyph: 'star',
    },
    long_list: {
        label: 'Long list',
        boost: '+1',
        glyph: 'star-empty',
    },
    seen: {
        label: 'Seen',
        boost: '0',
        glyph: 'ok',
    },
    reject: {
        label: 'Reject',
        boost: '-10',
        glyph: 'ban-circle',
    }
};


export function PublishClarification({id, title, affiliation}) {
    const displayedId = id ? `[${id}]` : '';
    const body = (id && title && affiliation) ?
        `Vacancy${displayedId} ${title} at ${affiliation} missing payment request.`
        : 'Vacancy missing payment request.';

    return <>
        <p>
            The vacancy will become listed and available for applications immediately.
        </p>
        <p>
            We will email you a payment request
            of <strong>$250</strong> within the next 24 hours.
        </p>
        <p>
            If you don&apos;t receive it, please check your &quot;Spam&quot; folder or
            write us an email:{' '}
            <a href={`mailto:jobs@prophy.ai?subject=Vacancy${displayedId} missing payment request` +
                     `&body=${body}`}>
                jobs@prophy.ai
            </a>
        </p>
    </>
}


export default {
    passedDeadline(deadlineStr: string) {
        return new Date() > new Date(
            ...deadlineStr.split('-').map(n => parseInt(n, 10)).map((n, i) => i === 1 ? n - 1 : n),
            23, 59, 59
        );
    },
    summarizeVotes(applicant) {
        return applicant.votes
            .reduce((sum, vote) => sum + parseInt(voteLabels[vote.voteType].boost, 10), 0);
    },
    postTag(tag: TagType, jobId: number) {
        return sendRequest('/api/jobs/vacancy-tag/', {
            method: 'post',
            body: JSON.stringify({ ...tag, vacancyId: jobId })
        }).then(response => {
            if (response.ok) {
                updateGlobalData(postJobTag, {
                    vacancyId: jobId,
                    tagId: response.value.id,
                    tag
                });
            } else {
                throw new Error(response.value.error);
            }

            return response;
        });
    },
    voteLabels,
    addPreliminaryApplicants(vacancyId, authorsIds) {
        return sendRequest('/api/jobs/add-preliminary-applicants/', {
            method: 'POST',
            body: JSON.stringify({ vacancyId, authorsIds }),
        })
        .then(response => {
            updateGlobalData(wipeJobCache, {vacancyId});
            return response;
        });
    },
    addAllPreliminaryApplicants(docId, docKind, vacancyId, settings, customAuthorIds) {
        const formData = {
            docId, docKind,
            vacancyId,
            authorsIds: customAuthorIds,
            ...settings,
        }

        const formBody = refereesHelpers.settingsToQSDefined(formData);
        return sendRequest('/api/jobs/add-all-preliminary-applicants/', {
            method: 'POST',
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
            body: formBody,
        })
        .then(response => {
            updateGlobalData(wipeJobCache, {vacancyId});
            return response;
        });
    },
    isPreliminary(application) {
        return application && !application.email;
    },
    addTag(tag, applicationsIds, jobId) {
        return sendRequest('/api/jobs/applicant-tag/', {
            method: 'post',
            body: JSON.stringify({
                applicationsIds,
                vacancyTagId: tag.id,
            })
        }).then(({ ok, value }) => {
            if (ok) {
                updateGlobalData(addJobTag, {
                    vacancyId: jobId,
                    applicationsIds,
                    tag
                });
            } else {
                throw new Error(value.error);
            }

            return { ok, value: tag };
        });
    },
    markAsReferees(proposalId, authorsIds) {
        return sendRequest(`/api/proposals/${proposalId}/mark-as-referees/`, {
            method: 'POST',
            body: JSON.stringify({ proposalId, authorsIds }),
        })
    },
    unmarkAsReferees(proposalId, authorsIds) {
        return sendRequest(`/api/proposals/${proposalId}/unmark-as-referees/`, {
            method: 'POST',
            body: JSON.stringify({ proposalId, authorsIds }),
        })
    }
}
