import React from 'react';

import type { AdvisorWithScoresType, ApplicationType } from './Types';

import TextWithTooltip from '../widgets/TextWithTooltip';


const RANKS = {
    '0': '',
    '1': 'low',
    '2': 'medium',
    '3': 'high',
    '4': 'very high'
};

export default function Rank({ person }: { person: ApplicationType | AdvisorWithScoresType }) {
    return <span className="rank">
        <TextWithTooltip id="rank-detalization-popover"
                         help={person.rankReason.map((rr, i) => <div key={i}>{rr}</div>)}>
            {RANKS[person.rank]}
        </TextWithTooltip>
    </span>;
}
