import React from 'react';
import { cx, singlePlural } from '../utils';

import AuthorLink from '../authors/AuthorLink';

import '../referees/search-entity.css';


export function SearchAuthorResult({ author, isActive=false, children }) {
    return <li className={cx("article-suggest", {'active': isActive, 'with-action': !!children})}>
        <div className="article-card">
            <div className="brief" key={author.id}>
                <div className="name">
                    <AuthorLink {...author} /> — {author.articlesCount}{' '}
                    {singlePlural('article', author.articlesCount)},{' '}
                    {author.hIndex} h-index
                </div>

                <ul>
                    {author.affiliations.map((aff, i) => <li key={i}>
                        {aff.value}{' '}
                        ({aff.startYear}{aff.endYear > aff.startYear ? `-${aff.endYear}` : ''})
                        <sup>{aff.count}</sup>
                    </li>)}
                </ul>
            </div>
        </div>
        {children}
    </li>;
}
