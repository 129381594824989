import React from 'react';

import Navbar from 'react-bootstrap/lib/Navbar';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';

import CurrentUser from '../accounts/CurrentUser';
import { useCurrentUser } from '../accounts/hooks';


const LANDINGS = [
    {
        path: '/referee-finder/',
        title: 'Referee Finder',
    },
    {
        path: '/semantic-jobs/',
        title: 'Semantic Jobs',
    },
    {
        path: '/monitorings/',
        title: 'Monitoring',
    }
];


function activeSubpath(prefix, path) {
    if (path.substring(0, prefix.length) === prefix)
        return 'active';
}


export default function Header({ location: { pathname }}) {
    const [currentUser] = useCurrentUser();
    const { isAuthenticated } = currentUser;
    const linksToLandings = LANDINGS.map((landing, i) =>
        <li key={landing.path} role="presentation">
           <a href={landing.path}>{landing.title}</a>
        </li>
    );

    return <Navbar staticTop>
        <Navbar.Header>
            <Navbar.Brand>
                <a href="/">Prophy</a>
            </Navbar.Brand>
            <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
            <>
                <ul className="nav navbar-nav">
                    <li role="presentation">
                        <a href="/search/">Search</a>
                    </li>
                    {isAuthenticated && !!currentUser.activeQuotas.monitoring &&
                        <li role="presentation">
                            <a href="/saved-monitorings/">Monitoring</a>
                        </li>}
                    <li role="presentation">
                        <a href="/folders/">Referee finder</a>
                    </li>
                    {isAuthenticated && <>
                        {!!currentUser.activeQuotas.panel_composer &&
                            <li className={activeSubpath('/panel-composer/', pathname)} role="presentation">
                                <a href="/panel-composer/">Panel composer</a>
                            </li>}
                        {!!currentUser.activeQuotas.authors_group &&
                            <li role="presentation">
                                <a href="/authors-groups/">Authors groups</a>
                            </li>}
                    </>}
                </ul>
                <ul className="nav navbar-nav pull-right">
                    <NavDropdown title="Products" id="products">
                        {linksToLandings}
                    </NavDropdown>
                    <CurrentUser />
                </ul>
            </>
        </Navbar.Collapse>
    </Navbar>;
}
