import React from 'react';


export default function Footer() {
    return <footer className="page-footer">
        <div className="container">
            <p>Prophy is a service provider for the European Research Council.</p>

            <div>
                For any information, please contact us at <a href="mailto:info@prophy.ai">
                    info@prophy.ai
                </a>.
            </div>
            <ul>
                <li>
                    <a href="/privacy/">Privacy policy</a>
                </li>
            </ul>
        </div>
    </footer>;
}
