import * as React from 'react';

import { cx } from '../utils';
import './collapsible.css';


export default class Collapsible extends React.Component<
    {
        children: React.Node,
        className?: string
    },
    {collapsed: bool}
> {
    constructor() {
        super();
        this.state = {
            collapsed: true
        };
    }

    handleClick = () => {
        this.setState({ collapsed: !this.state.collapsed });
    };

    render() {
        const classes = cx('collapsible', this.props.className || '', {
            'expanded': !this.state.collapsed,
        });

        return <div className={classes}>
            {this.props.children}
            <button className="toggle" onClick={this.handleClick}>
                {this.state.collapsed ? '\u2193 Show more \u2193' : '\u2191 Show less \u2191'}
            </button>
        </div>;
    }
}
