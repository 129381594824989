import * as React from 'react';

import { useCurrentUser } from '../accounts/hooks';


export default function withCurrentUser(WrappedComponent) {
    const WithCurrentUser = (props) => {
        const [currentUser] = useCurrentUser();
        return <WrappedComponent currentUser={currentUser} {...props} />;
    }

    return WithCurrentUser;
}
