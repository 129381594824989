import React from 'react';

import { SearchCallsLimitExceededGuestMessage } from './accounts/GuestMessages';


export default function LoadingREST(
    { reqstate, doc, what, children, partialResponse = false, loadingString = 'Loading' }:
    { reqstate: any, doc: any, what?: string, children: any,
        partialResponse?: boolean, loadingString?: string }
) {
    // `partialResponse` is useful for lists, when we want to display a start
    // of a list while loading next elements
    if (doc && (partialResponse || reqstate.ok)) {
        return typeof children === 'function' ?
            children(doc)
            : children;
    }

    if (reqstate.ok === null || reqstate.loading) {
        if (what) {
            loadingString += ' ' + what;
        }
        return <div className="loading-wrapper">
            {loadingString}<span className="loading" aria-hidden="true" />
        </div>
    }

    let message;
    const limits = reqstate.value && reqstate.value.limits;
    if (limits && limits.name === 'search') {
        if (limits.reason === 'is_anonymous') {
            message = <SearchCallsLimitExceededGuestMessage limit={limits.limit}/>
        }
    } else if (reqstate.value && reqstate.value.error) {
        message = `Error: ${reqstate.value && reqstate.value.error}`;
    } else {
        message = `Unknown error happened during loading ${what}`
                + (reqstate.statusText ? ': '+reqstate.statusText : '');
    }
    return <div className="alert alert-danger">{message}</div>
}
