import React from 'react';
import type { Node } from 'react';

import Col from 'react-bootstrap/lib/Col';

import ConceptBrief from './ConceptBrief';
import LRUCache from '../LRUCache';
import { checkStatus } from '../utils';
import { SuggestBox } from '../widgets/Suggest';

import type { OntologyConceptType } from './Types';
import type { SuggestType, SuggestsResponseType } from '../widgets/Suggest';


type ConceptSearchPropsType = {
    requestCache: typeof LRUCache,
    disabled?: boolean,
    actions: (concept: OntologyConceptType) => Node,
    ignore: Array<string>
};

export default class ConceptSearch extends React.Component<
    ConceptSearchPropsType,
    { conceptId: ?number }
> {
    static defaultProps = {
        disabled: false,
        ignore: []
    };

    suggestFetch = (query: string): Promise<SuggestsResponseType> => {
        return fetch(`/api/autocomplete/concepts?query=${encodeURIComponent(query)}`, {
            credentials: 'same-origin',
        })
            .then(checkStatus)
            .then(response => response.json())
            .then(response => {
                if (!response.results || !this.props.ignore.length) {
                    return response;
                }

                const filteredResults = response.results
                    .filter(hit => this.props.ignore.indexOf(hit.id) === -1);

                return {
                    ...response,
                    results: filteredResults,
                    total: response.total - (response.results.length - filteredResults.length)
                };
            });
    };

    input: ?HTMLInputElement;

    state = {
        conceptId: null
    };

    setInput = (input: ?HTMLInputElement) => {
        this.input = input;
    }

    componentDidMount() {
        if (this.input) {
            this.input.focus();
        }
    }

    handleConceptSelect = (concept: SuggestType) => {
        if (concept.id.indexOf('temp_') === 0) return;

        this.setState({
            conceptId: concept.id
        });
    }

    render() {
        return <div className="concept-search">
            <div className="row">
                <Col sm={12}>
                    <SuggestBox minChars={2}
                                maxLabelLength={70}
                                callback={this.handleConceptSelect}
                                suggestFetch={this.suggestFetch}
                                divClasses="concept-suggest"
                                inputRef={this.setInput}
                                requestCache={this.props.requestCache}
                                showSuggestType={false}
                                selectOnExactMatch={true}
                                disabled={this.props.disabled} />
                    <ConceptBrief
                        id={this.state.conceptId}
                        actions={this.props.actions}
                    />
                </Col>
            </div>
        </div>;
    }
}
