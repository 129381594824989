import React from 'react';

import Button from 'react-bootstrap/lib/Button';

import { useTrack } from '../datastore';
import { makeLoadMoreArticlesQuery } from './AuthorData';
import LoadingButton from '../widgets/LoadingButton';


export function LoadMoreArticlesButton(
    { authorId, queryString, offset }
    : { authorId: number, queryString: string, offset: number }
) {
    const [queriedOffset, setOffset] = React.useState(null);
    useTrack(makeLoadMoreArticlesQuery(authorId, queryString, queriedOffset));
    if (queriedOffset === offset) {
        return <LoadingButton workingMessage="Loading more articles"
                              working disabled />;
    }
    return <Button onClick={() => setOffset(offset)}>Load more articles</Button>;
}