import { sendRequest } from '../datastore';

export const makeFullProposalKey = proposalId => `proposal-${proposalId}`;

export function makeFullProposalQuery(proposalId, fetchText) {
    const fullKey = makeFullProposalKey(proposalId);
    return {
        id: fullKey,
        get: fullKey,
        set: function (store, { value: { proposal }}) {
            store[fullKey] = proposal;
        },
        req: () => sendRequest(
            `/api/proposals/${proposalId}/full` + (fetchText ? `?fulltext=1` : '')),
    };
}
