import * as React from 'react';
import memoize from 'memoize-one';
import get from 'lodash/get';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Pagination from 'react-bootstrap/lib/Pagination';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';

import TextWithTooltip from '../widgets/TextWithTooltip';

import { cx, KEYS } from '../utils';

import './sortable-table.css';


export type ColumnType = {
    field: ?string,
    text?: React.Node,
    sortable?: boolean,
    sortFn?: (r1: any, r2: any) => -1 | 0 | 1,
    alphaNum?: boolean,
    isList?: boolean,
    selectable?: boolean,
    className?: any,
    help?: string,
    key?: boolean,
    extra?: any, // will be passed to th
    filter?: {
        type: 'between' | 'substring' | 'checkbox' | 'select' | 'select[]',
        options?: Array<{ value: string, label: any}>,
        fn?: (pattern: string, item: any) => boolean,
        label?: string,
        placeholder?: string
    }
};

type FilterType = {| min?: number, max?: number |} |
    {| pattern: string |};

function sortAlphaNum(a: string | number, b: string | number) {
    return a.toString().localeCompare(b.toString(), 'en', { numeric: true });
}


export default class SortableTable extends React.Component<
    {
        data: Array<any>,
        columns: Array<ColumnType>,
        renderRow: (row: any, i: number, items?: Array<any>, filterInCell?: {[key: string]: boolean}) => React.Node,
        renderNoResults?: () => React.Node,
        // editable: set this flag to true if table data is user editable in some interactive way.
        // This flag switches the behavior of sorting and filtering, so that editing the row doesn't make it jump
        // across the table when sorting/filtering field is changed
        editable: boolean,
        pagination?: {
            rowsPerPage: number
        },
        className?: any,
        animation?: {
            timeout?: number,
            keyFieldName?: string,
        },
        onSelect?: (row: any, checked: boolean) => any,
        defaultSortingField?: string,
        defaultSortingDirection?: string,
        thStyle?: any,
    },
    {
        frozen: Array<any>,
        sortingField: ?string,
        sortingDirection: string,
        filter: {[key: string]: FilterType},
        filterInCell: {[key: string]: boolean},
        showFilters: boolean,
        page: number
    }
> {
    static defaultProps = {
        editable: false
    };

    state = {
        frozen: this.props.data,
        sortingField: this.props.defaultSortingField || null,
        sortingDirection: this.props.defaultSortingDirection || 'desc',
        filter: {},
        filterInCell: {},
        showFilters: false,
        page: 1
    };

    sortAndFilter = memoize(
        (data: Array<any>, sortField: ?string, sortDirection: any, filter: any, editable: boolean, frozen: Array<any>) =>
            this.getSortedData(this.getFilteredData(data, editable, frozen), sortField, sortDirection)
    );

    page = memoize(
        (page: number, data: Array<any>) => this.getPage(page, data)
    );

    isAlphaNum(field: ?string) {
        if (field === null) {
            return false;
        }

        return this.props.columns.some(column =>
            column.field === field && column.alphaNum === true);
    }

    isList(field: ?string) {
        if (field === null) {
            return false;
        }

        return this.props.columns.some(column =>
            column.field === field && column.isList === true);
    }

    searchFn = (src: any) => {
        const key = this.props.columns.find(c => c.key);

        if (!key || !key.field) {
            throw new Error('Unable to sort the table. Key column is required.');
        }

        const mustUseGet = key.field.indexOf('.') !== -1;
        const original = get(src, key.field);

        return (f: any) => original === (mustUseGet ? get(f, key.field) : f[key.field]);
    };

    getSortedData(data: Array<any>, field: ?string, direction: ?string): Array<any> {
        if (field) {
            const findItems = (r1, r2) => {
                return this.props.editable ?
                    [
                        this.state.frozen.find(this.searchFn(r1)) || r1,
                        this.state.frozen.find(this.searchFn(r2)) || r2
                    ]
                    : [r1, r2];
            };
            const column = this.props.columns.find(c => c.field === field);
            const columnSortFn = column && column.sortFn;
            const sortFn = (columnSortFn && ((r1, r2) => columnSortFn.apply(null, findItems(r1, r2)))) || (this.isAlphaNum(field) ?
                (r1, r2) => {
                    const [item1, item2] = findItems(r1, r2);

                    return sortAlphaNum(get(item2, field), get(item1, field))
                }
                : this.isList(field) ?
                    (r1, r2) => {
                        const [item1, item2] = findItems(r1, r2);
                        const val2 = get(item2, field);
                        const val1 = get(item1, field);

                        if (val2.length < val1.length) return -1;
                        if (val2.length > val1.length) return 1;

                        return 0;
                    }
                    : (r1, r2) => {
                        const [item1, item2] = findItems(r1, r2);

                        const val2 = get(item2, field);
                        const val1 = get(item1, field);

                        if (val2 < val1) return -1;
                        if (val2 > val1) return 1;

                        return 0;
                    }
            );
            data = data.slice().sort(sortFn);

            if (direction !== 'desc') {
                data = data.reverse();
            }
        }

        return data;
    }

    getFilteredData(data: Array<any>, editable: boolean, frozen: Array<any>) {
        const mustUseFrozen = editable && frozen;

        if (mustUseFrozen) {
            data = frozen;
        }

        Object.keys(this.state.filter).forEach(field => {
            const filter = this.state.filter[field];
            let filterFn;

            if (!filter) return;

            if (filter.pattern) {
                const column = this.props.columns.find(c => c.field === field);
                const fn = column && column.filter && column.filter.fn;

                filterFn = fn ?
                    fn.bind(this, filter.pattern)
                    : item => item[field].indexOf(filter.pattern) !== -1;
            } else {
                filterFn = item => (
                    (filter.min === undefined || item[field] >= filter.min) &&
                    (filter.max === undefined || item[field] <= filter.max)
                );
            }

            data = data.filter(filterFn);
        });

        if (mustUseFrozen) {
            data = data.map(r => this.props.data.find(this.searchFn(r)))
                .filter(row => row);
        }

        return data;
    }

    getFrozenRows() {
        return this.props.data;
    }

    handleSortChange(field: string) {
        let direction = 'desc';
        if (field === this.state.sortingField &&
            this.state.sortingDirection === 'desc'
        ) {
            direction = 'asc';
        }

        this.setState({
            frozen: this.getFrozenRows(),
            sortingField: field,
            sortingDirection: direction,
        });
    }

    createSetPattern(field: string) {
        return (e: SyntheticEvent<HTMLInputElement>) => {
            this.setState({
                filter: {
                    ...this.state.filter,
                    [field]: e.currentTarget.value !== '' ?
                        { pattern: e.currentTarget.value }
                        : undefined
                },
                frozen: this.getFrozenRows(),
            });
        }
    }

    createSetExtremum(type: 'min' | 'max', field: string) {
        return (e: SyntheticEvent<HTMLInputElement>) => {
            const ext = e.currentTarget.value !== '' ? e.currentTarget.value : undefined;
            const shouldHaveFilter = (
                (
                    this.state.filter[field] &&
                    this.state.filter[field].pattern === undefined &&
                    this.state.filter[field][type === 'max' ? 'min' : 'max'] !== undefined
                ) || // has opposite extremum
                e.currentTarget.value !== '' // or will have at least one now
            );

            this.setState({
                frozen: this.getFrozenRows(),
                filter: {
                    ...this.state.filter,
                    [field]: shouldHaveFilter ?
                        {
                            ...(this.state.filter[field] || {}),
                            [type]: ext
                        }
                        : undefined
                }
            });
        }
    }

    createHandleKeyDown = (field: string) => {
        return (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
            if (e.which === KEYS.ESCAPE) {
                this.setState({
                    filter: {
                        ...this.state.filter,
                        [field]: undefined
                    }
                });
            }
        }
    }

    toggleFilterCheckbox(field: string) {
        return (e: SyntheticEvent<HTMLInputElement>) => {
            const checked = e.currentTarget.checked;

            this.setState({
                filterInCell: {
                    ...this.state.filterInCell,
                    [field]: checked
                }
            })
        }
    }

    createToggleFilterSelect(field: string) {
        return (option: string) => {
            const filter = { ...this.state.filter };

            if (option === '') {
                delete filter[field];
            } else {
                filter[field] = { pattern: option };
            }

            this.setState({
                filter,
                frozen: this.getFrozenRows(),
            });
        };
    }

    createAddFilterSelect(field: string) {
        return (option: string) => {
            const filter = { ...this.state.filter };
            const pattern = filter[field]?.pattern || [];

            filter[field] = {
                pattern: pattern.indexOf(option) === -1 ? [...pattern, option] : pattern,
            };

            this.setState({
                filter,
                frozen: this.getFrozenRows(),
            });
        };
    }

    createRemoveFilterSelect(field: string) {
        return (option: string) => {
            const filter = { ...this.state.filter };

            filter[field] = {
                pattern: (filter[field]?.pattern || []).filter(o => o !== option),
            };

            if (!filter[field].pattern.length) {
                delete filter[field];
            }

            this.setState({
                filter,
                frozen: this.getFrozenRows(),
            });
        };
    }

    goto = (page: number) => {
        return () => {
            this.setState(state => ({ page }));
        };
    }

    getPage(page: number, data: Array<any>): Array<any> {
        const { pagination } = this.props;

        if (pagination && pagination.rowsPerPage) {
            const start = pagination.rowsPerPage * (page - 1);
            return data.slice(start, start + pagination.rowsPerPage);
        }

        return data;
    }

    toggleFilters = (e: SyntheticEvent<*>) => {
        e.stopPropagation();

        this.setState({ showFilters: !this.state.showFilters });
    }

    handleSelect = (e: SyntheticEvent<HTMLInputElement>) => {
        if (this.props.onSelect) {
            this.page(
                this.state.page,
                this.getSortedAndFilteredData()
            ).forEach(row => {
                this.props.onSelect(row, e.currentTarget.checked);
            });
        }
    }

    /* eslint-disable-next-line complexity */
    renderHeaderCell = (data: Array<any>, column: ColumnType, index: number, columns: Array<ColumnType>) => {
        const { text, sortable, selectable, field, className, filter, help, extra } = column;
        const isSorted = sortable && this.state.sortingField === field;
        const thClassName = cx(className, {
            'sortable-cell': sortable,
            'selectable-cell': selectable,
            'sortable-cell-sorted': isSorted,
            'has-help': help,
            'sortable-cell-asc': isSorted && this.state.sortingDirection === 'asc',
            'sortable-cell-desc': isSorted && this.state.sortingDirection === 'desc',
            'filterable-between': filter && filter.type === 'between',
            'filterable-substring': filter && filter.type === 'substring',
            'filterable-checkbox': filter && filter.type === 'checkbox',
        });
        const filterable = columns.some(c => c.filter);
        let allSelected = false;
        let someSelected = false;

        if (selectable) {
            const isSelected = field ?
                r => !!r[field]
                : this.props.isSelected;

            allSelected = data.every(isSelected);
            someSelected = data.some(isSelected);
        }

        let filterMarkup = null;
        let onClick;

        const getValue = (field: string, subfield: string) => {
            return this.state.filter[field] && this.state.filter[field][subfield] !== undefined ?
                this.state.filter[field][subfield]
                : '';
        }

        if (field) {
            onClick = () => this.handleSortChange(field);
            const keyDownHandler = this.createHandleKeyDown(field);

            if (filter) {
                switch (filter.type) {
                    case 'between':
                        filterMarkup = <div className="filters">
                            <input type="text"
                                   placeholder="min"
                                   value={getValue(field, 'min')}
                                   onKeyDown={keyDownHandler}
                                   onChange={this.createSetExtremum('min', field)}/> to{' '}
                            <input type="text"
                                   placeholder="max"
                                   value={getValue(field, 'max')}
                                   onKeyDown={keyDownHandler}
                                   onChange={this.createSetExtremum('max', field)}/>
                        </div>;
                        break;
                    case 'substring':
                        filterMarkup = <div className="filters">
                            <input type="text"
                                   value={getValue(field, 'pattern')}
                                   placeholder={filter.placeholder || ''}
                                   onChange={this.createSetPattern(field)}
                                   onKeyDown={keyDownHandler}/>
                        </div>;
                        break;
                    case 'checkbox':
                        filterMarkup = <div className="filters">
                            <label>
                                <input type="checkbox" onChange={this.toggleFilterCheckbox(field)}/>
                                {' '}{filter.label}
                            </label>
                        </div>;
                        break;
                    case 'select': {
                        const selectedOption = this.state.filter[field] && filter.options && filter.options
                            .find(o => typeof this.state.filter[field].pattern !== 'undefined' &&
                                o.value === this.state.filter[field].pattern
                            );
                        const title = selectedOption ?
                            selectedOption.label
                            : 'Choose filter';

                        filterMarkup = <div className="filters">
                            <DropdownButton onSelect={this.createToggleFilterSelect(field)}
                                            bsSize="sm"
                                            title={title}
                                            id={`filter-select-${field}`}>
                                <MenuItem eventKey="" key="no-option">
                                    Choose filter
                                </MenuItem>
                                {filter.options && filter.options.map(o => <MenuItem eventKey={o.value} key={o.value}>
                                    {o.label}
                                </MenuItem>)}
                            </DropdownButton>
                        </div>;
                        break;
                    }
                    case 'select[]': {
                        filterMarkup = <div className="filters">
                            <DropdownButton onSelect={this.createAddFilterSelect(field)}
                                            bsSize="sm"
                                            title="Choose filter (AND)"
                                            id={`filter-select-${field}`}>
                                {filter.options && filter.options.map(o => <MenuItem eventKey={o.value} key={o.value}>
                                    {o.label}
                                </MenuItem>)}
                            </DropdownButton>
                            {filter.renderSelectedOptions({
                                options: this.state.filter[field]?.pattern,
                                onRemove: this.createRemoveFilterSelect(field),
                            })}
                        </div>;
                        break;
                    }
                    // no default
                }
            }
        }

        const tooltip = <Tooltip id="show-filters-tooltip">
            {this.state.showFilters ? 'Hide filters' : 'Show filters'}
        </Tooltip>;
        const content = <React.Fragment>
            {selectable && <input type="checkbox"
                                  checked={someSelected}
                                  onChange={this.handleSelect}
                                  className={cx({'partial': someSelected && !allSelected})}/>
            }
            <div className="th-label">
                {sortable && <span className="order"
                                   onClick={this.state.showFilters ? onClick : null}>
                    <span className="dropup"><span className="caret" /></span>
                    <span className="dropdown"><span className="caret" /></span>
                </span>}
                {help ?
                    <TextWithTooltip help={help} id={`th-help-${field || ''}`}>
                        {text || ''}
                    </TextWithTooltip>
                    : <span>{text}</span>
                }
            </div>
            {field && this.state.filter[field] && <Glyphicon glyph="filter" className="filter-indicator"/>}
            {filterMarkup}
            {filterable && index === columns.length - 1 &&
                <OverlayTrigger overlay={tooltip} placement="bottom">
                    <span className="toggle-filter"
                          onClick={this.toggleFilters}>
                        <span className="toggle-filter-inner">
                            <span className="glyphicon glyphicon-filter"></span>
                        </span>
                    </span>
                </OverlayTrigger>
            }
        </React.Fragment>;

        return <th key={index}
                   onClick={sortable && !this.state.showFilters ? onClick : null}
                   className={thClassName}
                   {...extra}
                   style={this.props.thStyle}>
            {filterable && index === columns.length - 1 ?
                <div className="filterable-wrapper">
                    {content}
                </div>
                : content
            }
        </th>;
    };

    getSortedAndFilteredData() {
        return this.sortAndFilter(
            this.props.data,
            this.state.sortingField,
            this.state.sortingDirection,
            this.state.filter,
            this.props.editable,
            this.state.frozen
        );
    }

    render() {
        const sortedData = this.getSortedAndFilteredData();
        let data = sortedData;
        let lastPage = 0;

        const { animation, pagination } = this.props;
        const animationTimeout = (animation && animation.timeout) || 500;
        const rowsPerPage = pagination && pagination.rowsPerPage;

        if (rowsPerPage) {
            data = this.page(this.state.page, sortedData);
            lastPage = Math.ceil(sortedData.length / rowsPerPage);
        }

        const sortableTableClassName = cx('sortable-table', {
            'has-pagination': lastPage > 1
        });

        const className = cx('table', this.props.className, {
            'table-filterable': this.props.columns.some(c => c.filter),
            'filters-enabled': this.state.showFilters,
            'table-animated': this.props.animation,
        });

        const pager = rowsPerPage && lastPage > 1 &&
            <div className="table-pagination">
                <span>Page {this.state.page} / {lastPage}{' '}</span>
                <Pagination>
                    <Pagination.First onClick={this.goto(1)} />
                    <Pagination.Prev onClick={this.goto(Math.max(1, this.state.page - 1))} />
                    <Pagination.Next onClick={this.goto(Math.min(lastPage, this.state.page + 1))} />
                    <Pagination.Last onClick={this.goto(lastPage)} />
                </Pagination>
            </div>;

        return <div className={sortableTableClassName}>
            {pager}
            <table className={className}>
                <thead>
                    <tr>
                        {this.props.columns.filter(c => c.text !== undefined)
                            .map(this.renderHeaderCell.bind(this, data))
                        }
                    </tr>
                </thead>

                <tbody>
                    {animation ?
                        <TransitionGroup component={null}>
                            {data.length ?
                                data.map((row, i, items) => (
                                    <CSSTransition key={row[animation.keyFieldName] || row.id || row._id}
                                                timeout={animationTimeout}
                                                classNames="fade">
                                        {this.props.renderRow(row, i, items, this.state.filterInCell)}
                                    </CSSTransition>
                                ))
                                : this.props.renderNoResults &&
                                    <CSSTransition key="empty" timeout={animationTimeout} classNames="fade">
                                        {this.props.renderNoResults()}
                                    </CSSTransition>
                            }
                        </TransitionGroup>
                        : data.length ?
                            data.map((row, i, items) => this.props.renderRow(row, i, items, this.state.filterInCell))
                            : this.props.renderNoResults && this.props.renderNoResults()
                    }
                </tbody>
            </table>
            {pager}
        </div>;
    }
}
