import React, { Fragment } from 'react';
import type { Element } from 'react';

import Overlay from '../widgets/Overlay';


/*
This is overriden version of <OverlayTrigger/>, that renders Overlay within
the context of <BrowserRouter/> without using <Portal/>, so that links
in overlay don't cause page crashes on mount.
Look like this is fixed in react-bootstrap 1.x
*/
export default class OverlayTrigger extends React.Component<
    {
        overlay: Element<*>,
        placement?: string,
        trigger?: string,
        rootClose?: boolean,
        children: Element<*>
    },
    {
        open: boolean
    }
> {
    static defaultProps = {
        placement: 'bottom',
        trigger: 'click',
        rootClose: false
    }

    state = {
        open: false
    }

    trigger: ?HTMLSpanElement;

    setTrigger = (el: ?HTMLSpanElement) => {
        this.trigger = el;
    }

    handleShow = (e) => {
        this.setState({ open: true });
        if (!e.ctrlKey && !e.metaKey) {
            e.preventDefault();
        }
    }

    handleHide = () => {
        this.setState({ open: false });
    }

    render() {
        let triggerHandlers = {};

        if (this.props.trigger === 'click') {
            triggerHandlers = { onClick: this.handleShow };
        } else {
            // eslint-disable-next-line no-console
            console.warn('OverlayTrigger: trigger prop values other than "click" are not yet implemented');
        }

        return <Fragment>
            <span ref={this.setTrigger}
                  { ...triggerHandlers }>
                {this.props.children}
            </span>
            <Overlay show={this.state.open}
                     onHide={this.handleHide}
                     placement={this.props.placement}
                     animation={true}
                     rootClose={this.props.rootClose}
                     target={this.trigger}>
                {this.props.overlay}
            </Overlay>
        </Fragment>;
    }
}
