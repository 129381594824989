export const SimilarConceptPropsFragment = `
fragment similarConceptProps on GSimilarConcept {
    id
    name
    basic
    canBeParent
}
`;

export const possibleConceptsGQL = `possibleConcepts {
    name
    score
    literals {
        name
        isAmbiguous
        df
        tf
        similarLiteralsConcepts {
                ...similarConceptProps
        }
        lexicalNeighborsConcepts {
                ...similarConceptProps
        }
    }
}
`;

export const wikipediaLiteralFragment = `
fragment wikipediaLiteral on GWikipediaLiteral {
    name
    link
    isAmbiguous
    kind
    lang
}
`;