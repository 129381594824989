export default class LRUCache {
    MAXLENGTH = 100;
    cache = {};
    i = 0;
    keys = [];

    constructor(MAXLENGTH: ?number) {
        this.MAXLENGTH = MAXLENGTH || 100;
    }

    get(key: any) {
        if (this.has(key)) {
            return this.cache[key];
        }
    }

    has(key: any) {
        return Object.hasOwnProperty.call(this.cache, key);
    }

    set(key: any, value: any) {
        if (this.keys.length >= this.MAXLENGTH) {
            delete this.cache[this.keys[this.i]];
            this.keys[this.i] = key;
            this.i++;
            if (this.i >= this.keys.length) {
                this.i = 0;
            }
        } else {
            this.keys.push(key);
        }

        this.cache[key] = value;
    }
}
