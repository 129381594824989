import React from 'react';

import Button from 'react-bootstrap/lib/Button';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Modal from 'react-bootstrap/lib/Modal';

import { KEYS } from '../utils';
import useModal from '../common/useModal';


export default function HideReferee({ options, refereeType, onHideReferee, ...props }) {
    const [showModal, { show: showReasonModal, hide: hideReasonModal }] = useModal(false);
    const [reason, setReason] = React.useState('');
    const [hideType, setHideType] = React.useState('');
    const [reasonError, setReasonError] = React.useState('');

    const reasonClasses = {
        'has-error': !!reasonError.length,
    };

    const handleReasonChange = React.useCallback(e => {
        setReason(e.currentTarget.value);
    }, []);

    const handleHideReasonModal = React.useCallback(() => {
        hideReasonModal();
        setReasonError('');
        setReason('');
    }, [hideReasonModal]);

    const mustHaveReason = React.useCallback(
        eventKey => !!options[eventKey].commentRequired,
        [options]
    );

    const handleHideTypeSelect = React.useCallback(eventKey => {
        if (mustHaveReason(eventKey)) {
            showReasonModal();
            setHideType(eventKey);
        } else {
            onHideReferee(eventKey, reason);
            handleHideReasonModal();
        }
    }, [reason, mustHaveReason, onHideReferee, handleHideReasonModal, showReasonModal]);

    const handleModalSubmit = React.useCallback(() => {
        if (mustHaveReason(hideType) && reason) {
            onHideReferee(hideType, reason);
            handleHideReasonModal();
        } else {
            setReasonError('This field is required.');
        }
    }, [handleHideReasonModal, hideType, mustHaveReason, onHideReferee, reason]);

    const handleReasonKeyDown = React.useCallback(e => {
        if (e.which === KEYS.ENTER) {
            handleModalSubmit();
        }
    }, [handleModalSubmit]);

    return <>
        <DropdownButton title={<span><Glyphicon glyph="eye-close"/>&nbsp;Hide</span>}
                        onSelect={handleHideTypeSelect}
                        {...props}>
            {Object.keys(options).map(r => (
                <MenuItem key={r} eventKey={r}>
                    {options[r].label}
                </MenuItem>
            ))}
        </DropdownButton>
        <Modal show={showModal} onHide={handleHideReasonModal} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Hide {refereeType}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup className={reasonClasses}>
                    <FormControl
                        type="text"
                        autoFocus
                        value={reason}
                        placeholder="Comment"
                        onChange={handleReasonChange}
                        onKeyDown={handleReasonKeyDown}
                    />
                    <div className="help-block">
                        {reasonError}
                    </div>
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleHideReasonModal}>Cancel</Button>
                <Button bsStyle="primary" onClick={handleModalSubmit}>
                    Hide {refereeType}
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}
