import React, { Fragment } from 'react';

import DocumentTitle from 'react-document-title';
import Alert from 'react-bootstrap/lib/Alert';

import EditJobForm from './EditJobForm';
import { LoadingJob, LoadingNewJob } from './Job';
import type { FindClaimArticleType } from '../articles/Types';

import type { ReactRouterHistoryType, ReactRouterMatchType } from '../common/Types';
import type { JobType } from './Types';
import withAuthorization from '../common/withAuthorization';


function EditJobPage(
    { match, history }: { match: ReactRouterMatchType, history: ReactRouterHistoryType }
) {
    return <div>
        {!match.params.id && <Fragment>
            <Alert bsStyle="info">
                Wonder what an <strong>ideal candidate</strong> would be like? Submit your vacancy,
                get access to the list of best fits for your listing and invite them to apply!
            </Alert>
        </Fragment>}
        <h2 className="hero-heading">
            {match.params.id ? 'Edit' : 'Open'} a job vacancy
        </h2>

        {match.params.id ?
            <LoadingJob id={match.params.id}>
                {(job: JobType<FindClaimArticleType>) =>
                    job && job.canEdit ?
                        <Fragment>
                            <DocumentTitle title={`${job.title} - Edit - Jobs - Prophy`}/>
                            <EditJobForm { ...job } history={history} />
                        </Fragment>
                        : <Alert bsStyle="danger">
                            You don&apos;t have permissions to edit this vacancy.
                        </Alert>
                }
            </LoadingJob>
            : <LoadingNewJob>
                { lastArticles =>
                    lastArticles && <EditJobForm history={history} lastArticles={lastArticles} />}
            </LoadingNewJob>
        }
    </div>;
}

export default withAuthorization(EditJobPage);
