import slugify from '../common/helpers/slugify';
import type {
    LiteralType, LocalLiteralType,
    LiteralMutationType, LiteralCompositePartType,
} from './Types';
import { pick } from '../utils';


type ConceptType = {
    literals: Array<any>,
    ontologyCategories: Array<*>,
    basic: boolean
};


const pureLiteral = (literal: LiteralType | LocalLiteralType) : {
    composite?: any, name?: any, lang: string, isAmbiguous: boolean
} => {
    if (literal.composite) {
        const pure = literal.composite
            .filter(part => typeof part.string !== 'string' || part.string !== '')
            .map(part => typeof part.concept_id === 'number' ?
                { concept_id: part.concept_id }
                : { ...part }
            ).reduce((result, part, i) => {
                const prev = result[i - 1];

                if (
                    prev &&
                    prev.concept_id === undefined &&
                    typeof part.string === 'string' &&
                    part.string.indexOf('#') !== 0
                ) {
                    prev.string = `${prev.string} ${part.string}`;
                } else {
                    result.push({ ...part });
                }

                return result;
            }, []);

        return pure.length === 1 && pure[0].string ?
            {
                name: pure[0].string,
                isAmbiguous: literal.isAmbiguous,
                lang: literal.lang,
            }
            : {
                composite: pure,
                isAmbiguous: literal.isAmbiguous,
                lang: literal.lang,
            };
    } else {
        return pick(['name', 'lang', 'isAmbiguous'], literal);
    }
}


const helpers = {
    constructConceptPath: function(id: number|string, name: ?string) {
        return `/ontology/concept/${id}` + (
            name ? `-${slugify(name)}` : ''
        );
    },
    constructConceptLogPath: function(id: number|string) {
        return `/ontology/concept/log/${id}`;
    },
    constructMergedConcept: function<T: LiteralType>(
        src: ConceptType,
        dest: ConceptType,
        missingLiterals: Array<T>
    ) {
        const literals: Array<LiteralType> = dest.literals.concat(missingLiterals)
            .map(l => pick(l.composite ? ['composite', 'lang', 'isAmbiguous'] : ['name', 'lang', 'isAmbiguous'], l));

        return {
            ...dest,
            literals,
            basic: src.basic || dest.basic
        };
    },
    pureLiteral,
    nameOrComposite: function(literal: LiteralType | LocalLiteralType) {
        const pure = pureLiteral(literal);
        return pick([pure.composite ? 'composite' : 'name', 'lang', 'isAmbiguous'], pure);
    },
    getSearchQuery: function(concept: any) {
        let searchQuery = concept.searchQuery;
        if (searchQuery === undefined) {
            const searchParts = [];
            concept.literals.forEach((literal) => {
                if (!literal.isAmbiguous) {
                    searchParts.push(`"${literal.name}"`);
                }
            });
            searchQuery = searchParts.join(' OR ');
        }
        return searchQuery;
    },
    stringifyComposite: (composite: Array<LiteralCompositePartType>) => (
        composite.reduce(
            (acc, part) => {
                const partStr = part.name || (
                    typeof part.string !== 'undefined' && part.string
                );

                return acc + (partStr ? ` ${partStr}` : '')
            },
            ''
        )
    ),
    getExpandedLiteralsVariants: function(literal: LocalLiteralType) {
        if (literal.composite && !literal.expandedLiteralsVariants) {
            throw new Error('expandedLiteralsVariants is required for composite literals');
        }

        return literal.expandedLiteralsVariants || [{ name: literal.name }];
    },
    checkConceptName: function(name: string, literals: Array<LocalLiteralType>) {
        let warning = '';

        if (
            name !== '' &&
            !literals.some(l => (
                this.getExpandedLiteralsVariants(l)
                    .map(variant => variant.name.toLowerCase())
                    .indexOf(name.toLowerCase()) !== -1
            ))
        ) {
            warning = `Chosen concept name "${name}" doesn't appear in the list of literals.`;
        }

        return warning;
    },
    validateName: function(name: string) {
        return name.trim() === '' ?
            ['Concept name cannot be empty.']
            : [];
    },
    getErrorsForLiteral: function(index: number, literals: Array<LocalLiteralType>) {
        const errors = [];
        const literal = literals[index];

        if (typeof literal.name === 'string' && literal.name.trim() === '' && !literal.composite) {
            errors.push('Literal cannot be empty');
        }

        const composite = literal.composite;

        if (
            (literal.name && literals.some((l, i) => l.name === literal.name && i !== index)) ||
            (
                composite &&
                composite.length &&
                literals.some((l, i) => (
                    l.composite &&
                    l.composite.length &&
                    i !== index &&
                    this.stringifyComposite(l.composite) === this.stringifyComposite(composite)
                ))
            )
        ) {
            errors.push('Literal must be unique across concept literals');
        }

        return errors;
    },
    validate: function(concept: any) {
        const errors = this.validateName(concept.name);

        if (!concept.literals.some(l => l.name || (l.composite && l.composite.length))) {
            errors.push('Concept should have at least one literal.');
        }

        if (!concept.literals.some(l => (
            !l.isAmbiguous && (!l.warnings || !l.warnings.length)
        ))) {
            errors.push(
                'Concept must have at least one correct non-ambiguous literal.'
            );
        }

        if (concept.literals.some((l, i) => this.getErrorsForLiteral(i, concept.literals).length)) {
            errors.push(
                'One or more literals are incorrect. Please check below.'
            );
        }

        return errors;
    },
    validateAll: function(concept: any) {
        return {
            errors: this.validate(concept),
            nameErrors: this.validateName(concept.name),
            warning: this.checkConceptName(concept.name, concept.literals)
        };
    },
    collectConcept: function(concept: any) {
        const conceptData: {
            id?: number,
            name: string,
            literals: Array<LiteralMutationType>,
            basic: boolean,
        } = {
            name: concept.name,
            literals: (concept.literals || [])
                .map(this.pureLiteral),
            basic: concept.basic,
        };

        const id = parseInt(concept.id, 10);

        if (id) {
            conceptData.id = id;
        }

        return conceptData;
    },
    saveConcept: function(concept: any, saveFn: (data: {}) => Promise<*>) {
        const conceptData = this.collectConcept(concept);
        const errors = helpers.validate(concept);

        if (!errors.length) {
            return saveFn.call(this, conceptData);
        } else {
            return Promise.reject(errors);
        }
    }
};

export default helpers;
