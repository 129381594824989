import React from 'react';

import DocumentReferees from './DocumentReferees';
import BodyClass from '../common/BodyClass';

import withCurrentUser from '../common/withCurrentUser';
import { sendRequest } from '../datastore';
import LoadingREST from '../LoadingREST';
import type { UserType } from '../../accounts/Types';

import './referees.css';


function RefereeFinderAdvertisement() {
    return <>
        <section>
            <div className="jumbotron" style={{ padding: '20px', display: 'flex' }}>
                <div style={{ margin: '0 20px 0 0', maxWidth: '33%' }}>
                    <img width="100%" src="/static/landings/referees-screen.png"
                         alt="Screenshot of Referee Finder"/>
                </div>
                <div style={{ flex: 1 }}>
                    <h2 style={{ marginTop: '10px' }}>Quickly find the best referees
                        to move science forward</h2>
                    <p>Utilizing our collection of 170M+ articles, we
                        automatically create profile for every scientist, and
                        rank them according to their semantic similarity to a
                        refereed manuscript or proposal.</p>
                    <p>
                        <a className="btn btn-default btn-md" target="_blank"
                           href="/referee-finder/">Learn more</a>
                    </p>
                </div>
            </div>
        </section>
        <section>
            <div style={{maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto'}}>
                <p>
                    With the referee finder you can find people who know the best
                    about a given topic.
                    The topic can be indicated by a journal article, a scientific
                    proposal, even by another author from our database.
                </p>
                <p>
                    Soon you will be able to buy a plan with referee finder in
                    one click.
                    In the meantime, we grant them on request.
                </p>
                <p className="text-center">
                    <a className="btn btn-primary btn-lg"
                       href="mailto:info@prophy.ai?subject=Request referee finder plan&body=I would like to request a referee finder plan to use in my daily scientific activities.">
                        Request referee finder plan</a>
                </p>
            </div>
        </section>
    </>
}


function RefereesPage({currentUser, history, location, match }: { currentUser: UserType, history: any, location: any, match: any }) {
    const { docKind, docId } = match.params;
    const [showAdvertisement, setShowAdvertisement] = React.useState(false);
    const [reqstate, setReqstate] = React.useState({loading: true});

    React.useEffect(() => {
        if (docKind === 'author' && (!currentUser.id || !currentUser.activeQuotas.author_report)) {
            window.location.href = `/reports/author/${docId}/no-plan`;
        } else if (docKind === 'article' && !currentUser.activeQuotas.referee_finder) {
            setReqstate({loading: true, done: false})

            sendRequest(`/api/is-article-already-refereed/${docId}/`)
            .then(response => {
                if (response.ok) {
                    // setShowAdvertisement must be done before setReqstate
                    setShowAdvertisement(!response.value.alreadyRefereed);
                    setReqstate({loading: false, ok: true});
                }
                else {
                    setReqstate({loading: false, ok: false, value: {error: response.error}})
                }
            });
        } else {
            setReqstate({loading: false, ok: true})
        }
    }, [docKind, docId, currentUser])

    return <LoadingREST reqstate={reqstate} doc={true}>
        {showAdvertisement ? <RefereeFinderAdvertisement/>
            : <>
                <BodyClass className="referee-finder-page" />
                <DocumentReferees docKind={docKind}
                                  id={docId}
                                  history={history}
                                  location={location} />
            </>}
    </LoadingREST>
}

export default withCurrentUser(RefereesPage);
