import 'react-app-polyfill/ie11';
import '@webcomponents/template';
import 'core-js/es6/symbol';
import 'core-js/es6/set';
import 'core-js/es6/map';
import 'core-js/modules/es6.array.find';
import 'core-js/modules/es6.string.includes';


import './css/core.css';

import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';

import { ApolloProvider } from '@apollo/react-common';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import analytics from './analytics';


if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://f2f91ebb6a014aa48340c2fe5bf3da90@sentry.io/1509015',
        environment: document.location.hostname.indexOf('stage') !== -1 ?
            'stage'
            : 'prod',
        beforeBreadcrumb(breadcrumb, hint) {
            // we want more details on what exact GraphQL queries are executed
            if (breadcrumb.category === 'fetch' && hint.input[0] === '/api/graphql/') {
                const operationNameMatch = hint.input[1].body.match(/"operationName":"(\w+)"/);

                if (operationNameMatch) {
                    breadcrumb.data.operation = operationNameMatch[1];
                }
            }

            return breadcrumb;
        },
        release: `${process.env.REACT_APP_VERSION || 0}`
    });
}
analytics.initialize_mixpanel(window._MIXPANEL_PROJECT_TOKEN);

const cache = new InMemoryCache({
    dataIdFromObject: object => {
        switch (object.__typename) {
            case 'GSimilarConcept': return null;
            default: return defaultDataIdFromObject(object);
        }
    }
});
const client = new ApolloClient({
    link: createHttpLink({
        uri: '/api/graphql/',
        credentials: 'same-origin',
    }),
    resolvers: {
        GUser: {
            lastFetchedAt: () => Date.now(),
        },
        GUserInOrganization: {
            lastFetchedAt: () => Date.now(),
        },
    },
    cache
});


const root = document.getElementById('root');

if (!root) {
    throw new Error('Markup error!');
}

const renderMarkup = (AppInstance) => {
    ReactDOM.render(
        <ApolloProvider client={client}>
            <BrowserRouter>
                <AppInstance />
            </BrowserRouter>
        </ApolloProvider>,
        root
    );
}

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        renderMarkup(NextApp);
    });
}

renderMarkup(App);
