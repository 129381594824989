import React from 'react';

import RelevantArticle, { CoauthoredArticle } from '../articles/RelevantArticle';
import TwoStepCollapsibleList from '../widgets/TwoStepCollapsibleList';


export default React.memo(
    function RefereeArticles({
        mostRelevantArticles, mostRelevantArticlesCount,
        coauthoredArticles, retractedArticles, author
    }) {
        return <div className="referee-articles">
            {mostRelevantArticles.length > 0 &&
                <div>
                    <span className="referee-row-anchor" id={`similar-${author.id}`} />
                    <h5 style={{display: 'inline'}}>Most similar articles:</h5>{' '}
                    <TwoStepCollapsibleList defaultCount={5}
                                            total={mostRelevantArticlesCount}>
                        {mostRelevantArticles.map(a =>
                            <RelevantArticle article={a} key={a.id} authorId={author.id} />)}
                    </TwoStepCollapsibleList>
                </div>
            }
            {coauthoredArticles.length > 0 &&
                <div>
                    <span className="referee-row-anchor" id={`coauthored-${author.id}`} />
                    <h5 style={{display: 'inline'}}>
                        <span className="glyphicon glyphicon-user text-danger"
                              style={{marginLeft: '-20px', marginRight: '6px'}} />
                        Recently coauthored articles:
                    </h5>{' '}
                    <TwoStepCollapsibleList defaultCount={5} word="latest">
                        {coauthoredArticles.map(a =>
                            <CoauthoredArticle article={a} key={a.id} />)}
                    </TwoStepCollapsibleList>
                </div>
            }
            {retractedArticles.length > 0 &&
                <div>
                    <span className="referee-row-anchor" id={`retracted-${author.id}`} />
                    <h5 style={{display: 'inline'}}>
                        <span className="glyphicon glyphicon-erase text-danger"
                              style={{marginLeft: '-20px', marginRight: '6px'}} />
                        Retracted articles:
                    </h5>{' '}
                    <TwoStepCollapsibleList defaultCount={5}>
                        {retractedArticles.map(a =>
                            <RelevantArticle article={a} key={a.id} authorId={author.id} />)}
                    </TwoStepCollapsibleList>
                </div>
            }
        </div>
    }
);
