import React from 'react';


export default function useModal(defaultShown = false) {
    const [showModal, setShowModal] = React.useState(defaultShown);

    const show = React.useCallback(() => setShowModal(true), []);
    const hide = React.useCallback(() => setShowModal(false), []);

    return [showModal, { show, hide }];
}
