import React, { Fragment } from 'react';
import type { Element } from 'react';

import type { LiteralCompositePartType } from './Types';
import { ChosenConcept } from '../recommendations/Concepts';

import type { SuggestType } from '../widgets/Suggest';

import { cx } from '../utils';


export default function Literal(
    { literal, className, title, conceptPopoverContents }
    : {
        literal: { name: string, composite: ?Array<LiteralCompositePartType> },
        className?: ?string,
        title?: ?string,
        conceptPopoverContents?: (concept: SuggestType) => Element<any>
    }
) {
    const commonProps = {
        className: cx(className, {'composite': literal.composite }),
        title
    };

    return <span { ...commonProps }>
        {literal.composite ?
            literal.composite.map((part, i, composite) => {
                let value;

                if (typeof part.string === 'string') {
                    value = `${composite[i - 1] ? ' ' : ''}${part.string}`;
                } else if (
                    typeof part.concept_id === 'number' &&
                    typeof part.name === 'string'
                ) {
                    const suggest = {
                        id: part.concept_id,
                        label: part.name,
                        value: part.name,
                        type: 'suggest'
                    };

                    value = <Fragment key={part.concept_id}>
                        {' '}
                        <ChosenConcept conceptId={part.concept_id}
                                       name={part.name}
                                       draggable={false}>
                            {conceptPopoverContents && conceptPopoverContents(suggest)}
                        </ChosenConcept>
                    </Fragment>;
                }

                return value;
            })
            : literal.name
        }
        {literal.lang && ` [${literal.lang}]`}
    </span>;
}
