import React, { Fragment } from 'react';
import type { ApolloClient } from 'apollo-client';

import { withApollo } from 'react-apollo';

import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';

import { checkStatus, cx, KEYS, capitalizeFirstLetter } from '../utils';

import type { ReactRouterLocationType, ReactRouterMatchType } from '../common/Types';
import type { AuthorToClaimType } from './TypesClaim';
import type { UserType } from './Types';
import withAuthorization from '../common/withAuthorization';
import withCurrentUser from '../common/withCurrentUser';
import CurrentUserContext from './CurrentUserContext';
import AuthorToClaim from './AuthorToClaim';


const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


type AdvisorType = {
    attorneyToken: string,
    email: string,
    advisorName: string,
    authorId: ?number,
    authorName?: string,
    articlesCount?: number
};


export class FindAuthors extends React.Component<{
    history: any,
    match: ReactRouterMatchType,
    location: ReactRouterLocationType,
    client: ApolloClient,
    currentUser: UserType
}, {
    emailsInput: string,
    emailError: string,
    sentEmails: string,
    loading: boolean,
    fetchedAuthors: boolean,
    advisor: ?AdvisorType,
    authors: Array<{ author: AuthorToClaimType, selected: boolean }>,
    resultAuthorId: ?number,
    claimedAuthorsIds: Array<number>,
    initialShortAuthor: ?AuthorToClaimType,
    suggestedAuthors: Array<AuthorToClaimType>,
    fetchInitialShortAuthorDone: boolean,
    fetchInitialAuthorsDone: boolean,
    fetchInitialSuggestedAuthorsDone: boolean,
}> {
    state = {
        emailsInput: '',
        emailError: '',
        sentEmails: '',
        loading: false,
        fetchedAuthors: false,
        authors: [],
        resultAuthorId: null,
        claimedAuthorsIds: [],
        initialShortAuthor: null,
        suggestedAuthors: [],
        fetchInitialShortAuthor: false,
        fetchInitialAuthorsDone: false,
        fetchInitialSuggestedAuthorsDone: false,
    };

    emailsInput: { current: HTMLInputElement | null } = React.createRef();

    componentDidMount() {
        (this.props.match.params.attorneyToken ?
            this.fetchAdvisorByToken(this.props.match.params.attorneyToken)
            : Promise.resolve(this.props.currentUser)
        )
            .then(person => {
                if (person.authorId) {
                    this.setState({
                        resultAuthorId: person.authorId,
                        claimedAuthorsIds: [person.authorId]
                    });
                }
                this.fetchInitialShortAuthor(person.authorId);

                const initialEmailsInput = person.email;
                this.setState({ emailsInput: initialEmailsInput });
                this.submitEmails(initialEmailsInput)
                    .then(foundAuthors => {
                        if (!person.authorId && foundAuthors.length === 0) {
                            this.fetchSuggestedAuthors();
                        } else {
                            this.setState({ fetchInitialSuggestedAuthorsDone: true })
                        }
                    });
            });
    }

    attorneyToken() {
        return this.props.match.params.attorneyToken;
    }

    youOrAdvisor() {
        return this.state.advisor ? "your recommender" : 'you';
    }

    yourOrAdvisors() {
        return this.state.advisor ? "your recommender's" : 'your';
    }

    fetchAdvisorByToken(token: string) {
        return this.fetch(`/api/jobs/fetch-advisor-by-attorney/${token}`, { method: 'GET' })
            .then(({ ok, advisor }) => {
                this.setState({ advisor });
                return advisor;
            });
    }

    onModalClose = () => {
        const authorId = this.state.resultAuthorId;
        let url;

        if (authorId) {
            url = `/author/${authorId}`;
            const attorneyToken = this.attorneyToken();
            if (attorneyToken) {
                url += `?attorney_token=${attorneyToken}`;
            }
        } else {
            url = localStorage.getItem('session:redirectTo') || '/accounts/dashboard/';
        }
        window.location.href = url;
    }

    fetchSuggestedAuthors = () => {
        const attorneyToken = this.attorneyToken();
        const url = attorneyToken ? `/api/authors/suggest-author-for-advisor/${attorneyToken}`
            : `/api/authors/suggest-author-for-user/`;

        return fetch(url, {
            credentials: 'same-origin',
            method: 'GET',
        })
            .then(response => {
                this.setState({ loading: false });
                return response;
            })
            .then(checkStatus)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    suggestedAuthors: response.authors.map(
                        author => ({ ...author, matchedEmails: []})),
                    fetchInitialSuggestedAuthorsDone: true,
                })
            });
    }

    fetchAuthors = (emails: Array<string>) => {
        return this.fetch('/api/authors/find-by-emails/', {
            body: JSON.stringify({ emails })
        });
    }

    claimAuthor = (author: AuthorToClaimType) => {
        const advisor = this.state.advisor;

        const data = {
            authors_ids: [author.id],
        };
        let comment;

        if (!!advisor) {
            const attorneyToken = advisor.attorneyToken;
            comment = `User claimed this author for advisor ${advisor.advisorName} ${advisor.email}`
            data.attorney_token = attorneyToken;
        } else {
            comment = 'User claimed this author'
        }

        if (author.matchedEmails.length > 0) {
            comment = comment + ` by emails: [${author.matchedEmails.join(', ')}]`;
        } else {
            comment = comment + ` by suggest`;
        }

        data.comment = comment;

        return this.fetch('/api/authors/claim-authors/', {
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                // refetch user to update it's authorId
                if (!advisor) {
                    const [, { fetchUser }] = this.context;
                    fetchUser();
                }

                this.setState({
                    error: '',
                    resultAuthorId: response.author.id,
                    resultAuthorName: response.author.name,
                    claimedAuthorsIds: this.state.claimedAuthorsIds.concat([author.id])
                });
            } else {
                this.setState({ error: response.error });
            }

            return response;
        });
    }

    splitEmails(emailsInput: string) {
        return emailsInput.trim() ? emailsInput.split(/\s*,\s*|\s+/) : [];
    }

    canSubmitEmails() {
        const failed = this.splitEmails(this.state.emailsInput)
            .find(email => !email.match(EMAIL_REGEX));

        this.setState({
            emailError: failed ?
                `"${failed}" doesn't look like a valid email address.`
                : ''
        });

        return !failed;
    }

    submitEmails = (initialEmailsInput: ?string = null) => {
        const emailsInput = initialEmailsInput || this.state.emailsInput;

        if (emailsInput === this.state.sentEmails) {
            return;
        }

        if (this.canSubmitEmails()) {
            this.setState({ sentEmails: emailsInput });

            const emails = this.splitEmails(emailsInput);

            if (emails.length === 0) {
                return;
            }

            return this.fetchAuthors(emails)
                .then(response => {
                    // move already claimed author up
                    const resultAuthorId = this.state.resultAuthorId;
                    let authors = response.authors;

                    if (resultAuthorId) {
                        authors = (
                            authors
                            .filter(a => a.id === this.state.resultAuthorId)
                            .concat(authors.filter(a => a.id !== this.state.resultAuthorId)));
                    }

                    const newState = {
                        fetchedAuthors: true,
                        authors: authors,
                    };
                    if (initialEmailsInput) {
                        newState.fetchInitialAuthorsDone = true;
                    }

                    this.setState(newState);

                    this.emailsInput.current && this.emailsInput.current.focus();

                    return authors;
                });
        }
    }

    fetch(url: string, options: any) {
        this.setState({ loading: true });

        return fetch(url, {
            credentials: 'same-origin',
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            ...options
        })
            .then(response => {
                this.setState({ loading: false });
                return response;
            })
            .then(checkStatus)
            .then(response => response.json());
    }

    handleEmailsChange = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
        this.setState({ emailsInput: e.currentTarget.value });
    }

    handleEmailsKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
        if (e.which === KEYS.ENTER) {
            e.preventDefault();
            this.submitEmails();
        }
    }

    fetchInitialShortAuthor = (authorId: ?number) => {
        if (!authorId) {
            this.setState({ fetchInitialShortAuthorDone: true });
            return;
        }

        return fetch(`/api/authors/${authorId}/short`, {
            credentials: 'same-origin',
            method: 'GET',
        })
            .then(response => {
                this.setState({ loading: false });
                return response;
            })
            .then(checkStatus)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    initialShortAuthor: response.author,
                    fetchInitialShortAuthorDone: true,
                })
            });
    }

    renderInitialShortAuthor() {
        if (!this.state.initialShortAuthor) {
            return
        }

        const author = {
            ...this.state.initialShortAuthor,
            matchedEmails: [],
        }

        return <div>
            <div><label>{capitalizeFirstLetter(this.yourOrAdvisors())}&nbsp;linked author:</label></div>
            <AuthorToClaim author={author}
                           resultAuthorId={this.state.resultAuthorId}
                           claimed
                           hideEmails
                           attorneyToken={this.attorneyToken()}
                           loading={false}/>
        </div>
    }

    renderClaimLink() {
        let url = '/claim-publications';
        const attorneyToken = this.attorneyToken();
        if (attorneyToken) {
            url += `/${attorneyToken}`;
        }
        return <Link to={url} target="_blank">here</Link>;
    }

    renderSuggestAuthor() {
        const suggestedCount = this.state.suggestedAuthors.length;

        if (suggestedCount === 0) {
            return null
        }

        return <div>
            <div>
                <label htmlFor="users-search">
                    Is this {this.youOrAdvisor()}?
                    <span className="help-block">{this.state.emailError}</span>
                </label>
            </div>
            <Fragment>
                <ul className="list-unstyled">
                    {this.state.suggestedAuthors.map(author => <li key={author.id}>
                            <AuthorToClaim
                                author={author}
                                claimed={this.state.claimedAuthorsIds.indexOf(author.id) !== -1}
                                onClaim={this.claimAuthor}
                                resultAuthorId={this.state.resultAuthorId}
                                attorneyToken={this.attorneyToken()}
                                loading={this.state.loading}/>
                        </li>
                    )}
                </ul>
            </Fragment>
            <hr/>
        </div>;
    }

    renderFindAuthors() {
        return <div className={cx('emails', { 'has-error': !!this.state.emailError })}>
            <div>
                <label htmlFor="users-search">
                    Find articles by emails and claim them
                    <span className="help-block">{this.state.emailError}</span>
                </label>
            </div>

            <div>
                <div className="input-group claim-search-emails-input">
                        <input type="text"
                            className="form-control"
                            disabled={this.state.loading}
                            onChange={this.handleEmailsChange}
                            onKeyDown={this.handleEmailsKeyDown}
                            placeholder="albert-einstein@example.com, aeinstein@example.com"
                            ref={this.emailsInput}
                            value={this.state.emailsInput}
                        />
                    <div className="input-group-btn">
                        <Button bsStyle="primary" onClick={() => this.submitEmails()}>
                            Find
                        </Button>
                    </div>
                </div>
            </div>

            {this.state.error && <Alert bsStyle="warning">{this.state.error}</Alert>}

            {this.state.authors.length ?
                    <Fragment>
                        <ul className="list-unstyled">
                            {this.state.authors.map(author => <li key={author.id}>
                                <AuthorToClaim
                                    author={author}
                                    claimed={this.state.claimedAuthorsIds.indexOf(author.id) !== -1}
                                    onClaim={this.claimAuthor}
                                    resultAuthorId={this.state.resultAuthorId}
                                    attorneyToken={this.attorneyToken()}
                                    loading={this.state.loading}/>
                                </li>
                            )}
                        </ul>
                        <div>
                            If you cannot find your articles &#8212; try adding more emails or
                            upload {this.yourOrAdvisors()} bibliography&nbsp;{this.renderClaimLink()}.
                        </div>
                    </Fragment>
                    : this.state.fetchedAuthors && <p>
                        No articles found. Try adding more emails or
                        upload {this.yourOrAdvisors()} bibliography&nbsp;{this.renderClaimLink()}.</p>
                }
        </div>
    }

    render() {
        const initialFetchDone = (
            this.state.fetchInitialShortAuthorDone &&
            this.state.fetchInitialAuthorsDone &&
            this.state.fetchInitialSuggestedAuthorsDone);

        if (!initialFetchDone) {
            return <Modal show
                      animation={false}
                      backdrop="static"
                      dialogClassName="claim-dialog">
                <Modal.Body>
                    Loading<span className="loading" aria-hidden="true" />
                </Modal.Body>
            </Modal>
        }

        const advisor = this.state.advisor;
        const modalTitle = advisor ? `Find and claim articles for ${advisor.advisorName}` : 'Find and claim articles';

        return <Modal show
                      animation={false}
                      backdrop="static"
                      onHide={this.onModalClose}
                      dialogClassName="claim-dialog">
            <Modal.Header>
                <Modal.Title>
                    {modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.renderSuggestAuthor()}
                {this.renderInitialShortAuthor()}
                {this.renderFindAuthors()}
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle="default" onClick={this.onModalClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}

FindAuthors.contextType = CurrentUserContext;

export default withAuthorization(withApollo(withCurrentUser(FindAuthors)));
