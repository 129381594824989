import React from 'react';

import Loading from '../Loading';
import { checkStatus, roundInteger } from "../utils";
import helpers from './helpers';
import AuthorLink from '../authors/AuthorLink';
import SortableTable from '../widgets/SortableTable';
import type { RefereeType } from './Types';
import './referees.css';


const RefereeCoauthorsColumns = [
    {field: 'position', text: 'Position', sortable: true, alphaNum: true, key: true, className: "text-right"},
    {field: 'name', text: 'Name'},
    {field: 'commonCorpusArticlesCount', text: 'Relevant Coauthored', sortable: true, className: "text-right"},
    {field: 'articlesCount', text: 'Articles', sortable: true, className: "text-right"},
    {field: 'hIndex', text: 'h‑index', sortable: true, className: "text-right"},
    {field: 'citationsCount', text: 'Citations', sortable: true, className: "text-right"},
];


function RefereeCoauthorRow({coauthor, docKind, docId, settings}) {
    return <tr key={coauthor.author.id}>
        <td className="text-right"><a href={`#author-${coauthor.author.id}`}>{coauthor.position}</a></td>
        <td><AuthorLink target="_blank" {...coauthor.author} /></td>
        <td className="text-right">{coauthor.commonCorpusArticlesCount}</td>
        <td className="text-right">{coauthor.articlesCount}</td>
        <td className="text-right">{coauthor.hIndex}</td>
        <td className="text-right">{roundInteger(coauthor.citationsCount)}</td>
    </tr>;
}


export function LoadedRefereeCoauthors({ referee, docId, docKind, settings, coauthors }: { referee: RefereeType, docId: int, docKind: str }) {
    return <div style={{marginTop: '5px', border: '1px solid #ccc'}}><SortableTable
            className="table-referee-coauthors table-striped table-condensed"
            data={coauthors}
            renderRow={coauthor =>
                <RefereeCoauthorRow key={coauthor.author.id}
                                    coauthor={coauthor}
                                    docKind={docKind}
                                    docId={docId}
                                    settings={settings} />}
            columns={RefereeCoauthorsColumns}
        /></div>
}

export default class RefereeCoauthors extends React.Component<
    {
        id: number
    },
    {
        loadingPromise: any,
        author: RefereeType
    }
> {
    state = {
        loadingPromise: null,
        author: null,
    };

    componentDidMount() {
        const loadingPromise = fetch(`/api/referees/${this.props.docKind}/${this.props.docId}/coauthors`, {
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
            method: 'POST',
            body: helpers.settingsToQSDefined({
                ...this.props.settings,
                refereeAuthorId: this.props.id,
            }),
            credentials: 'same-origin',
        })
            .then(checkStatus)
            .then(resp => resp.json())
            .then(json => {
                this.setState({
                    loadingPromise: null,
                    author: json.author,
                    settings: json.settings,
                    coauthors: json.coauthors
                })
            });
        this.setState({ loadingPromise });
    }

    render() {
        const { author, settings, coauthors, loadingPromise } = this.state;
        if (loadingPromise) {
            return <Loading what="author details" loading={!!loadingPromise} />
        }
        if (author) {
            return <LoadedRefereeCoauthors
                referee={author}
                docId={this.props.docId}
                docKind={this.props.docKind}
                settings={settings}
                coauthors={coauthors} />
        }
        return <div>Something is wrong, try to reload.</div>;
    }
}
