import React from 'react';

import FileSaver from 'file-saver';

import {checkStatus} from '../utils';


function createDownloadFile(url: string, filename: string) {
    return (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();

        return fetch(url, {
            credentials: 'same-origin',
            headers: {'cache-control': 'no-cache'},
        }).then(checkStatus)
            .then(result => result.blob())
            .then(result => FileSaver.saveAs(result, filename));
    };
}

export default function DownloadFileButton(
    { to, filename, children }
    : { to: string, filename: string, children: any }
) {
    return <button onClick={createDownloadFile(to, filename)}
                   className="inline-link">
        {children}
    </button>;
}
