import React from 'react';

import { Link } from 'react-router-dom';

import helpers from '../jobs/helpers';
import { singlePlural } from '../utils';


export default function AddPreliminaryApplicantButton(
    { docId, docKind, vacancyId, visibleCount, totalCount, getAuthorsIds, settings, customAuthorIds }:
    {
        docId: number,
        docKind: string,
        vacancyId: number,
        visibleCount: number,
        totalCount: number,
        getAuthorsIds: () => Array<number>,
        settings: object,
        customAuthorIds: Array<number>,
    }
) {
    const [working, setWorking] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [done, setDone] = React.useState(false);
    const [addedCount, setAddedCount] = React.useState(0);

    const setStates = (working, error, done, addedCount) => {
        setWorking(working);
        setError(error);
        setDone(done);
        setAddedCount(addedCount)
    };

    const addVisiblePreliminaryApplicants = () => {
        setStates(true, null, false);
        helpers.addPreliminaryApplicants(vacancyId, getAuthorsIds())
        .then(response => {
            const { ok, value } = response;

            if (ok) {
                setStates(false, null, true, value.addedCount);
            } else {
                setStates(false, value.error, false, 0);
            }
        });
    };

    const addAllPreliminaryApplicants = () => {
        setStates(true, null, false);
        helpers.addAllPreliminaryApplicants(docId, docKind, vacancyId, settings, customAuthorIds)
        .then(response => {
            const { ok, value } = response;

            if (ok) {
                setStates(false, null, true, value.addedCount);
            } else {
                setStates(false, value.error, false, 0);
            }
        });
    };

    return <div className="alert alert-info alert alert-info">
        <p>
            Below is the list of people whose expertise may be a good fit for your vacancy.
            Press the button below to add them as preliminary candidates.
        </p>
        <div style={{ marginTop: '1em' }}>
            {working ? <i>Adding candidates<span className="loading" /></i>
             : <>
                 <button className="btn btn-default"
                         style={{ marginRight: '0.5em' }}
                         disabled={working}
                         onClick={addVisiblePreliminaryApplicants}>
                     Add {visibleCount} candidates as preliminary applicants
                 </button>
                 <button className="btn btn-default"
                         disabled={working}
                         onClick={addAllPreliminaryApplicants}>
                     Add all {totalCount} candidates as preliminary applicants
                 </button>
             </>}
        </div>
        {!!error && <span className="text-danger">{error}</span>}
        {done && <div style={{ marginTop: '1em', display: 'flex', alignItems: 'center' }}>
            <img src="/static/images/success.png" alt="Success!" width="14px" height="14px" style={{ marginRight: '0.5em' }}/>
            <span>Added {addedCount} {singlePlural('applicant', addedCount)}.</span>
            &nbsp;<Link to={`/jobs/${vacancyId}/applicants/preliminary`}>Go to applicants list &rarr;</Link>
        </div>}
    </div>
}
