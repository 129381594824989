import React from 'react';
import type { Node } from 'react';

import Popover from 'react-bootstrap/lib/Popover';

import { ConceptLink } from '../articles/Concept';
import OverlayTrigger from '../widgets/OverlayTrigger';
import QConceptBrief from './ConceptBrief';
import type { OntologyConceptType, SimpleConceptType } from './Types';


export default function ConceptBriefPopover(
    { concept, actions }
    : {concept: SimpleConceptType, actions?: (concept: OntologyConceptType) => Node}
) {
    const conceptObj = { name: concept.name, conceptId: concept.id };
    const title = <>
        <span>{concept.name}</span>
        <ConceptLink concept={conceptObj}/>
    </>;
    const o = <Popover id="concept-brief-popover"
                       title={title}
                       className="concept-brief-popover concept-popover">
        <QConceptBrief id={concept.id} actions={actions}/>
    </Popover>;

    return <li>
        <OverlayTrigger overlay={o} rootClose>
            <button className="inline-link overlay-trigger">
                {concept.name}
            </button>
        </OverlayTrigger>
    </li>;
}
