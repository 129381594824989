import React from 'react';
import Alert from 'react-bootstrap/lib/Alert';

import { SearchCallsLimitExceededGuestMessage } from './accounts/GuestMessages';

import './css/loading.css';


export default function Loading(
    {what, loading, error, children = null, style, loadingString = 'Loading'}:
    {what?: string, loading?: bool, error?: any, children?: any, style?: any, loadingString?: string}
) {
    if (error) {
        if (error.message.indexOf('search-calls-count') !== -1 && error.message.indexOf('limit exceeded') !== -1) {
            const limit = error.message.split(' ').slice(-1)[0];
            let message = null;
            if (error.message.indexOf('is_anonymous') !== -1) {
                message = <SearchCallsLimitExceededGuestMessage limit={limit}/>
            }
            return <Alert bsStyle="info">
                {message}
            </Alert>;
        }

        return <div className="loading-wrapper error" style={style}>
            <h3>Error</h3>
            {error.message}
        </div>;
    }

    if (loading) {
        if (what) {
            loadingString += ' ' + what;
        }

        return <div className="loading-wrapper" style={style}>
            {loadingString}<span className="loading" aria-hidden="true" />
        </div>;
    }

    return children;
}
