import React from 'react';

import Row from 'react-bootstrap/lib/Row';
import DocumentTitle from 'react-document-title';

import helpers from './helpers';
import EditConceptForm from './EditConceptForm';
import withAuthorization from '../common/withAuthorization';

import type { EditConceptResponseType, OntologyConceptType } from './Types';

import './styles.css';
import queryString from 'query-string';


export class EditConceptPage extends React.Component {
    onSubmit = (res: EditConceptResponseType) => {
        if (res && res.data && res.data.concept && !res.deleting) {
            const concept = res.data.concept.concept;
            window.location.href = helpers.constructConceptPath(concept.id, concept.name);
        }
    };

    onMerge = (concept: OntologyConceptType) => {
        window.location.href = helpers.constructConceptPath(concept.id, concept.name);
    };

    render() {
        const { match: { params }, location: { search } } = this.props;
        const parsedQS = queryString.parse(search);
        const name = params.name || parsedQS.name;
        const conceptId = Number(params.id);

        return <Row>
            <DocumentTitle title="Edit concept - Ontology - Prophy" />
            <EditConceptForm
                id={conceptId}
                name={name}
                onSubmit={this.onSubmit}
                onMerge={this.onMerge} />
        </Row>;
    }
}

export default withAuthorization(EditConceptPage);
