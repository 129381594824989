import * as React from 'react';
import Reflux from 'reflux';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';

import Badge from 'react-bootstrap/lib/Badge';
import Button from 'react-bootstrap/lib/Button';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import MenuItem from 'react-bootstrap/lib/MenuItem';

import { getConceptTopicIndex } from '../common/helpers/recommendationTopics';
import helpers from '../ontologies/helpers';
import {
    ChosenRecommendationTopicsActions, ChosenRecommendationTopicsStore
} from '../recommendations/ChosenRecommendationTopicsStore';
import { cx, singlePlural } from '../utils';
import type { ConceptWithDFType, SimpleConceptType } from '../ontologies/Types';
import Loading from '../Loading';
import OverlayTrigger from '../widgets/OverlayTrigger';
import Popover from '../widgets/Popover';
import ConceptBadge from '../widgets/ConceptBadge';
import CollapsibleList from '../widgets/CollapsibleList';

import '../css/concept.css';


export type ConceptType = {
    conceptId: string,
    tf: number,
    name: string,
    basic: boolean,
    highlight?: boolean,
    df?: number
};

export const ConceptLink = (
    { concept, children }:
    { concept: {conceptId: string, name: string}, children?: React.Node }
) => (
    <Link target="_blank"
          to={helpers.constructConceptPath(concept.conceptId, concept.name)}>
        {children || 'Go to concept page →'}
    </Link>
);

export class SuggestionsPopover extends Reflux.Component {
    constructor(props) {
        super(props);
        this.store = ChosenRecommendationTopicsStore;
        this.state = {
            changed: false
        }
    }

    handleTopicChange = eventKey => {
        this.setState({ changed: true });

        const conceptTopicIndex = getConceptTopicIndex(
            this.props.concept.conceptId,
            this.state.topics,
        );

        if (conceptTopicIndex !== -1) {
            if (eventKey === 'remove') {
                ChosenRecommendationTopicsActions.removeConceptFromTopic(
                    this.props.concept.conceptId,
                    conceptTopicIndex
                );

                return;
            }

            ChosenRecommendationTopicsActions.moveConcept(
                this.props.concept.conceptId, conceptTopicIndex, eventKey
            );
        } else {
            // removing not existing concept is a noop
            if (eventKey === 'remove') {
                return;
            }
            ChosenRecommendationTopicsActions.addConceptToTopic(this.props.concept, eventKey);
        }
    }

    hideOk = isOpen => {
        if (isOpen) {
            this.setState({ changed: false });
        }
    }

    handleRemove = () => {
        ChosenRecommendationTopicsActions.removeConceptFromTopic(
            this.props.concept.conceptId,
            getConceptTopicIndex(this.props.concept.conceptId, this.state.topics),
        );
    }

    render() {
        // leave in popoverProps only what is needed for Popover
        const { concept, className, onHide, onApply, ...popoverProps } = this.props;
        const inTopicIndex = this.state.topics.findIndex(t => t.concepts.some(c => c.conceptId === concept.conceptId));
        const inTopic = this.state.topics[inTopicIndex];

        const title = <React.Fragment>
            <span>{concept.name}</span>
            {' '}
            <ConceptLink concept={concept}/>
        </React.Fragment>;
        const footer = <div className="btn-toolbar">
            <Button onClick={this.handleRemove} disabled={!inTopic}>
                Remove
            </Button>
            <Button onClick={onHide}>
                Close
            </Button>
            <Button bsStyle="primary" onClick={onApply} className="pull-right">
                Apply changes
            </Button>
        </div>;

        return <Popover
            id="concept-suggestions-popover"
            className={cx('concept-popover', className)}
            title={title}
            footer={footer}
            {...popoverProps}>
            <div>
                <label htmlFor="add-to-topic-select">
                    {inTopic ?
                        'Added to topic:'
                        : 'Add to topic:'
                    }
                </label>
                <DropdownButton title={inTopic ?
                    <><span className={`topic-color topic-color-${inTopicIndex}`}/>&nbsp;{inTopic.title}</>
                    : 'No topic'
                }
                                id="add-to-topic-select"
                                onSelect={this.handleTopicChange}
                                onToggle={this.hideOk}>
                    <MenuItem key="none" eventKey="remove">No topic</MenuItem>
                    {this.state.topics.map((t, i) => <MenuItem key={t.title} eventKey={i}>
                        <span className={`topic-color topic-color-${i}`}/>&nbsp;{t.title}
                    </MenuItem>)}
                </DropdownButton>
                <Glyphicon glyph="ok" className={cx({'disappeared': !this.state.changed})} />
            </div>
        </Popover>;
    }
}


export function Concept(
    {concept, popover, topicIndices = [], className, showDf = false, onRemove}:
    {
        concept: ConceptType, popover?: boolean, topicIndices?: Array<number>,
        className?: string
    }
) {
    const classNames = cx('concept concept-pointer', className, {
        'basic-concept': concept.basic,
        'highlighted-concept': concept.highlight,
        'original-concept': concept.original,
    });

    const popoverRef = React.useRef(null);
    const onHide = React.useCallback(() => {
        if (popoverRef.current) {
            popoverRef.current.handleHide();
        }
    }, []);

    const handleRemove = React.useCallback(e => {
        e.stopPropagation();
        onRemove(concept);
    }, [onRemove, concept]);

    const overlay = popover ?
        popover(concept, onHide)
        : <Popover id="basic-concept-popover"
                   title={<><span>{concept.name}</span><ConceptLink concept={concept}/></>}
                   className="concept-basic-popover">
            {concept.tf && <p>
                This concept occurs {concept.tf}{' '}
                {singlePlural('time', concept.tf)} in this article.
            </p>}
            {concept.original && <p>
                <span className="original-concept"/> means this article introduced
                the concept or had a big impact on its understanding
                {!concept.tf && ", but it wasn't named here by any of the modern terms"}.
            </p>}
            <QConceptDF concept={concept} />
        </Popover>;

    return <OverlayTrigger trigger="click" placement="bottom" rootClose
                           overlay={overlay} ref={popoverRef}>
        <ConceptBadge classNames={classNames} topicIndices={topicIndices}>
            {concept.name}
            <sup>{showDf ? concept.df : concept.tf}</sup>
            {onRemove && <>
                {' '}<span onClick={handleRemove}>&times;</span>
            </>}
        </ConceptBadge>
    </OverlayTrigger>;
}


type ConceptListProps = {
    concepts: Array<ConceptType>,
    collapsible: boolean,
    collapseThreshold: number,
    collapsedCount: number,
    highlightTopics: boolean,
    onRemove?: any,
    popover: boolean,
    static: boolean,
    className?: string,
};


type ConceptListState = {
    topicIndices: {}
};


export class ConceptList extends Reflux.Component {
    props: ConceptListProps;

    static defaultProps = {
        collapsible: false,
        collapseThreshold: 20,
        collapsedCount: 15,
        highlightTopics: true,
        onRemove: null,
        popover: false,
        static: false,
        showDf: false,
    };

    state: ConceptListState = {
        topicIndices: {}
    };

    constructor(props: ConceptListProps) {
        super(props);

        this.mapStoreToState(ChosenRecommendationTopicsStore, fromStore => {
            return fromStore.topicIndices ? { topicIndices: fromStore.topicIndices } : {};
        });
    }

    shouldComponentUpdate(nextProps: ConceptListProps, nextState: ConceptListState) {
        return Object.keys(nextProps).some(key => nextProps[key] !== this.props[key]) ||
            nextState.topicIndices !== this.state.topicIndices;
    }

    /* eslint-disable react/display-name */
    tumblerMarkup = this.props.static ?
        (collapsed: boolean, hiddenCount: number) => (
            <Badge className="concept static-more">
                {collapsed ? `... ${hiddenCount} more` : null}
            </Badge>
        )
        : (collapsed: boolean, hiddenCount: number, toggle: () => any) => (
            <Badge className="concept expand-concepts-link"
                onClick={toggle}>
                {collapsed ? `show ${hiddenCount} more →` : '← collapse'}
            </Badge>);
    /* eslint-enable react/display-name */

    render() {
        if (!this.props.concepts) return null;

        const { collapsible, popover, highlightTopics, showDf, onRemove, ...listProps } = this.props;
        const items = this.props.concepts.map(concept =>
            <Concept
                key={concept.conceptId}
                popover={popover}
                concept={concept}
                showDf={showDf}
                onRemove={onRemove}
                topicIndices={highlightTopics ?
                    this.state.topicIndices[concept.conceptId]
                    : []
                }
            />
        );

        const className = cx('concept-list', this.props.className);

        return <div className={className}>
            {collapsible ?
                <CollapsibleList tumblerMarkup={this.tumblerMarkup} { ...listProps }>
                    {items}
                </CollapsibleList>
                : items
            }
        </div>;
    }
}

export function ConceptDF(
    {concept, loading, error}:
    {concept: ConceptWithDFType, loading?: boolean, error?: any}
) {
    const ArticlesCount = ({ concept }: { concept: ?ConceptWithDFType }) => {
        if (!concept) {
            return null;
        }

        return <p>
            This concept occurs in approximately{' '}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={`/search/?q=c:${concept.id}`} target="_blank">
                {concept.df} {singlePlural('article', concept.df)}.
            </a>
        </p>;
    };

    return <Loading what="document frequency" loading={loading} error={error}>
        <ArticlesCount concept={concept} />
    </Loading>;
}

export function ConceptBasic({concept}: {concept: SimpleConceptType}) {
    return (
        concept.basic &&
        <div>This is a basic concept and it doesn&apos;t affect article analysis.</div>
    );
}

// TODO: Remove this GraphQL and use regular rest JSON api
export const QConceptDF = graphql(gql`
query concept($id: Int!) {
    concept(id: $id) {
        id
        df
    }
}
`, {
    options: ({ concept: { conceptId, df }}) => ({
        variables: { id: conceptId },
        skip: df !== undefined
    }),
    props: ({ data, ownProps }) => ({
        loading: data.loading,
        error: data.error,
        concept: data.loading ?
            ownProps.concept
            : { ...ownProps.concept, ...data.concept }
    })
})(ConceptDF);
