import React from 'react';
import qs from 'query-string';

import Bar from 'recharts/es6/cartesian/Bar';
import BarChart from 'recharts/es6/chart/BarChart';
import Tooltip from 'recharts/es6/component/Tooltip';
import XAxis from 'recharts/es6/cartesian/XAxis';
import YAxis from 'recharts/es6/cartesian/YAxis';

import Button from 'react-bootstrap/lib/Button';

import AuthorFromGroupList from '../../authors/AuthorFromGroupList';
import helpers from '../../referees/helpers';
import { cx, roundInteger, toFixed } from '../../utils';
import RefereeConcepts from '../../referees/RefereeConcepts';
import HideReferee from '../../referees/HideReferee';
import ArticleLink from '../../articles/ArticleLink';
import AuthorLink from '../../authors/AuthorLink';
import AffiliationItem from '../../authors/AffiliationItem';
import GenderIndicator from '../../common/GenderIndicator';

import { defaultColor, memberColor, hoverColor, selectedColor } from './PanelChart';
import ChartCursor from './ChartCursor';
import BarBackground from './BarBackground';
import LoadingButton from '../../widgets/LoadingButton';
import { useLoadingAwareSubmit } from '../../common/helpers/loading';
import BarLabel from './BarLabel';
import { getHiddenTitle, renderHiddenIcon } from '../../referees/RefereeLabels'

import './styles.css';


const margin = { top: 0, right: 0, left: 0, bottom: 0 };

const truncate = (text, limit = 45) => text.length > limit ?
    `${text.slice(0, limit)}...`
    : text;

const formatter = (value, label, { payload }) => `${toFixed(value, 2)} (#${payload.position})`;


const PanelMemberRow = React.memo(function PanelMemberRow({
    isCandidate, person, scoreChartDomain, panel, compositionId, isEditable,
    onPersonSelect, onPersonToggle, onPersonHide,
    sortedBySubpanels, onSubpanelBarClick, isSelected, isHovered, isCustom,
}) {
    const [expanded, setExpanded] = React.useState(false);
    const classNames = cx('panel-member-row', { expanded, 'panel-member-custom': isCustom });
    const concepts = React.useMemo(
        () => person.mostRelevantConcepts.map(c => ({ ...c, conceptId: c.id })),
        [person]
    );
    const barData = React.useMemo(
        () => panel.subpanels.map(({ id, code }) => ({
            position: person.subpanelId2position[id],
            score: person.subpanelId2score[id] || 0,
            name: code,
        })),
        [person, panel]
    );
    const { working, done, submit: toggleCandidateWrapped } = useLoadingAwareSubmit(
        { submitFn: () => onPersonToggle(person), noDone: true }
    );

    const handlePersonSelect = React.useCallback(e => {
        onPersonSelect(person, e.currentTarget.checked, null);
    }, [onPersonSelect, person]);

    const mouseHandlers = {
        onMouseEnter: React.useCallback(
            (e) => {
                onPersonSelect(person, null, true);
            },
            [onPersonSelect, person]
        ),
        onMouseLeave: React.useCallback(
            (e) => {
                onPersonSelect(person, null, false);
            },
            [onPersonSelect, person]
        ),
    };

    const labelFormatter = React.useCallback(labelCode => {
        try {
            const [, { documents, name }] = Object.entries(panel.subpanels).find(
                ([id, { code }]) => code === labelCode);
            return <BarLabel code={labelCode} name={name} docCount={documents.total} />;
        } catch (e) {
            console.error(`No subpanel found for ${labelCode}`);
            return null;
        }
    }, [panel]);

    const onBarClick = React.useCallback((payload, index, e) => {
        onSubpanelBarClick(payload, e.shiftKey);
    }, [onSubpanelBarClick]);

    const handleHide = React.useCallback(
        (reason, comment) => helpers
            .hideReferees('panel', panel.id, [person.author.id], reason, comment)
            .then(({ ok }) => {
                if (ok) {
                    onPersonHide(person);
                }
            }),
        [person, panel, onPersonHide]
    );

    let borderColor;
    if (isHovered) {
        borderColor = hoverColor;
    } else if (isSelected) {
        borderColor = selectedColor;
    } else {
        borderColor = person.status && memberColor;
    }

    const hiddenTitle = getHiddenTitle(person.hideReasons);

    return <React.Fragment key={person.author.id}>
        <tr className={classNames} {...mouseHandlers}>
            <td style={{borderLeft: !borderColor ? `5px solid transparent` : `5px solid ${borderColor}`,
                        paddingTop: '5px'}}>
                <input type="checkbox" onChange={handlePersonSelect} checked={isSelected} />
            </td>
            <td className="text-right">{person.position}</td>
            <td className="referee-name-cell">
                {/* eslint-disable-next-line */}
                <a className="referee-row-anchor" id={`author-${person.author.id}`}></a>

                <AuthorLink id={person.author.id} name={person.author.name} target="_blank">
                    {person.author.name}
                </AuthorLink>{' '}

                <AuthorFromGroupList list={person.authorFromGroupList} />

                {person.status === 'leaves' &&
                    <small className="text-danger">leaves</small>
                }
                {person.status === 'candidate' &&
                    <small className="text-info">added&nbsp;candidate</small>
                }
                {person.status === 'member' &&
                    <small className="text-info">member</small>
                }
            </td>
            <td className="panel-member-candidate-icons-cell">
                <GenderIndicator authorId={person.author.id}
                                 gender={person.author.gender}
                                 manualGender={person.author.manualGender} />
                {!!hiddenTitle && <div className="referee-icons referee-icons-singleline">
                    {renderHiddenIcon(hiddenTitle)}
                </div>}
            </td>
            <td className="text-right">{toFixed(person.score, 0)}</td>
            <td>
                <BarChart
                    width={100}
                    height={30}
                    data={barData}
                    margin={margin}>
                    <XAxis dataKey="name" hide />
                    <YAxis yAxisId="left" hide domain={scoreChartDomain} />
                    <Tooltip
                        label="Score"
                        formatter={formatter}
                        labelFormatter={labelFormatter}
                        wrapperStyle={{ top: '100%', zIndex: 1 }}
                        contentStyle={{ padding: '5px 10px' }}
                        cursor={<ChartCursor onClick={onSubpanelBarClick} />}
                        separator=": "
                    />
                    <Bar name="Score"
                         yAxisId="left"
                         dataKey="score"
                         fill={!borderColor ? defaultColor : borderColor}
                         onClick={onBarClick}
                         isAnimationActive={false}
                         cursor="pointer"
                         background={props =>
                             <BarBackground {...props}
                                            cursor="pointer"
                                            isSortedBy={sortedBySubpanels.indexOf(props.payload.name) !== -1} />
                         }
                    />
                </BarChart>
            </td>
            <td className="text-right">{person.articlesCount}</td>
            <td className="text-right">{person.hIndex}</td>
            <td className="text-right">{roundInteger(person.citationsCount)}</td>
            <td>
                <button className="inline-link" onClick={() => setExpanded(!expanded)}>
                    {expanded ? 'less' : 'more'}
                </button>
            </td>
        </tr>
        <tr className={cx({ expanded, 'panel-member-custom': isCustom })} {...mouseHandlers}>
            <td colSpan="10">
                <div>
                    <RefereeConcepts
                        highlightTopics={false}
                        mostRelevantConcepts={concepts} />
                </div>
                <div>
                    {!person.mostRelevantDocuments.length &&
                     <div><strong>No relevant articles</strong></div>}
                    {!!person.mostRelevantDocuments.length && !panel.noSubpanelDocs && <>
                        <div>
                            <strong>Most similar proposal &rarr; most similar article</strong>
                        </div>
                        <ul>
                            {person.mostRelevantDocuments.map(pair => <li key={pair.document.docKind + pair.document.docId}>
                                <a href={`/${pair.document.docKind}/${pair.document.docId}`} title={pair.document.title} target="_blank" rel="noopener noreferrer">
                                    {truncate(pair.document.title)}
                                </a> &rarr; <ArticleLink {...pair.article} showTitle>
                                    {truncate(pair.article.title)}
                                </ArticleLink>
                            </li>)}
                        </ul>
                    </>}
                    {!!person.mostRelevantDocuments.length && panel.noSubpanelDocs && <>
                        <div>
                            <strong>Most similar articles</strong>
                        </div>
                        <ul>
                            {person.mostRelevantDocuments.map(pair => <li key={pair.article.id}>
                                <ArticleLink {...pair.article} showTitle>
                                    {truncate(pair.article.title, 80)}
                                </ArticleLink>
                            </li>)}
                        </ul>
                    </>}                </div>
                <div className="affiliations">
                    <strong>Most recent affiliations</strong>
                    {!!person.affiliations.length && <ul>
                        {person.affiliations.map(aff =>
                            <li key={aff.institutionId}>
                                <AffiliationItem item={aff} /><sup>{aff.count}</sup>
                            </li>)}
                    </ul>}
                </div>
                <div className="actions">
                    {isCandidate ? <>
                        <HideReferee
                            options={helpers.HIDE_REASONS}
                            onHideReferee={handleHide}
                            refereeType="candidate"
                            id={`hide-${person.author.id}`} />
                        {isEditable &&
                            <LoadingButton onClick={toggleCandidateWrapped}
                                           working={working}
                                           done={done}>
                                {person.status === null ?
                                    'Add to composition'
                                    : 'Remove from composition'}
                            </LoadingButton>
                        }
                    </> : <>
                        <Button href={`/panel-composer/${panel.id}/compositions/${compositionId}/candidates?${qs.stringify({ sort: person.topSubpanelsCodes })}`} target="_blank">
                            Find a replacement
                        </Button>

                        {isEditable &&
                            <LoadingButton onClick={toggleCandidateWrapped}
                                           working={working}
                                           done={done}
                                           disabled={working}>
                                Remove from composition
                            </LoadingButton>
                        }
                    </>}
                </div>
            </td>
        </tr>
    </React.Fragment>;
});

export default PanelMemberRow;
