export default {
    hasParent(el: Element, parent: Element | string) {
        let p = el;

        // eslint-disable-next-line no-cond-assign
        while (p && (p = p.parentNode) && !(p instanceof Document)) {
            if (typeof parent === 'string' ? this.matches(p, parent) : p === parent) {
                return true;
            }
        }

        return false;
    },
    matches: (el: Element, selector: string) => {
        const matches = Element.prototype.matches ||
            Element.prototype.webkitMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.mozMatchesSelector;

        if (el instanceof HTMLElement) {
            return matches.call(el, selector);
        }

        return false;
    }
};
