import { sendRequest } from '../datastore';
import { makeArticleKey } from '../articles/ArticleData';


export function makeFullAuthorKey(authorId, queryString) {
    let key = `author-${authorId}`;
    if (queryString) {
        key = `${key}-${queryString}`;
    }
    return key;
}

export function makeAuthorConceptsKey(authorId) {
    return `author-concepts-${authorId}`;
}


export function makeAuthorArticlesKey(authorId, queryString, offset) {
    let key = `author-articles-${authorId}`;
    if (queryString) {
        key = `${key}-qs-${queryString}`;
    }
    if (offset) {
        key = `${key}-offset${offset}`;
    }
    return key;
}


export const makeFullAuthorQuery = (authorId, queryString) => ({
    id: makeFullAuthorKey(authorId, queryString),
    moreIds: [makeAuthorArticlesKey(authorId, queryString)],
    get: makeFullAuthorKey(authorId, queryString),
    set: function (store, { value: { author, authorArticles, articles } }) {
        store[makeFullAuthorKey(authorId, queryString)] = author;
        store[makeAuthorArticlesKey(authorId, queryString)] = authorArticles;

        if (articles) {
            articles.forEach(article => {
                store[makeArticleKey(article.id)] = article;
            });
        }
    },
    req: () => sendRequest(
        `/api/authors/${authorId}/full${queryString}`),
});


export const makeAuthorConceptsQuery = (authorId) => ({
    id: makeAuthorConceptsKey(authorId),
    get: makeAuthorConceptsKey(authorId),
    set: function (store, { value: { author } }) {
        store[makeAuthorConceptsKey(authorId)] = author;
    },
    req: () => sendRequest(
        `/api/authors/${authorId}/concepts`),
});


export const makeLoadMoreArticlesQuery = (authorId, queryString, offset) => ({
    // add offset to id for request identification
    id: makeAuthorArticlesKey(authorId, queryString, offset),
    get: makeAuthorArticlesKey(authorId, queryString),
    set: function (store, { value: { authorArticles, articles } }) {

        const authorArticlesKey = makeAuthorArticlesKey(authorId, queryString);
        if (authorArticles && store[authorArticlesKey]) {
            const existingArticleIds = store[authorArticlesKey].articleIds;
            store[authorArticlesKey] = {
                hasMoreArticles: authorArticles.hasMoreArticles,
                articleIds: existingArticleIds.concat(authorArticles.articleIds),
            };
        } else {
            store[authorArticlesKey] = authorArticles;
        }

        if (articles) {
            articles.forEach(article => {
                store[makeArticleKey(article.id)] = article;
            });
        }
    },
    req: () => sendRequest(
        `/api/authors/${authorId}/articles${queryString || '?'}&offset=${offset || 0}`),
});
