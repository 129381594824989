import React from 'react';

import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/lib/Alert';

import withLogs from './withLogs';

import { useCurrentUser } from '../accounts/hooks';


export function PendingOntologyChangesAlert(
    { data: { ontologyLogs, loading }, conceptId }
) {
    const [currentUser] = useCurrentUser();
    const isModerator = !!currentUser.activeQuotas.ontology_moderator;

    if (!isModerator || !conceptId || !ontologyLogs || !ontologyLogs.length) {
        return null;
    }

    const { are, count, changes } = ontologyLogs.length > 1 ?
        { are: 'are', count: ontologyLogs.length, changes: 'changes' }
        : { are: 'is', count: 'one', changes: 'change' };

    return <Alert bsStyle="warning">
        There {are} {count} pending {changes}.
        Click&nbsp;
        <Link to={`/ontology/concept/log/${conceptId}`}>
            here
        </Link> to review.
    </Alert>;
}

export default withLogs(PendingOntologyChangesAlert);
