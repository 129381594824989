import React, { useState, useEffect, useMemo } from 'react';
import qs from 'query-string';

import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Panel from 'react-bootstrap/lib/Panel';
import Row from 'react-bootstrap/lib/Row';

import { Concept, ConceptList } from '../articles/Concept';
import { sendRequest, updateGlobalData, useSubscription } from '../datastore';
import AdvisorApplicant from './AdvisorApplicant';
import RelevantArticlesList from '../common/RelevantArticlesList';
import {
    makeSearchApplicantsByConceptsKey,
    makeSearchApplicantsTopConceptsKey,
    setJobConcepts,
} from './JobData';

import SortableTable from '../widgets/SortableTable';
import { SuggestBox } from '../widgets/Suggest';
import { noop } from '../utils';
import LoadingREST from '../LoadingREST';
import useModal from '../common/useModal';
import ApplicantTagModal from './tags/ApplicantTagModal';
import helpers from './helpers';
import ApplicantTags from './ApplicantTags';
import BodyClass from '../common/BodyClass';


const COLUMNS = [
    {
        field: 'id',
        key: true
    },
    {
        text: '',
        selectable: true
    },
    {
        field: 'applicant',
        text: 'Applicant',
    },
    {
        field: 'mostRelevantArticles',
        text: 'Most relevant articles',
    },
];

export default function JobApplicantsSearch({ jobId, showPreliminaries, minSim }) {
    const [selected, setSelected] = useState([]);
    const [concepts, setConcepts] = useState([]);
    const conceptIds = useMemo(() => concepts.map(c => c.conceptId), [concepts]);

    const [reqstate, setReqstate] = useState({loading: true});
    useEffect(() => {
        let cancel = false;
        const args = { minSim, cids: conceptIds };
        if (showPreliminaries) {
            args.withPreliminaries = true;
        }

        setReqstate({loading: true});
        sendRequest(`/api/jobs/vacancy/${jobId}/concepts?${qs.stringify(args)}`)
            .then((data) => {
                if (!cancel) {
                    updateGlobalData(
                        setJobConcepts.bind(null, jobId, conceptIds, showPreliminaries, minSim),
                        data
                    );
                    setReqstate({loading: false, ok: true});
                }
            })
        return () => {cancel = true;}
    }, [jobId, conceptIds, showPreliminaries, minSim]);

    const applicants = useSubscription(makeSearchApplicantsByConceptsKey(
        jobId, conceptIds, showPreliminaries, minSim));
    const topConcepts = useSubscription(makeSearchApplicantsTopConceptsKey(
        jobId, conceptIds));
    const [tagModalVisible, { hide, show: showAddTagModal }] = useModal();

    const suggestConcept = query => {
        return sendRequest(`/api/autocomplete/concepts?query=${query}`, {
            credentials: 'same-origin',
        }).then(({ ok, value }) => {
            return Promise.resolve(value);
        });
    };

    const handleSuggestSelect = ({ isNotSuggest, id, label }) => {
        if (isNotSuggest) {
            return;
        }

        if (!concepts.find(c => c.conceptId === id)) {
            setConcepts([...concepts, { conceptId: id, name: label }]);
        }
    };

    const selectApplicant = id => {
        setSelected(selected => selected.indexOf(id) !== -1 ?
            selected.filter(sid => sid !== id)
            : [...selected, id]
        );
    }

    const selectPerson = (person, checked) => {
        selectApplicant(person.applicationId);
    }

    const afterAddTag = (tag, applicationId) => {
        updateGlobalData(store => {
            const key = makeSearchApplicantsByConceptsKey(jobId, conceptIds);
            store[key] = store[key].map(app =>
                applicationId === app.applicationId ?
                    { ...app, tagsIds: [...app.tagsIds, tag.id] }
                    : app
            );
        });
    }

    const handleRemoveTag = (tag, applicationId) => {
        updateGlobalData(store => {
            const key = makeSearchApplicantsByConceptsKey(jobId, conceptIds);
            store[key] = store[key].map(app =>
                applicationId === app.applicationId ?
                    { ...app, tagsIds: app.tagsIds.filter(id => id !== tag.id) }
                    : app
            )
        });
    }

    const renderRow = (applicant, index) => {
        const { applicationId } = applicant;
        return <tr key={applicationId}>
            <td className="check">
                <div className="cell-wrapper">
                    <input type="checkbox"
                           checked={selected.indexOf(applicationId) !== -1}
                           onChange={() => selectApplicant(applicationId)}/>
                </div>
            </td>
            <td className="name">
                {/* eslint-disable-next-line */}
                <a className="row-anchor" id={`applicant-${applicationId}`}></a>
                <AdvisorApplicant id={applicationId} short />
                <ApplicantTags
                    applicant={{ ...applicant, vacancyId: jobId }}
                    onAdd={tag => afterAddTag(tag, applicationId)}
                    onRemove={tag => handleRemoveTag(tag, applicationId)}
                />
            </td>
            <td>
                <RelevantArticlesList articles={applicant.mostRelevantArticles} />
            </td>
        </tr>;
    };

    const addTag = tag => {
        return helpers.addTag(tag, selected, jobId)
            .then(({ ok, value: tag }) => {
                selected.forEach(applicationId => afterAddTag(tag, applicationId));
            });
    }

    const isSelected = applicant => {
        return selected.indexOf(applicant.applicationId) !== -1;
    }

    return <div className="job-applicants-search">
        <BodyClass className="job-applicants-table-page"/>
        <Panel className="panel-table-container job-applicants" expanded={true} onToggle={noop}>
            <Panel.Body>
                <p>Search applicants by concepts and assign tags to them</p>
                <Row>
                    <Col xs={12} md={4}>
                        <div className="search-omnibox input-group">
                            <SuggestBox
                                suggestFetch={suggestConcept}
                                shouldClearOnSelect
                                placeholder="Type concept name"
                                callback={handleSuggestSelect}
                            />
                        </div>
                    </Col>
                    <Col xs={12} md={8} className="concepts">
                        {concepts.map(concept =>
                            <Concept
                                key={concept.conceptId}
                                concept={concept}
                                onRemove={() => setConcepts(concepts.filter(c => c.conceptId !== concept.conceptId))}
                            />
                        )}
                    </Col>
                </Row>
                {!!topConcepts?.length &&
                <div>
                    <h4>Top concepts for {!!applicants?.length ? 'found' : 'all'} applicants</h4>
                    <ConceptList
                        showDf
                        collapsible
                        className="top-concepts"
                        concepts={topConcepts}
                    />
                </div>}
                {!!applicants?.length &&
                    <div className="mass-actions">
                        <Button onClick={showAddTagModal} disabled={!selected.length}>
                            <span className="icon">
                                <Glyphicon glyph="tag"/>
                            </span> Add tag
                        </Button>
                    </div>
                }
                {!!conceptIds.length &&
                    <LoadingREST what="applicants" reqstate={reqstate} doc={applicants}>
                        <SortableTable
                            className="table-sticky-head"
                            columns={COLUMNS}
                            data={showPreliminaries ? applicants :
                                applicants?.filter(appl => !appl.preliminary)}
                            isSelected={isSelected}
                            onSelect={selectPerson}
                            renderRow={renderRow}
                            renderNoResults={() => <tr>
                                <td colSpan="4" className="text-center">No applicants found.</td>
                            </tr>}
                        />
                    </LoadingREST>
                }
            </Panel.Body>
        </Panel>
        <ApplicantTagModal show={tagModalVisible} jobId={jobId} onHide={hide} onAssign={addTag} />
    </div>
}
