import React from 'react';

import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';

import queryString from 'query-string';

import DocsBatchDetails from './possibleConcepts/DocsBatchDetails';
import withAuthorization from '../common/withAuthorization';

import type {
    ReactRouterMatchType
} from '../common/Types';


function OntologyPossibleConceptsPage(
    { match, location: { search }, history }
    : { match: ReactRouterMatchType, location: { search: string }, history: any}
) {
    const showHidden = queryString.parse(search).showHidden === 'true';

    return <Row className="possible-concepts-list">
        <Col xs={12}>
            <a href="/ontology/possible-concepts">&larr; back to the list</a>
            <DocsBatchDetails id={Number(match.params.id)} showHidden={showHidden} history={history}/>
        </Col>
    </Row>;
}

export default withAuthorization(OntologyPossibleConceptsPage);
