import React from 'react';

import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/lib/Button';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import Modal from 'react-bootstrap/lib/Modal';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import type { CurrentUserType } from '../accounts/Types';
import type { ApplicationType } from './Types';
import TextWithTooltip from '../widgets/TextWithTooltip';
import Collapsible from '../widgets/Collapsible';
import { sendRequest, updateGlobalData } from '../datastore';
import { setApplicantComment } from './JobData';
import withCurrentUser from '../common/withCurrentUser';

import { KEYS } from '../utils';


type ApplicantCommentPropsType = {
    applicant: ApplicationType,
    currentUser: CurrentUserType,
    showAll?: boolean
};

type ApplicantCommentStateType = {
    showCommentModal: boolean,
    working: boolean,
    comment: string
};


export class ApplicantComments extends React.Component<
    ApplicantCommentPropsType,
    ApplicantCommentStateType
> {
    static defaultProps = {
        showAll: false
    };

    ownComment = () => this.props.applicant.comments.find(c => c.ownComment);

    constructor(props: ApplicantCommentPropsType) {
        super(props);

        const ownComment = this.ownComment();

        this.state = {
            showCommentModal: false,
            comment: ownComment ? ownComment.comment : '',
            working: false
        };
    }

    commentInput: ?HTMLInputElement;

    setCommentInput = (el: ?HTMLInputElement) => {
        if (!this.commentInput && el) {
            el.selectionStart = 0;
            el.selectionEnd = el.value.length;
            el.focus();
        }

        this.commentInput = el;
    }

    openCommentModal = () => {
        this.setState({ showCommentModal: true });
    }

    closeCommentModal = () => {
        this.setState({ showCommentModal: false });
    }

    cancel = () => {
        const ownComment = this.ownComment();

        this.setState({
            comment: ownComment ? ownComment.comment : ''
        });
        this.closeCommentModal();
    }

    remove = () => {
        this.setState({ comment: '' });
        return this.comment('').then(this.closeCommentModal);
    }

    commentAndClose = () => {
        return this.comment().then(this.closeCommentModal);
    }

    handleCommentKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
        if (e.which === KEYS.ENTER) {
            e.preventDefault();
            this.commentAndClose();
        }
    }

    handleCommentChange = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
        this.setState({ comment: e.currentTarget.value });
    }

    comment = (overrideComment) => {
        const comment = overrideComment !== undefined ? overrideComment : this.state.comment;
        const revoke = !comment;
        const applicationId = this.props.applicant.applicationId;
        this.setState({ working: true });

        return sendRequest('/api/jobs/comment/', revoke ?
            { method: 'DELETE', body: JSON.stringify({ applicationId }), }
            : { method: 'POST', body: JSON.stringify({ comment, applicationId }) }
        ).then(response => {
            this.setState({ working: false });

            if (response.ok) {
                updateGlobalData(
                    setApplicantComment,
                    {
                        vacancyId: this.props.applicant.vacancyId,
                        applicationId,
                        revoke,
                        comment,
                        currentUser: this.props.currentUser
                    }
                );
            }
        });
    }

    render() {
        const ownComment = this.ownComment();
        const comments = this.props.applicant.comments || [];
        const ctaLabel = ownComment ? 'Edit comment' : 'Add comment';
        const ctaButton = <button className="inline-link applicant-comment-button" onClick={this.openCommentModal}>
            <Glyphicon glyph="pencil" />
            {' '}{ctaLabel}
        </button>;

        const commentModal = <Modal show={this.state.showCommentModal}
                id="job-applicant-comment-modal"
                onHide={this.cancel}
                animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{ctaLabel}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label className="full-width" htmlFor="vote-comment">
                    Type your comment here:
                </label>
                <textarea id="vote-comment"
                          value={this.state.comment}
                          className="form-control"
                          disabled={this.state.working}
                          rows={5}
                          ref={this.setCommentInput}
                          onKeyDown={this.handleCommentKeyDown}
                          onChange={this.handleCommentChange} />
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar className="pull-left">
                    <Button onClick={this.remove} bsStyle="danger">Delete</Button>
                </ButtonToolbar>
                <ButtonToolbar className="pull-right">
                    <Button onClick={this.cancel}>Cancel</Button>
                    <Button onClick={this.commentAndClose} bsStyle="primary">Save</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>;

        if (comments.length > 0 && this.props.showAll) {
            // display list of comments with own comment last and no comment counter
            return <>
                <ul>
                    {comments.filter(c => c !== ownComment)
                        .map((c, i) => <li key={i}>
                        <div className="applicant-comment talk-bubble tri-right btm-left">
                            <Collapsible className="talktext">
                                <p>{c.comment}</p>
                            </Collapsible>
                            <div className="comment-author">
                                {c.name}&nbsp;
                                {c.equalityOfficer &&
                                    <TextWithTooltip help="Equal Opportunity Office" id="equal-opportunity-office-tooltip">
                                        (EOO<sup>?</sup>)
                                    </TextWithTooltip>}
                            </div>
                        </div>
                    </li>)}
                    <li>
                        {ownComment ?
                            <div className="applicant-comment own-comment talk-bubble tri-right btm-right">
                                <Collapsible className="talktext">
                                    <p>{ownComment.comment}</p>
                                </Collapsible>
                                <div className="comment-author">
                                    {ctaButton}
                                </div>
                            </div>
                            : ctaButton
                        }
                    </li>
                </ul>
                {commentModal}
            </>
        }

        if (ownComment) {
            // display own comment with comment counter and "add comment" button
            return <>
                <div className="comment applicant-comment talk-bubble tri-right btm-left">
                    <Collapsible className="talktext">
                        <p>{ownComment.comment}</p>
                    </Collapsible>
                    <div className="comment-author">
                        {ctaButton}&nbsp;&nbsp;
                        {!!comments.length && <>
                            &nbsp;&nbsp;
                            <Link to={`/jobs/${this.props.applicant.vacancyId}/applicant/${this.props.applicant.applicationId}`}
                                  target="_blank"
                                  title={comments.length + " comments"}
                                  className="comment-counter">
                                 <Glyphicon glyph="comment" />&nbsp;{comments.length}
                            </Link>
                        </>}
                    </div>
                </div>
                {commentModal}
            </>;
        }

        // just show the "add comment" button and a counter if there are other comments
        return <>
            <div className="comment-author pull-right">
                {ctaButton}
                {!!comments.length && <>
                    &nbsp;&nbsp;
                     <Link to={`/jobs/${this.props.applicant.vacancyId}/applicant/${this.props.applicant.applicationId}`}
                           target="_blank"
                           title={comments.length + " comments"}
                           className="comment-counter"
                            style={{float: 'right', padding: '0px 3px', color: '#999'}}>
                        <Glyphicon glyph="comment" />&nbsp;{comments.length}
                    </Link>
                </>}
            </div>
            {commentModal}
        </>;
    }
}

export default withCurrentUser(ApplicantComments);
