export default {
    log(action, data) {
        return fetch('/api/actions-log', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                action,
                payload: data
            }),
        });
    }
}
