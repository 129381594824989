import React from 'react';


const SelectedRefereesContext = React.createContext();

const reducer = (selectedReferees, action) => {
    switch (action.type) {
        case 'SET_SELECTED':
            const checkedAuthorId = action.payload.referee.author.id;
            return action.payload.checked ?
                selectedReferees.some(alreadySelected => alreadySelected.author.id === checkedAuthorId) ?
                    selectedReferees
                    : [...selectedReferees, action.payload.referee]
                : selectedReferees.filter(alreadySelected => alreadySelected.author.id !== checkedAuthorId);
        case 'SELECT':
            return selectedReferees.some(r => r.author.id === action.payload.author.id) ?
                selectedReferees
                : [...selectedReferees, action.payload];
        case 'CLEAR':
            return []
        default:
            throw new Error(`SelectedRefereesContext: unknown action type "${action.type}"`);
    }
};

export const SelectedRefereesContextProvider = ({ children }) => {
    const [selectedReferees, dispatch] = React.useReducer(reducer, []);

    const setSelected = React.useCallback((referee, checked) => {
        dispatch({
            type: 'SET_SELECTED',
            payload: {referee, checked},
        });
    }, [dispatch]);

    const clearSelected = React.useCallback(() => {
        dispatch({ type: 'CLEAR' });
    }, [dispatch]);

    return (
        <SelectedRefereesContext.Provider value={[selectedReferees, { clearSelected, setSelected }]}>
            {children}
        </SelectedRefereesContext.Provider>
    );
};

export default SelectedRefereesContext;
