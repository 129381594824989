import React from 'react';

import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import SelectedRefereesContext from './SelectedRefereesContext';

import helpers from '../jobs/helpers';
import { updateGlobalData } from '../datastore';
import { makeRefereesKey } from './RefereesData';


export default function MarkSelectedAsReferee(
    {
        proposalId,
        markedAuthorsIds,
        clearSelected
    }:
    {
        proposalId: number,
        markedAuthorsIds: Array<number>,
        clearSelected: () => any,
    }
) {
    const [selectedReferees] = React.useContext(SelectedRefereesContext);
    const mode = selectedReferees.length > 0 && selectedReferees.every(
        referee => markedAuthorsIds.indexOf(referee.author.id) !== -1)
        ? 'unmark'
        : 'mark';

    const [working, setWorking] = React.useState(false);
    const [error, setError] = React.useState(null);

    const setStates = (working, error) => {
        setWorking(working);
        setError(error);
    };

    const postAndUpdateStore = () => {
        setWorking(true);
        const authorsIds = selectedReferees.map(referee => referee.author.id);

        let postFunction;
        let clearAfterSuccess = false;
        if (mode === 'mark') {
            postFunction = helpers.markAsReferees;
        } else {
            postFunction = helpers.unmarkAsReferees;
            clearAfterSuccess = true;
        }

        postFunction(proposalId, authorsIds)
            .then(response => {
                const { ok, value } = response;

                if (ok) {
                    setStates(false, null);

                    const [key, ] = makeRefereesKey('proposal', proposalId);
                    updateGlobalData((store, { value: { markedAuthorsIds }} ) => {
                        store[key] = {
                            ...store[key],
                            markedAuthorsIds: markedAuthorsIds,
                        };

                    }, response);
                    if (clearAfterSuccess) {
                        clearSelected();
                    }
                } else {
                    setStates(false, value.error);
                }
            });
    }

    return <>
        <Button className="btn btn-default"
                disabled={selectedReferees.length === 0 || working}
                onClick={postAndUpdateStore}
                title="Select candidates to mark them as referees">
            <span>
                {mode === 'mark'
                    ? <><Glyphicon glyph="ok"/>&nbsp;Mark as referee</>
                    : <><Glyphicon glyph="remove"/>&nbsp;Unmark as referee</>
                }
            </span>
        </Button>
        {!!error && <Alert bsStyle="danger"
                           className="alert-fixed-bottom-right"
                           onDismiss={() => {setError(null)}}>
            <p>{error}</p>
        </Alert>}
    </>
}
