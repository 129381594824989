import React from 'react';

import RelevantArticle from '../articles/RelevantArticle';


export default function RelevantArticlesList({ title, articles }) {
    if (!articles || !articles.length) {
        return null;
    }

    title = title || 'Most relevant articles:';

    return <div className="most-relevant-articles">
        <div>
            <strong>{title}</strong>
        </div>
        <ul>
            {articles.map(a => <RelevantArticle key={a.id} article={a} />)}
        </ul>
    </div>
}
