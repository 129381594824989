import * as React from 'react';

import { Route, Switch } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import { CurrentUserContextProvider } from './accounts/CurrentUserContext';

import Header from './layout/Header';
import Footer from './layout/Footer';
import ArticlePossibleConceptsPage from './articles/ArticlePossibleConceptsPage';

import FindAuthors from './accounts/FindAuthors';
import ClaimArticles from './articles/ClaimArticles';

import ApplicantRecommendationPage from './jobs/ApplicantRecommendationPage';
import ClaimAdvisorArticles from './jobs/ClaimAdvisorArticles';
import EditApplicationPage from './jobs/EditApplicationPage';
import EditJobPage from './jobs/EditJobPage';
import JobApplicantPage from './jobs/JobApplicantPage';
import JobApplicantsPage from './jobs/JobApplicantsPage';

import { MonitoringLoader, NewMonitoring } from './recommendations/Recommendation';

import RefereesPage from './referees/RefereesPage';
import FolderSettingsPage from './referees/FolderSettingsPage';
import OrganizationSettingsPage from './referees/OrganizationSettingsPage';

import PanelMemberCandidatesPage
    from './proposals/panelMembers/PanelMemberCandidatesPage';
import PanelMembersPage from './proposals/panelMembers/PanelMembersPage';
import SubpanelDocuments from './proposals/panelMembers/SubpanelDocuments';

import ProposalPossibleConceptsPage from './proposals/ProposalPossibleConceptsPage';

import OntologyModerationPage from './ontologies/OntologyModerationPage';
import OntologyPossibleConceptsPage from './ontologies/OntologyPossibleConceptsPage';
import EditConceptPage from './ontologies/EditConceptPage';
import ImportConceptsPage from './ontologies/ImportConceptsPage';

import FlashMessages from './widgets/FlashMessages';
import FileExchangePage from './fileExchange/FileExchangePage';

// is needed for server-side rendered Article and Proposal pages
// do not delete before moving all relevant styles to server side
import './articles/article.css';


export default function App() {
    return <div>
        <CurrentUserContextProvider>
            <Route component={Header} />

            <DocumentTitle title="Prophy"/>

            <div className="container" id="page-container">
                <Route component={FlashMessages} />
                <Switch>
                    <Route path="/find-authors/:attorneyToken" component={FindAuthors}/>
                    <Route path="/find-authors" component={FindAuthors}/>
                    <Route path="/edit-recommender-profile/:attorneyToken" component={FindAuthors}/>
                    <Route path="/claim-publications/:attorneyToken" component={ClaimAdvisorArticles}/>
                    <Route path="/claim-publications" component={ClaimArticles}/>

                    <Route path="/article/:id/possible-concepts" component={ArticlePossibleConceptsPage}/>

                    <Route path="/referees-beam/folder/:folderId/settings" component={FolderSettingsPage}/>
                    <Route path="/referees-beam/organization/:orgId/settings" component={OrganizationSettingsPage}/>
                    <Route path="/referees-beam/:docKind/:docId" component={RefereesPage}/>

                    <Route path="/monitoring/:id" component={MonitoringLoader}/>
                    <Route path="/monitoring" component={NewMonitoring}/>

                    <Route path="/panel-composer/:id/compositions/:compositionId/members" component={PanelMembersPage}/>
                    <Route path="/panel-composer/:id/compositions/:compositionId/candidates" component={PanelMemberCandidatesPage}/>
                    <Route path="/panel-composer/:id/subpanels/:subpanelCode/documents" component={SubpanelDocuments}/>

                    <Route path="/proposal/:id/possible-concepts" component={ProposalPossibleConceptsPage}/>

                    <Route path="/ontology/concept/new/:name?" component={EditConceptPage} />
                    <Route path="/ontology/concept/edit/:id" component={EditConceptPage} />

                    <Route path="/ontology/possible-concepts/:id" component={OntologyPossibleConceptsPage} />
                    <Route path="/ontology/import" component={ImportConceptsPage} />
                    <Route path="/ontology/log" component={OntologyModerationPage} />

                    <Route path="/jobs/recommendation/:advisorUuid" component={ApplicantRecommendationPage} />
                    <Route path="/jobs/new" component={EditJobPage} />
                    <Route path="/jobs/edit/:id" component={EditJobPage} />
                    <Route path="/jobs/:id/apply" component={EditApplicationPage} />
                    <Route path="/jobs/:id/applications/edit/:applicationId" component={EditApplicationPage} />
                    <Route path="/jobs/:jobId/applicant/:id" component={JobApplicantPage} />
                    <Route path="/jobs/:id/:tab(applicants|recommenders|search)/:includePreliminary(preliminary)?" component={JobApplicantsPage} />

                    <Route path="/file-exchange" component={FileExchangePage} />

                    <Route render={() => <h1>Page not found</h1>} />
                </Switch>
            </div>
            <Route component={Footer} />
        </CurrentUserContextProvider>
    </div>;
}
