import React from 'react';

import CurrentUser from '../accounts/CurrentUser';
import { interpose, roundInteger } from '../utils';


export default function AuthorBrief({ author }) {
    return <div className="author-brief">
        <h3>Dear <strong>{author.name}!</strong></h3>

        <p>Here&apos;s what Prophy knows about you:</p>
        <div><strong>h-index:</strong> {author.hIndex}</div>
        <div><strong>Total articles:</strong> {author.articlesCount}</div>
        {!!author.citationsCount &&
            <div><strong>Total citations:</strong> {roundInteger(author.citationsCount)}</div>
        }
        <div>
            <strong>Top concepts:</strong>{' '}
            {interpose(
                author.topConcepts.slice(0, 5).map(c => <span key={c.conceptId}>{c.name}</span>),
                ', '
            )}
        </div>

        <h4>Wish to change?</h4>
        <CurrentUser registerLinkMode>
            <a href="/accounts/register/" className="btn btn-block btn-large btn-primary">Register now</a>
        </CurrentUser>
        <p>
            and get access to <strong>tens of millions</strong> of science articles,{' '}
            including those on{' '}
            {interpose(
                author.topConcepts.slice(0, 2).map(c => <strong key={c.conceptId}>{c.name}</strong>),
                ', '
            )}{' '}
            and other topics!
        </p>
    </div>;
}
