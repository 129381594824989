import React from 'react';

import { ConceptLink } from '../../articles/Concept';
import Literal from '../Literal';

import { cx, formatDate } from '../../utils';


export default function LogRow(log, i) {
    const literalChanges = log.actions.filter(
        ({ type }) => type === 'remove_literal' || type === 'add_literal'
    );
    const isDeleted = log.actions.some(a => a.type === 'remove_concept');
    const isRestored = log.actions.some(a => a.type === 'restore_concept');
    const isCreated = log.actions.some(a => a.type === 'add_concept');
    const conceptModified = log.actions.find(a => a.type === 'modify_concept');
    const basicChange = conceptModified && conceptModified.data.basic;

    return <tr key={log.id} className={cx({ 'danger': isDeleted, 'success': isRestored, 'info': isCreated })}>
        <td>{formatDate(log.dateCreated)}</td>
        <td>
            <ConceptLink concept={log}>{log.name}</ConceptLink>
        </td>
        <td>
            {literalChanges.map(({ type, data }, i) =>
                <div key={i} className={
                    isCreated ? undefined : cx({
                        'text-success': type === 'add_literal',
                        'text-danger': type === 'remove_literal',
                        'text-muted': data.is_ambiguous
                    })
                }>
                    <Literal
                        literal={data}
                        className={data.isAmbiguous ? 'text-muted' : null}
                        title={data.isAmbiguous ? 'ambiguous literal' : null}
                        showLang/>
                </div>
            )}
        </td>
        <td>
            {basicChange !== undefined &&
                <div className={cx({'text-danger': !basicChange, 'text-success': basicChange})}>basic</div>
            }
        </td>
        <td>
            {log.actions
                .filter(({ type }) => type === 'add_concept_to_category' || type === 'remove_concept_from_category')
                .map(({ type, data }, i) =>
                    <div key={i} className={isCreated ? undefined : cx({
                        'text-success': type === 'add_concept_to_category',
                        'text-danger': type === 'remove_concept_from_category'
                    })}>
                        {data.category_name}
                    </div>
                )}
        </td>
        <td>
            <span title={log.createdBy.organizations.join(', ')}>
                {log.createdBy.email}
            </span>&nbsp;
            {isDeleted && <i>removed concept</i>}
            {isRestored && <i>restored concept</i>}
        </td>
    </tr>;
}
