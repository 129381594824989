import React from 'react';
import compose from 'lodash/flowRight';
import gql from 'graphql-tag';
import { graphql, withApollo } from '@apollo/react-hoc';
import type { ApolloClient } from 'apollo-client';

import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';

import FormGroup from 'react-bootstrap/lib/FormGroup';
import Button from 'react-bootstrap/lib/Button';
import Loading from '../Loading';
import { LoadedDocsBatchDetails } from './possibleConcepts/DocsBatchDetails';
import withCurrentUser from '../common/withCurrentUser';
import mutateConcept from './gql/mutateConceptGQL';
import type { UserType } from '../accounts/Types';
import type { PossibleConceptType } from '../articles/Types';

import withAuthorization from '../common/withAuthorization';

import { SimilarConceptPropsFragment } from '../common/Fragments';
import OntologyNav from './OntologyNav';


const queryImportPossibleConcepts = gql`
query possibleConcepts($text: String!, $separators: String!) {
    possibleConcepts: possiblesFromList(text: $text, separators: $separators) {
        name
        score
        literals {
            name
            isAmbiguous
            df
            tf
            similarLiteralsConcepts {
                ...similarConceptProps
            }
            lexicalNeighborsConcepts {
                ...similarConceptProps
            }
        }
    }
}

${SimilarConceptPropsFragment}
`;


function importPossibleConceptsTable(
    { data: { loading, possibleConcepts, error }, submit, reportFalse, currentUser, client }:
    {
        data: {
            loading: boolean, possibleConcepts: any, error: any
        },
        submit: () => any,
        reportFalse: () => any,
        currentUser: UserType,
        client: ApolloClient
    }
) {
    const docsBatch = possibleConcepts && {
        status: 'success',
        source: '',
        possibleConcepts,
    };

    return <Loading what="possible concepts" error={error} loading={loading}>
        <LoadedDocsBatchDetails
            batch={docsBatch}
            submit={submit}
            client={client}
            currentUser={currentUser}
            showDfCollection={false}
            showTf={true}
        />
    </Loading>;
};


function makeQImportPossibleConceptsTable(queryDocPossible: any) {
    return withCurrentUser(withApollo(compose(
        graphql(queryDocPossible, {
            options: ({ text, separators }) => ({
                variables: { text, separators },
                fetchPolicy: 'network-only',
            })
        }),
        graphql(mutateConcept, {
            props: ({ mutate, ownProps }) => ({
                submit: ({id, name, literals, basic, deleted, ontologyCategories}) =>
                    mutate({
                        variables: {id, name, literals, basic, deleted, ontologyCategories},
                        refetchQueries: [{
                            query: queryDocPossible,
                            variables: { text: ownProps.text,
                                         separators: ownProps.separators },
                        }]
                    }),
            }),
        })
    )(importPossibleConceptsTable)));
}


const QTextPossibleTable = makeQImportPossibleConceptsTable(queryImportPossibleConcepts);


class ImportConceptsPage extends React.Component<
    {}, {
        input: ?string,
        separators: ?string,
        conceptList: ?string,
        possibleConcepts: ?Array<PossibleConceptType>,
    }
> {

    state = {
        input: null,
        separators: '[.,;()]',
        conceptList: null,
        possibleConcepts: null,
    }

    placeholderInput = `food, drugs
administration`;

    form = {
        input: React.createRef(),
        separators: React.createRef(),
    }

    handleSubmit = (e: SyntheticInputEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (this.form.input.current &&
            this.form.input.current.value &&
            this.form.separators.current &&
            this.form.separators.current.value)
        {
            this.setState({
                conceptList: this.form.input.current.value,
                separators: this.form.separators.current.value,
            });

        }
    }

    render() {
        return <div className="tab-content">
            <OntologyNav />
            <div className="fieldset">
                <h3>Enter list of concepts</h3>
                <em>One concept per line. Literals are distinguished using
                    the regular expression below.</em>
            </div>
            <div className="fieldset">
                <form onSubmit={this.handleSubmit} className="form-horizontal">
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Literal separators
                        </Col>
                        <Col sm={6}>
                            <input defaultValue={this.state.separators}
                                   ref={this.form.separators}
                                   className="form-control" />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Concept list
                        </Col>
                        <Col sm={6}>
                            <textarea cols={80} rows={10}
                                      className="form-control"
                                      placeholder={this.placeholderInput}
                                      ref={this.form.input}></textarea>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col sm={2}></Col>
                        <Col sm={6}>
                            <Button bsStyle="success"
                                    type="submit"
                                    onClick={this.handleSubmit}>
                                Process
                            </Button>
                        </Col>
                    </FormGroup>
                </form>
            </div>
            {this.state.conceptList &&
                <Row className="possible-concepts-list">
                    <Col xs={12}>
                        <QTextPossibleTable text={this.state.conceptList}
                                            separators={this.state.separators} />
                    </Col>
                </Row>}
        </div>;
    }
}

export default withAuthorization(ImportConceptsPage);
