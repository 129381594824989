import React from 'react';

import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';

import Literal from './Literal';

import { interpose } from '../utils';
import type { LiteralType } from './Types';


export default function MergeActions(
    { concept, missingLiterals, onMerge }:
    { concept: any, missingLiterals: Array<LiteralType>, onMerge: () => any }
) {
    return <div className="row">
        <Col xs={9}>
            Merge with &quot;{concept.name}&quot; by adding{' '}
            literals: {
                interpose(
                    missingLiterals.map(l => <Literal key={l.name} literal={l}/>),
                    ', '
                )
            }
        </Col>
        <Col xs={3}>
            <Button bsStyle="primary"
                    className="action-button"
                    onClick={onMerge}>
                Merge
            </Button>
        </Col>
    </div>
}
