import React from 'react';
import gql from 'graphql-tag';

import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';

import makeQDocPossibleTable from '../ontologies/possibleConcepts/DocPossibleTable';
import withAuthorization from '../common/withAuthorization';
import type { ReactRouterMatchType } from '../common/Types';
import { possibleConceptsGQL, SimilarConceptPropsFragment } from '../common/Fragments';
import { constructArticlePath } from './ArticleLink';


const queryArticlePossible = gql`
query docPossible($id: Int!) {
    docPossible: article(id: $id) {
        id
        title
        ${possibleConceptsGQL}
    }
}

${SimilarConceptPropsFragment}
`;


const QArticlePossibleTable = makeQDocPossibleTable(queryArticlePossible);


function ArticlePossibleConceptsPage(
    { match }
    : { match: ReactRouterMatchType }
) {
    const articleId = Number(match.params.id);
    return <Row className="possible-concepts-list">
        <Col xs={12}>
            <a href={constructArticlePath(articleId)}>&larr; back to the article</a>
            <QArticlePossibleTable id={articleId} />
        </Col>
    </Row>;
}

export default withAuthorization(ArticlePossibleConceptsPage);
