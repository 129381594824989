import React from 'react';

import LoadingButton from '../widgets/LoadingButton';
import { mergeApplications as mergeJobApplications } from './JobData';
import { sendRequest, updateGlobalData } from '../datastore';
import type { ApplicationType } from './Types';


export default function PreliminaryBadge({application = null}:
                                         {application?: ?ApplicationType}) {
    const [working, setWorking] = React.useState(false);
    const [error, setError] = React.useState(null);

    const mergeApplications = () => {
        setWorking(true);

        const data = {
            applicationId1: application.applicationId,
            applicationId2: application.mergeWith.applicationId,
        };

        sendRequest('/api/jobs/merge-applications/', {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(response => {
            setWorking(false);
            setError(null);

            const { ok, value } = response;

            if (ok) {
                updateGlobalData(mergeJobApplications, {
                    vacancyId: application.vacancyId,
                    actualApplication: value.actualApplication,
                    preliminaryApplicationId: value.preliminaryApplicationId
                })
            } else {
                setError(value.error);
            }
        })
    };

    const isActual = !!application && !!application.email;
    const mergeWith = !!application && application.mergeWith;

    let text = isActual ? '(actual)' : '(preliminary)';
    let button = null;

    if (mergeWith) {
        text += ` (click to merge with ${mergeWith.name})`;
        button = <LoadingButton disabled={working}
                                onClick={mergeApplications}
                                working={working}>
            {text}
        </LoadingButton>;
    } else {
        button = <small className="text-muted">{text}</small>
    }

    return <div className="preliminary-badge">
        {button}
        {!!error && <p className="text-danger">{error}</p>}
    </div>;
}
