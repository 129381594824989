import * as React from 'react';
import type { Node } from 'react';
import Reflux from 'reflux';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Badge from 'react-bootstrap/lib/Badge';
import Button from 'react-bootstrap/lib/Button';

import Loading from '../Loading';
import { ChosenRecommendationTopicsStore } from './ChosenRecommendationTopicsStore';
import { getConceptIds } from '../common/helpers/recommendationTopics';
import { cx } from '../utils';
import { ConceptLink } from '../articles/Concept';
import ConceptBadge from '../widgets/ConceptBadge';
import Draggable from '../widgets/Draggable';
import Overlay from '../widgets/Overlay';
import Popover from '../widgets/Popover';
import type { ConceptItemType } from '../ontologies/Types';

import '../css/concept.css';


export type HandleClickType = (id: string) => any;
export type HandleRecommendClickType = (item: ConceptItemType) => any;


export class ChosenConcept extends React.PureComponent<
    {
        conceptId: any,
        name: string,
        selected?: boolean,
        topicIndices?: Array<number>,
        draggable?: boolean,
        except?: Array<ConceptItemType>,
        handleToggle?: (e: SyntheticKeyboardEvent<HTMLElement>, concept: ConceptItemType, toggle: boolean) => any,
        handleClickRemove?: HandleClickType,
        handleClickAdd?: HandleRecommendClickType,
        handleSelect?: (conceptId: number, selected: boolean) => any,
        children?: Node
    },
    {
        open: boolean
    }
> {
    badgeContainer: ?HTMLElement = null;

    state = {
        open: false
    }

    setBadgeContainer = (el: ?HTMLElement) => {
        this.badgeContainer = el;
    }

    handleToggle = (e: SyntheticKeyboardEvent<*>, toggle: boolean) => {
        this.setState({ open: toggle });

        this.props.handleToggle && this.props.handleToggle(
            e,
            {
                conceptId: this.props.conceptId,
                name: this.props.name,
                selected: this.props.selected
            },
            toggle
        );
    }

    handleShow = (e: SyntheticKeyboardEvent<*>) => {
        this.handleToggle(e, true);
    }

    handleHide = (e: SyntheticKeyboardEvent<*>) => {
        this.handleToggle(e, false);
    }

    handleClick = (e: SyntheticKeyboardEvent<HTMLElement>) => {
        if (e.shiftKey && this.props.handleSelect) {
            this.props.handleSelect(this.props.conceptId, !this.props.selected);
        } else {
            this.handleShow(e);
        }
    }

    handleClickRemove = () => {
        this.props.handleClickRemove &&
        this.props.handleClickRemove(this.props.conceptId);
    }

    render() {
        const {
            conceptId, name, selected, topicIndices, draggable, except,
            handleClickAdd, handleClickRemove, children,
        } = this.props;

        const shortName = name.length > 12 ? name.slice(0, 9) + '...' : name;
        const removeButton = handleClickRemove && (
            <Button className="button-remove-concept" bsStyle="link"
                    onClick={this.handleClickRemove}>
                Remove &quot;{shortName}&quot;
            </Button>
        );
        const title = <>
            <span>{name}</span>
            <ConceptLink concept={{ conceptId, name }}/>
        </>;

        const classNames = cx('concept concept-chosen', { selected });

        return <>
            <span ref={this.setBadgeContainer} onClick={this.handleClick}>
                <ConceptBadge classNames={classNames}
                              draggable={draggable}
                              topicIndices={topicIndices}>
                    {name}
                </ConceptBadge>
            </span>
            <Overlay show={this.state.open}
                     onHide={this.handleHide}
                     onEntered={this.handleShow}
                     placement="bottom"
                     key={conceptId}
                     animation={true}
                     rootClose
                     target={this.badgeContainer}>
                <Popover className="concept-popover"
                         id="popover-positioned-right"
                         title={title}
                         footer={removeButton}>
                    {children || (
                        handleClickAdd && <QRecommendedConceptList
                            conceptId={conceptId}
                            isDragDisabled
                            except={except}
                            draggable={draggable}
                            handleClickSuggestions={handleClickAdd}
                        />
                    )}
                </Popover>
            </Overlay>
        </>;
    }
}


type ChosenConceptListProps = {
    concepts: Array<ConceptItemType>,
    handleClickRemove: HandleClickType,
    handleClickAdd: HandleRecommendClickType,
    handleToggle: (e: SyntheticEvent<HTMLElement>) => any,
    handleSelect?: (conceptId: number, selected: boolean) => any,
    collapsible: boolean,
    collapseThreshold: number,
    collapsedCount: number,
    draggablePrefix: ?string,
};


export class ChosenConceptList extends Reflux.Component {
    props: ChosenConceptListProps;

    static defaultProps = {
        collapsible: false,
        collapseThreshold: 12,
        collapsedCount: 8,
        draggablePrefix: ''
    };

    constructor(props: ChosenConceptListProps) {
        super(props);

        this.state = { collapsed: true, topicIndices: {} };

        this.mapStoreToState(ChosenRecommendationTopicsStore, fromStore => {
            return fromStore.topicIndices ? { topicIndices: fromStore.topicIndices } : {}
        });
    }

    isCollapsible() {
        return (this.props.collapsible &&
            this.props.concepts.length > this.props.collapseThreshold)
    }

    toggleCollapsed = () => {
        this.setState({collapsed: !this.state.collapsed});
    };

    render() {
        let concepts = this.props.concepts;
        let expandLink = null;

        if (this.isCollapsible()) {
            let expandLinkText = '← collapse';
            if (this.state.collapsed) {
                const collapsedCount = concepts.length - this.props.collapsedCount;
                expandLinkText = `show ${collapsedCount} more →`;
                concepts = concepts.slice(0, this.props.collapsedCount);
            }
            expandLink = <Badge className="concept expand-concepts-link"
                onClick={this.toggleCollapsed}>
                {expandLinkText}</Badge>;
        }

        const prefix = this.props.draggablePrefix !== '' ?
            `${String(this.props.draggablePrefix)}-`
            : '';

        return <div>
            {concepts.map((concept) =>
                <Draggable draggableId={`${prefix}${concept.conceptId}`}
                           key={concept.conceptId}
                           type="concept">
                    {(provided, snapshot) => (
                        <span>
                            <span
                                className="concept-draggable"
                                ref={provided.innerRef}
                                style={provided.draggableStyle}
                                {...provided.dragHandleProps}>
                                <ChosenConcept
                                    conceptId={concept.conceptId}
                                    selected={concept.selected}
                                    topicIndices={this.state.topicIndices[concept.conceptId]}
                                    name={concept.name}
                                    draggable={true}
                                    handleSelect={this.props.handleSelect}
                                    handleToggle={this.props.handleToggle}
                                    handleClickRemove={this.props.handleClickRemove}
                                    handleClickAdd={this.props.handleClickAdd}/>
                            </span>
                            {provided.placeholder}
                        </span>
                    )}
                </Draggable>)}
            {expandLink}
        </div>;
    }
}


export function RecommendedConcept(
    {
        concept, draggableClassName, highlight,
        draggablePrefix = '', isDragDisabled = false, handleClick,
        draggable = true
    }: {
        concept: ConceptItemType,
        draggableClassName?: string,
        highlight: boolean,
        isDragDisabled?: boolean,
        draggablePrefix?: string,
        draggable?: boolean,
        handleClick: HandleRecommendClickType
    }
) {
    const className = cx(
        'concept', 'concept-clickable', {'unconfirmed-concept': highlight});
    const badge = <Badge className={className} onClick={() => handleClick(concept)}>
        {concept.name}
    </Badge>;

    if (draggable) {
        const prefix = draggablePrefix !== '' ?
            `${String(draggablePrefix)}-`
            : '';

        return <Draggable draggableId={`${prefix}${concept.conceptId}`}
                   type="concept"
                   isDragDisabled={isDragDisabled}>
            {(provided) => (
                <span>
                    <span
                        className={'concept-draggable' + (
                            draggableClassName ? ` ${draggableClassName}` : '')
                        }
                        ref={provided.innerRef}
                        style={provided.draggableStyle}
                        {...provided.dragHandleProps}
                    >
                        {badge}
                    </span>
                    {provided.placeholder}
                </span>
            )}
        </Draggable>
    } else {
        return badge;
    }
}

type LoadedRecommendedConceptListProps = {
    concepts: Array<ConceptItemType>,
    isDragDisabled?: boolean,
    draggable?: boolean,
    handleClickSuggestions: HandleClickType,
};

export class LoadedRecommendedConceptList extends Reflux.Component {
    constructor(props: LoadedRecommendedConceptListProps) {
        super(props);
        this.state = {};

        this.mapStoreToState(ChosenRecommendationTopicsStore, fromStore => {
            if (fromStore.topics) {
                return {
                    concepts: getConceptIds(fromStore.topics)
                };
            }
        });
    }

    render() {
        const notChosenElements = this.props.concepts
            .filter(c => this.state.concepts.indexOf(c.conceptId) === -1)
            .slice(0, 10);

        if (notChosenElements.length === 0) {
            return <div>No suggestions</div>;
        }

        return <div>
            Suggestions:&nbsp;
            <span>
                {notChosenElements.map(concept =>
                    <RecommendedConcept
                        isDragDisabled={this.props.isDragDisabled}
                        draggable={this.props.draggable}
                        key={concept.conceptId}
                        concept={concept}
                        handleClick={this.props.handleClickSuggestions}
                    />
                )}
            </span>
        </div>
    }
}

export function RecommendedConceptList({
    data: {loading, error, concepts},
    isDragDisabled = false, except, handleClickSuggestions,
    draggable
}:
    {
        data: {loading: bool, error: any, concepts: any},
        isDragDisabled: boolean,
        except: Array<any>,
        draggable?: boolean,
        handleClickSuggestions: HandleRecommendClickType
}) {
    if (except && except.length && concepts && concepts.length) {
        except = except.map(e => e.conceptId);
        concepts = concepts.filter(c => except.indexOf(c.conceptId) === -1);
    }

    return <Loading what="suggestions" loading={loading} error={error}
        style={{height: 25}}>
        <LoadedRecommendedConceptList
            concepts={concepts}
            draggable={draggable}
            isDragDisabled={isDragDisabled}
            handleClickSuggestions={handleClickSuggestions}
        />
    </Loading>;
}


// TODO: Remove this GraphQL and use regular rest JSON api
export const QRecommendedConceptList = graphql(gql`
query concepts($conceptId: String!) {
    concepts: recommendedConcepts(conceptId: $conceptId, topn: 50) {
        conceptId
        name
    }
}
`)(RecommendedConceptList);
