import React from 'react';


export default {
    loadingAwareSubmit: function loadingAwareSubmit(
        submitProps,
        options?: {
            resolveWith?: Object,
            submitFn?: Function,
            workingVarName?: string,
            doneVarName?: string,
        }
    ) {
        options = {
            resolveWith: {},
            submitFn: null,
            workingVarName: 'working',
            doneVarName: 'done',
            noDone: false,
            ...options
        };

        const isMounted = () => (
            (typeof this._mounted === 'boolean' && this._mounted) ||
            typeof this._mounted === 'undefined'
        );

        const submitFn = options.submitFn || this.props.submit.bind(this);
        const { workingVarName, doneVarName, noDone } = options;

        this.setState({ [workingVarName]: true });

        return submitFn(submitProps)
            .then(result => {
                const newState = { [workingVarName]: false };

                if (!noDone) {
                    newState[doneVarName] = true;

                    this.loadingTimeout = setTimeout(() => {
                        if (isMounted()) {
                            this.setState({ [doneVarName]: false });
                        }
                    }, 1500);
                }

                if (isMounted()) {
                    this.setState(newState);
                }

                return { ...result, ...options.resolveWith };
            })
            .catch(error => {
                if (isMounted()) {
                    this.setState({
                        [workingVarName]: false
                    });
                }

                throw error;
            });
    }
};

export const useLoadingAwareSubmit = options => {
    const [done, setDone] = React.useState(false);
    const [working, setWorking] = React.useState(false);
    const loadingTimeout = React.useRef();

    // loadingTimeout.current is a guarantee that component is still mounted -
    // the reference to it is cleared on unmount
    const isMounted = () => loadingTimeout.current !== null;

    const submit = (submitProps) => {
        const { submitFn, noDone } = options;

        setWorking(true);

        return submitFn(submitProps)
            .then(result => {
                setWorking(false);

                if (!noDone) {
                    setDone(true);

                    loadingTimeout.current = setTimeout(() => {
                        if (isMounted()) {
                            setDone(false);
                        }
                    }, 1500);
                }

                return { ...result, ...options.resolveWith };
            })
            .catch(error => {
                if (isMounted()) {
                    setWorking(false);
                }

                throw error;
            });
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(loadingTimeout.current);
            loadingTimeout.current = null;
        };
    });

    return { done, working, submit };
};
