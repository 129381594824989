import React from 'react';


export default function ConceptBadge(
    {topicIndices, classNames, children, draggable, ...props}
    : {
        topicIndices?: Array<number>, classNames?: string, children?: any, draggable?: boolean
    }
) {
    const ComponentClass = draggable ? 'div' : 'button';

    return <ComponentClass onClick={(e) => e.preventDefault()} className={`${classNames || ''} badge`} { ...props }>
        {topicIndices && topicIndices.map(i =>
            <span key={i} className={`topic-color topic-color-${i}`}></span>
        )}
        {children}
    </ComponentClass>;
}
