import React, { useState, useEffect } from 'react';

import {
    makePanelMembersKey, makePanelKey, makePanelMembersUrl
} from './PanelMembersData';
import { sendRequest, useSubscription, updateGlobalData } from '../../datastore';
import LoadingREST from '../../LoadingREST';
import PanelMemberCandidates from './PanelMemberCandidates';
import { useLocation } from 'react-router';
import { NonEditableWarning } from './helpers';


export default function PanelMembersPage({ match: { params: { id, compositionId }} }) {
    const location = useLocation();

    const membersKey = makePanelMembersKey(id, compositionId, location.search);
    const panelKey = makePanelKey(id, compositionId);

    const response = useSubscription(membersKey);
    const panel = useSubscription(panelKey);


    const [reqstate, setReqstate] = useState({loading: true});
    useEffect(() => {
        let cancel = false;
        setReqstate({loading: true});
        sendRequest(makePanelMembersUrl(id, compositionId, 'members', location.search))
            .then((data) => {
                if (!cancel) {
                    updateGlobalData(
                        (store, { value: {scoredPanelMembers, panelName, subpanels,
                                          countries, hasMore, isEditable, noSubpanelDocs,
                                          cacheMiss, inProgress, error} }) => {
                            store[membersKey] = {
                                scoredPanelMembers,
                                subpanels: subpanels.reduce((acc, { id, score }) => ({ ...acc, [id]: score }), {}),
                                countries,
                                hasMore,
                                isEditable,
                                cacheMiss,
                                inProgress,
                                error
                            };
                            store[panelKey] = {
                                subpanels,
                                id,
                                noSubpanelDocs,
                                name: panelName,
                            }
                        },
                        data
                    );
                    setReqstate({loading: false, ok: true});
                }
            })
        return () => {cancel = true;};
    }, [id, compositionId, location, panelKey, membersKey]);

    const { scoredPanelMembers, subpanels, isEditable, cacheMiss, inProgress, error } = response || {};

    return <LoadingREST what="panel" reqstate={reqstate} doc={response}>
        {response && <>
            <ol className="breadcrumb">
                <li><a href={`/panel-composer/${panel.id}/compositions/`}>{panel.name} compositions</a></li>
                <li className="active">Members</li>
                <li><a href={`/panel-composer/${panel.id}/compositions/${compositionId}/candidates/`}>Candidates</a></li>
            </ol>
            {!isEditable && <NonEditableWarning panelId={panel.id} compositionId={compositionId} /> }
            <PanelMemberCandidates
                panel={panel}
                compositionId={compositionId}
                storeKey={membersKey}
                isEditable={isEditable}
                people={scoredPanelMembers}
                panelScores={subpanels}
                cacheMiss={cacheMiss}
                inProgress={inProgress}
                error={error} />
        </>}
    </LoadingREST>;
}
