import React, { Fragment } from 'react';
import type { Node } from 'react';
import compose from 'lodash/flowRight';

import { graphql, withApollo } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import type { ApolloError } from 'apollo-client';

import Alert from 'react-bootstrap/lib/Alert';

import Button from 'react-bootstrap/lib/Button';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';

import { MixedInput } from '../search/SearchInput2';
import CollapsibleList from '../widgets/CollapsibleList';
import PendingOntologyChangesAlert from './PendingOntologyChangesAlert';
import mutateConcept from './gql/mutateConceptGQL';
import Loading from '../Loading';
import QConceptBrief from './ConceptBrief';
import ConceptSearch from './ConceptSearch';
import Literal from './Literal';
import MergeActions from './MergeActions';
import QMissingLiterals from './MissingLiterals';
import UseAsParentComposite from './UseAsParentComposite';
import { singlePlural } from '../utils';
import type { SplitTokensType, ItemType } from '../widgets/MixedInput';
import LoadingButton from '../widgets/LoadingButton';
import LRUCache from '../LRUCache';
import { cx } from '../utils';
import helpers from '../common/helpers/loading';
import ontologyHelpers from './helpers';
import CurrentUserContext from '../accounts/CurrentUserContext';
import ConceptBriefPopover from './ConceptBriefPopover';

import type {
    OntologyConceptType, UseAsParentCompositeResultType,
    LiteralType, LocalLiteralType, ConceptMutationResponseType,
    SimilarConceptType, LiteralCompositePartType
} from './Types';

import './styles.css';


export const ErrorsList = ({ errors }: {errors: Array<string|Node>}) => (
    <div className="help-block">
        <ul>
            {errors.map((e, i) => <li key={i}>{e}</li>)}
        </ul>
    </div>
);

type EditConceptFormPropsType = {
    id: number,
    error: boolean,
    submit: () => any,
    onEdit?: () => any,
    onSubmit: () => any,
    onMerge?: (concept: OntologyConceptType) => any,
    onError?: (errors: Array<string>, form?: any) => any,
    onRendeActions?: (conceptForAction: OntologyConceptType) => any,
    refetch: () => any,
    name: string,
    className: string|{},
    literals?: Array<LocalLiteralType>,
    dateModified: Date,
    basic: boolean,
    deleted: boolean,
    errors: Array<string>,
    nameErrors: Array<string>,
    warning: string,
    hideBreadcrumbs: bool,
    batchItemIdentifier?: string | number,
    potentialTotalDf: number,
    searchQuery: string,
};

type EditConceptFormStateType = {
    done: bool,
    working: bool,
    showMergeModal: boolean
};

type EditConceptFormStatefulStateType = {
    id: ?string,
    name: string,
    literals: Array<LocalLiteralType>,
    basic: bool,
    deleted: bool,
    isManuallyModified: bool,
    conceptSuggestCache: any,
    done: bool,
    working: bool,
    errors: Array<string>,
    warning: ?string,
    nameErrors: Array<string>,
    potentialTotalDf: number,
    searchQuery: string,
};


export class EditConceptForm extends React.Component<
    EditConceptFormPropsType,
    EditConceptFormStateType
> {
    static defaultProps = {
        className: '',
        nameErrors: [],
        errors: [],
        literals: [],
        name: '',
        deleted: false,
        basic: false,
        conceptSuggestCache: new LRUCache(),
    };

    state = {
        done: false,
        working: false,
        showMergeModal: false,
    };

    loadingTimeout: TimeoutID;
    literalInputs: {[key: number|string]: ?HTMLInputElement} = {};

    getWarningsForLiteral(l: LocalLiteralType, literals: Array<LocalLiteralType>) {
        const warnings = [];

        if (l.similarLiteralsConcepts && l.similarLiteralsConcepts.length && !l.isAmbiguous) {
            warnings.push(this.getLiteralDuplicatedMessage());
        }

        const thisLiteralVariants = ontologyHelpers.getExpandedLiteralsVariants(l).map(list => list.name);
        let found;

        if (thisLiteralVariants.some(variant => {
            found = literals
                .find((literal, i) =>
                    l !== literal &&
                    ontologyHelpers.getExpandedLiteralsVariants(literal).some(otherVariant =>
                        otherVariant.name.toLowerCase() === variant.toLowerCase()
                    )
                );

            return !!found;
        })) {
            if (found) {
                const another = <Literal
                    literal={found}
                    conceptPopoverContents={this.renderConceptPopoverContents} />;
                let msg;

                if (l.composite) {
                    msg = <Fragment>
                        Composite literal contains another literal within the same concept: &quot;
                        {another}&quot;
                    </Fragment>;
                } else {
                    msg = !found.composite ?
                        <Fragment>
                            Similar to literal &quot;{another}&quot;. Possible duplicate?
                        </Fragment>
                        : <Fragment>
                            Literal already exists in literal &quot;{another}&quot;
                        </Fragment>;
                }

                warnings.push(msg);
            }
        }

        return warnings;
    }

    componentDidMount() {
        const change: any = {};
        let isNewConceptWithName = false;

        if (this.props.literals && this.props.literals.length) {
            change.literals = this.props.literals.map(l => ({
                ...l,
                warnings: l.warnings || this.getWarningsForLiteral(l, this.props.literals)
            }));
        } else {
            // initialize literals from concept name if passed
            if (this.props.name) {
                change.literals = [{
                    ...this.getEmptyLiteral(),
                    name: this.props.name,
                    isAmbiguous: this.isAmbiguous(this.props.name),
                }];
                isNewConceptWithName = true;
            }
        }

        if (this.props.name) {
            change.warning = ontologyHelpers.checkConceptName(
                this.props.name,
                change.literals || this.props.literals || []
            );
        }

        if (Object.keys(change).length) {
            this.props.onChange(change, this.props.batchItemIdentifier);

            if (isNewConceptWithName) {
                this.checkLiteralUniqueness(0, change.literals)
            }
        }
    }

    componentDidUpdate(prevProps: EditConceptFormPropsType, prevState: EditConceptFormStateType) {
        if (this.props.dateModified &&
            this.props.dateModified.valueOf() >
                (prevProps.dateModified || Number(0)).valueOf()
        ) {
            this.props.refetch();
        }
    }

    createSetLiteralInput = (index: number|string) => {
        return (el: ?HTMLInputElement) => {
            if (el) {
                this.literalInputs[index] = el;
            } else {
                delete this.literalInputs[index];
            }
        };
    };

    handleNameChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
        const partialState = {};

        if (this.props.literals.map(l => l.name || '<composite>').join('') === this.props.name) {
            const literals = this.props.literals.slice();
            let isAmbiguous;

            if (this.props.literals[0]) {
                isAmbiguous = !this.props.literals[0].checkboxInteracted ?
                    this.isAmbiguous(e.target.value)
                    : this.props.literals[0].isAmbiguous;
            } else {
                isAmbiguous = this.isAmbiguous(e.target.value);
            }

            literals[0] = {
                ...this.getEmptyLiteral(),
                checkboxInteracted: !!(
                    this.props.literals[0] &&
                    this.props.literals[0].checkboxInteracted
                ),
                name: e.target.value,
                isAmbiguous
            };

            partialState.literals = literals;
        }

        const errors = ontologyHelpers.validateName(e.target.value);
        const warning = ontologyHelpers.checkConceptName(
            e.target.value,
            partialState.literals || this.props.literals
        );

        this.updateValues({
            ...partialState,
            warning,
            name: e.target.value,
            nameErrors: errors
        });
    };

    checkLiteralUniqueness(index: number, literals: Array<LocalLiteralType>): Promise<*> {
        return this.props.client.query({
            query: checkLiteral,
            variables: {
                currentLiterals: [ontologyHelpers.nameOrComposite(literals[index])],
                literals: literals.map(ontologyHelpers.nameOrComposite)
            },
        }).then(({ data }) => {
            // we need to use the latest literals; since we are in a promise
            // callback, the latest literals are in this.state.literals.
            // But, the order might have changed, so literals[1] may become
            // this.state.literals[0], and so on.
            // Here, we match literals <-> this.state.literals and assign received
            // data to appropriate literals.
            const updatedLiterals = this.props.literals.map((l, i) => {
                // find index in literals; if not found, skip.
                const oldLiteral = literals.find(oldL => l.composite ?
                    oldL.composite === l.composite
                    : oldL.name === l.name
                );
                const oldLiteralIndex = literals.indexOf(oldLiteral);

                if (!oldLiteral) {
                    return l;
                } else {
                    return {
                        ...l,
                        similarLiteralsConcepts: index === oldLiteralIndex ?
                            (data.conflictConcepts || []).filter(c => c.id !== this.props.id)
                            : l.similarLiteralsConcepts,
                        lexicalNeighborsConcepts: index === oldLiteralIndex ?
                            (data.neighborConcepts || []).filter(c => c.id !== this.props.id)
                            : l.lexicalNeighborsConcepts,
                        expandedLiteralsVariants: data.expandedLiteralsVariants[oldLiteralIndex],
                        df: data.literalsDfs.perLiteral[oldLiteralIndex].df,
                        searchQuery: data.literalsDfs.perLiteral[oldLiteralIndex].searchQuery,
                    }
                }
            });

            const literalsWithWarning = this.checkLiteralsForDuplicates(
                updatedLiterals
                    .map((l, i) => ({
                        ...l,
                        errors: ontologyHelpers.getErrorsForLiteral(i, updatedLiterals)
                    }))
            );

            this.updateValues({
                potentialTotalDf: data.literalsDfs.total.df,
                searchQuery: data.literalsDfs.total.searchQuery,
                literals: literalsWithWarning,
            });

            return literalsWithWarning;
        }).catch(() => {
            this.updateValues({
                errors: ['Network problem, please try again.']
            });
        });
    }

    handleChangeBasic = (e: SyntheticInputEvent<HTMLInputElement>) => {
        this.updateValues({ basic: !!e.target.checked });
    };

    checkLiteralsForDuplicates = (literals: Array<LocalLiteralType>): Array<LocalLiteralType> => {
        return literals.map((l: LocalLiteralType) => ({
            ...l,
            warnings: this.getWarningsForLiteral(l, literals)
        }));
    }

    triggerLiteralValidation(index: number, literals: Array<LocalLiteralType>) {
        const errors = ontologyHelpers.getErrorsForLiteral(index, this.props.literals);
        const newLiterals = literals.slice();

        newLiterals[index] = {
            ...literals[index],
            errors
        };

        this.updateValues({
            literals: newLiterals
        });

        this.checkLiteralUniqueness(index, newLiterals)
            .then(updatedLiterals => {
                if (updatedLiterals) {
                    this.updateValues({
                        warning: ontologyHelpers.checkConceptName(this.props.name, updatedLiterals)
                    });
                }
            });

        return errors;
    }

    createHandleBlur = (index: number) => {
        return () => {
            this.triggerLiteralValidation(index, this.props.literals);
        };
    };

    updateValues(change: $Shape<EditConceptFormStatefulStateType>) {
        this.props.onChange(change, this.props.batchItemIdentifier);
    }

    handleNameBlur = () => {
        this.updateValues({ nameErrors: ontologyHelpers.validateName(this.props.name) });
    };

    saveConcept = () : Promise<*> => {
        this.updateValues(ontologyHelpers.validateAll(this.props));

        return ontologyHelpers.saveConcept(this.props, helpers.loadingAwareSubmit.bind(this))
            .then((response: ConceptMutationResponseType) => {
                if (response.data.concept.ok && response.data.concept.concept.id) {
                    this.updateValues({ id: response.data.concept.concept.id });
                }

                return response;
            })
            .then(this.props.onSubmit)
            .catch(errors => {
                if (errors instanceof Error) {
                    this.handleError(errors);
                } else {
                    this.props.onError && this.props.onError(errors, this);
                    return Promise.resolve(new Error('Validation errors'));
                }
            });
    };

    handleError = (error: ApolloError) => {
        const errors = error.graphQLErrors ?
            error.graphQLErrors.map(e => e.message)
            : [error.message];
        this.updateValues({ errors });

        this.props.onError && this.props.onError(errors, this);
    };

    getEmptyLiteral() {
        return {
            index: null,
            name: '',
            isAmbiguous: false,
            checkboxInteracted: false,
            similarLiteralsConcepts: [],
            lexicalNeighborsConcepts: [],
            df: 0,
            lang: null,
        };
    }

    toggleDeleted = (deleted?: boolean) => {
        deleted = typeof deleted === 'boolean' ? deleted : !this.props.deleted;
        return helpers.loadingAwareSubmit.call(this, {
            id: this.props.id,
            deleted,
        }, {
            resolveWith: { deleting: true }
        })
            .then(this.props.onSubmit)
            .then(this.updateValues({ deleted }))
            .catch(this.handleError);
    };

    copyAndFocusLiteralInput(toIndex: number|string, from: HTMLInputElement): Promise<*> {
        return new Promise(resolve => {
            setTimeout(() => {
                if (this.literalInputs[toIndex]) {
                    const input = this.literalInputs[toIndex];
                    input.value = from.value;

                    input.focus();
                    this.selectInputContents(input, true);

                    resolve();
                }
            }, 1);
        });
    }

    selectInputContents = (input, shouldCollapseToEnd) => {
        if (input instanceof HTMLInputElement) {
            input.setSelectionRange(0, input.value.length);
        } else {
            const sel = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(input.node);
            sel.removeAllRanges();
            sel.addRange(range);

            if (shouldCollapseToEnd) {
                sel.collapseToEnd();
            }
        }
    }

    createLiteral = (e: SyntheticInputEvent<HTMLInputElement>) => {
        if (this.copying) return;

        const input = e.currentTarget;
        const newLiteralIndex = Object.keys(this.literalInputs).length - 1;

        this.updateValues({
            literals: this.props.literals.concat([{
                ...this.getEmptyLiteral(),
                name: e.currentTarget.value
            }])
        });

        this.copying = this.copyAndFocusLiteralInput(newLiteralIndex, input)
            .then(() => {
                const literals = this.props.literals.slice();

                literals[this.props.literals.length - 1] = {
                    ...this.props.literals[this.props.literals.length - 1],
                    name: input.value
                };

                this.updateValues({ literals });
                input.value = '';
                delete this.copying;
            });
    };


    createDeleteLiteral = (index: number) => {
        return (e: SyntheticMouseEvent<HTMLButtonElement>) => {
            let newLiterals = this.props.literals.slice();
            newLiterals.splice(index, 1);

            newLiterals = this.checkLiteralsForDuplicates(
                newLiterals.map((l, i) => ({
                    ...l,
                    errors: ontologyHelpers.getErrorsForLiteral(i, newLiterals)
                }))
            );

            this.updateValues({
                literals: newLiterals,
                warning: ontologyHelpers.checkConceptName(this.props.name, newLiterals)
            });

            this.props.client.query({
                query: fetchLiteralsDfs,
                variables: {
                    literals: newLiterals.map(ontologyHelpers.pureLiteral)
                },
            }).then(({ data }) => {
                this.updateValues({ potentialTotalDf: data.literalsDfs.total.df });
            });
        };
    };

    getLiteralDuplicatedMessage() {
        return <span>Literal is not unique. See conflicting concepts.</span>;
    }

    isAmbiguous(literal: Array<ItemType> | string) {
        const isAmbiguous = (l: string) => !!l && l.length > 1 && l.toUpperCase() === l;

        return (
            typeof literal === 'string' &&
            isAmbiguous(literal)
        ) || (
            literal.length === 1 &&
            typeof literal[0] === 'string' &&
            isAmbiguous(literal[0])
        );
    }

    createHandleLiteralNameChange = (i: number) => {
        return items => {
            const literals = this.props.literals.slice();
            const isAmbiguous = !this.props.literals[i].checkboxInteracted ?
                this.isAmbiguous(items)
                : this.props.literals[i].isAmbiguous;

            const composite = items.map((item, i: number) => {
                return typeof item === 'string' ?
                    { string: item }
                    : {
                        concept_id: parseInt(item.id, 10),
                        name: item.label,
                    }
            }, []);

            const nameOrComposite = items.length === 1 && typeof items[0] === 'string' ?
                { name: items[0], composite: null, expandedLiteralsVariants: [{ name: items[0] }] }
                : { name: '', composite };

            literals[i] = {
                ...literals[i],
                ...nameOrComposite,
                isAmbiguous
            };

            this.updateValues({ literals });
        };
    };

    createHandleLiteralIsAmbiguousChange = (i: number) => {
        return (e: SyntheticInputEvent<HTMLInputElement>) => {
            const literals = this.props.literals.slice();

            literals[i] = {
                ...literals[i],
                isAmbiguous: !literals[i].isAmbiguous,
                checkboxInteracted: true
            };

            this.updateValues({ literals });
            this.triggerLiteralValidation(i, literals);
        };
    };

    createHandleLiteralNameSubmit = index => {
        return (e) => {
            const next = this.literalInputs[index + 1] ? index + 1 : 'newLiteral';

            setTimeout(() => {
                if (this.literalInputs[next]) {
                    const input = this.literalInputs[next];
                    this.literalInputs[next].focus();

                    this.selectInputContents(input);
                }
            }, 1);
        }
    }

    createHandleLiteralLangChange = i => {
        return e => {
            const literals = this.props.literals.slice();

            literals[i] = {
                ...literals[i],
                lang: e.target.value,
            };

            this.updateValues({ literals });
            this.triggerLiteralValidation(i, literals);
        }
    }

    showMergeModal = () => {
        this.setState({ showMergeModal: true });
    }

    hideMergeModal = () => {
        this.setState({ showMergeModal: false });
    }

    componentWillUnmount() {
        clearTimeout(this.loadingTimeout);
    }

    splitIntoTokens(str: string): SplitTokensType {
        const splitter = /^(#)|\s+(#)|"/g;
        let match;
        let prevIndex = 0;
        const tokens = [];
        const indices = [];

        // eslint-disable-next-line no-cond-assign
        while (match = splitter.exec(str)) {
            let matchIndex;

            // match with quote
            if (match[0] === '"') {
                matchIndex = prevIndex !== match.index && str[prevIndex] === '"' ? match.index + 1 : match.index;
            } else {
                matchIndex = match.index + match[0].indexOf('#');
            }

            const fragment = str.slice(prevIndex, matchIndex);

            if (fragment.trim()) {
                tokens.push(fragment);
                indices.push(prevIndex);
            }

            prevIndex = matchIndex;
        }

        if (str.slice(prevIndex).trim()) {
            tokens.push(str.slice(prevIndex));
            indices.push(prevIndex);
        }

        return { tokens, indices };
    }

    getInputItems(l: LocalLiteralType): Array<ItemType> {
        return l.composite ?
            l.composite.map((item: LiteralCompositePartType) => {
                if (typeof item.string === 'string') {
                    return item.string;
                }

                if (typeof item.concept_id === 'number' && typeof item.name === 'string') {
                    return {
                        id: item.concept_id,
                        label: item.name,
                        value: item.name,
                        type: 'concept',
                    };
                }

                throw new Error(`Unexpected composite part (${JSON.stringify(item)})`);
            })
            : [l.name || ''];
    }

    renderConceptPopoverContents(concept) {
        return <QConceptBrief id={concept.conceptId}/>;
    }

    renderLang(i, literal) {
        const title = literal.lang ? literal.lang : 'all';

        return <select id={`lang-dropdown-${i}`}
                       title={title}
                       className="form-control"
                       style={{ textAlign: "right", width: 52, height: 36, marginLeft: "1em", padding: 0 }}
                       value={literal.lang || ''}
                       onChange={this.createHandleLiteralLangChange(i)}>
            <option value="">all</option>
            <option value="de">de</option>
            <option value="en">en</option>
            <option value="fr">fr</option>
            <option value="ru">ru</option>
        </select>;
    }

    renderDf(df: number, searchQuery: string) {
        return <Link to={`/search/?q=${searchQuery}`} target="_blank">{df}</Link>;
    }

    renderSimilar(similarConcepts: Array<SimilarConceptType>) {
        if (!similarConcepts) {
            return;
        }

        return <CollapsibleList className="similar-concepts-list"
                                collapseThreshold={3}
                                collapsedCount={1}
                                componentClass="ul">
        {similarConcepts.map(similarConcept =>
            <ConceptBriefPopover key={similarConcept.id}
                                 concept={similarConcept}
                                 actions={popoverConcept =>
                                     this.renderActions(popoverConcept, similarConcept.canBeParent)}/>)}
        </CollapsibleList>
    }

    prepareSuggestQuery = query => query.indexOf('#') === 0 ? query.slice(1) : null;

    stringifyLiteralItems = query => query
        .map((item, i) => item.label ?
            `"${item.label}"${query[i + 1]?.label || typeof query[i + 1] === 'string' ? ' ' : ''}`
            : item
        ).join('');

    renderLiteral(l: LocalLiteralType, i: number) {
        const hasErrors = l.errors && !!l.errors.length;
        const hasWarning = !hasErrors && l.warnings && !!l.warnings.length;
        const itemClasses = cx('list-group-item condensed', {
            'has-info': l.isAmbiguous && hasWarning,
            'has-warning': !l.isAmbiguous && hasWarning,
            'has-error': hasErrors
        });

        const inputItems = this.getInputItems(l);

        return <li key={i} className={itemClasses}>
            <Row className="literal-row">
                <Col sm={3} style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        <MixedInput
                            ref={this.createSetLiteralInput(i)}
                            inputClassName="literal-input"
                            omniProps={null}
                            buttonProps={null}
                            autoFocus
                            placeholder="Literal name"
                            query={inputItems}
                            splitIntoTokens={this.splitIntoTokens}
                            stringifyQuery={this.stringifyLiteralItems}
                            prepareSuggestQuery={this.prepareSuggestQuery}
                            onSubmit={this.createHandleLiteralNameSubmit(i)}
                            onChange={this.createHandleLiteralNameChange(i)}
                            onBlur={this.createHandleBlur(i)}
                            conceptPopoverContents={this.renderConceptPopoverContents}
                        />
                    </div>
                    {this.renderLang(i, l)}
                </Col>
                <Col sm={1} className="text-center">
                    <Checkbox checked={l.isAmbiguous}
                              onChange={this.createHandleLiteralIsAmbiguousChange(i)}
                              inline>
                        &nbsp;
                    </Checkbox>
                </Col>
                <Col sm={1}>
                    {this.renderDf(l.df, l.searchQuery)}
                </Col>
                <Col sm={3}>
                    {this.renderSimilar(l.similarLiteralsConcepts)}
                </Col>
                <Col sm={3}>
                    {this.renderSimilar(l.lexicalNeighborsConcepts)}
                </Col>
                <Col sm={1} className="text-right">
                    <Button onClick={this.createDeleteLiteral(i)}
                            title="Click here to remove literal">
                        <Glyphicon glyph="trash" />
                    </Button>
                </Col>
            </Row>
            {(hasErrors || hasWarning) &&
            <Row>
                {hasErrors && l.errors && <ErrorsList errors={l.errors}/>}
                {hasWarning && l.warnings && <ErrorsList errors={l.warnings}/>}
            </Row>}
        </li>;
    }

    getLabel(str: string) {
        const exclusion = { 'Save': 'edit' };
        const [currentUser] = this.context;

        const isModerator = !!currentUser.activeQuotas.ontology_moderator;
        return isModerator ? str : `Suggest ${exclusion[str] || str.toLowerCase()}`;
    }

    createMergeInto(concept: OntologyConceptType, missingLiterals: Array<LiteralType>) {
        return () => {
            const isNewConcept = !this.props.id;
            const warning = isNewConcept ? '' : ontologyHelpers.checkConceptName(this.props.name, this.props.literals);
            const nameErrors = isNewConcept ? [] : ontologyHelpers.validateName(this.props.name);
            const errors = isNewConcept ? [] : ontologyHelpers.validate(this.props);
            let submitFunction: ?Promise<Response> = null;

            this.updateValues({ errors, nameErrors, warning });

            if (errors.length === 0) {
                const resultingConcept = ontologyHelpers.constructMergedConcept(
                    ontologyHelpers.collectConcept(this.props),
                    concept,
                    missingLiterals
                );

                this.hideMergeModal();

                if (isNewConcept) {
                    submitFunction = helpers.loadingAwareSubmit.call(this, resultingConcept);
                } else {
                    submitFunction = this.toggleDeleted(true)
                        .then(() => {
                            return this.props.submit(resultingConcept);
                        })
                }
            }

            if (submitFunction) {
                return submitFunction
                    .then(() => {
                        this.props.onMerge && this.props.onMerge(concept);
                    })
                    .catch(err => {
                        let errors;

                        if (err.graphQLErrors && err.graphQLErrors.length) {
                            errors = err.graphQLErrors.map(e => e.message);
                        } else {
                            errors = ['Network problem, please try again.'];
                        }

                        this.updateValues({errors});

                        this.hideMergeModal();
                        if (this.props.deleted === true) {
                            this.toggleDeleted(false); // rollback
                        }
                    });
            }
        };
    }

    renderMergeActions = (intoConcept: OntologyConceptType) => {
        if (this.props.onRenderActions) {
            return this.props.onRenderActions(intoConcept);
        }

        if (this.props.literals.length === 0) {
            return null;
        }

        return <div className="merge">
            <QMissingLiterals conceptId={intoConcept.id} literals={this.props.literals}>
                {missingLiterals => (
                    <MergeActions
                        concept={intoConcept}
                        missingLiterals={missingLiterals}
                        onMerge={this.createMergeInto(intoConcept, missingLiterals)}
                    />
                )}
            </QMissingLiterals>
        </div>
    }

    updateAfterUseAsParentComposite = (newConcept: UseAsParentCompositeResultType) => {
        this.updateValues({
            ...newConcept,
            literals: newConcept.literals.map(newLiteral => ({
                ...newLiteral,
                warnings: this.getWarningsForLiteral(newLiteral, newConcept.literals)
            }))
        });
    }

    renderActions = (conceptForAction: OntologyConceptType, canBeParent: boolean) => {
        return <div>
            {this.renderMergeActions(conceptForAction)}
            {canBeParent &&
             <UseAsParentComposite conceptId={this.props.id}
                                   parentConcept={conceptForAction}
                                   literalsForUpdate={this.props.literals}
                                   onResult={this.updateAfterUseAsParentComposite}/>}
        </div>
    }

    getCantMessage(action: string, childCount: number) {
        return `Can't ${action} because this concept is a parent
                for ${childCount} other ${singlePlural('concept', childCount)}.`
    }

    renderDeleteMergeButtons(buttonsDisabled: boolean) {
        let deleteTitle = null;
        let mergeTitle = null;
        const childCount = this.props.childConceptsCount || 0;
        if (!(this.props.deleted || buttonsDisabled) && childCount !== 0) {
            buttonsDisabled = true;
            deleteTitle = this.getCantMessage('delete', childCount);
            mergeTitle = this.getCantMessage('merge', childCount);
        }
        return <Fragment>
            {!!this.props.id &&
             <LoadingButton bsStyle="danger"
                            disabled={buttonsDisabled}
                            onClick={this.toggleDeleted}
                            working={this.state.working}
                            done={this.state.done}
                            title={deleteTitle}>
                 {this.getLabel(this.props.deleted ? 'Restore' : 'Delete')}
             </LoadingButton>}
            {this.props.literals.length !== 0 &&
             <LoadingButton disabled={buttonsDisabled}
                            className="merge-btn"
                            onClick={this.showMergeModal}
                            working={this.state.working}
                            done={this.state.done}
                            title={mergeTitle}>
                 Merge to&hellip;
             </LoadingButton>}
        </Fragment>
    }

    renderAlerts() {
        return <Fragment>
            <PendingOntologyChangesAlert conceptId={this.props.id}/>
            {this.props.deleted &&
                <Alert bsStyle="danger">
                    This concept was deleted. Restore to edit.
                </Alert>
            }
            {!!this.props.errors.length &&
                <Alert bsStyle="danger" id="errors">
                    Error saving concept:
                    <ul>
                        {this.props.errors.map(e => (
                            <li key={e}>{e}</li>
                        ))}
                    </ul>
                </Alert>
            }
        </Fragment>;
    }

    render() {
        const disabled = this.props.deleted;
        const buttonsDisabled = this.state.working || this.state.done;
        const labelWidth = 1;
        const inputWidthSm = 8;
        const inputWidthMd = 4;
        const hasWarning = !this.props.nameErrors.length && !!this.props.warning;
        const nameClasses = {
            'has-warning': hasWarning,
            'has-error': !!this.props.nameErrors.length,
        };
        const classes = cx('concept-form form-horizontal', this.props.className);
        const additionalButtons = typeof this.props.additionalButtons === 'function' ?
            this.props.additionalButtons(this)
            : this.props.additionalButtons;

        return <div className={classes}>
            {!this.props.hideBreadcrumbs &&
                <ConceptFormBreadcrumbs
                    conceptId={this.props.id}
                    name={this.props.name}/>}
            <h1>Concept <em>{this.props.name}</em></h1>

            {this.renderAlerts()}

            <FormGroup className={nameClasses}>
                <Col componentClass={ControlLabel} sm={labelWidth}>
                    Name
                </Col>
                <Col sm={inputWidthSm} md={inputWidthMd}>
                    <input type="text" placeholder="name"
                                       className="form-control"
                                       onChange={this.handleNameChange}
                                       onBlur={this.handleNameBlur}
                                       disabled={disabled}
                                       value={this.props.name} />
                </Col>
                {!!this.props.nameErrors.length && <Col sm={10} smOffset={labelWidth}>
                    <ErrorsList errors={this.props.nameErrors}/>
                </Col>}
                {hasWarning &&
                    <Col sm={inputWidthSm} smOffset={labelWidth}>
                        <div className="help-block">
                            {this.props.warning}
                        </div>
                    </Col>
                }
            </FormGroup>
            <FormGroup>
                <Col componentClass={ControlLabel} sm={labelWidth}>Basic</Col>
                <Col sm={inputWidthSm}>
                    <Checkbox onChange={this.handleChangeBasic}
                              disabled={disabled}
                              checked={this.props.basic}>
                        Mark concept as &quot;basic&quot; if you don&apos;t want it to be
                        used in article analysis.
                    </Checkbox>
                </Col>
            </FormGroup>
            <FormGroup>
                <Col componentClass={ControlLabel} sm={labelWidth}>DF</Col>
                <Col sm={inputWidthSm} className="form-group-text">
                    <div>{this.renderDf(this.props.potentialTotalDf, this.props.searchQuery)}</div>
                </Col>
            </FormGroup>
            <div className="literals">
                <ul className="list-group">
                    <li className="list-group-item condensed" key="header">
                        <Row className="row-header">
                            <Col sm={3}>Literal</Col>
                            <Col sm={1}>Ambiguous</Col>
                            <Col sm={1}>DF</Col>
                            <Col sm={3}>Conflicts</Col>
                            <Col sm={3}>Neighbors</Col>
                            <Col sm={1}/>
                        </Row>
                    </li>
                    {this.props.literals.map(this.renderLiteral.bind(this))}
                    <li className="list-group-item condensed" key="new">
                        <Row>
                            <Col sm={3}>
                                <FormControl
                                    type="text"
                                    className="form-control"
                                    inputRef={this.createSetLiteralInput('newLiteral')}
                                    placeholder="Type here to add..."
                                    onChange={this.createLiteral}/>
                            </Col>
                        </Row>
                    </li>
                </ul>
            </div>

            <ButtonToolbar>
                {additionalButtons}
                <LoadingButton bsStyle="primary"
                               disabled={buttonsDisabled || this.props.deleted}
                               onClick={this.saveConcept}
                               working={this.state.working}
                               done={this.state.done}>
                    {this.props.saveText || this.getLabel('Save')}
                </LoadingButton>

                {this.renderDeleteMergeButtons(buttonsDisabled)}
            </ButtonToolbar>
            {!!this.props.errors.length &&
                <div className="has-error">
                    <div className="help-block">
                        * An error occurred.&nbsp;
                        <a href="#errors">Click here</a> to scroll up for details.
                    </div>
                </div>
            }
            <Modal show={this.state.showMergeModal}
                   onHide={this.hideMergeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Search concept to merge it with current one:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="merge-modal">
                    <ConceptSearch
                        actions={this.renderMergeActions}
                        ignore={this.props.id ? [this.props.id] : []}
                        requestCache={this.props.conceptSuggestCache} />
                </Modal.Body>
            </Modal>
        </div>;
    }
}

export function ConceptFormBreadcrumbs({conceptId, name}) {
    return <ol className="breadcrumb" role="navigation">
        <li><a href="/ontology/concepts/">Concepts</a></li>
        <li className="active">
            {conceptId ?
             <a href={ontologyHelpers.constructConceptPath(conceptId, name)}>{name}</a> :
             'New concept'}
        </li>
        {conceptId && <a className="pull-right" href={
            ontologyHelpers.constructConceptLogPath(conceptId)}>Modification log</a>}
    </ol>;
}

EditConceptForm.contextType = CurrentUserContext;

const queryConcept = gql`
query concept($id: Int) {
    concept(id: $id) {
        id
        name
        literals {
            name
            composite
            isAmbiguous
            lang
            similarLiteralsConcepts {
                id
                name
            }
            lexicalNeighborsConcepts {
                id
                name
                canBeParent
            }
            expandedLiteralsVariants {
                name
            }
        }
        df
        basic
        isManuallyModified
        dateModified
        deleted
        childConceptsCount
        potentialDfs {
            perLiteral {
                searchQuery
                df
            }
            total {
                searchQuery
                df
            }
        }
    }
}
`;

const checkLiteral = gql`
query validateLiterals($literals: [LiteralInput]!, $currentLiterals: [LiteralInput]!) {
    conflictConcepts: searchConflictConcepts(literals: $currentLiterals) {
        id
        name
    }
    neighborConcepts: searchNeighborConcepts(literals: $currentLiterals) {
        id
        name
        canBeParent
    }
    expandedLiteralsVariants(literals: $literals) {
        name
    }
    literalsDfs(literals: $literals) {
        perLiteral {
            searchQuery
            df
        }
        total {
            searchQuery
            df
        }
    }
}
`;

const fetchLiteralsDfs = gql`
query fetchLiteralsDfs($literals: [LiteralInput]!) {
    literalsDfs(literals: $literals) {
        total {
            searchQuery
            df
        }
    }
}
`;

export class EditConceptFormStateful extends React.Component<
    EditConceptFormPropsType,
    EditConceptFormStatefulStateType
> {
    state = {
        // concept fields
        id: this.props.id ? `${this.props.id}` : null,
        name: this.props.name || '',
        literals: this.props.literals || [],
        basic: this.props.basic || false,
        deleted: this.props.deleted || false,
        isManuallyModified: false,
        conceptSuggestCache: new LRUCache(),
        done: false,
        working: false,
        errors: [],
        warning: null,
        nameErrors: [],
        potentialTotalDf: this.props.potentialTotalDf || 0,
        searchQuery: this.props.searchQuery || '',
    };

    handleChange = (change: $Shape<EditConceptFormStatefulStateType>, id: any) => {
        this.setState(change);
    };

    handleError = (errors: Array<string>, form?: EditConceptForm) => {
        this.setState({ errors });
    }

    render() {
        return <EditConceptForm
            onChange={this.handleChange}
            onError={this.handleError}
            { ...this.props }
            { ...this.state } />;
    }
}


function setDfs(concept) {
    if (concept === undefined || concept === null) {
        return concept;
    }

    const conceptWithDfs = {
        ...concept,
        potentialTotalDf: concept.potentialDfs.total.df,
        searchQuery: concept.potentialDfs.total.searchQuery,
        literals: concept.literals.map((literal, i) => ({
            ...literal,
            df: concept.potentialDfs.perLiteral[i].df,
            searchQuery: concept.potentialDfs.perLiteral[i].searchQuery
        }))
    };

    return conceptWithDfs;
}


export function EditConceptFormWrapper({
    id,
    data: { concept, loading, error, refetch },
    ...rest
} : {
    id: string,
    data: {
        concept: any,
        loading: boolean,
        error: any,
        refetch: () => any
    }
}) {
    return <Loading what="concept"
                    loading={loading && !!id}
                    error={error}>
        {!id || concept !== null ?
            <EditConceptFormStateful
                { ...rest }
                { ...setDfs(concept) }
                refetch={refetch} />
            : <p>Concept doesn&apos;t exist.</p>}
    </Loading>;
}

export default withApollo(compose(
    graphql(queryConcept, {
        options: ({id}) => ({ variables: {id} }),
    }),
    graphql(mutateConcept, {
        props: ({ mutate }) => ({
            submit: ({id, name, literals, basic, deleted}) =>
                mutate({
                    variables: {id, name, literals, basic, deleted},
                }),
        }),
    })
)(EditConceptFormWrapper));
