import React from 'react';

import { SuggestBox } from '../widgets/Suggest';
import { checkStatus, singlePlural } from '../utils';
import { ConceptList } from '../articles/Concept';

import '../ontologies/styles.css';


export function SuggestSelectConceptsList({ onAdd, onRemove, selectedConcepts, children }) {

    function suggestFetch(query: string) {
        return fetch(`/api/autocomplete/concepts?query=${encodeURIComponent(query)}`, {
            credentials: 'same-origin',
        })
            .then(checkStatus)
            .then(response => response.json());
    }

    return <>
        <div className="added search-articles selected-articles">
            <div className="search-entity results">
                { children }
                <div>
                    {selectedConcepts &&
                     <ConceptList concepts={selectedConcepts}
                                  collapseThreshold={110}
                                  collapsedCount={100}
                                  collapsible
                                  onRemove={onRemove} />
                    }
                </div>
            </div>
        </div>
        <div className="panel panel-default search-block">
            <div className="top search-header panel-title">
                <div className="search-omnibox input-group">
                    <SuggestBox minChars={2}
                                maxLabelLength={70}
                                callback={onAdd}
                                shouldClearOnSelect={true}
                                suggestFetch={suggestFetch}
                                divClasses="concept-suggest"
                                showSuggestType={false}
                                selectOnExactMatch={true}
                                placeholder="Search concepts" />
                </div>
            </div>
        </div>
    </>;
}


export default function JobConcepts({ onAdd, onRemove, selectedConcepts }) {
    return <SuggestSelectConceptsList onAdd={onAdd} onRemove={onRemove} selectedConcepts={selectedConcepts}>
        <h4 className="info" style={{marginLeft: 0}}>{selectedConcepts.length} selected {singlePlural('concept', selectedConcepts.length)}
            <p className="help-block">
                (Candidates cannot see this list)
            </p>
        </h4>
        <p className="help-block">Typically added concepts will be suppressed by concepts from authors and articles.
                Use this when no authors and articles are available.</p>
    </SuggestSelectConceptsList>;
}
