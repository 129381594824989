import React from 'react';

import TwoStepCollapsibleList from '../widgets/TwoStepCollapsibleList';
import AuthorLink from '../authors/AuthorLink';


function CommonInstitution({ institution }) {
    return <li>
        <strong>
            {institution.startYear}{institution.endYear > institution.startYear && `-${institution.endYear}`}
        </strong>{' '}
        {institution.name} with <AuthorLink {...institution.commonWith} />
    </li>;
}

export default function CommonInstitutions({ commonInstitutions, author }) {
    return <div className="common-institutions">
        {commonInstitutions.length > 0 &&
            <div>
                <span className="referee-row-anchor" id={`affiliations-${author.id}`}></span>
                <h5 style={{display:'inline'}}>
                    <span className="glyphicon glyphicon-home text-danger" style={{marginLeft: '-20px', marginRight: '6px'}} />
                    Recent common affiliations:
                </h5>{' '}
                <TwoStepCollapsibleList defaultCount={5} word="latest">
                    {commonInstitutions.map((inst, index) =>
                        <CommonInstitution key={inst.name+'-'+index} institution={inst} />)}
                </TwoStepCollapsibleList>
            </div>
        }
    </div>
}
