import React from 'react';
import DocumentTitle from 'react-document-title';


export default function RefereedSavedSearch({ search }) {
    let params = new URLSearchParams({q: search.query, sort: search.sort});
    search.facets.forEach(([fname, vals]) =>
        vals.forEach(val => params.append(fname, val)));
    return <>
        <DocumentTitle title={`Commissioning - ${search.name}`} />
        <h2><a href={`/search/?${params.toString()}`}>{search.name}</a></h2>
        <p>{search.query}</p>
    </>;
}
