import { sendRequest } from '../datastore';


export const makeArticleKey = articleId => `article-${articleId}`;
export const makeAsRefDocArticleKey = articleId => `article-${articleId}-as-ref-doc`;

// Don't bother with de-duplicating data with partial article
// we have some additional flags here, references, full author list, etc
export function makeRefDocArticleQuery(articleId) {
    const refdocKey = makeAsRefDocArticleKey(articleId);
    return {
        id: refdocKey,
        get: refdocKey,
        set: function (store, { value: { article } }) {
            store[refdocKey] = article;
        },
        req: () => sendRequest(`/api/articles/${articleId}/as-ref-doc`),
    };
}
