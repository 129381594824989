import React from 'react';

import _objectWithoutProperties from 'babel-runtime/helpers/objectWithoutProperties';


import BaseOverlay from 'react-overlays/lib/Overlay';
import Fade from 'react-bootstrap/lib/Fade';
import Portal from 'react-overlays/lib/Portal';
import Position from 'react-overlays/lib/Position';

import RootCloseWrapper from './RootCloseWrapper';


export default class Overlay extends BaseOverlay {
    render() {
        const {
            container, containerPadding, target, placement,
            shouldUpdatePosition, rootClose, children, animation,
        } = this.props;

        const Transition = animation === true ? Fade : animation || null;

        const props = _objectWithoutProperties(
            this.props,
            ['container', 'containerPadding', 'target', 'placement', 'shouldUpdatePosition', 'rootClose', 'children', 'transition']
        );
        // Don't un-render the overlay while it's transitioning out.

        const mountOverlay = props.show || (Transition && !this.state.exited);
        if (!mountOverlay) {
            // Don't bother showing anything if we don't have to.
            return null;
        }

        var child = children;

        // Position is be inner-most because it adds inline styles into the child,
        // which the other wrappers don't forward correctly.
        child = <Position { ...{ container, containerPadding, target, placement, shouldUpdatePosition } }>
            {child}
        </Position>;

        if (Transition) {
            const { onExit, onExiting, onEnter, onEntering, onEntered } = props;

            // This animates the child node by injecting props, so it must precede
            // anything that adds a wrapping div.

            child = <Transition in={props.show}
                                appear={true}
                                onExit={onExit}
                                onExiting={onExiting}
                                onExited={this.onHiddenListener}
                                onEnter={onEnter}
                                onEntering={onEntering}
                                onEntered={onEntered}>
                {child}
            </Transition>
        }

        // This goes after everything else because it adds a wrapping div.
        if (rootClose) {
            child = <RootCloseWrapper onRootClose={props.onHide}>
                {child}
            </RootCloseWrapper>
        }

        return <Portal container={container}>
            {child}
        </Portal>;
    }
}
