import React from 'react';

import Alert from 'react-bootstrap/lib/Alert';
import FormControl from 'react-bootstrap/lib/FormControl';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import MaskedInput from 'react-text-mask';
import Popover from 'react-bootstrap/lib/Popover';
import SafeAnchor from 'react-bootstrap/lib/SafeAnchor';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

import type { FindClaimArticleType } from '../articles/Types';
import type { ConceptItemType } from '../ontologies/Types';
import type { JobType, FoundArticleType, FoundAuthorType } from './Types';
import type { CurrentUserType } from '../accounts/Types';

import { updateJob } from './JobData';
import JobArticles from './JobArticles';
import JobAuthors from './JobAuthors';
import JobConcepts from './JobConcepts';
import { PublishClarification, requirementsOptions } from './helpers';
import { updateGlobalData } from '../datastore';
import withCurrentUser from '../common/withCurrentUser';
import LoadingButton from '../widgets/LoadingButton';
import { checkStatus, cx, EMAIL_REGEX, pick } from '../utils';

import './jobs.css';
import { VacancyStatus } from './Types';


type EditJobFormPropsType = {
    ...JobType<FindClaimArticleType>,
    history: any,
    currentUser: CurrentUserType,
    lastArticles: ?Array<FoundArticleType>,
};

type EditJobFormStateType = {
    id: ?number,
    title: string,
    descriptionMarkdown: string,
    startInfo: string,
    duration: string,
    dateDeadline: string,
    affiliation: string,
    infoEmail: string,
    requirements: Array<string>,
    articles: Array<FoundArticleType>,
    authors: Array<FoundAuthorType>,
    concepts: Array<ConceptItemType>,
    text: string,
    working: boolean,
    error: string,
    formErrors: { [key: string]: string },
    lastArticles: ?Array<FoundArticleType>,
    modalOpened: boolean,
};

const isIntBetween = (num: string, min: number, max: number) => {
    const int = parseInt(num, 10);
    return !isNaN(int) && int >= min && int <= max && num.match(/^\d+$/)
};


class EditJobForm extends React.Component<
    EditJobFormPropsType,
    EditJobFormStateType
> {
    static defaultProps = {
        id: null,
        title: '',
        descriptionMarkdown: '',
        startInfo: '',
        duration: '',
        dateDeadline: '',
        affiliation: '',
        articles: [],
        authors: [],
        concepts: [],
        text: '',
        error: '',
        lastArticles: null,
    };

    handlers = {};
    validators = {};

    DEADLINE_MASK = [/[2-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-1]/, /[0-9]/, '-', /[0-3]/, /[0-9]/];

    requiredFields = [
        'title',
        'descriptionMarkdown',
        'dateDeadline',
        'affiliation',
        'infoEmail',
    ];

    constructor(props: EditJobFormPropsType) {
        super(props);
        const relevantArticles = props.articles && props.articles.length ?
            props.articles
            : (props.lastArticles || []);

        this.state = {
            id: this.props.id,
            title: '',
            descriptionMarkdown: '',
            startInfo: '',
            duration: '',
            dateDeadline: '',
            affiliation: '',
            infoEmail: this.props.currentUser.email,
            requirements: ['cv'],
            error: '',
            text: '',
            authors: [],
            concepts: [],
            ...pick([
                'title', 'descriptionMarkdown', 'startInfo', 'duration', 'dateDeadline',
                'affiliation', 'requirements', 'text', 'authors', 'concepts'
            ], props),
            // separate treatment: articles can be supplied either from the job itself
            // or from props.lastArticles
            articles: relevantArticles,
            working: false,
            formErrors: {},
            modalOpened: false,
        };
    }

    createValidate(field: string) {
        if (!this.validators[field]) {
            this.validators[field] = (shouldSetState: boolean = true) => {
                const formErrors = { ...this.state.formErrors };

                const error = this.validateField(field, this.state[field]);

                if (error) {
                    formErrors[field] = error;
                } else {
                    delete formErrors[field];
                }

                shouldSetState && this.setState({ formErrors });

                return formErrors;
            }
        }

        return this.validators[field];
    }

    validateField(field: string, value: string) {
        if (this.requiredFields.indexOf(field) !== -1 && !this.state[field]) {
            return 'This field is required.';
        }

        if (field === 'infoEmail' && !this.state[field].match(EMAIL_REGEX)) {
            return `"${this.state[field]}" doesn't look like a valid email address.`;
        }

        if (field === 'dateDeadline' && this.state[field]) {
            const [, month, day] = this.state[field].split('-');

            if (!isIntBetween(month, 1, 12) || !isIntBetween(day, 1, 31)) {
                return "This doesn't look like a valid date.";
            }
        }

        // scientificTopic is not a real input, it's a label used by a number
        // of components to track common validation state: at least one of them
        // has to be filled in
        if (field === 'scientificTopic'
            && (!this.state.text
                && !this.state.articles.length
                && !this.state.authors.length
                && !this.state.concepts.length))
        {
            return 'Please select at least one author, article, concept or enter text';
        }

        return '';
    }

    validate() {
        // Check that at least one of the scientific topic fields is filled in
        // similarly to the required fields check
        const scival = this.validateField('scientificTopic');
        return {
            ...(scival ? { scientificTopic: scival } : {}),
            ...this.requiredFields
            .reduce((acc, field) => {
                const error = this.validateField(field, this.state[field]);

                return {
                    ...acc,
                    ...(error ? { [field]: error } : {})
                }
            }, {})
        };
    }

    submit = (status) => {
        const formErrors = this.validate();

        if (Object.keys(formErrors).length) {
            this.setState({ formErrors });
            if (this.state.modalOpened) {
                this.closePublishClarification();
            }
            return;
        }

        const data = {
            id: this.state.id,
            title: this.state.title,
            descriptionMarkdown: this.state.descriptionMarkdown,
            startInfo: this.state.startInfo,
            duration: this.state.duration,
            dateDeadline: this.state.dateDeadline,
            affiliation: this.state.affiliation,
            infoEmail: this.state.infoEmail,
            requirements: this.state.requirements,
            articlesIds: this.state.articles.map(a => a.articleId),
            authorsIds: this.state.authors.map(a => a.id),
            conceptsIds: this.state.concepts.map(c => c.conceptId),
            text: this.state.text,
            status: status,
        };

        return fetch(`/api/jobs/edit-vacancy/`, {
            method: 'post',
            credentials: 'same-origin',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then(checkStatus)
            .then(response => response.json())
            .then((response: { ok: boolean, error?: string, id?: number }) => {
                const id = response.id;

                if (response.error) {
                    this.setState({
                        error: response.error,
                    });
                } else if (id) {
                    updateGlobalData(
                        updateJob,
                        {
                            id,
                            updatedFields: data,
                            articles: this.state.articles,
                            authors: this.state.authors,
                            concepts: this.state.concepts,
                        }
                    );
                    let redirectUrl = `/jobs/${id}${status === VacancyStatus.published ? '?published=1' : ''}`;
                    window.location.href = redirectUrl;
                    
                }
            });
    }

    showPublishClarification = () => {
        this.setState({modalOpened: true})
    }

    closePublishClarification = () => {
        this.setState({modalOpened: false})
    }

    saveAsDraft = (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        this.submit(VacancyStatus.draft);
    }

    createHandleChange(field: string) {
        if (!this.handlers[field]) {
            this.handlers[field] = (e: SyntheticEvent<HTMLInputElement>) => {
                this.setState({
                    [field]: e.currentTarget.value
                });
            }
        }

        return this.handlers[field];
    }

    createHandleRequirementsToggle(requirement: string) {
        const key = `requirements-${requirement}`;

        if (!this.handlers[key]) {
            this.handlers[key] = (e: SyntheticEvent<HTMLInputElement>) => {
                const requirements = e.currentTarget.checked ?
                    [ ...this.state.requirements, requirement]
                    : this.state.requirements.filter(r => r !== requirement);

                this.setState({ requirements });
            }
        }

        return this.handlers[key];
    }

    handleArticleAdd = article => {
        this.setState({ articles: this.state.articles.concat(article) },
                      () => this.createValidate('scientificTopic')());
    }

    handleArticleRemove = article => {
        this.setState({ articles: this.state.articles.filter(a => a.articleId !== article.articleId) },
                      () => this.createValidate('scientificTopic')());
    }

    handleAuthorAdd = author => {
        if (this.state.authors.filter(a => a.id === author.id).length === 0)
        {
            this.setState({ authors: this.state.authors.concat(author) },
                          () => this.createValidate('scientificTopic')());
        }
    }

    handleAuthorRemove = author => {
        this.setState({ authors: this.state.authors.filter(a => a.id !== author.id) },
                      () => this.createValidate('scientificTopic')());
    }

    handleConceptAdd = concept => {
        if (!concept.isNotSuggest && this.state.concepts.filter(c => c.conceptId === parseInt(concept.id)).length === 0) {
            this.setState({ concepts: this.state.concepts.concat({conceptId: parseInt(concept.id), name: concept.value}) },
                          () => this.createValidate('scientificTopic')());
        }
    }

    handleConceptRemove = concept => {
        this.setState({ concepts: this.state.concepts.filter(c => c.conceptId !== concept.conceptId) },
                      () => this.createValidate('scientificTopic')());
    }

    render() {
        const markdownOverlay = <Popover id="markdown-help-popover">
            <div className="markdown-help-container">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Element</th>
                            <th>Example</th>
                            <th>Markdown Syntax</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Headings</td>
                            <td>
                                <h3>H3</h3>
                                <h4>H4</h4>
                            </td>
                            <td>
                                ### H3<br/>
                                #### H4
                            </td>
                        </tr>
                        <tr>
                            <td>Bold text</td>
                            <td><strong>Bold text</strong></td>
                            <td>**Bold text**</td>
                        </tr>
                        <tr>
                            <td>Italic</td>
                            <td><i>Italic</i></td>
                            <td>*Italic*</td>
                        </tr>
                        <tr>
                            <td>Blockquote</td>
                            <td><blockquote>Blockquote</blockquote></td>
                            <td>&gt; blockquote</td>
                        </tr>
                        <tr>
                            <td>Ordered List</td>
                            <td>
                                <ol>
                                    <li>First item</li>
                                    <li>Second item</li>
                                </ol>
                            </td>
                            <td>
                                1. First item<br/>
                                2. Second item
                            </td>
                        </tr>
                        <tr>
                            <td>Unordered List</td>
                            <td>
                                <ul>
                                    <li>First item</li>
                                    <li>Second item</li>
                                </ul>
                            </td>
                            <td>
                                - First item<br/>
                                - Second item
                            </td>
                        </tr>
                        <tr>
                            <td>Links</td>
                            <td><button className="inline-link">Link</button></td>
                            <td>{'[Link](https://www.prophy.ai/)'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Popover>;

        const hasErrors = !!Object.keys(this.state.formErrors).length || this.state.error;
        const formErrors = this.state.formErrors;
        const requirementsOptionsToRender = [
            {
                id: 'electronicProfile',
                label: 'electronic profile',
                alwaysRequired: true
            }
        ].concat(requirementsOptions);

        return <form className="edit-job-form form-horizontal">
            {this.state.error && <Alert bsStyle="danger" id="errors">
                {this.state.error}
            </Alert>}
            <div className={cx('form-group row required', { 'has-error': !!formErrors.title })}>
                <label htmlFor="job-title" className="control-label col-sm-3">
                    Job title:
                </label>
                <div className="col-sm-9">
                    <FormControl
                        id="job-title"
                        onChange={this.createHandleChange('title')}
                        onBlur={this.createValidate('title')}
                        type="text"
                        placeholder="Vacancy title"
                        value={this.state.title}/>
                    {!!formErrors.title && <p className="help-block">
                        {formErrors.title}
                    </p>}
                </div>
            </div>
            <div className={cx('form-group row required', { 'has-error': !!formErrors.affiliation })}>
                <label htmlFor="job-affiliation" className="control-label col-sm-3">
                    Institution:
                </label>
                <div className="col-sm-6">
                    <FormControl
                        id="job-affiliation"
                        onChange={this.createHandleChange('affiliation')}
                        onBlur={this.createValidate('affiliation')}
                        type="text"
                        placeholder="e.g., Massachusetts Institute of Technology"
                        value={this.state.affiliation}/>
                    {!!formErrors.affiliation && <p className="help-block">
                        {formErrors.affiliation}
                    </p>}
                </div>
            </div>
            <hr/>
            <div className={cx('form-group row required', { 'has-error': !!formErrors.dateDeadline })}>
                <label htmlFor="job-deadline" className="control-label col-sm-3">
                    Application deadline:
                </label>
                <div className="col-sm-4">
                    <MaskedInput
                        id="job-deadline"
                        className="form-control"
                        onChange={this.createHandleChange('dateDeadline')}
                        onBlur={this.createValidate('dateDeadline')}
                        type="text"
                        placeholder="YYYY-MM-DD"
                        value={this.state.dateDeadline}
                        mask={this.DEADLINE_MASK}/>
                    {!!formErrors.dateDeadline && <p className="help-block">
                        {formErrors.dateDeadline}
                    </p>}
                </div>
                <p className="help-block col-sm-2">(apply before)</p>
            </div>
            <div className={cx('form-group row', { 'has-error': !!formErrors.startInfo })}>
                <label htmlFor="job-start" className="control-label col-sm-3">
                    Job start:
                </label>
                <div className="col-sm-4">
                    <FormControl
                        id="job-start"
                        className="form-control"
                        onChange={this.createHandleChange('startInfo')}
                        type="text"
                        placeholder="e.g., September 2020"
                        value={this.state.startInfo}/>
                </div>
            </div>
            <div className={cx('form-group row', { 'has-error': !!formErrors.duration })}>
                <label htmlFor="job-duration" className="control-label col-sm-3">
                    Job duration:
                </label>
                <div className="col-sm-4">
                    <FormControl
                        id="job-duration"
                        className="form-control"
                        onChange={this.createHandleChange('duration')}
                        type="text"
                        placeholder="e.g., 2 years"
                        value={this.state.duration}/>
                </div>
            </div>
            <hr/>
            <div className={cx('form-group row required', { 'has-error': !!formErrors.descriptionMarkdown })}>
                <label htmlFor="job-description" className="control-label col-sm-3">
                    Description:
                </label>
                <div className="col-sm-9">
                    <p className="help-block">
                        You can use Markdown here. For help,{' '}
                        <OverlayTrigger trigger="click" overlay={markdownOverlay} placement="top">
                            <SafeAnchor componentClass="button"
                                        className="get-help inline-link overlay-trigger"
                                        title="Click to get help">
                                click here.
                            </SafeAnchor>
                        </OverlayTrigger>
                    </p>
                    <textarea
                        id="job-description"
                        onChange={this.createHandleChange('descriptionMarkdown')}
                        onBlur={this.createValidate('descriptionMarkdown')}
                        className="form-control"
                        value={this.state.descriptionMarkdown}/>
                    {!!formErrors.descriptionMarkdown && <p className="help-block">
                        {formErrors.descriptionMarkdown}
                    </p>}
                </div>
            </div>
            <div className={cx('form-group row required', { 'has-error': !!formErrors.infoEmail })}>
                <label htmlFor="job-contact" className="control-label col-sm-3">
                    Contact email:
                </label>
                <div className="col-sm-4">
                    <FormControl
                        id="job-contact"
                        onChange={this.createHandleChange('infoEmail')}
                        onBlur={this.createValidate('infoEmail')}
                        type="text"
                        placeholder="john.smith@example.com"
                        value={this.state.infoEmail}/>
                    {!!formErrors.infoEmail && <p className="help-block">
                        {formErrors.infoEmail}
                    </p>}
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="job-requirements" className="control-label col-sm-3">
                    Requirements:
                </label>
                <div className="col-sm-9">
                    {requirementsOptionsToRender.map(r => <div key={r.id} className="checkbox">
                        <label>
                            <input type="checkbox"
                                   checked={r.alwaysRequired || (r.id && this.state.requirements.indexOf(r.id) !== -1)}
                                   disabled={r.alwaysRequired}
                                   { ...(!r.alwaysRequired ? { onChange: this.createHandleRequirementsToggle(r.id) } : {}) }/>
                            {r.label}
                            {r.helpText && <span className="help-block">
                                {r.helpText}
                            </span>}
                        </label>
                    </div>)}
                </div>
            </div>
            <div className={cx('form-group row required', { 'has-error': !!formErrors.scientificTopic })}>
                <div className="col-sm-3"></div>
                <div className="col-sm-9">
                    <h3><label>Scientific topic</label></h3>
                    <p className="help-block">
                        Specify the topic of the position using authors, articles, concepts or free-form text.{' '}
                        We will analyze them for scientific concepts to rank the job candidates by semantic similarty.{' '}
                        <em>This information won't be visible to the applicants.</em>
                    </p>
                </div>
            </div>
            <div className={cx('form-group row ', { 'has-error': !!formErrors.scientificTopic })}>
                <label htmlFor="job-articles" className="control-label col-sm-3">
                    Relevant authors:
                </label>
                <div className="col-sm-9">
                    <div style={{marginTop: "-20px"}}>
                        <JobAuthors
                            onAdd={this.handleAuthorAdd}
                            onRemove={this.handleAuthorRemove}
                            selectedAuthors={this.state.authors}
                        />
                    </div>
                </div>
            </div>
            <div className={cx('form-group row ', { 'has-error': !!formErrors.scientificTopic })}>
                <label htmlFor="job-articles" className="control-label col-sm-3">
                    Relevant articles:
                </label>
                <div className="col-sm-9">
                    <div style={{marginTop: "-20px"}}>
                        <JobArticles
                            onAdd={this.handleArticleAdd}
                            onRemove={this.handleArticleRemove}
                            selectedArticles={this.state.articles}
                        />
                    </div>
                </div>
            </div>
            <div className={cx('form-group row ', { 'has-error': !!formErrors.scientificTopic })}>
                <label htmlFor="job-concepts" className="control-label col-sm-3">
                    Relevant concepts:
                </label>
                <div className="col-sm-9">
                    <div style={{marginTop: "-20px"}}>
                        <JobConcepts
                            onAdd={this.handleConceptAdd}
                            onRemove={this.handleConceptRemove}
                            selectedConcepts={this.state.concepts}
                        />
                    </div>
                </div>
            </div>
            <div className={cx('form-group row', { 'has-error': !!formErrors.scientificTopic })}>
                <label htmlFor="job-text" className="control-label col-sm-3">
                    Free-form text:
                </label>
                <div className="col-sm-9">
                    <textarea
                        id="job-text"
                        rows={10}
                        onChange={this.createHandleChange('text')}
                        onBlur={this.createValidate('scientificTopic')}
                        className="form-control"
                        value={this.state.text}/>
                        {!!formErrors.scientificTopic && <p className="help-block">
                            {formErrors.scientificTopic}
                        </p>}
                    <p className="help-block">This information won't be visible to the applicants.</p>
                </div>
            </div>
            <div className={cx('form-group row actions btn-with-error-row-inline')}>
                <div className="col-sm-3"/>
                <div className="col-sm-9">
                    <br /><br />
                    {!this.state.id ? <>
                        <Button bsStyle="success"
                                bsSize="large"
                                working={!!this.state.working}
                                onClick={this.showPublishClarification}>
                            Publish vacancy
                        </Button>
                        <span className="job-or">or</span>
                        <button className="inline-link" onClick={this.saveAsDraft}>
                            Save as draft
                        </button>
                    </>
                    : <LoadingButton bsStyle="primary"
                                     bsSize="large"
                                     working={this.state.working}
                                     onClick={() => this.submit(this.props.status)}>
                        Save
                    </LoadingButton>}
                    {hasErrors &&
                        <span className="has-error">
                            <span className="help-block">
                                * Some field values are incorrect.&nbsp;Scroll up for details.
                            </span>
                        </span>
                    }
                </div>
            </div>
            <Modal show={this.state.modalOpened}
                   animation={false}
                   backdrop="static"
                   onHide={this.closePublishClarification}>
                <Modal.Header>
                    <Modal.Title>
                        Publish vacancy
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PublishClarification />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closePublishClarification}>Cancel</Button>
                    <LoadingButton
                        bsStyle="success"
                        onClick={() => this.submit(VacancyStatus.published)}
                        working={this.state.working}
                    >
                        Publish
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        </form>;
    }
}

export default withCurrentUser(EditJobForm);
