import React from 'react';

import { useTrack } from '../datastore';
import { makeApplicantRecommendationQuery } from './JobData';
import LoadingREST from '../LoadingREST';
import ApplicantRecommendationForm from './ApplicantRecommendationForm';
import type { ReactRouterMatchType } from '../common/Types';


export default function ApplicantRecommendationPage(
    { match }
    : { match: ReactRouterMatchType }
) {
    const [recommendation, reqstate] = useTrack(
        makeApplicantRecommendationQuery(match.params.advisorUuid)
    );

    return <LoadingREST what="recommendation" reqstate={reqstate} doc={recommendation}>
        {recommendation && <ApplicantRecommendationForm
            recommendation={recommendation}
            advisorUuid={match.params.advisorUuid} />
        }
    </LoadingREST>;
}
