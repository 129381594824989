import React, { Fragment } from 'react';
import type { Node } from 'react';

export default class FormList extends React.Component<
    {
        items: Array<any>,
        renderItem: (item: any, i: number, vars: { ref: Function }) => Node,
        getEmptyItem: () => Object,
        renderEmptyItem: (vars: { onChange: Function }) => Node,
        onChange: (list: Array<any>) => any
    }
> {
    copying = null;

    inputs = [];

    createSetInput = (i: number) => {
        return (el: ?HTMLInputElement) => {
            this.inputs[i] = el;
        }
    }

    createNewItem = (e: SyntheticInputEvent<HTMLInputElement>) => {
        if (this.copying) return;

        const input = e.currentTarget;
        const newItemIndex = this.props.items.length;

        this.props.onChange([
            ...this.props.items.map(item => {
                const newItem = { ...item };
                delete newItem.value;

                return newItem;
            }),
            { ...this.props.getEmptyItem(), value: e.currentTarget.value }
        ]);

        this.copying = this.copyAndFocusInput(newItemIndex, input)
            .then(() => {
                input.value = '';
                delete this.copying;
            });
    };

    copyAndFocusInput(toIndex: number, from: HTMLInputElement): Promise<*> {
        return new Promise(resolve => {
            setTimeout(() => {
                if (this.inputs[toIndex]) {
                    const input = this.inputs[toIndex];
                    const len = input.value.length;

                    this.inputs[toIndex].focus();
                    input.setSelectionRange(len, len);

                    resolve();
                }
            }, 1);
        });
    }

    render() {
        return <Fragment>
            {this.props.items.map((item, i) => {
                return this.props.renderItem(item, i, { ref: this.createSetInput(i) })
            })}
            {this.props.renderEmptyItem({ onChange: this.createNewItem })}
        </Fragment>;
    }
}
