import React from 'react';
import compose from 'lodash/flowRight';

import { graphql, withApollo } from '@apollo/react-hoc';
import type { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';

import Loading from '../../Loading';
import { LoadedDocsBatchDetails } from './DocsBatchDetails';
import withCurrentUser from '../../common/withCurrentUser';
import mutateConcept from '../gql/mutateConceptGQL';
import type { UserType } from '../../accounts/Types';


function DocPossibleTable(
    { data: { loading, docPossible, error }, submit, reportFalse, currentUser, client }:
    {
        data: {
            loading: boolean, docPossible: any, error: any
        },
        submit: () => any,
        reportFalse: () => any,
        currentUser: UserType,
        client: ApolloClient
    }
) {
    const docsBatch = docPossible && {
        status: 'success',
        source: docPossible.title,
        possibleConcepts: docPossible.possibleConcepts,
    };

    return <Loading what="possible concepts" error={error} loading={loading}>
        <LoadedDocsBatchDetails
            batch={docsBatch}
            submit={submit}
            client={client}
            currentUser={currentUser}
            showDfCollection={false}
            showTf={true}
            reportFalse={reportFalse}
        />
    </Loading>;
}


const mutationBanLiterals = gql`
mutation banPossibleLiterals($literals: [String]) {
    banPossibleLiterals(literals: $literals) {
        ok
    }
}
`;


export default function makeQDocPossibleTable(queryDocPossible: any) {
    return withCurrentUser(withApollo(compose(
        graphql(queryDocPossible, {options: ({ id }) => ({ variables: { id } }) }),
        graphql(mutationBanLiterals, {
            props: ({ mutate, ownProps }) => ({
                reportFalse: ({ literals }) =>
                    mutate({
                        variables: { literals },
                        refetchQueries: [{
                            query: queryDocPossible,
                            variables: { id: ownProps.id },
                        }]
                    })
            }),
        }),
        graphql(mutateConcept, {
            props: ({ mutate, ownProps }) => ({
                submit: ({id, name, literals, basic, deleted, ontologyCategories}) =>
                    mutate({
                        variables: {id, name, literals, basic, deleted, ontologyCategories},
                        refetchQueries: [{
                            query: queryDocPossible,
                            variables: { id: ownProps.id },
                        }]
                    }),
            }),
        })
    )(DocPossibleTable)));
}
