/*
datastore.js is used to fetch data from server and keep it on client side.
All data is stored in a plain object map using long-ish string keys,
like "article-542", "author-123", "author-123-articles-filter-concept12".

There are two React hooks - useTrack and useSubscription.

`useSubscription` is for cases when we need to get data from the store but we don't
need to fetch it from a server.

Example:
Each `Article` component in a list (search, monitoring, author) receives
only its own `articleId` and calls `useSubscription` to get all its data.
It will receive update whenever the article changes (e.g. article is bookmarked).

Accepts only one argument which is a string key (like "article-542").

`useTrack` is used to fetch data from server to a local store. It expects a dict
with these fields:

- id: string for server resource identification and request deduplication.
- get: if present, passed to useSubscription
- req:
- set: it's a function that receives global store and response from request
       and is tasked with properly laying out data in a store.
- moreIds: optional list of additional identifiers to stop them from duplicating
           requests to server.
           Example: full article receives a list of citations with default sorting.
           Underlying component can load more or change sorting (requests), but
           there is no need in requesting the first page with default sorting.
 */

import * as React from 'react';


function handleResponse(response: Response): Promise {
    const { status, statusText } = response;

    // status 204 by standard means success and no body, so response.json() fails
    if (status === 204) {
        return new Promise(resolve => resolve({
            ok: response.ok,
            value: null,
            status: response.status,
            statusText: response.statusText,
            loading: false
        }));
    }

    return response.json()
        .then(value => {
            const ok = status < 400;
            // TODO: rename value to body
            const rv = { ok, value, status, statusText, loading: false };
            if (!ok && value) {
                rv.error = value.error;
            }
            return rv;
        }).catch(error => {
            console.error(error);
            return {
                status,
                statusText,
                error,
                ok: false,
                loading: false,
            }
        });
}


function handleNoResponse(error) {
    console.error(error);
    return {
        ok: false,
        loading: false,
        error: error,
        statusText: '' + error,
    }
}


export function sendRequest(url, options): Promise<Response> {
    const { headers = {}, ...otherOptions } = options || {};
    const optionsToFetch = {
        credentials: 'same-origin',
        headers: {
            'Content-type': 'application/json',
            ...headers,
        },
        ...otherOptions,
    };

    return fetch(url, optionsToFetch)
        .then(handleResponse, handleNoResponse);
}


function start() {
    return {ok: null, loading: true};
}

function empty() {
    return {ok: null, loading: false};
}


const _datastore = {};
const _reqstate = {};
const _subscriptions = new Map();
const _sentRequests = new Set();
const _getToId = new Map();
let _lastSubscriptionId = 0;


export function updateGlobalData(setter, value) {
    setter(_datastore, value);
    _subscriptions.forEach(callback => callback());
}


function _nextSubscriptionId() {
    return ++_lastSubscriptionId;
}


function _useSendRequest(id, set, get, req, moreIds, skipReq) {
    React.useDebugValue(`${id} request`);
    const [reqstate, setReqstate] = React.useState(_reqstate[id]);
    /* eslint-disable react-hooks/exhaustive-deps */
    React.useEffect(() => {
        if (_sentRequests.has(id) || (skipReq && skipReq())) {
            return;
        }
        _sentRequests.add(id);
        _getToId[get] = [...(_getToId[get] || []), id];
        if (moreIds && moreIds.length > 0) {
            moreIds.forEach(additionalId => _sentRequests.add(additionalId));
        }

        if (!_reqstate[id]) {
            _reqstate[id] = start();
            setReqstate(_reqstate[id]);
        }

        req().then(value => {
            if (value.ok) {
                _reqstate[id] = { ok: value.ok, loading: value.loading };
                set(_datastore, value);
            } else {
                _reqstate[id] = value;
            }

            setReqstate(_reqstate[id]);

            // we postpone notifying subscribers to walk around the issue
            // when components-subscribers get unmounted before reqstate
            // is updated in state, hence provoking setting state in unmounted
            // components
            if (value.ok) {
                _subscriptions.forEach(callback => callback());
            }
        });
    }, [id]);
    /* eslint-enable react-hooks/exhaustive-deps */
    // when useTrack is called with changed `id` in the same component
    // (e.g. different sorting for citations) we want to actually get
    // empty(), not a reqstate from previous config
    return _reqstate[id] !== undefined ? reqstate : empty();
}


// handy function to send requests with getting reqstate without storing them
// in any global storage
export function useRequest(url, options) {
    const [response, setResponse] = React.useState();
    React.useEffect(() => {
        sendRequest(url, options)
            .then(resp => setResponse(resp));
    }, [url, options]);
    if (response) {
        const { value, ...reqstate } = response;
        return [value, reqstate]
    }
    return [null, start()];
}


export function useSubscription(get, getPrev) {
    const subscriptionId = _nextSubscriptionId();
    let currentValue = _datastore[get];

    if (!currentValue && getPrev) {
        currentValue = _datastore[getPrev];
    }

    let [value, setValue] = React.useState(currentValue);
    const setValueInState = v => {
        setValue(v);

        if (getPrev) {
            _datastore[getPrev] = v;
        }
    }

    function watch() {
        const newValue = _datastore[get];
        if (newValue !== value) {
            setValueInState(newValue);
        }
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    React.useEffect(() => {
        _subscriptions.set(subscriptionId, watch);
        return () => {
            _subscriptions.delete(subscriptionId);
        }
    }, [get]);
    /* eslint-enable react-hooks/exhaustive-deps */

    // if component was already mounted and data fetched, no request happens
    // and state cell is old, set value directly here.
    if (currentValue !== value) {
        setValueInState(currentValue);
        value = currentValue;
    }

    return value;
}


type QueryType = {
    id: string,
    get?: string,
    getPrev?: string,
    set: fn,
    req: fn,
    moreIds?: Array<string>
};

export function useTrack(
    { id, get, getPrev, set, req, moreIds, skipReq }
    : QueryType
) {
    let value = null;
    let requestState = null;

    // disable hooks because these parameters are static for given components
    /* eslint-disable react-hooks/rules-of-hooks */
    if (req !== undefined) {
        if (!id || !set || !req) {
            return new Error(
                'All three `id`, `set` and `req` should be specified if `req`' +
                ' is specified')
        }
        requestState = _useSendRequest(id, set, get, req, moreIds, skipReq);
    }

    if (get !== undefined) {
        value = useSubscription(get, getPrev);
    }
    /* eslint-enable react-hooks/rules-of-hooks */

    return [value, requestState];
}


export function query(
    { id, get, set, req, moreIds }: QueryType
) {
    function getFromStorage() {
        if (get !== undefined) {
            const value = _datastore[get];

            if (value) {
                return Promise.resolve(value);
            }
        }
    }

    return getFromStorage() || (function() {
        if (!_sentRequests.has(id)) {
            _sentRequests.add(id);

            if (moreIds && moreIds.length) {
                moreIds.forEach(additionalId => _sentRequests.add(additionalId));
            }

            return req().then(response => {
                if (response.ok) {
                    set(_datastore, response);
                    _subscriptions.forEach(callback => callback());
                }

                return getFromStorage();
            });
        } else {
            throw new Error(`Request for ${get} was triggered earlier but data was not found in store`);
        }
    })();
}


export function pullKey(key: string) {
    return _datastore[key];
}


export function clearCacheKey(get) {
    delete _datastore[get];

    if (_getToId[get]) {
        _getToId[get].forEach(id => {
            _sentRequests.delete(id);
            delete _reqstate[id];
        });
    }
}
