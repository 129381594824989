import React from 'react';

import slugify from '../common/helpers/slugify';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';


export function constructArticlePath(id: number, title: ?string) {
    let url = `/article/${id}`;
    if (title) {
        url = `${url}-${slugify(title)}/`;
    }
    return url;
}


export default function ArticleLink(
    { id, title, target = '', showTitle = false, showNotableBadge = false, notable, children }
    : { id: number, title: string, target?: string }
) {
    return <>
        {notable && showNotableBadge && <>
            <Glyphicon glyph="fire" className="notable-icon" title="Notable article" />
            &nbsp;
        </>}
        <a href={constructArticlePath(id, title)} { ...(target ? { target } : {})} {...(showTitle && { title })}
            className="article-link"
            title={title}>
        {children || title || '[undefined]'}
        </a>
    </>;
}
