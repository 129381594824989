import React from 'react';

import Button from 'react-bootstrap/lib/Button';

import LoadingREST from '../LoadingREST';
import TopConceptCooccurences from './TopConceptCooccurences';
import { ConceptLink, ConceptList } from '../articles/Concept';
import { RecommendedArticle } from '../search/RecommendedArticles';
import { makeMonitoringArticlesQuery } from './MonitoringData';
import { useTrack } from '../datastore';
import LoadingButton from '../widgets/LoadingButton';
import Popover from '../widgets/Popover';
import { singlePlural } from '../utils';


export function LoadMoreArticlesButton({ query, monitoringId, nextPage }) {
    const [queriedPage, setNextPage] = React.useState(1);
    useTrack(makeMonitoringArticlesQuery(query, monitoringId, queriedPage));
    if (queriedPage === nextPage) {
        return <LoadingButton workingMessage="Loading articles"
                              working disabled />;
    }
    return <Button onClick={() => setNextPage(nextPage)}>Load more&hellip;</Button>;
}


function LoadedMonitoringArticles({ query, monitoringResult, monitoringId, popover }) {
    const [showTopConcepts, setShowTopConcepts] = React.useState(false);
    const toggleTopConcepts = React.useCallback(() => setShowTopConcepts(show => !show), []);

    const renderPopover = (concept, onHide) => {
        return <Popover id="top-concept-popover"
                        title={<><span>{concept.name}</span><ConceptLink concept={concept}/></>}
                        className="concept-basic-popover">
            {concept.tf && <p>
                This concept occurs {concept.tf}{' '}
                {singlePlural('time', concept.tf)} in this article.
            </p>}
            <TopConceptCooccurences conceptId={concept.conceptId} query={query} />
        </Popover>
    };

    return <div className="recommended-articles">
        <h4>
            {monitoringResult.articles.length === monitoringResult.total ?
                `${monitoringResult.total} ${singlePlural('article', monitoringResult.total)}`
                : `${monitoringResult.articles.length} / ${monitoringResult.total} articles`
            }
            {!!monitoringResult.topConcepts?.length &&
                <button className="inline-link toggle-top-concepts" onClick={toggleTopConcepts}>
                    {showTopConcepts ? 'hide top found concepts' : 'show top found concepts'}
                </button>
            }
        </h4>
        {!!monitoringResult.topConcepts?.length && showTopConcepts &&
            <ConceptList
                showDf
                collapsible
                className="top-concepts"
                concepts={monitoringResult.topConcepts}
                popover={renderPopover}
            />
        }
        <div>
            {monitoringResult.articles.map((article, position) =>
                <RecommendedArticle
                    key={article.id}
                    article={article}
                    popover={popover}
                />
            )}
            {monitoringResult.nextPage && <LoadMoreArticlesButton
                query={query}
                nextPage={monitoringResult.nextPage}
                monitoringId={monitoringId} />
            }
        </div>
    </div>;
}

export default React.memo(function MonitoringArticles(props) {
    const { monitoringId, popover, ...query } = props;
    const [monitoringResult, reqstate] = useTrack(makeMonitoringArticlesQuery(
        query, monitoringId));

    return <LoadingREST reqstate={reqstate} doc={monitoringResult} what="articles">
        <LoadedMonitoringArticles
            query={query} monitoringResult={monitoringResult}
            monitoringId={monitoringId}
            popover={popover} />
    </LoadingREST>;
});
