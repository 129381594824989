import React from 'react';
import type { Node } from 'react';

import { useTrack } from '../datastore';

import { makeJobQuery, makeLastArticlesQuery } from './JobData';
import type { FoundArticleType } from './Types';
import LoadingREST from '../LoadingREST';


export function LoadingJob(
    { id, children }
    : { id: string, children: (job: any) => Node }
) {
    const [job, reqstate] = useTrack(makeJobQuery(id));

    return <LoadingREST what="vacancy" reqstate={reqstate} doc={job}>
        {!id ? children(null) : children(job)}
    </LoadingREST>;
}


export function LoadingNewJob(
    { children }
    : { children: (lastArticles: Array<FoundArticleType>) => Node }
) {
    const [lastArticles, reqstate] = useTrack(makeLastArticlesQuery());

    return <LoadingREST what="vacancy" reqstate={reqstate} doc={lastArticles}>
        { children(lastArticles) }
    </LoadingREST>;
}
