import React from 'react';

import LoadingREST from '../../LoadingREST';
import { makeSubpanelDocumentsQuery } from './PanelMembersData';
import { useTrack } from '../../datastore';

import './styles.css';


function LoadedSubpanelDocuments({ documents }) {
    return <div>
        <strong>Documents<sup>{documents.length}</sup></strong>
        <ul>
            {documents.map(({ docKind, docId, title }) =>
            <li key={docKind + docId}>
                <a href={`/${docKind}/${docId}`} target="_blank" rel="noopener noreferrer" title={title}> {title}</a>
            </li>)}
        </ul>
    </div>
}


export default function SubpanelDocuments({ match: { params: { id, subpanelCode }} }) {
    const [documents, documentsReqstate] = useTrack(makeSubpanelDocumentsQuery(id, subpanelCode));

    return <LoadingREST what="documents" reqstate={documentsReqstate} doc={documents}>
        <LoadedSubpanelDocuments documents={documents}/>
    </LoadingREST>
};
