import React from 'react';
import gql from 'graphql-tag';

import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';

import makeQDocPossibleTable from '../ontologies/possibleConcepts/DocPossibleTable';
import withAuthorization from '../common/withAuthorization';
import type { ReactRouterMatchType } from '../common/Types';
import { possibleConceptsGQL, SimilarConceptPropsFragment } from '../common/Fragments';


const queryProposalPossible = gql`
query docPossible($id: Int!) {
    docPossible: proposal(id: $id) {
        id
        title
        ${possibleConceptsGQL}
    }
}

${SimilarConceptPropsFragment}
`;


const QProposalPossibleTable = makeQDocPossibleTable(queryProposalPossible);


function ProposalPossibleConceptsPage(
    { match }
    : { match: ReactRouterMatchType }
) {
    const proposalId = Number(match.params.id);
    return <Row className="possible-concepts-list">
        <Col xs={12}>
            <a href={`/proposal/${proposalId}`}>&larr; back to the proposal</a>
            <QProposalPossibleTable id={proposalId} />
        </Col>
    </Row>;
}

export default withAuthorization(ProposalPossibleConceptsPage);
