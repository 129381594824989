import mixpanel from 'mixpanel-browser';

function initialize_mixpanel(mixpanelToken) {
    mixpanel.init(mixpanelToken, {secure_cookie: true});
}

function track_mixpanel(event_name, ...params) {
    if (typeof window !== 'undefined') {
         mixpanel.track(event_name, ...params);
     }
}

function reset_mixpanel() {
    if (typeof window !== 'undefined') {
        mixpanel.reset();
    }
}

function identify_mixpanel(user_id) {
    if (typeof window !== 'undefined') {
        mixpanel.identify(user_id);
    }
}

export default {
    initialize_mixpanel,
    track_mixpanel,
    reset_mixpanel,
    identify_mixpanel
}