import React from 'react';

import Button from 'react-bootstrap/lib/Button';
import Glyphicon from "react-bootstrap/lib/Glyphicon";

import { checkStatus } from '../../utils';


export const HideOrUnhideMode = {
    hide: 'hide',
    unhide: 'unhide',
};


type HideDocsBatchPossibleConceptPropsType = {
    mode: string,
    docsBatchId: number,
    names: Array<string>,
    onResult: (names: Array<string>) => any,
};


export class HideOrUnhideDocsBatchPossibleConcept extends React.Component<
    HideDocsBatchPossibleConceptPropsType,
> {
    onClick = () => {
        const docs_batch_id = this.props.docsBatchId;
        const names = this.props.names;

        let viewFunction = '';
        if (this.props.mode === HideOrUnhideMode.hide) {
            viewFunction = 'hide-batch-possible-concepts';
        } else if (this.props.mode === HideOrUnhideMode.unhide) {
            viewFunction = 'unhide-batch-possible-concepts';
        }

        return fetch(`/api/ontology/${viewFunction}`, {
            credentials: 'same-origin',
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ docs_batch_id, names }),
        })
        .then(checkStatus)
        .then(() => this.props.onResult(names))
    };

    render() {
        let child = null;
        if (this.props.mode === HideOrUnhideMode.hide) {
            child = <span className="icon">
                <Glyphicon glyph="eye-close"/> Hide selected
            </span>
        } else if (this.props.mode === HideOrUnhideMode.unhide) {
            child = <span className="icon">
                <Glyphicon glyph="eye-open"/> Unhide selected
            </span>
        }

        const disabled = this.props.names.length === 0;

        return <Button className="action-button"
                       onClick={this.onClick}
                       disabled={disabled}>
            {child}
        </Button>
    }
}
