import React from 'react';

import Alert from 'react-bootstrap/lib/Alert';


export const defaultSettings = {
    exclude_countries: [],
    include_countries: [],
    authors_group_effect: 'highlight',
    gender: '',
};


export const NonEditableWarning = ({ panelId, compositionId }) => {
    return <Alert bsStyle="warning" style={{ marginTop: '10px'}}>
        This is a base composition.{' '}
        <a href={`/panel-composer/${panelId}/compositions/?duplicate=${compositionId}`}
           className="btn btn-default" style={{margin: '-4px 0 0 5px'}}>
            Duplicate
        </a>{' '}it to add candidates to the panel.
    </Alert>
}
