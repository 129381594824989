import { sendRequest } from '../datastore';


export function makeFileExchangeKey() {
    return `file-exchange`;
}


export const makeFileExchangeListQuery = () => ({
    id: makeFileExchangeKey(),
    get: makeFileExchangeKey(),
    set: function (store, { value: { result } }) {
        store[makeFileExchangeKey()] = result;
    },
    req: () => sendRequest(`/api/file-exchange/list`),
});
