import React from 'react';

import type { ApplicationType, VoteLabelType } from './Types';

import { sendRequest, updateGlobalData } from '../datastore';
import { voteApplicant } from './JobData';
import helpers, { voteLabels } from './helpers';
import TextWithTooltip from '../widgets/TextWithTooltip';
import { cx } from '../utils';


type VoteButtonStateType = {
    showCommentModal: boolean,
    working: boolean,
    comment: string
};


export default class VoteButton extends React.Component<
    {
        applicant: ApplicationType,
    },
    VoteButtonStateType
> {
    vote(voteType: VoteLabelType) {
        const ownVote = this.ownVote();
        const revoke = ownVote && ownVote.voteType === voteType;

        return sendRequest('/api/jobs/vote/', {
            method: revoke ? 'DELETE' : 'POST',
            body: JSON.stringify({
                voteType,
                applicationId: this.props.applicant.applicationId,
            }),
        }).then(response => {
            const { ok } = response;

            if (ok) {
                updateGlobalData(
                    voteApplicant,
                    {
                        applicationId: this.props.applicant.applicationId,
                        vacancyId: this.props.applicant.vacancyId,
                        revoke,
                        voteType
                    }
                );
            }
        });
    }

    ownVote = () => this.props.applicant.votes.find(v => v.ownVote);

    render() {
        const ownVote = this.ownVote();
        const sum = helpers.summarizeVotes(this.props.applicant);
        const votesDetalization = sum || ownVote ?
            this.props.applicant.votes.map(v => {
                const copy = v.voteType === 'seen' ?
                    (v.ownVote ? 'Marked as seen (you)' : '')
                    : `${voteLabels[v.voteType].boost} ` +
                      (v.voteType === 'reject' ?
                          'for rejecting'
                          : `for adding to ${voteLabels[v.voteType].label.toLowerCase()}`
                      ) +
                      ` (${v.ownVote ? 'you' : v.name})`;

                return <div key={v.voteType + v.name}>
                    {v.ownVote ?
                        <strong>{copy}</strong>
                        : copy
                    }
                </div>
            })
            : <div>No votes yet.</div>;


        return <div className="vote-button">
            <TextWithTooltip id={`votes-detalization-${this.props.applicant.applicationId}`}
                             className="votes-detalization"
                             help={votesDetalization}>
                {sum}
                {ownVote && <strong> ({voteLabels[ownVote.voteType].boost})</strong>}
            </TextWithTooltip>
            &nbsp;
            <div className="vote-button-container">
                {Object.keys(voteLabels).map(currentVoteType => {
                    const vote = voteLabels[currentVoteType];
                    const cls = cx(`glyphicon glyphicon-${vote.glyph}`, {
                        voted: ownVote && ownVote.voteType === currentVoteType });
                    return <span
                        key={vote.label}
                        onClick={() => this.vote(currentVoteType)}
                        className={cls}
                        title={`${vote.label} (${vote.boost})`}
                    />;
                })}
            </div>
        </div>
    }
}
