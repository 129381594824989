import React from 'react';

import type { ApplicationType } from './Types';
import ApplicantTag from './tags/ApplicantTag';
import ApplicantTagModal from './tags/ApplicantTagModal';
import { useTrack } from '../datastore';
import { makeJobTagsQuery } from './JobData';
import helpers from './helpers';
import { cx } from '../utils';


export default function ApplicantTags(
    { applicant, onAdd, onRemove, inline = false }
    : { applicant: ApplicationType, onAdd: () => any, onRemove: () => any, inline: boolean }
) {
    const [showModal, setShowModal] = React.useState(false);
    const closeModal = React.useCallback(() => setShowModal(false), []);
    const openModal = React.useCallback(() => setShowModal(true), []);
    const jobId = applicant.vacancyId;
    const [tags] = useTrack(makeJobTagsQuery(jobId));

    const addTag = tag => {
        return helpers.addTag(tag, [applicant.applicationId], jobId)
            .then(() => {
                onAdd && onAdd(tag, applicant);
            });
    };

    if (!tags) {
      return null;
    }

    const applicantTags = tags.sort((t1, t2) => t1.name.localeCompare(t2.name))
                          .filter(t => applicant.tagsIds.indexOf(t.id) !== -1);

    return <>
        <div className={cx("applicant-tags", {'pull-right': !inline && applicantTags.length === 0})}>
          {applicantTags
              .map(tag => <ApplicantTag key={tag.id}
                                        applicationId={applicant.applicationId}
                                        jobId={jobId}
                                        onRemove={onRemove}
                                        tag={tag} />
              )
          }
          <span className="label label-applicant-tag label-applicant-tag-add"
                onClick={openModal}>
              <span>+ add tag</span>
          </span>
        </div>
        <ApplicantTagModal show={showModal}
                           jobId={applicant.vacancyId}
                           availableTags={tags}
                           onHide={closeModal}
                           onAssign={addTag}
                           applicant={applicant} />
      </>;
}
