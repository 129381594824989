import React from 'react';
import { scaleOrdinal, schemeCategory20 } from 'd3-scale';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

export function toFixed(float: number, decimals: number = 4) {
    return float.toFixed(decimals);
}


export function interpose(array: Array<any>, separator: string): Array<any> {
    if (array.length === 0) {
        return [];
    }

    let result = [array[0]];
    for (var i = 1; i < array.length; i++) {
        result.push(separator);
        result.push(array[i]);
    }
    return result;
}


export function partitionBy(array, index) {
    let currentPartition;
    const partitionedNames = [];
    for (let i = 0; i < array.length; i++) {
        if (i % index === 0) {
            currentPartition = [];
            partitionedNames.push(currentPartition);
        }
        currentPartition.push(array[i]);
    }
    return partitionedNames;
}


export function cx(...anything: Array<any>) {
    let classes = '';

    for (let i = 0; i < arguments.length; i++) {
        const arg = arguments[i];

        if (!arg) {
            continue;
        }

        if ('string' === typeof arg || 'number' === typeof arg) {
            classes += ' ' + arg;
        } else if (Array.isArray(arg)) {
            classes += ' ' + cx.apply(null, arg);
        } else if ('object' === typeof arg) {
            for (const key in arg) {
                if (!arg.hasOwnProperty(key) || !arg[key]) {
                    continue;
                }
                classes += ' ' + key;
            }
        }
    }
    return classes.substr(1);
}


export function singlePlural(str: string, count: number) {
    return str + (count > 1 || count === 0 ? 's' : '');
}


export function arrayComparator(a: Array<any>, b: Array<any>) {
    for (var i = 0; i < a.length; i++) {
        if (a[i] > b[i]) return 1;
        if (a[i] < b[i]) return -1;
    }
    return 0;
}


export const KEYS = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    ESCAPE: 27,
    SPACE: 32,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    DELETE: 46,
};


export function checkStatus(response: Response): Promise<Response> {
    let msg;
    const status = response.status;

    if (status === 500) {
        msg = 'Internal server error';
    } else if (status === 401) {
        msg = 'Please login';
    } else if (status === 403) {
        msg = 'Sorry, you are not permitted to perform the requested operation';
    } else if (status === 404 || status === 405) {
        msg = response.statusText;
    }

    if (msg) {
        alert(msg);
        return Promise.reject(new Error(response.statusText));
    }

    return Promise.resolve(response);
}

export const color20 = scaleOrdinal().range(schemeCategory20);

export function pick(props: Array<string>, src: {}): Object {
    const obj = {};

    props.forEach(p => { if (src.hasOwnProperty(p)) obj[p] = src[p]; });

    return obj;
}

export const hex2rgba = (hex: string, alpha: number = 1) => {
    let color = hex;

    if (color.length === 4) {
        color = color.replace(/(\w)/g, '$1$1');
    }

    const match = color.match(/\w\w/g);

    if (match) {
        const [r, g, b] = match.map(x => parseInt(x, 16));
        return `rgba(${r},${g},${b},${alpha})`;
    } else {
        throw new Error(`Expected HEX color string, received ${hex}`);
    }
};

export function enumerateFormatter(cell: any, row: any, enumObject: any, index: number) {
    return index + 1;
}

export function roundInteger(number: number) {
    if (number < 100) {
        return number.toString();
    }
    const roundingDivider = Math.pow(10, Math.floor(Math.log(number) / Math.LN10) - 1);
    return (number - (number % roundingDivider)) + '+';
}

export function snake2camel(src: string) {
    const camel = [];

    src.split('').forEach((char, i) => {
        if (src[i - 1] === '_') {
            camel.push(char.toUpperCase());
        } else if (src[i] !== '_') {
            camel.push(char);
        }
    });

    return camel.join('');
}

export function snake2camelObj(obj: {}) {
    return Object.keys(obj).reduce(
        (acc, key: string) => ({
            ...acc,
            [snake2camel(key)]: obj[key]
        }),
        {}
    );
}

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isArraysEqual(arr1: Array<*>, arr2: Array<*>) {
    return isEqual(sortBy(arr1), sortBy(arr2));
}

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function formatDate(dateToFormat) {
    const date = new Date(dateToFormat);
    const now = new Date();

    const [nowDay, day] = [now.getDate(), date.getDate()];
    const [nowMonth, month] = [now.getMonth(), date.getMonth()];
    const [nowYear, year] = [now.getYear(), date.getYear()];
    const [hours, minutes] = [date.getHours(), date.getMinutes()];
    const time = `${(hours < 10 ? '0' : '') + hours}:${(minutes < 10 ? '0' : '') + minutes}`;

    return nowDay === day && nowMonth === month && nowYear === year ?
        time
        : nowYear === year ?
            `${MONTHS[date.getMonth()]} ${date.getDate()} ${time}`
            : `${MONTHS[date.getMonth()]} ${date.getDate()} ${date.getFullYear()} ${time}`;
}

export function noop() {}

export function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
}

export function combineRefs(refs) {
    return {
        _current: null,
        get current() {
            return this._current;
        },
        set current(value) {
            this._current = value;
            refs.forEach(r => {
                if (!r) return;

                if (typeof r === 'function') {
                    r(value);
                } else {
                    r.current = value;
                }
            });
        },
    };
}


// Hook
export function useWhyDidYouUpdate(name, props) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = React.useRef();
  React.useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });
      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log("[why-did-you-update]", name, changesObj);
      }
    }
    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}
