import React from 'react';

import { graphql } from 'react-apollo';

import missingLiterals from './gql/missingLiteralsGQL';
import Loading from '../Loading';
import { pick } from '../utils';
import type { LiteralType } from './Types';


export function MissingLiterals(
    { data: { loading, error, missingLiterals }, children }:
    {
        data: {
            loading: boolean,
            error: any,
            missingLiterals: Array<LiteralType>
        },
        children: (missingLiterals: Array<LiteralType>) => Node
    }
) {
    return <Loading what="missing literals" loading={loading}>
        {missingLiterals && children(missingLiterals)}
    </Loading>;
}

export default graphql(missingLiterals, {
    options: ({ conceptId, literals }) => ({
        variables: {
            conceptId,
            literals: literals.map(pick.bind(null, ['name', 'composite', 'lang', 'isAmbiguous']))
        }
    }),
})(MissingLiterals);
