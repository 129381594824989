import React from 'react';

import { interpose } from '../utils';
import type { ArticleJournalType } from './Types';


export default function ArticleJournal(
    { journal }:
    { journal: ArticleJournalType }
) {
    const { shortTitle, volume, issue, pages, journalId } = journal;
    const parts = [];
    if (shortTitle) {
        parts.push(
            !journalId ? shortTitle :
            <a key="link" href={`/search/?q=j:${journalId}`}>
                {shortTitle}</a>);
    }
    if (volume) {
        parts.push(`v. ${volume}`);
    }
    if (issue) {
        parts.push(`i. ${issue}`);
    }
    if (pages) {
        parts.push(pages);
    }

    return <span>{interpose(parts, ', ')}</span>;
}
