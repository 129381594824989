import * as React from 'react';

import queryString from 'query-string';

import { Link } from 'react-router-dom';

import Col from 'react-bootstrap/lib/Col';

import { cx } from '../utils';

import './author.css';


export function AuthorArticlesHeader(
    { activeParams, displayedArticlesCount, totalArticlesCount, makeUrl, headerSmWidth }) {
    const { sort } = activeParams;
    const count = (
        displayedArticlesCount < totalArticlesCount
            ? `${displayedArticlesCount}/${totalArticlesCount}`
            : `${displayedArticlesCount}`);

    return <div className="row">
        <Col sm={headerSmWidth || 6}>
            <h4>Articles<sup>{count}</sup></h4>
        </Col>
        {totalArticlesCount > 1 &&
        <Col sm={6}>
            <div className="sort-by pull-right">
                <span>Sort by: </span>
                <div className="btn-group btn-group-sm" role="group" aria-label="Sort author's articles">
                    <Link to={makeUrl({ ...activeParams, sort: undefined })}
                          className={cx('btn btn-default', {active: !sort || sort === 'date-desc'})}>
                        Newest first</Link>
                    <Link to={makeUrl({ ...activeParams, sort: 'citations' })}
                          className={cx('btn btn-default', {active: sort === 'citations'})}>
                        Citations count</Link>
                </div>
            </div>
        </Col>}
    </div>;
}


export function parseAuthorParams(searchString) {
    const parsed = queryString.parse(searchString);
    let concepts = parsed.concepts || [];
    if (!Array.isArray(concepts)) {
        concepts = [parsed.concepts];
    }
    concepts = concepts.map(cid => parseInt(cid, 10));
    // one entry in parsed can be sort, which is not a filter
    const hasActiveFilters = (
        Object.keys(parsed).length > 1 ||
        (Object.keys(parsed).length === 1 && !parsed.sort))
    const activeParams = { ...parsed, concepts };
    return [activeParams, hasActiveFilters];
}