import React, { Fragment } from 'react';

import type { AffiliationItemType } from './Types';


export default function AffiliationItem({ item }: { item: AffiliationItemType }) {
    const title = [item.value];

    if (item.city && item.value.indexOf(item.city) === -1) {
        title.push(item.city);
    }

    if (item.country && item.country !== item.city) {
        title.push(item.country);
    }

    const years = [item.startYear];

    if (item.endYear !== item.startYear) {
        years.push(item.endYear);
    }

    return <Fragment>
        {title.join(', ')} ({years.join('-')})
    </Fragment>;
}
