import React from 'react';

import { singlePlural } from '../../utils';


const BarLabel = ({ code, name, docCount }) => <>
    <span>{code} - {name}</span>
    <br/>
    {!!docCount &&
        <span>{docCount} {singlePlural('document', docCount)}</span>}
</>;


export default BarLabel;
