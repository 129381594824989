import React, { Fragment } from 'react';
import memoize from 'memoize-one';

import Tooltip from 'react-bootstrap/lib/Tooltip';

import { scaleLinear, scaleLog } from 'd3-scale';
import type { RefereeDataType, RefereeMetaType } from './Types';

import { cx, singlePlural } from '../utils';


type TickType = {
    label: string,
    position: number
};


const X_AXIS_START = 13;
const X_AXIS_END = 100;
// xScale.ticks(2) produces strange results, hardcode good ticks
const X_TICKS = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000];

const Y_AXIS_START = 0;
const Y_AXIS_END = 90;
// define multiple domains for linearScale, so it's more spread out
// where more data is present (near 0.8..1)
const Y_DOMAIN = [0., 0.6, 0.8, 0.93, 1.0];
const Y_RANGE = [Y_AXIS_END, 70, 45, 20, Y_AXIS_START + 5];
const Y_TICKS = [0., 0.5, 0.75, 0.9, 1.];
const Y_DOMAIN_SHORT = [0.75, 0.85, 0.9, 0.95, 1.0];
const Y_TICKS_SHORT = [0.75, 0.85, 0.9, 0.95, 1.0];


export class AuthorCurve extends React.PureComponent<
    {
        author: RefereeDataType,
        color?: string,
        isCurrent?: boolean,
        onClick?: (author: RefereeDataType) => any,
        onMouseOver: (author: RefereeDataType, e: SyntheticMouseEvent<*>) => any,
        onMouseOut: (author: RefereeDataType, e: SyntheticMouseEvent<*>) => any,
        highlight?: boolean,
    }
> {
    handleClick = () => {
        this.props.onClick && this.props.onClick(this.props.author);
    }

    handleMouseOver = (e: SyntheticMouseEvent<*>) => {
        this.props.onMouseOver && this.props.onMouseOver(this.props.author, e);
    }

    handleMouseOut = (e: SyntheticMouseEvent<*>) => {
        this.props.onMouseOut && this.props.onMouseOut(this.props.author, e);
    }

    render() {
        const path = this.props.author.data
            .map((point, index) => `${index === 0 ? 'M' : 'L'}${point.x},${point.y}`)
            .join(' ');

        const className = cx(
            'author',
            'candidate',
            {
                'custom': this.props.author.meta.isCustom,
                'marked': this.props.author.meta.isMarked,
                'selected': this.props.author.meta.selected,
                'highlight': this.props.highlight,
            }
        );

        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <a xlinkHref={`#author-${this.props.author.meta.id}`}>
            <path d={path}
                  className={className}
                  stroke={this.props.color}
                  onMouseOver={this.handleMouseOver}
                  onMouseOut={this.handleMouseOut}
                  onClick={this.handleClick}
                  opacity={this.props.isCurrent === false ? 0.2 : 1}/>
        </a>;
    }
}

function Axis({ x, y, ticks}: { x?: boolean, y?: boolean, ticks: Array<TickType> }) {
    const axisClassMap = { x, y };

    return <Fragment>
        <path className={cx('axis', axisClassMap)}
              d={`M${X_AXIS_START},${x ? Y_AXIS_END : 0} L${x ? X_AXIS_END + 5 : X_AXIS_START},${Y_AXIS_END}`}/>
        <g className={cx('axis-ticks', axisClassMap)}>
            {ticks.map(t => <Fragment key={t.position}>
                <text x={x ? t.position : X_AXIS_START - 2} y={y ? t.position : Y_AXIS_END + 5}>
                    {t.label}
                </text>
                {x && <path d={`M${t.position},${Y_AXIS_END - 1} L${t.position},${Y_AXIS_END + 1}`}/>}
                {y && <path d={`M${X_AXIS_START - 1},${t.position} L${X_AXIS_START + 1},${t.position}`}/>}
            </Fragment>
            )}
        </g>
    </Fragment>
}


type RefereeChartPropsType = {
    series: Array<RefereeDataType>,
    highlightAuthorId: ?number,
    onCurveClick?: (author: RefereeDataType) => any,
    onMouseOver?: (authorId: number) => any,
    onMouseOut?: () => any,
    axis: boolean,
    grid: boolean,
    refereeType: string,
    markedType: string,
    showMarked: boolean,
};

type ChartDataType = {
    data: Array<RefereeMetaType>,
    xTicks: Array<TickType>,
    yTicks: Array<TickType>
};

export default class RefereeChart extends React.PureComponent<
    RefereeChartPropsType,
    {
        hideCandidates: boolean,
        hideCustom: boolean,
        hideSelected: boolean,
        hideMarked: boolean,
        x: number,
        y: number,
        title: string,
    }
> {
    // TODO: Flow doesn't have type definitions for SVG _yet_
    // @see https://github.com/facebook/flow/pull/4551
    container: any = null;

    state = {
        hideCandidates: false,
        hideCustom: false,
        hideSelected: false,
        hideMarked: false,
        x: 0,
        y: 0,
        title: '',
    }

    setContainer = (el: any) => {
        this.container = el;
    }

    handleMouseOver = (author: RefereeDataType, e: SyntheticMouseEvent<*>) => {
        const rect = this.container.getBoundingClientRect();

        this.setState({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
            title: `${author.meta.name} (${author.meta.role} ${author.meta.position})`,
        });

        if (this.props.onMouseOver) {
            this.props.onMouseOver(author.meta.id);
        }
    }

    handleMouseOut = () => {
        this.setState({ title: ''});
        if (this.props.onMouseOut) {
            this.props.onMouseOut();
        }
    }

    toggleCandidates = () => {
        this.setState({ hideCandidates: !this.state.hideCandidates });
    }

    toggleCustom = () => {
        this.setState({ hideCustom: !this.state.hideCustom });
    }

    toggleSelected = () => {
        this.setState({ hideSelected: !this.state.hideSelected });
    }

    toggleMarked = () => {
        this.setState({ hideMarked: !this.state.hideMarked });
    }

    filteredAuthors = (data: Array<RefereeDataType>, highlightAuthorId: ?number): Array<RefereeDataType> => {
        return data
            .filter(author => {
                if (this.state.hideSelected && author.meta.selected) {
                    return false;
                }
                if (this.state.hideCustom && author.meta.isCustom) {
                    return false;
                }
                if (this.state.hideMarked && author.meta.isMarked) {
                    return false;
                }
                if (this.state.hideCandidates &&
                        !author.meta.selected &&
                        !author.meta.isCustom &&
                        !author.meta.isMarked) {
                    return false;
                }
                return true;
            })
            .sort((ref1, ref2) => {
                const byHighlight = (
                    Number(ref1.meta.id === highlightAuthorId) -
                    Number(ref2.meta.id === highlightAuthorId));
                const bySelected = Number(ref1.meta.selected) - Number(ref2.meta.selected);
                const byMarked = Number(ref1.meta.isMarked) - Number(ref2.meta.isMarked);
                const byCustom = Number(ref1.meta.isCustom) - Number(ref2.meta.isCustom);
                return byHighlight || bySelected || byMarked || byCustom;
            });
    }

    getChartData = memoize((series: $PropertyType<RefereeChartPropsType, 'series'>): ChartDataType => {
        const allSimilarities = this.props.series.reduce((acc, s) => acc.concat(s.data), []);
        const xMin = 1; // log scale requires 1-based indexing
        const xMax = Math.max.apply(null, series.map(s => s.data.length));

        const shortYScale = Math.min.apply(null, allSimilarities) > Y_DOMAIN_SHORT[0];
        const yDomain = shortYScale ? Y_DOMAIN_SHORT : Y_DOMAIN;
        const yTicks = shortYScale ? Y_TICKS_SHORT : Y_TICKS;

        const xScale = scaleLog().domain([xMin, xMax]).range([X_AXIS_START, X_AXIS_END - 5]);
        const yScale = scaleLinear().domain(yDomain).range(Y_RANGE);

        const data = series
            .map(authorSimilarities => ({
                data: authorSimilarities.data.map((s, index) => ({
                    x: xScale(index + 1), // +1 for log scale and for humans
                    y: yScale(s)
                })),
                meta: authorSimilarities.meta
            }));

        return {
            data,
            xTicks: X_TICKS
                .filter(t => t < xMax)
                .map(t => ({
                    label: t.toString(),
                    position: xScale(t)
                })),
            yTicks: yTicks
                .map(t => ({
                    label: t.toFixed(2),
                    position: yScale(t)
                }))
        };
    });

    render() {
        const { data, xTicks, yTicks } = this.getChartData(this.props.series);
        const chartClassName = cx('referee-chart', {
            'hide-candidates': this.state.hideCandidates,
            'hide-custom': this.state.hideCustom,
            'hide-selected': this.state.hideSelected,
            'hide-marked': this.state.hideMarked,
        });
        const chartWithLegendClassName = cx('referee-chart-with-legend', {
            'hide-candidates': this.state.hideCandidates,
            'hide-custom': this.state.hideCustom,
            'hide-selected': this.state.hideSelected,
            'hide-marked': this.state.hideMarked,
        });

        const referees = singlePlural(this.props.refereeType, 2);

        return <div className={chartWithLegendClassName}>
            <div className={chartClassName}>
                <svg viewBox="0 0 100 100"
                     ref={this.setContainer}
                     preserveAspectRatio="xMidYMid meet"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink">
                    {this.props.grid && <g className="grid">
                        {xTicks.slice(1).map(
                            t => <path key={t.label} d={`M${t.position},${Y_AXIS_START} L${t.position},${Y_AXIS_END}`}/>
                        )}
                        {yTicks.map(
                            t => <path key={t.label} d={`M${X_AXIS_START},${t.position} L${X_AXIS_END},${t.position}`}/>
                        )}
                    </g>}
                    {this.filteredAuthors(data, this.props.highlightAuthorId).map(similarities =>
                        <AuthorCurve
                            key={similarities.meta.id}
                            author={similarities}
                            onClick={this.props.onCurveClick}
                            onMouseOut={this.handleMouseOut}
                            onMouseOver={this.handleMouseOver}
                            highlight={this.props.highlightAuthorId === similarities.meta.id}
                        />
                    )}
                    {this.props.axis && <g>
                        <Axis x ticks={xTicks} />
                        <Axis y ticks={yTicks} />
                        <text x="48" y="3" className="axis-desc y">Similarity</text>
                        <text x="40" y="100" className="axis-desc">Number of articles</text>
                    </g>}
                </svg>
                {this.state.title &&
                    <Tooltip placement="right"
                             className="in"
                             id="title"
                             positionTop={this.state.y}
                             positionLeft={this.state.x}>
                        {this.state.title}
                    </Tooltip>
                }
            </div>

            <dl className="legend">
                <dt className="legend-candidates"
                    title={`click to hide/show ${referees}`}
                    onClick={this.toggleCandidates}>
                    {this.props.refereeType}
                </dt>
                <dd className="legend-candidates" onClick={this.toggleCandidates}
                    title={`click to hide/show ${referees}`}>
                    {this.props.refereeType}
                </dd>
                <dt className="legend-custom"
                    title="click to hide/show custom"
                    onClick={this.toggleCustom}>
                    Custom
                </dt>
                <dd className="legend-custom" onClick={this.toggleCustom}
                    title="click to hide/show custom">
                    Custom
                </dd>
                <dt className="legend-selected"
                    title={`click to hide/show selected ${referees}`}
                    onClick={this.toggleSelected}>
                    Selected
                </dt>
                <dd className="legend-selected" onClick={this.toggleSelected}
                    title={`click to hide/show selected ${referees}`}>
                    Selected
                </dd>
                {this.props.showMarked && <>
                    <dt className="legend-marked"
                        title={`click to hide/show marked as ${this.props.markedType}`}
                        onClick={this.toggleMarked}>
                        {this.props.markedType}
                    </dt>
                    <dd className="legend-marked" onClick={this.toggleMarked}
                        title={`click to hide/show marked as ${this.props.markedType}`}>
                        {this.props.markedType}
                    </dd>
                </>}
            </dl>
        </div>;
    }
}
