import React from 'react';

import { Link } from 'react-router-dom';
import FileSaver from 'file-saver';

import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import FormControl from 'react-bootstrap/lib/FormControl';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Row from 'react-bootstrap/lib/Row';
import MaskedInput from 'react-text-mask';

import type { FindClaimArticleType } from '../articles/Types';

import withCurrentUser from '../common/withCurrentUser';
import { cx, EMAIL_REGEX, checkStatus } from '../utils';
import type { AdvisorType, AttachmentType, JobType, AttachmentFormatType } from './Types';
import { requirementsOptions } from './helpers';
import type { CurrentUserType } from '../accounts/Types';
import type { ReactRouterHistoryType } from '../common/Types';
import FileUpload from '../widgets/FileUpload';
import FormList from '../widgets/FormList';
import LoadingButton from '../widgets/LoadingButton';
import { updateGlobalData } from '../datastore';
import { makeJobKey, makeJobApplicationKey } from './JobData';


type JobMutationDataType = {
    id: string,
    vacancyId: string,
    phdAwardDate: string,
    advisors: Array<AdvisorType>,
    cv: any,
    researchStatement?: any,
    teachingStatement?: any,
    publicationList?: any,
    coverLetter?: any
};

type JobApplicationFormPropsType = {
    applicationId?: number,
    job: JobType<FindClaimArticleType>,
    phdAwardDate?: string,
    attachments?: Array<AttachmentType>,
    advisors?: Array<AdvisorType>,
    history: ReactRouterHistoryType,
    currentUser: CurrentUserType
};


export class JobApplicationForm extends React.Component<
    JobApplicationFormPropsType,
    {
        phdAwardDate: string,
        advisors: Array<AdvisorType>,
        attachments: Object,
        working: boolean,
        formErrors: { [key: string]: string },
        error: string,
        descriptionCollapsed: boolean,
        columnWidth: {
            name: number,
            email: number,
            delete: number,
            recommendation: ?number,
        },
        success: boolean,
        savedApplicationId: ?number,
    }
> {
    PHD_DATE_MASK = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-1]/, /[0-9]/];
    FILESIZE_LIMIT = 10 * Math.pow(1024, 2);

    requiredFields = ['phdAwardDate'];

    constructor(props: JobApplicationFormPropsType) {
        super(props);
        const isNewApplication = !props.applicationId;

        let attachments;
        ['cv', ...requirementsOptions.filter(r => r.fieldType === 'file' && props.job.requirements.indexOf(r.id) !== -1).map(ro => ro.id)]
            .forEach(formatType => {
                let value = null;

                if (this.props.attachments) {
                    const att = this.props.attachments.find(a => a.formatType === formatType);

                    if (att) {
                        value = att;
                        attachments = attachments || {};
                        attachments[formatType] = value;
                    }
                }
            });

        this.state = {
            advisors: props.advisors ?
                props.advisors.filter(a => !a.isPhdAdvisor)
                : props.job.isAdvisorsRequired ? [1, 2, 3].map(this.getDummyAdvisor) : [],
            attachments,
            working: false,
            formErrors: {},
            error: '',
            descriptionCollapsed: true,
            phdAwardDate: props.phdAwardDate ?
                props.phdAwardDate.split('-').slice(0, 2).join('-')
                : '',
            columnWidth: {
                name: isNewApplication ? 6 : 4,
                email: isNewApplication ? 5 : 4,
                delete: 1,
                recommendation: isNewApplication ? null : 3,  // no indicator for new application
            },
            success: false,
            savedApplicationId: null,
        };
    }

    handlers = {};
    validators = {};
    newAdvisorInput: ?HTMLInputElement;

    setNewAdvisorInput = (el: ?HTMLInputElement) => {
        this.newAdvisorInput = el;
    };

    getDummyAdvisor = () => ({
        name: '',
        email: ''
    });

    createHandleChange(field: string) {
        if (!this.handlers[field]) {
            this.handlers[field] = (e: SyntheticEvent<HTMLInputElement>) => {
                this.setState({
                    [field]: e.currentTarget.value
                });
            }
        }

        return this.handlers[field];
    }

    createHandleAdvisorChange = (i: number, field: 'name' | 'email') => {
        return (e: SyntheticEvent<HTMLInputElement>) => {
            const advisors = this.state.advisors.map((a, index) => (
                index !== i ?
                    a
                    : { ...a, [field]: e.currentTarget.value }
            ));

            this.setState({ advisors });
        }
    }

    handleAdvisorsChange = (advisors: Array<AdvisorType & { value: string }>) => {
        this.setState({
            advisors: advisors.map(a => ({
                ...a,
                name: a.value !== undefined ? a.value : a.name
            })),
        });
    }

    createDeleteAdvisor = (i: number) => {
        return () => {
            const advisors = this.state.advisors.slice(0, i)
                .concat(this.state.advisors.slice(i + 1));

            this.setState({ advisors });
        }
    }

    createValidateAdvisorField = (i: number, field: 'name' | 'email') => {
        return (e: SyntheticEvent<HTMLInputElement>) => {
            const errorMessage = this.validateAdvisorField(field, e.currentTarget.value);

            const advisors = this.state.advisors.map((a, index) => (
                index !== i ?
                    a
                    : { ...a, [`${field}Error`]: errorMessage }
            ));

            this.setState({ advisors });
        }
    }

    createHandleAttachmentSelect = (formatType: AttachmentFormatType) => {
        return (file: any) => {
            const formErrors = { ...this.state.formErrors };
            delete formErrors[formatType];

            const attachments = { ...this.state.attachments };
            attachments[formatType] = file;

            this.setState({
                attachments,
                formErrors
            });
        };
    }

    validateEmail(value: string) {
        return this.cleanEmail(value).match(EMAIL_REGEX) ?
            ''
            : `"${value}" doesn't look like a valid email address.`;
    }

    validateAdvisorField(field: 'name' | 'email', value: string) {
        return field === 'email' ?
            this.validateEmail(value)
            : value ?
                ''
                : 'This field is required.';
    }

    validateField(field: string, value: string) {
        const fieldIsFile = !!requirementsOptions.find(ro => ro.id === field && ro.fieldType === 'file');

        if (
            this.requiredFields.concat(this.props.job.requirements).indexOf(field) !== -1 && (
                (!fieldIsFile && !this.state[field]) ||
                (fieldIsFile && (!this.state.attachments || !this.state.attachments[field]))
            )
        ) {
            return 'This field is required.';
        }

        if (field === 'phdAwardDate') {
            const [, month] = this.state[field].split('-').map(num => parseInt(num, 10));

            if (isNaN(month) || month < 1 || month > 12) {
                return "This doesn't look like a valid date.";
            }
        }

        return '';
    }

    createValidate(field: string) {
        if (!this.validators[field]) {
            this.validators[field] = (shouldSetState: boolean = true) => {
                const formErrors = { ...this.state.formErrors };

                const error = this.validateField(field, this.state[field]);

                if (error) {
                    formErrors[field] = error;
                } else {
                    delete formErrors[field];
                }

                shouldSetState && this.setState({ formErrors });

                return formErrors;
            }
        }

        return this.validators[field];
    }

    validate(): {} {
        const formErrors = {};

        if (this.props.job.isAdvisorsRequired && this.state.advisors.length < 3) {
            formErrors.advisors = 'At least 3 references are required.';
        }

        return this.requiredFields.concat(this.props.job.requirements)
            .reduce((acc, field) => {
                const error = this.validateField(field, this.state[field]);

                return {
                    ...acc,
                    ...(error ? { [field]: error } : {})
                }
            }, formErrors);
    }

    collectData(): JobMutationDataType {
        const attachments = {};

        Object.keys(this.state.attachments).forEach(a => {
            attachments[a] = typeof this.state.attachments[a].id === 'string' ?
                this.state.attachments[a].id
                : this.state.attachments[a];
        });

        return {
            ...(this.props.applicationId ? { id: '' + this.props.applicationId } : {}),
            ...(this.props.job.id ? { vacancyId: '' + this.props.job.id } : {}),
            phdAwardDate: this.state.phdAwardDate,
            advisors: this.state.advisors.map(a => ({
                name: a.name,
                email: this.cleanEmail(a.email),
                isPhdAdvisor: false,
            })),
            cv: attachments.cv,
            ...attachments
        };
    }

    cleanEmail(email) {
        return email.trim();
    }

    submit = () => {
        const formErrors = this.validate();
        const advisors = this.state.advisors.map(a => ({
            ...a,
            email: this.cleanEmail(a.email),
            nameError: this.validateAdvisorField('name', a.name),
            emailError: this.validateAdvisorField('email', a.email),
        }));

        if (
            Object.keys(formErrors).length ||
            !this.props.job.id ||
            advisors.some(a => a.nameError || a.emailError)
        ) {
            this.setState({ advisors, formErrors });
            return;
        }

        const data = new FormData();
        const fields = this.collectData();

        fields.id && data.append('id', fields.id);
        data.append('vacancyId', fields.vacancyId);
        data.append('phdAwardDate', fields.phdAwardDate);
        data.append('advisors', JSON.stringify(fields.advisors));
        requirementsOptions.forEach(attachmentType => {
            if (fields[attachmentType.id] !== undefined) {
                data.append(attachmentType.id, fields[attachmentType.id]);
            }
        });

        this.setState({ working: true });

        return fetch(`/api/jobs/edit-application/`, {
            method: 'POST',
            body: data,
            credentials: 'same-origin'
        }).then(response => {
            this.setState({ working: false });

            return response;
        })
            .then(checkStatus)
            .then(response => response.json())
            .then(json => {
                if (json.ok) {
                    const savedApplicationId = json.application.applicationId;
                    const jobKey = makeJobKey(json.application.vacancyId);
                    // update vacancy application ID
                    updateGlobalData(
                        (store, updatedFields) => {
                            const appKey = makeJobApplicationKey(savedApplicationId);

                            store[jobKey] = {
                                ...store[jobKey],
                                applicationId: savedApplicationId,
                            };

                            store[appKey] = {
                                ...store[appKey],
                                ...json.application,
                            };
                        },
                        json
                    );

                    this.setState({ success: true, savedApplicationId: savedApplicationId });
                } else {
                    this.setState({ error: json.error });
                }

                return json;
            });
    }

    renderAdvisor = (advisor: AdvisorType, i: number, vars: { ref: Function }) => {
        const isNewApplication = !this.props.applicationId;
        const columnWidth = this.state.columnWidth;

        return <li key={i} className="list-group-item condensed">
            <Row className="advisor-row">
                <Col xs={columnWidth.name} className={cx({ 'has-error': advisor.nameError })}>
                    <FormControl
                        value={advisor.name}
                        placeholder="Name"
                        inputRef={vars.ref}
                        id={`name-${i}`}
                        onBlur={this.createValidateAdvisorField(i, 'name')}
                        onChange={this.createHandleAdvisorChange(i, 'name')}/>
                    {advisor.nameError && <p className="help-block">{advisor.nameError}</p>}
                </Col>
                <Col xs={columnWidth.email} className={cx({ 'has-error': advisor.emailError })}>
                    <FormControl
                        value={advisor.email}
                        placeholder="Email"
                        id={`email-${i}`}
                        onBlur={this.createValidateAdvisorField(i, 'email')}
                        onChange={this.createHandleAdvisorChange(i, 'email')}/>
                    {advisor.emailError && <p className="help-block">{advisor.emailError}</p>}
                </Col>
                <Col xs={columnWidth.delete} className="text-right">
                    <Button onClick={this.createDeleteAdvisor(i)}
                            title="Click here to remove literal">
                        <Glyphicon glyph="trash" />
                    </Button>
                </Col>
                {!isNewApplication &&
                 <Col xs={columnWidth.recommendation} className="text-left">
                     {advisor.hasPostedRecommendation ?
                         <div className="has-uploaded-recommendation">Recommendation received</div>
                         : <div className="waiting-for-recommendation">Waiting for recommendation</div>}
                 </Col>}
            </Row>
        </li>;
    }

    renderEmptyAdvisor = (vars: { onChange: Function }) => {
        return <li key="next"
                   className={cx('list-group-item condensed next', {
                       'has-error': !!this.state.formErrors.advisors
                   })}>
            <Row className="advisor-row">
                <Col xs={6}>
                    <FormControl
                        value=""
                        placeholder="Type here to add another advisor..."
                        inputRef={this.newAdvisorInput}
                        onChange={vars.onChange}/>
                </Col>
            </Row>
            {!!this.state.formErrors.advisors && <div className="help-block">
                {this.state.formErrors.advisors}
            </div>}
        </li>;
    }

    createDownloadFile = (formatType: 'cv' | 'researchStatement') => {
        return (e: SyntheticEvent<HTMLButtonElement>) => {
            e.preventDefault();

            const file = this.state.attachments[formatType];

            if (file) {
                return fetch(`/api/jobs/attachment/${file.id}`, {
                    credentials: 'same-origin',
                    headers: {'cache-control': 'no-cache'},
                }).then(checkStatus)
                    .then(result => result.blob())
                    .then(result => FileSaver.saveAs(result, file.filename));
            }
        };
    }

    toggleDescriptionCollapsed = () => {
        this.setState({ descriptionCollapsed: !this.state.descriptionCollapsed });
    }

    renderFieldError = (field: string) => (
        !!this.state.formErrors[field] && <p className="help-block">
            {this.state.formErrors[field]}
        </p>
    )

    renderImproveApplication() {
        if (!this.props.applicationId) {
            return null;
        }

        const items = [];
        const whom = []
        let advisorsAdded = false;
        let isManyLists = false;

        if (!this.props.currentUser.authorId) {
            items.push(<li key="claim-your-articles"><Link to="/find-authors">Claim your articles</Link></li>);
            whom.push('your');
        }

        this.state.advisors.forEach((advisor, i) => {
            if (!advisor.authorId) {
                items.push(<li key={`claim-articles-for-${i}`}><Link to={`/find-authors/${advisor.attorneyToken}`}>
                    {`Claim articles for ${advisor.name}`}</Link></li>);
                advisorsAdded = true;
                isManyLists = true;
            }
        })

        if (advisorsAdded) {
            whom.push("your recommenders'");
        }

        const listOrLists = isManyLists ? 'lists' : 'list';

        if (items.length === 0) {
            return null;
        }

        return <>
            <Row className="form-group">
                <label className="control-label col-sm-3">Improve your application:</label>
                <Col sm={8}>
                    <div className="help-block">
                        Complete {whom.join(' and ')} publication {listOrLists} to achieve best visibility for the hiring committee.
                    </div>
                    <ul className="list-unstyled">
                        {items}
                    </ul>
                </Col>
            </Row>
            <hr/>
        </>
    }

    renderSuccess() {
        const job = this.props.job;

        const blockStyle = {
            marginTop: '2em',
            marginBottom: '2em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }

        return <div style={blockStyle}>
            <img src="/static/images/success.png" alt="Success!" height="150px" width="150px"/>
            <ul style={{ marginTop: '1em' }}>
                <li>You've successfully applied for this position. We'll send you a confirmation email as well.</li>
                <li>You can <a href={`/jobs/${job.id}/applications/edit/${this.state.savedApplicationId}`}>edit your application</a> at any time before the deadline on {job.dateDeadline}.</li>
                {job.isAdvisorsRequired && <li>The message requesting a letter of recommendation will be sent in an hour.</li>}
            </ul>
            {!this.props.currentUser.authorId &&
                <>
                    <div style={{ marginTop: '1em' }}>
                        For your application to get the best possible chances
                    </div>
                    <Button style={{ marginTop: '0.35em' }} className="btn btn-primary btn-lg" href="/find-authors">Create electronic profile</Button>
                </>}
        </div>;
    }

    render() {
        if (this.state.success) {
            return this.renderSuccess();
        }

        const isNewApplication = !this.props.applicationId;
        const { job } = this.props;
        const hasErrors = !!Object.keys(this.state.formErrors).length || this.state.error;
        const columnWidth = this.state.columnWidth;

        return <div className="application-form">
            {job.id && <a href={`/jobs/${job.id}`}>&larr; back to vacancy</a>}
            <h2 className="hero-content">
                Apply: {job.title}&nbsp;
                <small>at {job.affiliation}</small>
            </h2>
            {!this.state.descriptionCollapsed &&
                <div className="description" dangerouslySetInnerHTML={{ __html: job.descriptionRendered}}/>
            }
            <button className="toggle-visibility toggle" onClick={this.toggleDescriptionCollapsed}>
                <span>
                    {this.state.descriptionCollapsed ?
                        '\u2193 Show description \u2193'
                        : '\u2191 Hide description \u2191'
                    }
                </span>
            </button>

            <br/><br/>

            {this.state.error && <Alert bsStyle="danger" className="alert-fixed-bottom-right">
                <p>{this.state.error}</p>
            </Alert>}

            <form className='form-horizontal'>
                {this.renderImproveApplication()}
                <Row className={cx('form-group required', { 'has-error': !!this.state.formErrors.phdAwardDate })}>
                    <label htmlFor="apply-job-phd-date"
                           className="control-label col-sm-3">
                        PhD awarded, date:
                    </label>
                    <Col sm={4}>
                        <MaskedInput
                            id="apply-job-phd-date"
                            className="form-control"
                            onChange={this.createHandleChange('phdAwardDate')}
                            onBlur={this.createValidate('phdAwardDate')}
                            type="text"
                            placeholder="YYYY-MM"
                            value={this.state.phdAwardDate}
                            mask={this.PHD_DATE_MASK}/>
                        {this.renderFieldError('phdAwardDate')}
                    </Col>
                    <Col sm={4} className="help-block">
                        (may be in the future)
                    </Col>
                </Row>
                {job.isAdvisorsRequired && <>
                    <hr/>
                    <Row className='form-group'>
                        <label className="col-sm-3 control-label">Recommenders contact information:</label>
                        <Col sm={8}>
                            <div className="help-block">
                                We will send them an email asking for recommendation letter on your behalf.
                                You will be able to reuse received recommendations when applying to other
                                positions listed at Prophy Semantic Jobs. Also, your recommenders can send
                                the recommendations directly to jobs@prophy.ai.
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-group'>
                        <Col sm={3}/>
                        <Col sm={9}>
                            <ul className="list-group form-advisors-list">
                                <li className="list-group-item condensed" key="header">
                                    <Row className="row-header">
                                        <Col xs={columnWidth.name} className="label-required">Name</Col>
                                        <Col xs={columnWidth.email} className="label-required">Email</Col>
                                        <Col xs={columnWidth.delete}/>
                                        {!isNewApplication && <Col xs={columnWidth.recommendation}/>}
                                    </Row>
                                </li>
                                <FormList
                                    items={this.state.advisors}
                                    renderItem={this.renderAdvisor}
                                    getEmptyItem={this.getDummyAdvisor}
                                    renderEmptyItem={this.renderEmptyAdvisor}
                                    onChange={this.handleAdvisorsChange} />
                            </ul>
                        </Col>
                    </Row>
                </>}
                <hr/>
                {requirementsOptions
                    .filter(r => this.props.job.requirements.indexOf(r.id) !== -1)
                    .map(r => <Row className={cx('form-group required', { 'has-error': !!this.state.formErrors[r.id] })}
                                   key={r.id}>
                        <label htmlFor={`apply-job-${r.id}`} className='control-label col-sm-3'>
                            {r.fieldType === 'file' ? `Please attach your ${r.label}` : r.label}:
                        </label>
                        {r.fieldType === 'file' && <Col sm={9} className="upload-parent-container">
                            {this.state.attachments && this.state.attachments[r.id] && typeof this.state.attachments[r.id].id !== 'undefined' && <p>
                                <a href={`/api/jobs/attachment/${this.state.attachments[r.id].id}/${this.state.attachments[r.id].filename}`}
                                    target="_blank"
                                    rel="noopener noreferrer">{this.state.attachments[r.id].filename}</a>
                                {' or '}
                            </p>
                            }
                            <FileUpload onSelect={this.createHandleAttachmentSelect(r.id)}
                                        accept=".pdf,.doc,.docx,.rtf"
                                        sizeLimit={this.FILESIZE_LIMIT}/>
                            <p className="help-block">
                                (10Mb max, only *.pdf, *.doc, *.docx or *.rtf are allowed)
                            </p>
                        </Col>}
                        {this.renderFieldError(r.id)}
                    </Row>)
                }
                <br/><br/><br/>
                <Row className={cx('form-group', {'btn-with-error-row': hasErrors})}>
                    <Col sm={3} />
                    <Col sm={9}>
                        <LoadingButton bsStyle="primary"
                                       working={this.state.working}
                                       onClick={this.submit}>
                            {this.props.applicationId ?
                                'Save changes'
                                : 'Apply for position'
                            }
                        </LoadingButton>
                        {hasErrors &&
                            <div className="has-error">
                                <div className="help-block">
                                    * Some field values are incorrect.&nbsp;Scroll up for details.
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
            </form>
        </div>;
    }
}

export default withCurrentUser(JobApplicationForm);
