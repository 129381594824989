import React from 'react';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import type { AdvisorWithScoresType, ApplicationType } from './Types';


export default function SocialLabels(
    { person }
    : { person: AdvisorWithScoresType | ApplicationType }
) {
    const { citedByHiringCouncil, referHiringCouncil, isEmailed } = person;
    if (!citedByHiringCouncil || !referHiringCouncil) {
        return null;
    }

    const citedByCouncil = Object.keys(citedByHiringCouncil)
        .reduce((count, memberId) => count + citedByHiringCouncil[parseInt(memberId, 10)], 0);
    const refersCouncil = Object.keys(referHiringCouncil)
        .reduce((count, memberId) => count + referHiringCouncil[parseInt(memberId, 10)], 0);

    const socialTitle = [];
    if (refersCouncil) {
        socialTitle.push(`${refersCouncil} articles refer to members of hiring committee`);
    }
    if (citedByCouncil) {
        socialTitle.push(`${citedByCouncil} applicant articles are cited by members of hiring committee`);
    }

    return <div className="social-labels">
        {!!(citedByCouncil || refersCouncil) &&
            <Glyphicon glyph="link"
                       className="social-label text-success"
                       title={socialTitle.join("\n")} />}
        {isEmailed && <Glyphicon glyph="envelope" className="social-label" title="emailed" />}
    </div>;
}
