import React from 'react';
import type { Node } from 'react';


export function constructAuthorPath(id: number, name: ?string, attorneyToken?: string) {
    let url = `/author/${id}/`;

    if (name) {
        name = name.replace(/\W+/g, '-');
        url = `${url}${name}/`;
    }
    // TODO: change attorneyToken to attorney_token after moving to author serverside page
    if (attorneyToken) {
        url = `${url}?attorney_token=${attorneyToken}`;
    }

    return url;
}


export default function AuthorLink(
    { id, name, attorneyToken, target, children }
    : { id: ?number, name?: string, target?: string, attorneyToken?: string, children?: Node }) {
    if (id) {
        return <a href={constructAuthorPath(id, name, attorneyToken)}
                     { ...(target ? { target } : {})}>
            {children || name}
        </a>;
    }
    return <span>{name}</span>;
}
