import React from 'react';

import Col from 'react-bootstrap/lib/Col';

import helpers from '../common/helpers/loading';
import LoadingButton from '../widgets/LoadingButton';
import { checkStatus } from '../utils';
import type { LiteralType, SimpleConceptType } from './Types';


type UseAsParentCompositePropsType = {
    conceptId?: ?number,
    parentConcept: SimpleConceptType,
    literalsForUpdate: Array<LiteralType>,
    onResult: (result: any) => any,
};


type UseAsParentCompositeStateType = {
    working: boolean,
    done: boolean,
};


export default class UseAsParentComposite extends React.Component<
    UseAsParentCompositePropsType,
    UseAsParentCompositeStateType,
> {
    static defaultProps = {
        conceptId: null,
    };

    state = {
        working: false,
        done: false,
    };

    submitFn = (submitProps: { concept_id: ?number, parent_concept_id: number, literals: Array<LiteralType> }) => {
        const concept_id = submitProps.concept_id;
        const parent_concept_id = submitProps.parent_concept_id;
        const literals = submitProps.literals;

        return fetch(`/api/ontology/use-as-parent-in-composite`, {
            credentials: 'same-origin',
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ concept_id, parent_concept_id, literals }),
        })
        .then(checkStatus)
        .then(response => response.json())
        .then(json => json.result)
        .then(result => this.props.onResult(result))
    };

    onClick = () => {
        const literals = this.props.literalsForUpdate.map(literal => ({
            name: literal.name,
            composite: literal.composite,
            is_ambiguous: literal.isAmbiguous,
            lang: literal.lang,
        }));

        const submitProps = {
            concept_id: this.props.conceptId,
            parent_concept_id: this.props.parentConcept.id,
            literals,
        };

        helpers.loadingAwareSubmit.call(this, submitProps, { submitFn: this.submitFn })
    };

    render() {
        if (this.props.literalsForUpdate === 0) {
            return null;
        }

        return <div className="row">
            <Col xs={9}>
                Costruct composite literal using &quot;{this.props.parentConcept.name}&quot;
            </Col>
            <Col xs={3}>
                <LoadingButton bsStyle="primary"
                               className="action-button"
                               onClick={this.onClick}
                               disabled={this.state.working}
                               working={this.state.working}
                               done={this.state.done}
                               workingMessage="Loading">
                    Construct
                </LoadingButton>
            </Col>
        </div>
    }
}
