import React from 'react';

import AdvisorApplicant from './AdvisorApplicant';
import ApplicantAdvisor from './ApplicantAdvisor';

import { roundInteger, toFixed } from '../utils';
import SocialLabels from './SocialLabels';
import Rank from './Rank';
import PreliminaryBadge from './PreliminaryBadge';


export default React.memo(function JobAdvisorRow(
    { advisor, jobId, index }
) {
    const isPreliminary = !advisor.email;

    return <tr key={advisor.email}>
        <td className="id">{index + 1}</td>
        <td className="name">
            {/* eslint-disable-next-line */}
            <a className="row-anchor" id={`advisor-${advisor.groupId}`}></a>
            <ApplicantAdvisor advisor={advisor} jobId={jobId} short />
            {isPreliminary && <>
                {' '}
                <PreliminaryBadge/>
            </>}
            &nbsp;<SocialLabels person={advisor} />
        </td>
        <td>
            <ul className="applicants-list">
                {advisor.applicantsIds.map(applicantId => <li key={applicantId}>
                    <AdvisorApplicant id={applicantId} hashLinkToRecommender />
                </li>)}
            </ul>
        </td>
        <td className="text-right">
            {!!advisor.rank && <Rank person={advisor} />}
        </td>
        <td className="text-right">
            {advisor.similarityCombined && toFixed(advisor.similarityCombined, 2)}
        </td>
        <td className="text-right">{advisor.similarityMax && toFixed(advisor.similarityMax, 2)}</td>
        <td className="text-right">{advisor.articlesCount}</td>
        <td className="text-right notable-articles-count">{advisor.notableArticlesCount}</td>
        <td className="text-right">{advisor.hIndex}</td>
        <td className="text-right">
            {advisor.citationsCount && roundInteger(advisor.citationsCount)}
        </td>
    </tr>;
});
