import React from 'react';

import { Link } from 'react-router-dom';

import { cx, color20, hex2rgba } from '../utils';

import './css/top-concepts-chart.css';


export default function TopConceptsChart(
    { concepts, activeParams, makeUrl, limit, short, enabled, disabledMessage }) {
    const [curLimit, setLimit] = React.useState(limit);
    function showMore() {
        setLimit(curLimit + limit);
    }

    if (!concepts || !concepts.length) {
        return null;
    }
    const maxtfidf = Math.max.apply(null, concepts.map(c => c.sumtfidf));
    const selected = activeParams ? activeParams.concepts || [] : [];

    return <div className="top-concepts-chart">
        {!short && <h4>Top author concepts</h4>}
        {makeUrl && selected.length > 0 && <div className="text-muted">
            <Link to={makeUrl({ ...activeParams, concepts: []})}>
                remove all filters by concepts
            </Link>
        </div>}

        <table className={cx({ 'has-selected': !!selected.length })}>
            <tbody>
                {concepts.slice(0, curLimit).map((c, i) => {
                    const isSelected = selected.indexOf(c.conceptId) !== -1;
                    const barStyle = {
                        width: `${(c.sumtfidf / maxtfidf) * 100}%`,
                        borderColor: color20(i % 20),
                        backgroundColor: hex2rgba(color20(i % 20), 0.8),
                    };
                    const bar = <div className="bar" style={barStyle} />;

                    if (!enabled) {
                        return <tr key={c.conceptId}>
                            <td title={disabledMessage}>{c.name}</td>
                            <td title={disabledMessage}>{bar}</td>
                        </tr>;
                    }

                    const newSelected = isSelected ?
                        selected.filter(id => id !== c.conceptId) :
                        selected.concat(c.conceptId);
                    const href = makeUrl({ ...activeParams, concepts: newSelected });

                    return <tr key={c.conceptId} className={cx({selected: isSelected})}>
                        <td><Link to={href}>{c.name}</Link></td>
                        <td><Link to={href}>{bar}</Link></td>
                    </tr>
                })}
                {curLimit <= concepts.length && <tr>
                    <td />
                    <td>
                        <button className="expand" onClick={showMore}>
                            Show more&hellip;</button>
                    </td>
                </tr>}
            </tbody>
        </table>

    </div>;
}
