import React from 'react';
import type { Node } from 'react';
import Reflux from 'reflux';

import Button from 'react-bootstrap/lib/Button';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import DragDropContext from 'react-beautiful-dnd/lib/view/drag-drop-context';

import {
    ChosenRecommendationTopicsActions, ChosenRecommendationTopicsStore
} from './ChosenRecommendationTopicsStore';
import { RecommendationTopic } from './RecommendationTopic';
import MonitoringTopics from './MonitoringTopics';
import type {
    RecommendationTopicType, RecommendationTopicStateType
} from './RecommendationTopic';
import { getEntityId } from '../common/helpers/dnd';
import Popover from '../widgets/Popover';


export default class BasicRecommendation extends Reflux.Component<
    {
        topicActions: (topic: RecommendationTopicType, state: RecommendationTopicStateType) => Node,
        canBeEmpty: boolean
    },
    {
        topics: Array<RecommendationTopicType>,
        droppableDisabled: Array<boolean>,
    }
> {
    static defaultProps = {
        canBeEmpty: false,
        allowImport: true
    };

    state = {
        topics: [],
        droppableDisabled: [],
    };

    importPopover = React.createRef();

    constructor() {
        super();

        this.store = ChosenRecommendationTopicsStore;
        this.storeKeys = ['topics'];
    }

    onDragStart = initial => {
        const droppableDisabled = {};
        const conceptId = getEntityId(initial.draggableId);
        const srcDroppableId = getEntityId(initial.source.droppableId);

        this.state.topics.forEach((t, i) => {
            // disable dropping at topics only if concept is chosen and either
            // is moved to home topic OR already exists in target topic
            droppableDisabled[i] = (
                srcDroppableId === parseInt(i, 10) ||
                t.concepts.map(c => parseInt(c.conceptId, 10)).indexOf(conceptId) !== -1
            );
        });

        this.setState({ droppableDisabled });
    }

    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const draggableId = getEntityId(result.draggableId);
        const sourceTopicId = getEntityId(result.source.droppableId);

        ChosenRecommendationTopicsActions.moveConcept(
            draggableId,
            sourceTopicId,
            getEntityId(result.destination.droppableId),
        );
    }

    handleAddTopic() {
        ChosenRecommendationTopicsActions.addTopic();
    }

    handleSelect = topic => {
        ChosenRecommendationTopicsActions.addTopic(topic);
        this.importPopover.current && this.importPopover.current.hide();
    }

    render() {
        const importPopover = <Popover id="import-popover"
                                       className="concept-basic-popover">
            <MonitoringTopics onSelect={this.handleSelect} />
        </Popover>;

        return <div className="topics">
            <DragDropContext onDragStart={this.onDragStart}
                             onDragEnd={this.onDragEnd}>
                {this.state.topics.map((topic, i) =>
                    <RecommendationTopic key={i}
                        topicIndex={i}
                        topicActions={this.props.topicActions}
                        canDelete={this.props.canBeEmpty || this.state.topics.length > 1}
                        isDropDisabled={this.state.droppableDisabled[i]} />
                )}
            </DragDropContext>
            <ButtonToolbar>
                <Button onClick={this.handleAddTopic}>Add topic</Button>
                {this.props.allowImport &&
                    <OverlayTrigger overlay={importPopover}
                                    trigger="click"
                                    placement="bottom"
                                    ref={this.importPopover}
                                    rootClose>
                        <Button onClick={this.openImportTopicModal}>Import topic&hellip;</Button>
                    </OverlayTrigger>}
            </ButtonToolbar>
        </div>;
    }
}
