import React, { Fragment } from 'react';
import Droppable from 'react-beautiful-dnd/lib/view/droppable';


export type DroppableOverlayPropsType = {
    children?: (provided, snapshot) => any,
    droppableId: string,
    type: string,
    isDropDisabled?: boolean,
    direction?: string,
};

export default function DroppableOverlay(props: DroppableOverlayPropsType) {
    const { droppableId, direction = 'horizontal', isDropDisabled, type } = props;

    return (
        <Fragment>
            <Droppable droppableId={droppableId}
                       type={type}
                       direction={direction}
                       isDropDisabled={!!isDropDisabled}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}
                         className={'droppable topic-droppable' +
                            (snapshot.isDraggingOver ? ' is-dragged-over' : '')
                         }>
                        <div className="hint">
                            <span>Drop here</span>
                        </div>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <Droppable droppableId={`readonly-${droppableId}`}
                       type={type}
                       direction={direction}
                       isDropDisabled={true}>
                {props.children}
            </Droppable>
        </Fragment>
    );
}
