import React, { Fragment } from 'react';
import type { Node } from 'react';

import type { ApolloClient } from 'apollo-client';
import { Link } from 'react-router-dom';

import uniqBy from 'lodash/uniqBy';

import Button from 'react-bootstrap/lib/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Modal from 'react-bootstrap/lib/Modal';

import { EditConceptForm } from '../EditConceptForm';
import Literal from '../Literal';
import ConceptBriefPopover from '../ConceptBriefPopover';

import helpers from '../helpers';
import { cx, singlePlural, interpose, toFixed } from '../../utils';

import type { ConceptMutationResponseType, OntologyConceptType } from '../Types';

import './possible-concept-modal.css';


type ConceptRowPropsType = {
    concept: any,
    isCurrent: bool,
    formRef?: (id: number, form: ?EditConceptForm) => any,
    onToggleRow: (index: number, flag: bool) => any,
    showDfCollection: bool,
    showTf: bool,
    hideBasicNeighbors: bool,
    submit: Function,
    client: ApolloClient,
    onShowForm: (id: ?number) => any,
    renderFormAdditionalButtons?: (form: EditConceptForm, concept: any) => Node,
    onFormEdit: Function,
    onFormError: Function,
    onFormSubmit: Function,
    onRenderActions: Function,
    onSelectConcept: (concept: any, flag: bool) => any
};


export function PossibleConceptSearchLink(
    {onClick, concept} : {onClick?: (e: SyntheticEvent<*>) => any, concept: any}
) {
    const searchQuery = helpers.getSearchQuery(concept);
    const title = `Show search results for "${concept.name}"`;

    // eslint-disable-next-line react/jsx-no-target-blank
    return <a href={`/search/?q=${searchQuery}`} target="_blank" {...{ onClick, title }}>
        {concept.potentialTotalDf != null ? concept.potentialTotalDf : concept.df}
    </a>;
}


export function WikipediaLiteral(
    { concept, addLiteral }
    : {concept: SimpleConceptType, actions?: (concept: OntologyConceptType) => Node}
) {
    return <li>
        <a href={concept.link}
           target="_blank"
           rel="noopener noreferrer"
           style={{float: "none", marginLeft: 0, textTransform: 'none'}}
           onClick={(e) => e.stopPropagation()}>
            {concept.name}
        </a>{!!concept.lang && <sup>{concept.lang}</sup>}&nbsp;
        <button className="btn btn-xs btn-default" onClick={addLiteral}>
            <Glyphicon glyph="plus"/>
        </button>
    </li>;
}


export default class ConceptRow extends React.PureComponent<ConceptRowPropsType> {
    handleCollapse = () => {
        this.props.onToggleRow(this.props.concept._id, false);
    };

    handleFormError = (...args: Array<string>) => {
        this.props.onFormError(this.props.concept._id, ...args);
    };

    handleFormSubmit = (response: ConceptMutationResponseType) => {
        this.props.onFormSubmit(this.props.concept._id, response);
    };

    stopPropagation(e: SyntheticEvent<*>) {
        e.stopPropagation();
    }

    handleSelectConcept = (e: SyntheticEvent<HTMLInputElement>) => {
        this.props.onSelectConcept(this.props.concept, e.currentTarget.checked);
    };

    setForm = (form: ?EditConceptForm) => {
        this.props.formRef && this.props.formRef(this.props.concept._id, form);
    };

    renderSimilarConceptCell(
        concept: any,
        similarConcepts: Array<OntologyConceptType>,
        hideBasic: boolean = false
    ) {
        let uniqueConcepts = uniqBy(similarConcepts, 'name');
        if (hideBasic) {
            uniqueConcepts = uniqueConcepts.filter(c => !c.basic);
        }

        return <td className="conflicts">
            <div className="cell-wrapper">
                <ul>
                    {uniqueConcepts.map(similarConcept =>
                        <ConceptBriefPopover
                            key={similarConcept.id}
                            concept={similarConcept}
                            actions={popoverConcept =>
                                this.props.onRenderActions(concept, popoverConcept, similarConcept.canBeParent)}/>
                    )}
                </ul>
            </div>
        </td>;
    }

    renderWikipediaCell(
        concept: any,
        similarLiterals: Array<OntologyConceptType>
    ) {
        let conceptLiterals = concept.literals.map(l => l.name);
        let uniqueLiterals = uniqBy(similarLiterals, 'name').filter(l => conceptLiterals.indexOf(l.name) === -1);
        return <td className="conflicts">
            <div className="cell-wrapper">
                <ul>
                    {uniqueLiterals.map((similarLiteral, i) =>
                        <WikipediaLiteral
                            key={i}
                            concept={similarLiteral}
                            renderActions={popoverConcept =>
                                this.props.onRenderActions(concept, popoverConcept)}
                            addLiteral={(e) => {
                                this.props.onFormEdit({
                                    literals: [
                                        ...concept.literals,
                                        {
                                            name: similarLiteral.name,
                                            similarLiteralsConcepts: [],
                                            lexicalNeighborsConcepts: [],
                                            lang: similarLiteral.lang,
                                            wikiLink: similarLiteral.link,
                                            wikiAmbiguous: similarLiteral.isAmbiguous,
                                            wikiVariants: null,
                                        }
                                    ]
                                }, concept._id);
                                e.stopPropagation();
                            }}/>
                    )}
                </ul>
            </div>
        </td>;
    }

    renderAdditionalButtons = (form: EditConceptForm) => (
        <Fragment>
            <Button onClick={this.handleCollapse}>Close and continue</Button>
            {this.props.renderFormAdditionalButtons &&
                this.props.renderFormAdditionalButtons(form, this.props.concept)
            }
        </Fragment>
    )

    render() {
        const { concept, isCurrent, showDfCollection, showTf } = this.props;
        const literals = interpose(concept.literals.map((l, i) => <span key={i}>
            <Literal key={i}
                     className={l.isAmbiguous ? 'text-muted' : null}
                     title={l.isAmbiguous ? 'ambiguous literal' : null}
                     literal={l} />
            {l.wikiLink && <sup>
                <a href={l.wikiLink}
                   target="_blank"
                   rel="noopener noreferrer"
                   onClick={(e) => e.stopPropagation()}>
                    wiki{l.wikiAmbiguous && '?'}
                </a>
            </sup>}
        </span>), ', ');

        const notHandled = !concept.created && !concept.merged;
        const canOpen = !isCurrent && notHandled;

        const trClassName = cx({
            'current': isCurrent,
            'expandable': canOpen,
            'warning': concept.literals
                .some(l => (
                    !l.isAmbiguous && (
                        (l.warnings && l.warnings.length) ||
                        (l.similarLiteralsConcepts && l.similarLiteralsConcepts.length)
                    )
                )),
            'danger': (concept.errors && concept.errors.length) ||
               concept.literals.some(l => l.errors && l.errors.length),
        });

        const expandableProps = canOpen ? {
            onClick: this.props.onShowForm(concept._id),
            title: 'Click to edit'
        } : {};

        return <tr key={concept._id}
                   className={trClassName}
                   { ...expandableProps }>
            <td className="check">
                {notHandled && <div className="cell-wrapper">
                    <input type="checkbox"
                           checked={concept.selected || false}
                           onChange={this.handleSelectConcept}/>
                </div>}
            </td>
            <td>
                <div className="cell-wrapper">
                    <Fragment>
                        {concept.hidden && <span className="icon"><Glyphicon glyph="eye-close"/>&nbsp;</span>}
                        {concept.id ?
                            <Link to={helpers.constructConceptPath(concept.id, concept.name)}
                                target="_blank">
                                {literals}
                            </Link>
                            : literals
                        } {concept.created &&
                            <span key="label" className="label label-success">
                                created
                            </span>
                        } {concept.merged &&
                            <span key="label" className="label label-primary">
                                merged
                            </span>
                        }
                    </Fragment>
                    <Modal show={isCurrent}
                           animation={false}
                           className="user-form-modal"
                           onHide={this.handleCollapse}
                           dialogClassName="possible-concept-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {concept.name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <p>
                                    Concept score is {toFixed(concept.score, 2)}
                                    {' '}in this collection.
                                </p>
                                {showDfCollection && <p>
                                    This concept occurs <strong>
                                        {concept.dfCollection}
                                    </strong> {
                                        singlePlural('time', concept.dfCollection)
                                    } in this collection.
                                </p>}
                                {showTf && <p>
                                    This concept occurs <strong>{concept.tf}</strong> {
                                        singlePlural('time', concept.tf)
                                    } in this document.
                                </p>}
                                <EditConceptForm
                                    id={concept.id}
                                    key={concept._id}
                                    name={concept.name}
                                    literals={concept.literals}
                                    basic={concept.basic}
                                    potentialTotalDf={concept.potentialTotalDf}
                                    searchQuery={concept.searchQuery}
                                    hideBreadcrumbs={true}
                                    saveText="Create concept"
                                    client={this.props.client}
                                    ref={this.setForm}
                                    batchItemIdentifier={concept._id}
                                    onError={this.handleFormError}
                                    errors={concept.errors}
                                    nameErrors={concept.nameErrors}
                                    warning={concept.warning || ''}
                                    onChange={this.props.onFormEdit}
                                    submit={this.props.submit}
                                    onSubmit={this.handleFormSubmit}
                                    onRenderActions={
                                        similarConcept => this.props.onRenderActions(concept, similarConcept)}
                                    additionalButtons={this.renderAdditionalButtons}
                                />
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </td>
            <td><div className="cell-wrapper">{toFixed(concept.score, 2)}</div></td>
            {showDfCollection &&
            <td><div className="cell-wrapper">{concept.dfCollection}</div></td>}
            {showTf &&
            <td><div className="cell-wrapper">{concept.tf}</div></td>}
            <td>
                <div className="cell-wrapper">
                    <PossibleConceptSearchLink onClick={this.stopPropagation}
                                               concept={concept}/>
                </div>
            </td>
            {this.renderWikipediaCell(
                concept,
                concept.literals.reduce((acc, literal) =>
                                        acc.concat(literal.wikiVariants || []), []))}
            {this.renderSimilarConceptCell(concept, concept.conflicts)}
            {this.renderSimilarConceptCell(concept, concept.neighbors, this.props.hideBasicNeighbors)}
        </tr>;
    }
}
