import React, { Component } from 'react';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import { checkStatus } from '../utils';
import LoadingButton from '../widgets/LoadingButton';

import { cx } from '../utils';

import './file-upload.css';


type FileUploadPropsType = {
    accept?: string,
    sizeLimit?: number,
    className: string,
    uploadFn?: (file: any) => Promise<any>,
    onSelect?: (file: any) => any,
    callback?: (arg: any) => any,
};

type FileUploadStateType = {
    selectedFile: any,
    error: string,
    uploading: boolean,
    dragged: boolean,
    done: boolean,
};

export default class FileUpload extends Component<
    FileUploadPropsType,
    FileUploadStateType
> {
    static defaultProps = {
        className: ''
    };

    state = {
        selectedFile: null,
        error: '',
        uploading: false,
        dragged: false,
        done: false,
    };

    fileInput = {};
    _mounted = false;

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    onFileSelect = () => {
        const files = this.fileInput.files;
        const selectedFile = files.length > 0 ? files[0] : null;
        let error = '';

        if (this.props.sizeLimit && selectedFile && selectedFile.size > this.props.sizeLimit) {
            error = 'File is too large.';
        }

        if (!error && this.props.onSelect) {
            this.props.onSelect(selectedFile);
        }

        this.setState({
            selectedFile,
            error,
            done: false,
            uploading: false
        });
    }

    doCallback = (fetchResult: any) => {
        const callback = this.props.callback;

        if (callback) {
            let response = callback(fetchResult);
            if (response && response.error) {
                this.setState({
                    error: response.error
                });
            }
        }
    }

    onUploadClick = () => {
        const file = this.state.selectedFile;

        if (file === null) {
            return;
        }

        this.setState({ uploading: true });

        this.props.uploadFn && this.props.uploadFn(file)
            .then(checkStatus)
            .then(response => response.json(), (error) => {
                this.setState({ error: error.message });
            })
            .then(result => {
                this.setState({
                    done: true,
                    uploading: false
                });

                setTimeout(() => {
                    if (this._mounted) {
                        this.setState({
                            selectedFile: null,
                            done: false
                        });
                    }
                }, 1500);

                return result;
            })
            .then(this.doCallback);
    }

    renderFileName = () => {
        const selectedFile = this.state.selectedFile;

        if (selectedFile === null) {
            return null;
        }

        return <div className="filename">{selectedFile.name}</div>;
    }

    onSelectFileClick = () => {
        this.fileInput.click();
    }

    setUpload = (el: ?HTMLInputElement) => {
        if (el) {
            this.fileInput = el;
        }
    }

    addDraggedOver = () => {
        if (!this.state.dragged) {
            this.setState({ dragged: true });
        }
    }

    removeDraggedOver = () => {
        if (this.state.dragged) {
            this.setState({ dragged: false });
        }
    }

    render() {
        const disabled = this.state.selectedFile === null ||
            this.state.uploading ||
            this.state.done;

        return <div className={cx('upload-container', this.props.className, { 'dragged': this.state.dragged, 'has-error': this.state.error })}
                    onDragOver={this.addDraggedOver}
                    onDragLeave={this.removeDraggedOver}
                    onDrop={this.removeDraggedOver}>
            <label>
                <input type="file"
                       ref={this.setUpload}
                       { ...(this.props.accept ? { accept: this.props.accept } : {}) }
                       onChange={this.onFileSelect} />
                <div className="wrapper">
                    <span>Click or drop file here</span>
                    {this.renderFileName()}
                    <div className="help-block">{this.state.error}</div>
                    {this.state.selectedFile && !this.props.onSelect &&
                        <LoadingButton disabled={disabled}
                                       className="upload-button"
                                       workingMessage="Uploading"
                                       onClick={this.onUploadClick}
                                       working={this.state.uploading}
                                       done={this.state.done}>
                            <Glyphicon glyph="glyphicon-cloud-upload"/>
                            Upload
                        </LoadingButton>
                    }
                </div>
            </label>
        </div>;
    }
}
