import React from 'react';

import { useSubscription } from '../datastore';
import { useCurrentUser } from '../accounts/hooks';
import type { RefereeType } from './Types';

import SortableTable from '../widgets/SortableTable';
import RefereeRow from './RefereeRow';
import SelectedRefereesContext from './SelectedRefereesContext';

import { makeFullProposalKey } from '../proposals/ProposalData';

export function renderRefereeRow({
    docId, docKind, settings, customAuthorIds, forJobId,
    AdditionalButtons, handleNameMouseEnter, handleNameMouseLeave,
    showOwnWorks, checkSelected, setSelected, checkMarkedAsReferee,
    folderId
}) {
    const renderRefereeRow = (referee: RefereeType) => {
        const authorId = referee.author.id;
        const isCustom = customAuthorIds.indexOf(authorId) !== -1;
        const isSelected = checkSelected(referee);
        const isMarkedAsReferee = checkMarkedAsReferee(referee);

        return <RefereeRow
            key={`${authorId}${isCustom ? '-custom' : ''}`}
            AdditionalButtons={AdditionalButtons}
            isCustom={isCustom}
            referee={referee}
            onNameMouseEnter={handleNameMouseEnter}
            onNameMouseLeave={handleNameMouseLeave}
            docId={docId}
            docKind={docKind}
            forJobId={forJobId}
            settings={settings}
            showOwnWorks={showOwnWorks}
            isSelected={isSelected}
            isMarkedAsReferee={isMarkedAsReferee}
            setSelected={setSelected}
            folderId={folderId}
        />;
    }
    return renderRefereeRow;
};


export default function RefereesTable({
    docId, docKind, settings, customAuthorIds, forJobId,
    referees,
    AdditionalButtons,
    handleNameMouseEnter, handleNameMouseLeave,
    markedAuthorsIds, displaySettings,
}) {

    const [currentUser, ] = useCurrentUser();
    const showOwnWorks = currentUser.activeQuotas.author_positions;
    const [selectedReferees, { setSelected }] = React.useContext(SelectedRefereesContext);

    const checkSelected = (referee) => selectedReferees.some(
        selected => selected.author.id === referee.author.id);
    const checkMarkedAsReferee = (referee) =>
        !!markedAuthorsIds ? markedAuthorsIds.some(
            authorId => referee.author.id === authorId)
            : false;

    // Fetching folder from store would work only when this is a proposal RF page.
    // In other cases we'd just get `undefined`.
    // Have to suffer like this because React hooks cannot be called conditionally.
    const query = makeFullProposalKey(docId);
    const doc = useSubscription(query);
    const folderId = doc?.folderId;

    const columns = [
        {field: '', text: '', selectable: true},
        {field: 'author.id', key: true},
        {field: 'position', text: '#', sortable: true, className: 'text-right'},
        {field: 'name', text: 'Name', className: 'referee-name-header'},
        {field: 'countries', text: ''},
        {field: 'score', text: 'Score', sortable: true, className: 'text-right', help: 'Cumulative ranking score across all relevant articles'},
        {field: 'maxArticleScore', text: 'Art. score', sortable: true, className: 'text-right', help: 'Score of the most relevant article'},
        {field: 'scoredArticlesCount', text: 'Articles', sortable: true, className: 'text-right', help: 'Number of relevant articles (Total number of articles)'},
        showOwnWorks && {field: 'ownWorksCount', text: 'Own works', sortable: true, className: 'text-right', help: 'Total count of papers with author as lead, corresponding, or senior'},
        {field: 'hIndex', text: 'h‑index', sortable: true, className: 'text-right'},
        {field: 'citationsCount', text: 'Citations', sortable: true, className: 'text-right'},

        ...displaySettings?.referee_finder_columns?.map(column => {
            if (column.from_author_group && !(!settings.ignore_authors_group && settings.authors_groups_ids)) {
                return null;
            }
            if (column.field === 'marked_in_folder') {
                // Should set only for a proposal
                return {
                    field: column.field,
                    text: <a href={`/folder/${folderId}/marks`} target='_blank' rel='noopener noreferrer'>{column.label}</a>,
                    sortable: true,
                    className: 'text-right',
                    help: column.help
                };
            }
            return {field: column.field, text: column.label, sortable: true, className: 'text-right', help: column.help}
        }) || [],

        {field: '', text: '', className: 'text-right'},
    ].filter(col => !!col);

    return <SortableTable
        className={`table-sticky-head table-double-striped table-referees condensed`}
        data={referees}
        renderRow={renderRefereeRow({
            docId, docKind, settings, customAuthorIds, forJobId,
            AdditionalButtons,
            handleNameMouseEnter, handleNameMouseLeave,
            showOwnWorks, checkSelected, setSelected,
            checkMarkedAsReferee, folderId
        })}
        columns={columns}
        defaultSortingField="position"
        defaultSortingDirection="asc"
        isSelected={checkSelected}
        onSelect={setSelected}
        thStyle={{top: '48px'}} // to be sticky together with mass actions.
    />;
}
