import React from 'react';

import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Modal from 'react-bootstrap/lib/Modal';

import { useSubscription } from '../../datastore';
import { makeJobTagsKey } from '../JobData';
import type { TagType } from '../Types';
import LoadingButton from '../../widgets/LoadingButton';
import { cx } from '../../utils';


type ButtonCaptionsType = {
    ['destroy' | 'save' | 'cancel']: string
};

type EditTagModalPropsType = {
    show: boolean,
    tag: TagType,
    onHide: Function,
    onSave: (data: any) => Promise<*>,
    onDestroy?: () => Promise<*>,
    availableTags: Array<TagType>,
    buttonCaptions?: ButtonCaptionsType,
};


const BUTTON_CAPTIONS = {
    destroy: 'Destroy tag',
    save: 'Save changes',
    cancel: 'Cancel',
};


class EditTagModalInner extends React.Component<
    EditTagModalPropsType,
    {
        name: string,
        description: string,
        autotag: boolean,
        nameError: string,
        error: string,
        working: boolean,
    }
> {
    static defaultProps = {
        buttonCaptions: {},
    };

    state = {
        error: '',
        nameError: '',
        working: false,
        ...this.props.tag
    };

    componentDidUpdate(prevProps: EditTagModalPropsType) {
        if (!prevProps.show && this.props.show) {
            this.setState({
                name: this.props.tag.name,
                description: this.props.tag.description,
                autotag: this.props.tag.autotag,
            });
        }
    }

    destroyTag = () => {
        this.handleHide();
        this.props.onDestroy && this.props.onDestroy();
    }

    handleNameChange = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ name: e.currentTarget.value });
    }

    handleDescriptionChange = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ description: e.currentTarget.value });
    }

    handleAutotagChange = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ autotag: e.currentTarget.checked });
    }

    handleHide = () => {
        this.setState({
            name: this.props.tag.name,
            description: this.props.tag.description,
            autotag: this.props.tag.autotag,
            nameError: '',
            error: '',
            working: false,
        });

        this.props.onHide();
    }

    handleSubmit = () => {
        let nameError = '';

        if (!this.state.name) {
            nameError = 'Tag name is required.';
        }

        if (
            this.props.availableTags.some(tag =>
                tag.id !== this.props.tag.id && tag.name === this.state.name
            )
        ) {
            nameError = `Tag "${this.state.name}" already exists.`;
        }

        this.setState({ nameError });

        if (!nameError) {
            const updatedTag = {
                id: this.props.tag.id,
                name: this.state.name,
                description: this.state.description,
                autotag: this.state.autotag,
            };

            this.setState({ working: true });
            return this.props.onSave(updatedTag)
                .then(this.handleHide)
                .catch(e => this.setState({ error: e.message, working: false }));
        }
    }

    render() {
        const { tag } = this.props;
        const buttonCaptions = { ...BUTTON_CAPTIONS, ...this.props.buttonCaptions };

        return <Modal show={this.props.show}
                      animation={false}
                      onHide={this.handleHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit tag &quot;{tag.name}&quot;</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.error && <Alert bsStyle="danger">
                    {this.state.error}
                </Alert>}
                <form>
                    <FormGroup className={cx({ 'has-error': this.state.nameError })}>
                        <label htmlFor="tag-name">
                            <strong>Name:</strong>
                        </label>{' '}
                        <input
                            id="tag-name"
                            autoFocus
                            className="form-control"
                            type="text"
                            value={this.state.name}
                            onChange={this.handleNameChange}
                            autoComplete="off" />
                        <div className="help-block">{this.state.nameError}</div>
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="tag-description">
                            <strong>Description:</strong>
                        </label>{' '}
                        <input
                            id="tag-description"
                            className="form-control"
                            type="text"
                            value={this.state.description}
                            onChange={this.handleDescriptionChange}
                            autoComplete="off" />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox checked={this.state.autotag}
                                  onChange={this.handleAutotagChange}>
                            this tag can be assigned or deleted by autotagging process
                        </Checkbox>
                    </FormGroup>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {this.props.onDestroy &&
                    <Button bsStyle="danger" onClick={this.destroyTag} className="pull-left">
                        {buttonCaptions.destroy}
                    </Button>
                }
                <ButtonToolbar className="pull-right">
                    <Button onClick={this.handleHide}>{buttonCaptions.cancel}</Button>
                    <LoadingButton bsStyle="primary"
                                   working={this.state.working}
                                   disabled={this.state.working}
                                   onClick={this.handleSubmit}>
                        {buttonCaptions.save}
                    </LoadingButton>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>;
    }
}

export default function EditTagModal(props : { jobId: number, ...EditTagModalPropsType }) {
    const tags = useSubscription(makeJobTagsKey(props.jobId));

    return <EditTagModalInner { ...props } availableTags={tags} />;
}
