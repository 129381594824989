import type { SplitTokensType } from '../widgets/MixedInput';


const keywords = ['title', 'abstract', 'authors', 'author', 'pubmed', 'PMC'];

function isCondition(str) {
    return typeof str === 'string' && str.match(/^\s*(AND|OR)\s*$/i);
}

function isBraceAndPartOfToken(brace, beforeBrace) {
    return (
        (brace === '(' && !isCondition(beforeBrace) && beforeBrace.trim()) ||
        (brace === ')' && beforeBrace.indexOf('(') !== -1)
    )
}

export function splitIntoTokens(str, prev = '') {
    const splitSubstr = (str: string) : SplitTokensType => {
        let tokens;
        let indices;

        const maybeAppendResults = (substr: string, start: number = 0) => {
            if (substr.trim()) {
                const { tokens: sTokens, indices: sIndices } = splitSubstr(substr);

                tokens = (tokens || []).concat(sTokens);
                indices = (indices || []).concat(sIndices.map(index => index + start));
            }
        };

        const maybeAppendAtomicResults = (substr: string, start: number = 0) => {
            const nonspaceMatch = /[^\s]/.exec(substr);

            if (nonspaceMatch) {
                tokens.push(substr);
                indices.push(start + nonspaceMatch.index);
            }
        };

        const quoteMatch = /(\s*)"\s*/.exec(str);
        // const keywordMatch = new RegExp(`(${keywords.join('|')}):\\s*"|"[^"]+"[^\\s]`).exec(str);
        const keywordMatch = new RegExp(`(${keywords.join('|')}):\\s*"?`).exec(str);

        const prevQuoteMatch = prev.match(/"/g);

        if (quoteMatch && !keywordMatch) {
            if (prevQuoteMatch && prevQuoteMatch.length % 2) {
                // the current quoting mark is closing
                const quotePos = quoteMatch.index + quoteMatch[0].length;

                tokens = [str.slice(0, quotePos)];
                indices = [0];

                maybeAppendResults(str.slice(quotePos), quotePos);

                return { tokens, indices };
            }

            const quotePos = quoteMatch.index + quoteMatch[1].length;

            // the current quoting mark is opening
            // search for the next quoting mark
            const nextQuoteMatch = /"\s*/.exec(str.slice(quotePos + 1));
            const left = str.slice(0, quotePos);
            const enquoted = str.slice(
                quotePos,
                nextQuoteMatch ?
                    quotePos + 1 + nextQuoteMatch.index + 1
                    : undefined
            );

            const { tokens: lTokens, indices: lIndices } = splitSubstr(left);
            tokens = [...lTokens, enquoted];
            indices = [...lIndices, quotePos];

            maybeAppendResults(
                str.slice(quotePos + enquoted.length),
                quotePos + enquoted.length
            );
        } else {
            const prevIsConcept = prev.match(/{concept:.*}$/);
            const condMatch = prevIsConcept ?
                /((?:\s+|\b)(?:AND|OR)\s+)/i.exec(str)
                : /\b((?:AND|OR)\s+)/i.exec(str);

            if (condMatch) {
                tokens = [];
                indices = [];

                const beforeCond = str.slice(0, condMatch.index);
                maybeAppendResults(beforeCond);

                if (beforeCond.trim()) {
                    tokens.push(condMatch[1]);
                    indices.push(condMatch.index);
                } else {
                    tokens.push(str.slice(0, condMatch.index + condMatch[1].length));
                    indices.push(0);
                }

                maybeAppendResults(
                    str.slice(condMatch.index + condMatch[1].length),
                    condMatch.index + condMatch[1].length
                );
            } else {
                const notMatch = /\b(NOT\s)/i.exec(str);

                if (notMatch) {
                    tokens = [];
                    indices = [];

                    maybeAppendResults(str.slice(0, notMatch.index));

                    tokens.push(notMatch[1]);
                    indices.push(notMatch.index);

                    if (str.slice(notMatch.index + 3).trim()) {
                        tokens.push(str.slice(notMatch.index + 3).trim());
                        indices.push(notMatch.index + notMatch[0].length);
                    }
                    // tokens = tokens.concat([notMatch[1], str.slice(notMatch.index + 3).trim()]);
                    // indices = indices.concat([notMatch.index, notMatch.index + notMatch[0].length]);
                }
            }

            if (!tokens || !indices) {
                // we still may have braces here
                const braceRe = /[()]\s*/g;
                let braceMatch;
                tokens = [];
                indices = [];
                let initialIdx = 0;

                // eslint-disable-next-line no-cond-assign
                while ((braceMatch = braceRe.exec(str))) {
                    // before brace
                    const beforeBrace = str.slice(initialIdx, braceMatch.index);

                    if (isBraceAndPartOfToken(braceMatch[0], beforeBrace)) {
                        continue;
                    }

                    maybeAppendAtomicResults(beforeBrace, initialIdx);

                    tokens.push(braceMatch[0]);
                    indices.push(braceMatch.index);

                    initialIdx = braceRe.lastIndex;
                }

                maybeAppendAtomicResults(str.slice(initialIdx), initialIdx);
            }
        }

        return { tokens, indices };
    };

    return splitSubstr(str);
}
