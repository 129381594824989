import Reflux from 'reflux';

import type { PossibleConceptType } from '../../articles/Types';


export const PossibleConceptsActions = Reflux.createActions([
    'addToQueue', 'removeFromQueue', 'reset',
    'changeQueueItem', 'init'
]);

export default class PossibleConceptsStore extends Reflux.Store {
    defaultState = {
        possibleConcepts: null,
    };

    constructor() {
        super();

        this.state = { ...this.defaultState };
        this.listenables = PossibleConceptsActions;
    }

    init(concepts: Array<PossibleConceptType>) {
        this.setState({
            possibleConcepts: concepts.slice()
        });
    }

    indexOf(concept: PossibleConceptType) {
        return this.state.possibleConcepts
            .map(c => c.name)
            .indexOf(concept.name);
    }

    addToQueue(concept: PossibleConceptType) {
        const concepts = this.state.possibleConcepts.slice();
        concepts.push(concept);

        this.setState({
            possibleConcepts: concepts
        });
    }

    removeFromQueue(concept: PossibleConceptType) {
        const possibleConcepts = this.state.possibleConcepts.slice();

        possibleConcepts.splice(this.indexOf(concept), 1);

        this.setState({ possibleConcepts });
    }

    changeQueueItem(index: number, obj: {}) {
        const possibleConcepts = this.state.possibleConcepts.slice();
        const changedConcept = {
            ...possibleConcepts[index],
            ...obj,
        };

        possibleConcepts.splice(index, 1, changedConcept);

        this.setState({ possibleConcepts });
    }

    reset() {
        this.setState({ ...this.defaultState });
    }
}
