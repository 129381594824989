import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
    dragSelector,
    pendingDropSelector,
    phaseSelector,
} from 'react-beautiful-dnd/lib/state/selectors';
import Draggable from 'react-beautiful-dnd/lib/view/draggable/draggable';
import { storeKey } from 'react-beautiful-dnd/lib/view/context-keys';
import { horizontal } from 'react-beautiful-dnd/lib/state/axis';
import {
    lift as liftAction,
    move as moveAction,
    moveForward as moveForwardAction,
    moveBackward as moveBackwardAction,
    crossAxisMoveForward as crossAxisMoveForwardAction,
    crossAxisMoveBackward as crossAxisMoveBackwardAction,
    drop as dropAction,
    cancel as cancelAction,
    dropAnimationFinished as dropAnimationFinishedAction,
    moveByWindowScroll as moveByWindowScrollAction,
} from 'react-beautiful-dnd/lib/state/action-creators';
import type {
    State,
    Position,
    DraggableDimension,
    DraggableDimensionMap
} from 'react-beautiful-dnd/lib/types';
import type {
    OwnProps,
    DispatchProps,
} from 'react-beautiful-dnd/lib/view/draggable/draggable-types';
import { add, subtract } from 'react-beautiful-dnd/lib/state/position';
import { makeSelector } from 'react-beautiful-dnd/lib/view/draggable/connected-draggable';
import moveToEdge from 'react-beautiful-dnd/lib/state/move-to-edge';


const origin: Position = { x: 0, y: 0 };

const getScrollDiff = ({
    initial,
    current,
    droppable,
}) => {
    const windowScrollDiff = subtract(
        initial.windowScroll,
        current.windowScroll
    );

    const droppableScrollDiff = droppable ? subtract(
        droppable.container.scroll.initial,
        droppable.container.scroll.current
    ) : origin;

    return add(windowScrollDiff, droppableScrollDiff);
};

const draggablesSelector = (state: State, ownProps: OwnProps) => {
    if (!state.dimension) {
        return null;
    }

    return state.dimension.draggable;
};

const droppablesSelector = (state: State, ownProps: OwnProps) => {
    if (!state.dimension) {
        return null;
    }

    return state.dimension.droppable;
};

const getDraggablesInsideDroppable = (destination, draggables: DraggableDimensionMap): DraggableDimensionMap => {
    const ids = Object.keys(draggables)
        .filter(id => draggables[id].droppableId === destination.id);

    const draggablesInDestination = [];

    for (const id of Object.keys(draggables)) {
        if (ids.indexOf(id) !== -1 && id.indexOf('-') !== -1) {
            draggablesInDestination.push(draggables[id]);
        }
    }

    draggablesInDestination.sort((a: DraggableDimension, b: DraggableDimension) => {
        if (a.client.withMargin.center.y > b.client.withMargin.center.y) return 1;
        if (a.client.withMargin.center.y === b.client.withMargin.center.y) {
            if (a.client.withMargin.center.x === b.client.withMargin.center.x) return 0;

            const diff = a.client.withMargin.center.x - b.client.withMargin.center.x;
            return diff / Math.abs(diff);
        }

        return -1;
    });

    return draggablesInDestination;
};

const makeCustomSelector = (type: string) => {
    const selector = makeSelector();
    let newHomeOffset;
    const containerClassName = `.${type}s`;

    return createSelector(
        [phaseSelector, dragSelector, selector, droppablesSelector, pendingDropSelector, draggablesSelector],
        (phase, drag, everything, droppables, pending, draggables) => {
            if (phase === 'DRAGGING') {
                if (
                    drag &&
                    drag.impact.destination &&
                    drag.initial.source.droppableId !== drag.impact.destination.droppableId
                ) {
                    const roDroppableId = 'readonly-' + drag.impact.destination.droppableId;

                    const draggablesInDestination = getDraggablesInsideDroppable(
                        { ...drag.impact.destination, id: roDroppableId },
                        draggables
                    );

                    const draggable = draggables[drag.current.id];

                    const destinationFragment = draggablesInDestination.length ?
                        draggablesInDestination[draggablesInDestination.length - 1].client.withMargin
                        : droppables[roDroppableId].client.withMargin;

                    let targetCenter = moveToEdge({
                        source: draggable.client.withMargin,
                        sourceEdge: 'start',
                        destination: destinationFragment,
                        destinationEdge: draggablesInDestination.length ? 'end' : 'start',
                        destinationAxis: horizontal,
                    });

                    const scrollDiff = getScrollDiff({
                        initial: drag.initial,
                        current: drag.current,
                        droppable: droppables[roDroppableId],
                    });

                    targetCenter = add(targetCenter, scrollDiff);

                    if (document && !draggablesInDestination.length) {
                        try {
                            const point = document.elementFromPoint(targetCenter.x, targetCenter.y);

                            if (point) {
                                const parent = point.offsetParent;

                                if (parent) {
                                    const concepts = parent.querySelector(containerClassName);

                                    if (concepts) {
                                        const rect = concepts.getBoundingClientRect();

                                        targetCenter = {
                                            x: rect.left + draggable.client.withMargin.width / 2,
                                            y: rect.top + draggable.client.withMargin.height / 2
                                        };
                                    }
                                }
                            }
                        } catch (e) {
                            // a couple of reasons for this: targetCenter point is "empty",
                            // there's no .concepts list around; we can't do anything about it,
                            // just keep going with calculated targetCenter moving draggable
                            // to top left edge of droppable.
                        }
                    }

                    newHomeOffset = subtract(targetCenter, draggable.client.withMargin.center);
                }
            }

            if (
                everything.isDropAnimating &&
                pending.result &&
                pending.result.destination &&
                pending.result.source.droppableId !== pending.result.destination.droppableId
            ) {
                everything = { ...everything, offset: newHomeOffset };
                pending.newHomeOffset = newHomeOffset;
            }

            return everything;
        });
};


const makeMapStateToProps = (_, ownProps) => {
    const selector = makeCustomSelector(ownProps.type);
    return (state: State, props: OwnProps) => selector(state, props);
};

const mapDispatchToProps: DispatchProps = {
    lift: liftAction,
    move: moveAction,
    moveForward: moveForwardAction,
    moveBackward: moveBackwardAction,
    crossAxisMoveForward: crossAxisMoveForwardAction,
    crossAxisMoveBackward: crossAxisMoveBackwardAction,
    moveByWindowScroll: moveByWindowScrollAction,
    drop: dropAction,
    dropAnimationFinished: dropAnimationFinishedAction,
    cancel: cancelAction,
};

// Leaning heavily on the default shallow equality checking
// that `connect` provides.
// It avoids needing to do it own within `Draggable`
export default connect(
    // returning a function to ensure each
    // Draggable gets its own selector
    makeMapStateToProps,
    mapDispatchToProps,
    null,
    { storeKey },
)(Draggable);
