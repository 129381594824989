import React from 'react';
import type { Node } from 'react';

import { useTrack } from '../datastore';

import { makeJobApplicationQuery } from './JobData';
import LoadingREST from '../LoadingREST';


export function LoadingApplication(
    { id, children }
    : { id: string, children: (application: any) => Node }
) {
    const [application, reqstate] = useTrack(makeJobApplicationQuery(id));

    return <LoadingREST what="application" reqstate={reqstate} doc={application}>
        {!id ? children() : children(application)}
    </LoadingREST>;
}
