import React from 'react';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import { useSubscription } from '../datastore';
import { makeRefereesKey } from './RefereesData';
import { interpose } from '../utils';

import AffiliationItem from '../authors/AffiliationItem';
import AuthorFromGroupList from '../authors/AuthorFromGroupList';
import AuthorLink from '../authors/AuthorLink';
import type { DocKindType, RefereeType, SettingsType } from './Types';
import RefereeLabels from './RefereeLabels';
import RefereeArticles from './RefereeArticles';
import RefereeConcepts from './RefereeConcepts';
import RefereeCoauthors from './RefereeCoauthors';
import CommonInstitutions from './CommonInstitutions';
import { cx, toFixed, roundInteger } from '../utils';
import GenderIndicator from '../common/GenderIndicator';


export default class RefereeRow extends React.PureComponent<
    {
        referee: RefereeType,
        isCustom: boolean,
        onNameMouseEnter: (authorId: number) => any,
        onNameMouseLeave: () => any,
        hideReferee: (authorId: number, reason: string, comment: string) => any,
        docId: string,
        docKind: DocKindType,
        settings: SettingsType,
        showOwnWorks: boolean,
        isSelected: boolean,
        isMarkedAsReferee: boolean,
        setSelected: (referee: RefereeType, checked: boolean) => any,
        folderId?: number,
    },
    {
        expanded: boolean,
    }
> {
    state = {
        expanded: false,
    }

    handleNameMouseEnter = () => {
        this.props.onNameMouseEnter(this.props.referee.author.id);
    }

    toggleRow = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    handleClickOnRow = (e) => {
        if (window.getSelection().toString()) {
            return;
        }
        if (e.target.tagName === 'TD' || e.target.tagName === 'TR') {
            this.toggleRow();
        }
    }

    renderDisplayFieldValue = (referee, displayField) => {
        return displayField.field === 'marked_in_folder' && displayField.value > 0 ?
            <a href={`/folder/${this.props.folderId}/marks?author_id=${referee.author.id}`}
               target="_blank" rel="noopener noreferrer">{displayField.value}</a>
            : displayField.field === 'client_id' && !!referee.authorFromGroupList && referee.authorFromGroupList[0]?.id && !!displayField.value ?
                <a href={`/authors-groups/edit-found-author/${referee.authorFromGroupList[0].id}/`}
                   target="_blank"
                   rel="noopener noreferrer">{displayField.value}</a>
                : <span>{displayField.value}</span>;
    }

    /* eslint-disable complexity */
    render() {
        const { referee, isSelected, setSelected, isCustom, isMarkedAsReferee,
                docId, docKind, settings, showOwnWorks } = this.props;

        const classNameArgs = {
            'marked-as-referee-row': isMarkedAsReferee,
            'custom-author-row': isCustom,
            'expanded': this.state.expanded
        }
        const shortInfoClassName = cx({
            'referee-row-short-info': true,
            ...classNameArgs});
        const moreInfoClassName = cx(classNameArgs);

        const shouldHighlight = settings.topics && settings.topics.length;

        const topicScore = referee.scoreTopic ? `Topic score: ${toFixed(referee.scoreTopic, 2)}` : '';
        const affiliations = referee.author.datedAffiliations || [];

        let extraColumns = referee?.extraColumns || [];
        let extraFields = referee?.extraFields || [];

        if (settings.ignore_authors_group || !settings.authors_groups_ids) {
            extraColumns = extraColumns.filter(col => !!col && !col.from_author_group);
            extraFields = extraFields.filter(col => !! col && !col.from_author_group);
        }

        return <React.Fragment>
            <tr className={shortInfoClassName}
                onMouseEnter={this.handleNameMouseEnter}
                onMouseLeave={this.props.onNameMouseLeave}
                onClick={this.handleClickOnRow}>
                <td>
                    <input type="checkbox"
                           checked={isSelected}
                           onChange={(e) => setSelected(referee, e.target.checked)}
                    />
                </td>
                <td className="text-right"><span><b>{referee.position}</b></span></td>
                <td className="referee-name-cell">
                    <GenderIndicator authorId={referee.author.id}
                                     gender={referee.author.gender}
                                     manualGender={referee.author.manualGender} />
                    <RefereeLabels referee={referee} />

                    <span>
                        {/* eslint-disable-next-line */}
                        <a className="referee-row-anchor" id={`author-${referee.author.id}`}></a>
                        <AuthorLink id={referee.author.id} name={referee.author.name} target="_blank">
                            {referee.author.name}
                        </AuthorLink>
                        &nbsp;
                        {referee.author.orcid &&
                            <a href={`https://orcid.org/${referee.author.orcid}`}
                               target="_blank" rel="noopener noreferrer">
                                <img style={{height: 16, width: 16, margin: '-2px 2px 0 0'}} alt="ORCID"
                                     src="/static/images/orcid-id.svg"/>
                            </a>}
                        <AuthorFromGroupList list={referee.authorFromGroupList} />
                        {isCustom && <div><span className="label label-info">custom</span></div>}
                        {isMarkedAsReferee && <div><span className="label label-success">referee</span></div>}
                    </span>
                </td>
                <td>
                    <RefereeCountries
                        docKind={docKind}
                        docId={docId}
                        countryIds={[...new Set(affiliations.map(aff => aff.countryId))]} />
                </td>
                <td className="text-right" title={topicScore}><span>{toFixed(referee.score, 2)}</span></td>
                <td className="text-right"><span>{toFixed(referee.maxArticleScore, 2)}</span></td>
                <td className="text-right"><span>{referee.scoredArticlesCount} ({referee.articlesCount})</span></td>
                {showOwnWorks && <td className="text-right"><span>{referee.ownWorksCount}</span></td>}
                <td className="text-right"><span>{referee.hIndex}</span></td>
                <td className="text-right"><span>{roundInteger(referee.citationsCount)}</span></td>
                {extraColumns.map((column, i) =>
                    <td key={i} className="text-right">{ /* should happen only for proposal RF page */
                        this.renderDisplayFieldValue(referee, column)
                    }</td>
                )}
                <td className="text-right">
                    <button className="inline-link show-more-referee-info-btn" onClick={this.toggleRow}>
                        {this.state.expanded ? <Glyphicon glyph="chevron-up" /> : <Glyphicon glyph="chevron-down" />}
                    </button>
                </td>
            </tr>
            <tr className={moreInfoClassName}
                onMouseEnter={this.handleNameMouseEnter}
                onMouseLeave={this.props.onNameMouseLeave}>
                <td colSpan={10 + (showOwnWorks ? 1:0) + extraColumns.length}>
                    <RefereeConcepts
                        highlightTopics={shouldHighlight}
                        mostRelevantConcepts={referee.mostRelevantConcepts} />
                    <RefereeLabels referee={referee} multiLine/>
                    <div className='referee-details'>
                        {/* Show extra fields from first authors group found for the referee */}
                        {extraFields.map((field, i) => <div key={i}>
                            <h5 style={{marginBottom: '0px'}}>{field.label}{' '}
                                {!!field.from_author_group && referee.authorFromGroupList && referee.authorFromGroupList[0]?.id && <small>
                                    from <a href={`/authors-groups/edit-found-author/${referee.authorFromGroupList[0].id}/`} target="blank">
                                        authors group
                                    </a>
                                </small>}
                            </h5>
                            {this.renderDisplayFieldValue(referee, field)}
                        </div>)}
                        <RefereeArticles {...referee} />
                        {affiliations?.length > 0 && <div>
                            <h5 style={{marginBottom: '0px'}}>Affiliations:</h5>
                            <ul style={{marginTop: 0}}>
                                {affiliations.map((aff, i) =>
                                    <li key={i}><AffiliationItem item={aff}/></li>
                                )}
                                {affiliations.length &&
                                    <li>
                                        <AuthorLink id={referee.author.id} name={referee.author.name} target="_blank">
                                            more on author page
                                        </AuthorLink>
                                    </li>
                                }
                            </ul>
                        </div>}
                        <div>
                            <h5 style={{display: 'inline'}}>Coauthors of {referee.author.name}:</h5>
                            {' '}
                            <CollapsibleBlock>
                                <RefereeCoauthors
                                    id={referee.author.id}
                                    docId={docId}
                                    docKind={docKind}
                                    settings={settings} />
                            </CollapsibleBlock>
                        </div>
                        <CommonInstitutions {...referee} />
                    </div>
                </td>
            </tr>
        </React.Fragment>;
    }
}


function RefereeCountries({ docKind, docId, countryIds = [] }) {
    const [key,] = makeRefereesKey(docKind, docId);
    const hits = useSubscription(key);
    const countries = hits?.countries || [];
    return <span style={{fontSize: '0.7em', whiteSpace: 'nowrap'}}>
        {interpose(
            countryIds.map(id =>
                countries.filter(country => country.id === id)[0]?.code
            ).sort()
        , ' ')}
    </span>;
}


function CollapsibleBlock({ open, children }) {
    const [expanded, setExpanded] = React.useState(open);

    if (expanded) {
        return <>
            <div className="two-step-list">
                <button className="toggle active" onClick={() => setExpanded(!expanded)} >
                    collapse</button>
            </div>
            {children}
        </>;
    }

    return <div className="two-step-list"><button className="toggle" onClick={() => setExpanded(!expanded)} >
        show</button></div>;
}
