import * as React from 'react';
import Reflux from 'reflux';

import Button from 'react-bootstrap/lib/Button';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Form from 'react-bootstrap/lib/Form';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Popover from 'react-bootstrap/lib/Popover';
import SafeAnchor from 'react-bootstrap/lib/SafeAnchor';

import {
    ChosenRecommendationTopicsActions, ChosenRecommendationTopicsStore
} from './ChosenRecommendationTopicsStore';
import { ChosenConceptList } from './Concepts';
import type { ConceptItemType } from '../ontologies/Types';
import DroppableOverlay from './DroppableOverlay';

import { SuggestBox } from '../widgets/Suggest';
import type { SuggestType, SuggestsResponseType } from '../widgets/Suggest';
import { checkStatus, KEYS, cx } from '../utils';
import dom from '../common/helpers/dom';
import LRUCache from '../LRUCache';


export type RecommendationTopicType = {
    title: string,
    concepts: Array<ConceptItemType>,
    disabled?: boolean,
    required?: boolean,
};

export type RecommendationTopicStateType = {
    topics: Array<RecommendationTopicType>,
    topic: RecommendationTopicType,
    openedConcept: ?number,
    canDisable: boolean,
    disabled: boolean,
    titleError: string,
    suggestCache: any
};

type RecommendationTopicPropsType = {
    topicIndex: number,
    topicActions: (
        topic: RecommendationTopicType,
        state: RecommendationTopicStateType
    ) => React.Node,
    canDelete: boolean
};

const defaultTopic = {
    title: 'Topic 0',
    concepts: [],
};

export class RecommendationTopic extends Reflux.Component {
    props: RecommendationTopicPropsType;

    state: RecommendationTopicStateType = {
        topics: [defaultTopic],
        topic: defaultTopic,
        openedConcept: null,
        canDisable: false,
        disabled: false,
        suggestCache: new LRUCache(),
        titleError: '',
    };

    titleInput: ?HTMLInputElement;
    conceptsContainer: ?HTMLDivElement;

    constructor(props: RecommendationTopicPropsType) {
        super(props);

        this.mapStoreToState(ChosenRecommendationTopicsStore, fromStore => {
            const obj = {};

            if (fromStore.topics && fromStore.topics.length &&
                fromStore.topics[this.props.topicIndex]
            ) {
                obj.topics = fromStore.topics;
                obj.topic = fromStore.topics[this.props.topicIndex];
                obj.canDisable = fromStore.topics.filter(t =>
                    t !== obj.topic && !t.disabled && t.concepts.length
                ).length > 0;
            }

            return obj;
        });
    }

    shouldComponentUpdate(
        nextProps: RecommendationTopicPropsType,
        nextState: RecommendationTopicStateType
    ) {
        return nextState.topic.title !== this.state.topic.title ||
            nextState.topic.concepts !== this.state.topic.concepts ||
            nextState.topic.disabled !== this.state.topic.disabled ||
            nextState.topic.required !== this.state.topic.required ||
            nextState.titleError !== this.state.titleError ||
            nextState.canDisable !== this.state.canDisable;
    }

    componentDidMount() {
        document.body && document.body.addEventListener('click', (e: MouseEvent) => {
            const actions = document.getElementById(`mass-actions-${this.props.topicIndex}`);

            // reset selection on clicking elsewhere
            if (
                !e.shiftKey &&
                e.target instanceof Element &&
                actions && !dom.hasParent(e.target, actions) &&
                !dom.hasParent(e.target, '.concept-draggable') &&
                !dom.matches(e.target, '.expand-concepts-link')
            ) {
                this.state.topic.concepts
                    .filter(c => c.selected)
                    .forEach(c => {
                        ChosenRecommendationTopicsActions.selectConceptInTopic(
                            c.conceptId,
                            this.props.topicIndex,
                            false
                        );
                    });
            }
        });
    }

    suggestFetch(query: string): Promise<SuggestsResponseType> {
        return fetch(`/api/autocomplete/concepts?query=${encodeURIComponent(query)}`, {
            credentials: 'same-origin',
        })
            .then(checkStatus)
            .then(response => response.json());
    }

    setEditOverlayTrigger = (el: ?React.Element<typeof OverlayTrigger>) => {
        this.editOverlayTrigger = el;
    };

    closeEdit = () => {
        this.editOverlayTrigger && this.editOverlayTrigger.hide();
    };

    saveTopicEdit = () => {
        let titleError = '';

        // topic name contains quotes
        if (this.titleDraft.indexOf('"') !== -1) {
            titleError = '(") in topic name is prohibited.';
        }

        const draft = this.titleDraft.toLowerCase();

        // topic name is not unique
        if (this.state.topics.filter(t => t.title !== this.state.topic.title)
            .some(t => t.title.toLowerCase() === draft)
        ) {
            titleError = `Topic "${this.titleDraft}" already exists.`;
        }

        if (!titleError) {
            ChosenRecommendationTopicsActions.setTopicTitle(
                this.props.topicIndex, this.titleDraft
            );

            this.closeEdit();
        }

        this.setState({ titleError });
    };

    handleChangeTopicTitle = (e: SyntheticEvent<HTMLInputElement>) => {
        this.titleDraft = e.currentTarget.value;
    };

    handleTopicTitleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
        if (e.which === KEYS.ENTER) {
            this.saveTopicEdit();
        }
    }

    deleteTopic = () => {
        ChosenRecommendationTopicsActions.deleteTopic(this.props.topicIndex);
    }

    toggleDisableTopic = () => {
        ChosenRecommendationTopicsActions.toggleTopic(
            this.props.topicIndex, !this.state.topic.disabled
        );
    }

    setTitleDraft = () => {
        setTimeout(() => {
            if (this.titleInput) {
                const input = this.titleInput;
                input.focus();
                input.setSelectionRange(0, input.value.length);
            }
        }, 1);

        this.titleDraft = this.state.topic.title;
        this.setState({ titleError: '' });
    }

    setTitleInput = (input: ?HTMLInputElement) => {
        this.titleInput = input;
    }

    setConceptsContainer = (el: ?HTMLDivElement) => {
        this.conceptsContainer = el;
    }

    handleSuggestSelectForTopic = (item: ConceptItemType) => {
        if (
            !this.state.topic ||
            this.state.topic.concepts.some(concept => concept.conceptId === item.conceptId)
        ) {
            return;
        }

        ChosenRecommendationTopicsActions.addConceptToTopic(item, this.props.topicIndex);
    };

    handleSearchSuggestSelect = (item: SuggestType) => {
        this.handleSuggestSelect(item);

        if (!item.isNotSuggest) {
            this.setState({ lastAddedConceptId: item.id });
        }
    };

    handleSuggestSelect = (item: SuggestType) => {
        // on enter press in the SuggestBox without selecting existing concept
        if (item.id.indexOf('temp_') === 0) {
            return;
        }

        if (!item.isNotSuggest) {
            this.handleSuggestSelectForTopic({ conceptId: item.id, name: item.label });
        }
    };

    handleConceptBadgeClick = (conceptId: string) => {
        ChosenRecommendationTopicsActions.removeConceptFromTopic(
            conceptId, this.props.topicIndex
        );
    };

    handleSelect = (conceptId: number, selected: boolean) => {
        if (
            selected &&
            this.state.topic.concepts.every(c => !c.selected) &&
            this.state.openedConcept &&
            this.state.openedConcept !== conceptId
        ) {
            ChosenRecommendationTopicsActions.selectConceptInTopic(
                this.state.openedConcept,
                this.props.topicIndex,
                true
            );
        }

        ChosenRecommendationTopicsActions.selectConceptInTopic(
            conceptId,
            this.props.topicIndex,
            selected
        );

        this.setState({ openedConcept: null });
    }

    handleToggle = (e: SyntheticEvent<HTMLElement>, concept: ConceptItemType, toggle: boolean) => {
        if (this.state.topic.concepts.every(c => !c.selected)) {
            this.setState({ openedConcept: toggle ? concept.conceptId : null });
        }
    }

    handleMoveToDropdownSelect = (toIndex: number | 'new-topic') => {
        const selectedIds = this.state.topic.concepts
            .filter(c => c.selected)
            .map(c => c.conceptId);

        if (toIndex === 'new-topic') {
            ChosenRecommendationTopicsActions.addTopic();
            toIndex = this.state.topics.length;
        }

        ChosenRecommendationTopicsActions.moveConcept(
            selectedIds,
            this.props.topicIndex,
            toIndex
        );

        selectedIds.forEach(conceptId => {
            ChosenRecommendationTopicsActions.selectConceptInTopic(
                conceptId,
                toIndex,
                false
            );
        });
    }

    handleRemoveClick = () => {
        ChosenRecommendationTopicsActions.removeConceptFromTopic(
            this.state.topic.concepts.filter(c => c.selected).map(c => c.conceptId),
            this.props.topicIndex
        );
    }

    renderTopicActions() {
        return this.props.topicActions ?
            this.props.topicActions(this.state.topic, this.state)
            : <React.Fragment>
                <Button onClick={this.toggleDisableTopic}
                        disabled={(!this.state.topic.disabled &&
                            !this.state.canDisable)}
                        title={this.state.topic.disabled ? 'Enable topic'
                            : 'Temporarily disable topic'}
                        className="disable-topic">
                    {this.state.topic.disabled ? 'Enable' : 'Disable'}
                </Button>
                {this.state.topic.disabled && this.props.canDelete &&
                    <Button className="delete-topic"
                            onClick={this.deleteTopic}>
                        Delete topic
                    </Button>
                }
            </React.Fragment>;
    }

    render() {
        const titleEditClasses = cx({ 'has-error': !!this.state.titleError });
        const inlineEditForm = (
            <Popover id="edit-title-popover">
                <FormGroup className={titleEditClasses}>
                    <FormControl
                        defaultValue={this.state.topic.title}
                        onChange={this.handleChangeTopicTitle}
                        onKeyDown={this.handleTopicTitleKeyDown}
                        inputRef={this.setTitleInput} />
                    {this.state.titleError &&
                        <span className="help-block">{this.state.titleError}</span>
                    }
                </FormGroup>
                <Button title="Save" bsSize="xsmall" bsStyle="primary" onClick={this.saveTopicEdit}>
                    <span>Save</span>
                </Button>
                <Button title="Cancel" bsSize="xsmall" onClick={this.closeEdit}>
                    <span>Cancel</span>
                </Button>
            </Popover>
        );

        const moveBtnTitle = <React.Fragment>
            <Glyphicon glyph="resize-vertical"/>
            &nbsp;Move to&hellip;
        </React.Fragment>;

        return (
            <div className={`recommendation-topic${this.state.topic.disabled ? ' disabled' : ''}`}>
                <DroppableOverlay droppableId={`${this.props.topicIndex}`} type="concept">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef}
                             className={snapshot.isDraggingOver ? 'is-dragged-over' : ''}>
                            <Form inline>
                                <FormGroup>
                                    <div className="editable">
                                        <OverlayTrigger trigger="click"
                                                        placement="bottom"
                                                        overlay={inlineEditForm}
                                                        rootClose={true}
                                                        ref={this.setEditOverlayTrigger}
                                                        onEnter={this.setTitleDraft}>
                                            <SafeAnchor componentClass="button"
                                                        className="inline-link"
                                                        title="Click to rename">
                                                <strong className="ellipsis">
                                                    {this.state.topic.title}
                                                </strong>
                                                <span className="icon-btn glyphicon glyphicon-pencil">
                                                </span>
                                            </SafeAnchor>
                                        </OverlayTrigger>
                                    </div>
                                </FormGroup>
                                <FormGroup style={{width: '100%', display: 'flex'}}>
                                    <div style={{marginRight: '20px', flexGrow: 1}}>
                                        <SuggestBox minChars={2}
                                            maxLabelLength={70}
                                            callback={this.handleSearchSuggestSelect}
                                            shouldClearOnSelect={true}
                                            suggestFetch={this.suggestFetch}
                                            divClasses="concept-suggest"
                                            showSuggestType={false}
                                            selectOnExactMatch={true}
                                            placeholder="Search concept"
                                            requestCache={this.state.suggestCache} />
                                    </div>
                                    {this.renderTopicActions()}
                                </FormGroup>
                            </Form>
                            <div className="topic-details">
                                <div className="chosen-concepts-container concepts"
                                     ref={this.setConceptsContainer}>
                                    <ChosenConceptList
                                        handleClickRemove={this.handleConceptBadgeClick}
                                        handleClickAdd={this.handleSuggestSelectForTopic}
                                        handleSelect={this.handleSelect}
                                        handleToggle={this.handleToggle}
                                        concepts={this.state.topic.concepts}
                                        droppablePlaceholder={provided.placeholder}
                                        draggablePrefix={this.props.topicIndex}
                                        collapsible
                                        popover/>
                                </div>
                            </div>
                            {this.state.topic.concepts.some(c => c.selected) &&
                                <Popover id={`mass-actions-${this.props.topicIndex}`}
                                         className="mass-actions"
                                         placement="top"
                                         positionLeft={0}
                                         positionTop={-6}
                                         title={null}>
                                    <div>
                                        <DropdownButton title={moveBtnTitle}
                                                        onSelect={this.handleMoveToDropdownSelect}
                                                        id="dropdown-move">
                                            {this.state.topics
                                                .map((t, i) => (
                                                    i !== this.props.topicIndex ?
                                                        <MenuItem key={i} eventKey={i}>
                                                            <span className={`topic-color topic-color-${i}`}></span>&nbsp;
                                                            {t.title || (t.concepts.length ?
                                                                t.concepts.slice(0, 2)
                                                                    .map(c => c.name)
                                                                    .join(', ')
                                                                : 'Empty topic'
                                                            )}
                                                        </MenuItem>
                                                        : null
                                                ))
                                            }
                                            <MenuItem key="new-topic" eventKey="new-topic">
                                                New topic
                                            </MenuItem>
                                        </DropdownButton>
                                        <Button onClick={this.handleRemoveClick}>
                                            <Glyphicon glyph="remove"/>
                                            &nbsp;Remove
                                        </Button>
                                    </div>
                                </Popover>}
                        </div>
                    )}
                </DroppableOverlay>
            </div>
        );
    }
}
