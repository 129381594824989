import React from 'react';

import { deleteApplication } from './JobData';
import { sendRequest, updateGlobalData } from '../datastore';
import { JobApplicantsTopicsStoreActions } from './JobApplicantsTopicsStore';



export default function DeletePreliminary({applicationId, jobId}:
    {
        applicationId: number,
        jobId: number
    }
) {
    const [working, setWorking] = React.useState(false);
    const [error, setError] = React.useState(null);

    const deletePreliminaryApplication = () => {
        setWorking(true);

        const data = { applicationId };

        sendRequest('/api/jobs/delete-preliminary-application/', {
            method: 'DELETE',
            body: JSON.stringify(data),
        }).then(response => {
            setWorking(false);
            setError(null);
            const { ok, value } = response;

            if (ok) {
                JobApplicantsTopicsStoreActions.deletePerson(applicationId);
                updateGlobalData(deleteApplication, { applicationId, vacancyId: jobId })
            } else {
                setError(value.error);
            }
        })
    };

    return <div className="applicant-tags">
        <button className="inline-link"
                onClick={deletePreliminaryApplication}
                disabled={working}>
            ({!working ? 'remove' : 'working...' })
        </button>
        {!!error && <p className="text-danger">{error}</p>}
    </div>;
}
