import React from 'react';

import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

import LoadingButton from '../widgets/LoadingButton';
import { sendRequest } from '../datastore';


export default function DeleteMonitoringButton({ id, onDelete, title }) {
    const [showModal, setShowModal] = React.useState(false);
    const [working, setWorking] = React.useState(false);

    function openModal() {
        setShowModal(true);
    }
    function closeModal() {
        setShowModal(false);
    }

    function handleDelete() {
        setWorking(true);
        return sendRequest(`/api/monitoring/${id}`, { method: 'DELETE' })
            .then(({ ok, error }) => {
                setWorking(false);
                if (ok) {
                    onDelete();
                }
                if (error) {
                    alert(error);
                }
            })
    }

    return <>
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete monitoring &quot;{title}&quot;?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button onClick={closeModal}>Close</Button>
                <LoadingButton bsStyle="primary"
                               working={working}
                               onClick={handleDelete}
                               workingMessage="Deleting">
                    Delete
                </LoadingButton>
            </Modal.Footer>
        </Modal>
        <LoadingButton className="modal-trigger with-spinner"
                       working={working}
                       onClick={openModal}
                       workingMessage="Deleting">
            Delete...
        </LoadingButton>
    </>;
}
