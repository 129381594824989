import React from 'react';

import Modal from 'react-bootstrap/lib/Modal';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';

import {interpose} from '../utils';
import AuthorLink from '../authors/AuthorLink';


export type AuthorType = {
    id?: number, // ArticleAuthor.id
    name: string,
    affiliations?: ?Array<string>,
    authorId: ?number,
};


export function Author({name, affiliations, authorId, showAffiliations = false}:
        AuthorType & {showAffiliations: bool}) {
    let displayedAffs = null;
    if (showAffiliations && affiliations && affiliations.length) {
        displayedAffs = [' (', interpose(affiliations, ', '), ')']
    }
    return <span>
        <b><AuthorLink id={authorId} name={name} /></b>
        {displayedAffs}</span>;
}

type AuthorListPropsType = {
    authors: Array<AuthorType>,
    authorsCount?: number,
    showAffiliations: bool,
    short: bool,
    currentAuthorId?: number,
    collapseThreshold: number,
    collapsedCount: number,
};

type AuthorListStateType = {
    collapsed: bool,
    showAllAuthorsModal: bool
};

export class AuthorList extends React.Component<AuthorListPropsType, AuthorListStateType> {
    props: AuthorListPropsType;

    static defaultProps: {
        short: bool,
        collapseThreshold: number,
        collapsedCount: number,
    } = {
        short: false,
        collapseThreshold: 10,
        collapsedCount: 5,
    };

    state: AuthorListStateType = {
        collapsed: true,
        showAllAuthorsModal: false
    };

    isCollapsible() {
        return this.props.authors.length > this.props.collapseThreshold;
    }

    toggleCollapsed = () => {
        this.setState({ collapsed: !this.state.collapsed });
    };

    toggleAllAuthorsModal = () => {
        this.setState({ showAllAuthorsModal: !this.state.showAllAuthorsModal });
    };

    renderAuthor = (author: AuthorType) => {
        const authorMarkup = <Author { ...author } key={author.name}/>;

        if (author.affiliations && author.affiliations.length) {
            const tooltip = <Tooltip id="tooltip" className="author-affiliations">
                <h5>Affiliations</h5>
                <ul>
                    {author.affiliations.map(aff => <li key={aff}>{aff}</li>)}
                </ul>
            </Tooltip>;

            return <OverlayTrigger placement="bottom" overlay={tooltip} key={author.name}>
                <span>{authorMarkup}</span>
            </OverlayTrigger>;
        } else {
            return authorMarkup;
        }
    };

    render() {
        let expandLink = null;
        const { authors } = this.props;
        let visibleAuthors = this.props.authors;
        let etal = null;

        if (this.isCollapsible()) {
            let expandLinkText = '← collapse';
            if (this.state.collapsed) {
                // always show currentAuthor, even if it's in the etal group
                if (this.props.currentAuthorId) {
                    visibleAuthors = authors.filter(
                        (author, i) => i < this.props.collapsedCount ||
                            author.authorId === this.props.currentAuthorId);
                } else {
                    visibleAuthors = authors.slice(0, this.props.collapsedCount);
                }

                const collapsedCount = this.props.authors.length - visibleAuthors.length;
                etal = 'et al.';
                expandLinkText = `show ${collapsedCount} more →`;
            }

            if (!this.props.short) {
                expandLink = <button className="expand" onClick={this.toggleCollapsed}>
                    {expandLinkText}
                </button>;
            } else {
                etal = <button className="trigger-modal inline-link"
                               title="Click to see all authors"
                               onClick={this.toggleAllAuthorsModal}>
                    {etal}
                </button>;
            }
        } else if (this.props.authorsCount &&
                this.props.authorsCount > this.props.authors.length) {
            etal = 'et al.';
        }

        const authorsMarkup = visibleAuthors.map(this.renderAuthor);

        return <div className="authors">
            {interpose(authorsMarkup, ', ')} {etal} {expandLink}
            {this.props.short && this.isCollapsible() &&
                <Modal show={this.state.showAllAuthorsModal}
                       onHide={this.toggleAllAuthorsModal}
                       animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Authors</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ol>
                            {authors.map(author => {
                                return <li key={author.name}>
                                    <Author { ...author } showAffiliations />
                                </li>;
                            })}
                        </ol>
                    </Modal.Body>
                </Modal>
            }
        </div>;
    }
}
