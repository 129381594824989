type LeafGroupType = {|
    name: string,
    unary?: string
|};

/* eslint-disable no-use-before-define */
type ParsedGroupType = {|
    condition: string,
    unary?: boolean,
    children: Array<ParsedTreeNodeType>
|};

export type ParsedTreeNodeType = ParsedGroupType | LeafGroupType;
/* eslint-enable no-use-before-define */

// OR has lower operator precedence over AND, so it goes _first_
const groupTypes = ['or', 'and'];
const unaryRegex = /^(not|boost\d?|neg\d?|negate\d?)\s+(.+)/i;
const groupRegex = /\([^()]+\)/g;
const quoteRegex = /(["'])(?:(?=(\\?))\2.)*?\1/g;
const groupAliasRegex = /^\$(\d+)\$$/;
const groupAliasNonStrictRegex = /\$(\d+)\$/;

/**
 * Parses input like "((DNA AND protein) AND Virus) OR Topic 3"
 * into condition tree.
 *
 * @throws Error
 */
export default function parse(text: string) : ParsedTreeNodeType {
    const msg = queryInvalid(text);

    if (msg) {
        throw new Error(msg);
    }

    const groups = [];

    function getLeaf(name: string, top: boolean = false) {
        return top ? { condition: 'OR', children: [{ name }] }
            : { name };
    }

    function parseNode(text: string, top: boolean = false) : ParsedTreeNodeType {
        // unwrap input, if it's wrapped with parentheses
        text = text.replace(/^\((.+)\)\s*$/g, '$1');
        const quoteMatch = text.match(/^"(.+?)"$/);

        // enquoted strings should not be parsed, return immediately
        if (quoteMatch) {
            return getLeaf(quoteMatch[0], top);
        }

        for (let i = 0; i < groupTypes.length; i++) {
            const group = text.split(
                new RegExp(`\\s+${groupTypes[i]}\\s+`, 'i')
            );
            const msg = groupInvalid(group);

            if (msg) {
                throw new Error(msg);
            }

            if (group.length > 1) {
                return {
                    condition: groupTypes[i].toUpperCase(),
                    children: group.map(g => parseNode(g))
                };
            }
        }

        // didn't find neither AND nor OR,
        // we are at either leaf node, or named group
        const trimmed = text.trim();
        const hasUnaryMatch = trimmed.match(unaryRegex);
        let next = hasUnaryMatch ? hasUnaryMatch[2] : trimmed;
        const namedGroupMatch = next.match(groupAliasRegex);
        // even if it's not a group, due to user mistake,
        // input may have group placeholder inside
        const result : ParsedTreeNodeType = namedGroupMatch ?
            parseNode(groups[parseInt(namedGroupMatch[1], 10)], top)
            : (() => {
                while (next.match(groupAliasNonStrictRegex)) {
                    next = next.replace(groupAliasNonStrictRegex, (_, groupId) => (
                        groups[parseInt(groupId, 10)]
                    ));
                }

                return getLeaf(next, top);
            })();

        const unaryPrefix = hasUnaryMatch && hasUnaryMatch[1].toUpperCase();
        if (unaryPrefix) {
            result.unary = unaryPrefix;
        }

        return result;
    }

    // simplify our input for parsing,
    // replacing groups with $1$, $2$, and so on...
    function groupReplacer(match) {
        groups.push(match);
        return `$${groups.length - 1}$`;
    }

    while (text.match(quoteRegex)) {
        text = text.replace(quoteRegex, groupReplacer);
    }

    while (text.match(groupRegex)) {
        text = text.replace(groupRegex, groupReplacer);
    }

    return parseNode(text, true);
}

function parenthesesBalanced(text: string) {
    if (!text) return true;

    const parentheses = [];

    for (let i = 0; i < text.length; i++) {
        if (text[i] === '(') {
            parentheses.push(text[i]);
        } else if (text[i] === ')' && !parentheses.pop()) {
            return false;
        }
    }

    return parentheses.length === 0;
}

function groupInvalid(group: Array<string>) : string {
    let maybeError = '';

    if (group.some(n => !n)) {
        maybeError = 'AND/OR should have two operands.';
    }

    if (group.some(n => groupTypes.indexOf(n.toLowerCase()) !== -1)) {
        maybeError = `AND and OR should not go immediately one after another.`;
    }

    return maybeError;
}

function queryInvalid(query: string) {
    let maybeError = '';
    const quotesMatch = query.match(/"/g);

    if (!parenthesesBalanced(query)) {
        maybeError = 'One or more parentheses are missing.';
    }

    if (quotesMatch && quotesMatch.length % 2) {
        maybeError = 'Closing quotation mark is missing.';
    }

    return maybeError;
}
