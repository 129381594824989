import React from 'react';

import { useSubscription } from '../datastore';
import { makeJobApplicationKey } from './JobData';
import ApplicantPopover from './ApplicantPopover';
import OverlayTrigger from '../widgets/OverlayTrigger';
import PreliminaryBadge from './PreliminaryBadge';

import { cx } from '../utils';


export default function AdvisorApplicant(
    { id, hashLinkToRecommender, className = null, short = false, draggable = false, ...buttonProps }
    : { id: number, hashLinkToRecommender?: boolean, className?: string, short?: boolean, draggable?: boolean }
) {
    const [triggerRef, setTriggerRef] = React.useState(null);
    const saveTriggerRef = React.useCallback(ref => setTriggerRef(ref), []);
    const applicant = useSubscription(makeJobApplicationKey(id));

    const applicantPopover = <ApplicantPopover
        applicant={applicant}
        jobId={applicant.vacancyId}
        close={triggerRef && triggerRef.handleHide}
        hashLinkToRecommender={hashLinkToRecommender}
        short={short} />;

    return <OverlayTrigger overlay={applicantPopover}
                           animation={false}
                           trigger="click"
                           rootClose
                           ref={saveTriggerRef}>
        <a href={`/jobs/${applicant.vacancyId}/applicant/${applicant.applicationId}`}
           rel="noopener noreferrer" target="_blank"
           className={cx('inline-link overlay-trigger', className)} { ...buttonProps }>
            {applicant.authorName || applicant.email}
        </a>
        {(!applicant.email || !!applicant.mergeWith) && <>
            &nbsp;
            <PreliminaryBadge application={applicant.mergeWith ? applicant : null} />
        </>}
    </OverlayTrigger>;
}
