import React from 'react';

import { Link } from 'react-router-dom';

import Col from 'react-bootstrap/lib/Col';
import Popover from 'react-bootstrap/lib/Popover';

import type { ApplicationType } from './Types';
import RelevantArticlesList from '../common/RelevantArticlesList';
import ApplicationAttachments from './ApplicationAttachments';
import LinkToRecommendation from './LinkToRecommendation';
import VoteButton from './VoteButton';
import { cx, toFixed } from '../utils';
import Rank from './Rank';
import ApplicantComment from './ApplicantComment';
import ApplicantTags from './ApplicantTags';
import DeletePreliminary from './DeletePreliminary';
import LoadingREST from '../LoadingREST';

import { useTrack } from '../datastore';
import { makeJobAuthorTopCitedArticlesQuery } from './JobData';


function _formatDate(date: string) {
    return date && date.replace(/-/g, '/');
}


function PhDDate({ date }) {
    const dateParts = date.split('-').map(parseInt);
    const now = new Date();
    const nowParts = [now.getFullYear(), now.getMonth()].map(parseInt);
    const inFuture = dateParts[0] > nowParts[0] ||
        (dateParts[0] === nowParts[0] && dateParts[1] > nowParts[1]);

    return <span className={cx({ 'in-future': inFuture })}>
        {inFuture ? _formatDate(date) : dateParts[0]}
    </span>;
}


export default function ApplicantPopover(
    { applicant, jobId, close, hashLinkToRecommender = false, short = false, ...popoverProps }
    : { applicant: ApplicationType, jobId: number, hashLinkToRecommender?: boolean, close: ?Function, short: boolean }
) {
    const title = <>
        {applicant.authorName || applicant.email}&nbsp;
        <Link to={`/jobs/${jobId}/applicant/${applicant.applicationId}`} target="_blank">
            open applicant page &rarr;
        </Link>
    </>;
    const email = <div>
        <strong>Email:&nbsp;</strong><a href={`mailto:${applicant.email}`}>{applicant.email}</a>
    </div>;
    const phd = <div>
        <strong>PhD award date:&nbsp;</strong>
        <PhDDate date={applicant.phdAwardDate} />
    </div>;
    const attachments = <div>
        <strong>File:&nbsp;</strong>
        <ApplicationAttachments application={applicant} listType="inline" />
    </div>
    const isPreliminary = !applicant.email;
    const preliminaryBadge = isPreliminary ?
        <div>
            <strong>Is preliminary: </strong>
            <span>yes </span>
            <DeletePreliminary applicationId={applicant.applicationId} jobId={jobId}/>
        </div>
        : email;

    let mostCitedArticlesList = '';
    const [mostCitedArticles, reqstate] =
        useTrack(makeJobAuthorTopCitedArticlesQuery(applicant.authorId));

    if (applicant.authorId) {
        mostCitedArticlesList = (
            <LoadingREST what="most cited articles" reqstate={reqstate} doc={mostCitedArticles}>
                <RelevantArticlesList articles={mostCitedArticles} title='Most cited articles:' />
            </LoadingREST>
        )
    }

    return <Popover id="applicant-popover"
                    placement="bottom"
                    className="applicant-popover"
                    title={title}
                    { ...popoverProps }>
        {!short ?
            <>
                <div className="row">
                    <Col md={5} xs={12}>
                        {preliminaryBadge}
                        {!!applicant.rank && <div>
                            <strong>Relevance: </strong>
                            <Rank person={applicant} />
                        </div>}
                        {applicant.similarityCombined && <div>
                            <strong>Score: </strong><span>{toFixed(applicant.similarityCombined, 2)}</span>
                        </div>}
                        {applicant.similarityMax && <div>
                            <strong>Article score: </strong><span>{toFixed(applicant.similarityMax, 2)}</span>
                        </div>}
                        {!isPreliminary && phd}
                    </Col>
                    <Col md={7} xs={12}>
                        <div className="social-container">
                            <div className="votes">
                                <strong>Votes:&nbsp;</strong>
                                <VoteButton applicant={applicant} />
                            </div>
                            <div className="comment">
                                <strong>Comment:&nbsp;</strong>
                                <ApplicantComment applicant={applicant} />
                            </div>
                        </div>
                    </Col>
                </div>
                <div>
                    <strong>Tags: </strong>
                    <ApplicantTags inline applicant={applicant} />
                </div>
            </>
            : <>
                {preliminaryBadge}
                {!isPreliminary && phd}
            </>
        }
        {!isPreliminary && attachments}
        {!!applicant.advisors.length && <div>
            <div>
                <strong>Recommenders: </strong>
            </div>
            <ul style={{paddingLeft: '20px'}}>
                {applicant.advisors.map(advisor => {
                    const hash = advisor.groupId || advisor.name;

                    return <li key={advisor.groupId}>
                        {hashLinkToRecommender ?
                            <a href={`#advisor-${hash}`} onClick={close}>
                                {advisor.name}
                            </a>
                            : <Link to={`/jobs/${jobId}/recommenders#advisor-${hash}`}>
                                {advisor.name}
                            </Link>
                        }
                        {' | '}
                        {advisor.filename ?
                            <LinkToRecommendation key={advisor.id} advisor={advisor} />
                            : <span className="text-muted">has not given recommendation</span>
                        }
                    </li>;
                })}
            </ul>
        </div>}
        <RelevantArticlesList articles={applicant.mostRelevantArticles} />
        {mostCitedArticlesList}
    </Popover>;
}
