import Reflux from 'reflux';

import type { TagType, TopicType } from './Types';


export const JobApplicantsTopicsStoreActions = Reflux.createActions([
    'setTopics', 'setUnclassifiedIds', 'removeTopic', 'updateTopicTag',
    'resetStore', 'mergeTopic', 'movePerson', 'deletePerson',
]);


export default class JobApplicantsTopicsStore extends Reflux.Store {
    defaultState = {
        topics: [],
        unclassifiedIds: []
    };

    state: {
        topics: Array<TopicType>,
        unclassifiedIds: Array<number>
    } = {
        ...this.defaultState
    };

    constructor() {
        super();
        this.listenables = JobApplicantsTopicsStoreActions;
    }

    resetStore() {
        this.setState({ ...this.defaultState });
    }

    setTopics(topics: Array<TopicType>) {
        this.setState({ topics });
    }

    setUnclassifiedIds(unclassifiedIds: Array<number>) {
        this.setState({ unclassifiedIds });
    }

    removeTopic(index: number) {
        const topics = this.state.topics.slice();
        const releasedApplicantIds = topics[index].applicantsIds;
        topics.splice(index, 1);

        this.setState({
            topics,
            unclassifiedIds: this.state.unclassifiedIds.concat(releasedApplicantIds)
        });
    }

    mergeTopic(fromIndex: number, toIndex: number) {
        const topics = this.state.topics.slice();
        const migratingApplicantIds = topics[fromIndex].applicantsIds;

        topics[toIndex] = {
            ...topics[toIndex],
            description: [topics[toIndex].description, topics[fromIndex].description].join(', '),
            applicantsIds: topics[toIndex].applicantsIds.concat(migratingApplicantIds),
        };
        topics.splice(fromIndex, 1);

        this.setState({ topics });
    }

    movePerson(applicantId: number, fromIndex: number, toIndex?: number) {
        const topics = this.state.topics.slice();

        if (fromIndex !== undefined) {
            topics[fromIndex] = {
                ...topics[fromIndex],
                applicantsIds: topics[fromIndex].applicantsIds.filter(id => id !== applicantId),
            };
        } else {
            this.setUnclassifiedIds(this.state.unclassifiedIds.filter(id => id !== applicantId));
        }

        if (toIndex !== undefined) {
            topics[toIndex] = {
                ...topics[toIndex],
                applicantsIds: topics[toIndex].applicantsIds.concat(applicantId),
            };
        } else {
            // move to unclassified otherwise
            this.setUnclassifiedIds(this.state.unclassifiedIds.concat(applicantId));
        }

        this.setState({ topics });
    }

    deletePerson(applicantId: number) {
        const topics = this.state.topics.map(topic => ({
            ...topic,
            applicantsIds: topic.applicantsIds.filter(id => id !== applicantId),
        }));
        const unclassifiedIds = this.state.unclassifiedIds.filter(id => id !== applicantId);
        this.setState({ topics, unclassifiedIds });
    }

    updateTopicTag(index: number, data: $Shape<TagType>) {
        this.setState({
            topics: this.state.topics.map((tag, i) => i === index ?
                { ...tag, ...data }
                : tag
            )
        });
    }
}
