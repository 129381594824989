import { sendRequest } from '../datastore';

import type { MonitoringQueryType } from './Types';


export function makeMonitoringArticlesKeys(query: MonitoringQueryType, page: number) {
    const queryString = JSON.stringify(query);
    const baseKey = `monitoring-query-${queryString}`;
    const pageKey = `${baseKey}-${page}`;
    return [baseKey, pageKey];
}

export const makeMonitoringsListKey = () => 'monitorings';
export const makeMonitoringTopicsKey = () => 'monitoring-topics';
export const makeMonitoringKey = (id: number) => `monitoring-${id}`;
export const makeBootstrapMonitoringKey = (link: string) => `bootstrap-monitoring-${link}`;


export function makeMonitoringsListQuery() {
    const listKey = makeMonitoringsListKey();

    return {
        id: listKey,
        get: listKey,
        set: function (store, { value: { monitorings } }) {
            store[listKey] = monitorings;
        },
        req: () => sendRequest(`/api/monitoring/list`),
    };
}


export function makeMonitoringTopicsQuery() {
    const listKey = makeMonitoringTopicsKey();

    return {
        id: listKey,
        get: listKey,
        set: function (store, { value: { monitorings } }) {
            store[listKey] = monitorings;
        },
        req: () => sendRequest('/api/monitoring/list?with_topics=true'),
    };
}


export function makeMonitoringQuery(id: number) {
    const key = makeMonitoringKey(id);

    return {
        id: key,
        get: key,
        set: function (store, { value: { monitoring } }) {
            store[key] = monitoring;
        },
        req: () => sendRequest(`/api/monitoring/${id}`),
    };
}


export function makeMonitoringArticlesQuery(
    query: MonitoringQueryType, monitoringId: ?number, page: number = 1,
) {
    const [baseKey, pageKey] = makeMonitoringArticlesKeys(query, page);
    const data = { ...query, monitoringId, page };

    return {
        id: pageKey,
        get: baseKey,
        set: (store, { value: { result } }) => {
            if (page > 1 && store[baseKey]) {
                store[baseKey] = {
                    ...result,
                    articles: store[baseKey].articles.concat(result.articles),
                }
            } else {
                store[baseKey] = result;
            }
        },

        req: () => sendRequest('/api/monitoring/query', {
            method: 'POST',
            body: JSON.stringify(data),
        }),
    };
}


export function makeBootstrapMonitoringQuery(link: string) {
    const key = makeBootstrapMonitoringKey(link);

    return {
        id: key,
        get: key,
        set: (store, { value: { topics } }) => {
            store[key] = topics;
        },

        req: () => sendRequest('/api/monitoring/bootstrap', {
            method: 'POST',
            body: JSON.stringify({ link }),
        }),
    };
}
