import React from 'react';
import flatten from 'lodash/flatten';

import Bar from 'recharts/es6/cartesian/Bar';
import BarChart from 'recharts/es6/chart/BarChart';
import Legend from 'recharts/es6/component/Legend';
import ResponsiveContainer from 'recharts/es6/component/ResponsiveContainer';
import Tooltip from 'recharts/es6/component/Tooltip';
import XAxis from 'recharts/es6/cartesian/XAxis';
import YAxis from 'recharts/es6/cartesian/YAxis';

import BarBackground from './BarBackground';
import ChartCursor from './ChartCursor';

import BarLabel from './BarLabel';
import { toFixed } from '../../utils';


export const defaultColor = '#8884d8';
export const memberColor = '#8884d8';
export const hoverColor = '#ff7f0e';
export const selectedColor = '#2ca02c';


const formatter = value => toFixed(value, 2);

const Tick = ({ x, y, stroke, payload }) =>
    <g transform={`translate(${x - 12},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-90)" fontSize="11">
            {payload.value}
        </text>
    </g>;

const defaultMargin = { top: 0, right: 0, left: 0, bottom: 0 };

const isMember = (person) => person.status !== null;


export default function PanelChart({
    panel, scores, people, hoveredPerson, sortedBySubpanels,
    onSubpanelBarClick, selectedPeople = [],
    width = '99%', aspect = 1.7, showingCandidates = false
}) {
    const labelFormatter = React.useCallback(labelCode => {
        try {
            const { documents, name } = panel.subpanels.find(({ code }) => code === labelCode);
            return <BarLabel code={labelCode} name={name} docCount={documents.total} />;
        } catch (e) {
            console.error(`No subpanel found for ${labelCode}`);
            return null;
        }
    }, [panel]);

    const getScore = React.useCallback(
        (person, subpanelId) => person.subpanelId2score[subpanelId] || 0,
        []
    );

    const onBarClick = React.useCallback((payload, index, e) => {
        onSubpanelBarClick(payload, e.shiftKey);
    }, [onSubpanelBarClick]);
    // maximum score value among all subpanels + maximum boost by candidate
    // OR just max score when showing panel members
    // not 100% accurate but easy to understand and calculate
    const domain = React.useMemo(
        () => showingCandidates ?
            [
                0,
                1.4 * (Math.max(...Object.values(scores)) + Math.max(...flatten(people.map(c => Object.values(c.subpanelId2score)))))
            ]
            : [0, Math.max(...Object.values(scores))],
        [scores, people, showingCandidates]
    );

    const chartData = React.useMemo(
        () => panel.subpanels
            .map(({ id, code, score }) => ({
                name: code,
                panelScore: score,
                score:
                    score
                    // if we hover a member, subtract his score from the subpanel
                    - (!!hoveredPerson && isMember(hoveredPerson) && getScore(hoveredPerson, id))
                    // if there are selected members, subtract them as well (except for the hovered one)
                    - (!!selectedPeople.length && selectedPeople.filter(isMember).reduce(
                            (total, person) => total + (person !== hoveredPerson && getScore(person, id)), 0)),
                // adding people scores
                selected: selectedPeople.reduce(
                    (total, person) => total + (person !== hoveredPerson && getScore(person, id)),
                    0
                ),
                hovered: (!!hoveredPerson && getScore(hoveredPerson, id)) || 0,
            })),
        [panel, selectedPeople, hoveredPerson, getScore]
    );

    return <ResponsiveContainer width={width} aspect={aspect}>
        <BarChart
            stackOffset="none"
            data={chartData}
            margin={defaultMargin}>
            <YAxis yAxisId="left" hide domain={domain} />
            <XAxis dataKey="name" tick={<Tick />} height={40} interval={0} />
            <Tooltip
                labelFormatter={labelFormatter}
                formatter={formatter}
                separator=": "
                cursor={<ChartCursor onClick={onSubpanelBarClick} />}
                allowEscapeViewBox={{x: false, y: true}}
            />
            <Legend align="left" verticalAlign="bottom" iconType="square" wrapperStyle={{ bottom: -10, minHeight: '40px' }} />
            <Bar name="Current panel score"
                 yAxisId="left"
                 stackId="scores"
                 dataKey="score"
                 fill={memberColor}
                 isAnimationActive={false}
                 onClick={onBarClick}
                 formatter={(value, name, bar) => formatter(bar.payload.panelScore)}
                 cursor="pointer"
                 background={props =>
                     <BarBackground {...props}
                                    cursor="pointer"
                                    isSortedBy={sortedBySubpanels.indexOf(props.payload.name) !== -1} />
                 }
            />
            {!!selectedPeople.length &&
                <Bar key="selected"
                     name="Selected"
                     yAxisId="left"
                     stackId="scores"
                     dataKey="selected"
                     fill={selectedColor}
                     isAnimationActive={false}
                     onClick={onBarClick}
                     cursor="pointer"
                />
            }
            {!!hoveredPerson &&
                <Bar key={hoveredPerson.author.id}
                     name={hoveredPerson.author.name}
                     yAxisId="left"
                     stackId="scores"
                     dataKey="hovered"
                     fill={hoverColor}
                     isAnimationActive={false}
                     onClick={onBarClick}
                     cursor="pointer"
                />
            }
        </BarChart>
    </ResponsiveContainer>;
}
