import React from 'react';


type BodyClassPropsType = {
    className: string
};


export default class BodyClass extends React.Component<
    BodyClassPropsType
> {
    componentDidMount() {
        this.toggleClasses(true, this.props.className);
    }

    toggleClasses(add: boolean, className: string) {
        if (document.body) {
            const body = document.body;
            const fn = add ?
                body.classList.add
                : body.classList.remove;

            className.split(/\s+/).forEach((className: string) => {
                fn.call(body.classList, className);
            });
        }
    }

    componentDidUpdate(prevProps: BodyClassPropsType) {
        this.toggleClasses(false, prevProps.className);
        this.toggleClasses(true, this.props.className);
    }

    componentWillUnmount() {
        this.toggleClasses(false, this.props.className);
    }

    render() {
        return null;
    }
}
