import React from 'react';

import { cx } from '../utils';

import './two-step-list.css';


export default function TwoStepCollapsibleList({
    firstStep = 5, stepThreshold = 2, defaultCount = 0, total = null,
    word = 'top', WrapperComponent = 'ul', className, children
}) {
    if (!total) {
        total = children.length;
    }

    let buttons;
    const [shown, setShown] = React.useState(defaultCount);

    if (total <= firstStep + stepThreshold) {
        if (shown > 0 && shown !== total) {
            setShown(total);
        }

        buttons = <>
            <button className={cx('toggle', {active: shown === 0})}
                    onClick={() => setShown(0)}>
                collapse</button>
            <button className={cx('toggle', {active: shown > 0})}
                    onClick={() => setShown(children.length)}>
                {word} {children.length}
            </button>
        </>;
    } else {
        buttons = <>
            <button className={cx('toggle', {active: shown === 0})}
                    onClick={() => setShown(0)}>
                collapse</button>
            <button className={cx('toggle', {active: shown > 0 && shown <= firstStep})}
                     onClick={() => setShown(firstStep)}>
                {word} {firstStep}
            </button>
            <button className={cx('toggle', {active: shown > firstStep})}
                    onClick={() => setShown(children.length)}>
                {word} {children.length}
            </button>
        </>
    }

    return <React.Fragment>
        <div className="two-step-list">
            {buttons}
        </div>
        <WrapperComponent className={className}>
            {children.slice(0, shown)}
        </WrapperComponent>
    </React.Fragment>;
}
