import React, { useEffect, useState } from 'react';

import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import { useSubscription, sendRequest } from '../datastore';
import {
    makeJobApplicantsKey, makeJobAdvisorsKey, makeJobTagsKey,
    setApplicantsAndAdvisors
} from './JobData';
import { LoadingJob } from './Job';
import JobApplicantsSearch from './JobApplicantsSearch';
import type { JobType } from './Types';
import withAuthorization from '../common/withAuthorization';
import withCurrentUser from '../common/withCurrentUser';
import type { FindClaimArticleType } from '../articles/Types';
import LoadingREST from '../LoadingREST';
import LoadedJobApplicantsList from './LoadedJobApplicantsList';
import { cx } from '../utils';
import { updateGlobalData } from '../datastore';


function JobApplicantsPage({ location, match: { params: { id, tab, includePreliminary }}}) {
    includePreliminary = !!includePreliminary;

    const [reqstate, setReqstate] = useState({loading: true});
    useEffect(() => {
        let cancel = false;
        setReqstate({loading: true});
        sendRequest(`/api/jobs/vacancy/${id}/applicants${includePreliminary ? '/preliminary' : ''}`)
            .then((data) => {
                if (!cancel) {
                    updateGlobalData(
                        setApplicantsAndAdvisors.bind(null, id),
                        data
                    );
                    setReqstate({loading: false, ok: true});
                }
            })
        return () => {cancel = true;};
    }, [id, includePreliminary]);

    const minSim = queryString.parse(location.search).minSim;
    const applicants = useSubscription(makeJobApplicantsKey(id));
    const advisors = useSubscription(makeJobAdvisorsKey(id));
    const tags = useSubscription(makeJobTagsKey(id));
    const [selectedApplicantsIds, setSelectedApplicantsIds] = React.useState([]);

    const openSendEmail = () => {
        const form = document.createElement('form');
        form.action = `/jobs/${id}/emails`;
        form.method = 'POST';
        form.target = '_blank';
        form.style.display = 'none';

        const applicantsIdsInput = document.createElement('input');
        applicantsIdsInput.type = 'hidden';
        applicantsIdsInput.name = 'applicantsIds';
        applicantsIdsInput.value = JSON.stringify(selectedApplicantsIds);
        form.appendChild(applicantsIdsInput);
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    };

    return <div id="job-applicants-page">
        <LoadingJob id={id}>
            {(job: JobType<FindClaimArticleType>) =>
                job ?
                    <div>
                        <DocumentTitle title={`Applicants - ${job.title} - Jobs - Prophy`}/>
                        <a href={`/jobs/${id}`}>&larr; back to vacancy </a>
                        <h2 className="hero-heading">{job.title}</h2>
                    </div>
                    : null
            }
        </LoadingJob>
        <LoadingREST what="applicants" reqstate={reqstate} doc={applicants}>
            <div className="search-bar">
                <div className={cx('search-bar-element', { 'active': tab === 'applicants' })}>
                    <Link to={`/jobs/${id}/applicants/${includePreliminary ? 'preliminary' : ''}`}>
                        By applicants
                    </Link>
                </div>
                <div className={cx('search-bar-element', { 'active': tab === 'recommenders' })}>
                    <Link to={`/jobs/${id}/recommenders/${includePreliminary ? 'preliminary' : ''}`}>
                        By recommenders
                    </Link>
                </div>
                <div className={cx('search-bar-element', { 'active': tab === 'search' })}>
                    <Link to={`/jobs/${id}/search/${includePreliminary ? 'preliminary' : ''}`}>
                        Search applicants
                    </Link>
                </div>
                <div className="search-bar-element pull-right">
                    <button className="inline-link" style={{ paddingRight: '5px' }}
                            onClick={openSendEmail}>
                        send email {selectedApplicantsIds.length ? `(${selectedApplicantsIds.length})` : ''}
                    </button>
                    |<Link to={`/referees-beam/vacancy/${id}`}>
                            find possible candidates
                    </Link>
                    |{includePreliminary ?
                        <Link to={`/jobs/${id}/${tab}${location.search}`}>show actual applicants only</Link>
                        : <Link to={`/jobs/${id}/${tab}/preliminary/${location.search}`}>show preliminary applicants</Link>
                    }
                </div>
            </div>
            {tab === 'search' ?
                <JobApplicantsSearch
                    jobId={id}
                    showPreliminaries={includePreliminary}
                    minSim={minSim}
                /> :
                <LoadedJobApplicantsList
                    applicants={applicants}
                    advisors={advisors}
                    availableTags={tags}
                    showPreliminaries={includePreliminary}
                    jobId={id}
                    location={location}
                    currentTab={tab}
                    selectedApplicantsIds={selectedApplicantsIds}
                    setSelectedApplicantsIds={setSelectedApplicantsIds}
                />}
        </LoadingREST>
    </div>;
}

export default withAuthorization(withCurrentUser(JobApplicantsPage));
